interface Props {
  image: string;
  size: number;
  borderColor?: string;
  alt?: string;
  shadow?: boolean;
  onClick?: any;
  padding?: number;
  backgroundColor?: string;
  href?: any;
}

const RoundIconBtn = ({
  image,
  size,
  borderColor = "none",
  alt = "image",
  shadow = false,
  backgroundColor = "none",
  onClick,
  padding = 0,
  href,
}: Props) => {
  let cursorType = onClick || href ? "pointer" : "auto";
  let isShadow = shadow
    ? " 0px -1px 21px -3px rgba(151, 151, 151, 0.25)"
    : "none";

  return (
    <a href={href}>
      <button
        onClick={onClick}
        className="flex justify-center items-center p-1 rounded-btn"
        style={{
          border: `1px solid ${borderColor} `,
          padding: `${padding}em`,
          boxShadow: isShadow,
          backgroundColor: backgroundColor,
        }}
      >
        <img
          src={image}
          alt={alt}
          style={{
            width: `${size}em`,
            height: `${size}em`,
            cursor: `${cursorType}`,
            objectFit: `${padding ? "contain" : "cover"}`,
          }}
        />
      </button>
    </a>
  );
};

export default RoundIconBtn;
