import { useState, useEffect , useContext} from "react";
import { SideMenu } from "../../components/HomePage/SideMenu";
import BackgroundShape from "../../components/BackgroundShape";
import IconBtn from "../../components/IconBtn";
import MonthlyProfitsCard from "../../components/ProfitsPage/DailyProfitsCard";
import { useHistory } from "react-router-dom";
import ProfileSection from "../../components/HomePage/ProfileSection";
import {
    getCompleteUserData,
    getDispatch,
    getPayments,
} from "../../utils/axiosRequest";
import ProfitsCards from "../../components/ProfitsPage/ProfitsCards";
import ModalWrapper from "../../components/ModalWrapper";
import { default as Accordion, PropsAccordion } from "../../components/Accordion";
import Preguntas from "./preguntas";
import Divider from "../../components/Divider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContextWrapper } from "../../context/AppWrapper";

const ProfitsPage = () => {
    const { userData } = useContext(ContextWrapper);
    const [user] = userData;
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [dispatchData, setDispatchData] = useState<any>(user&&user.current_dispatch_place);
    const [payData, setPayData] = useState([]);
    const [currentPay, setCurrentPay] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    
    const pintarPreguntas = (preguntas: Array<PropsAccordion>) => {
        return preguntas.map((props) => {
            return (
                <>
                    <Accordion {...props} />
                    <Divider percentage={90} spacing={0.5} />
                </>
            )
        })

    }


    let history = useHistory();
    const currentToken = localStorage.getItem("userToken");


   const getDpData= () => {
        getCompleteUserData(currentToken)
            .then((info) => {
                if (!info.error) {
                    !info.data && history.push('/telefono')
                    getDispatch(info.data.current_dispatch_place_id, currentToken).then(
                        (success) => {
                            if (success) {
                                setDispatchData(success.data);
                            } else {
                                toast.error("Error recuperar la información");
                            }
                        }
                    );
                }
            })
            .catch((error) => console.error(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const query = `
      query MyQuery {
      paymentMany(filter: {dispatch_place: "${dispatchData && dispatchData.id
        }", _operators: {createdAt: {gte: "2022-04-26T05:00:00.000+00:00"}}}, sort: CREATEDAT_DESC) {
        _id
        apply_invoice
        dispatch_place
        end_date
        fiscal_key
        fiscal_regimen
        iva
        originator_name
        packages_collected
        payment_cycle
        payment_date
        payment_status
        payment_orders
        start_date
        status
        total
        sub_total
        tracking_key
      }
    }
  `;

    useEffect(() => {
        if(!dispatchData){
            getDpData()
           return  
        }
        if (dispatchData && dispatchData.id) {
            getPayments(query)
                .then((payment) => {
                    if (!payment.data.data.paymentMany) {
                        toast.error("Error recuperar la información");
                    } else {
                        setIsLoading(false)
                        if (payment.data.data) {
                            
                            const statusPayment = payment.data.data.paymentMany.length !== 0 && payment.data.data.paymentMany[0].payment_status === 'OPEN' ? payment.data.data.paymentMany[0].total : 0
                            
                            setCurrentPay(statusPayment)
                        }
                        setPayData(payment.data.data.paymentMany);
                    }
                })
                .catch((e) => console.error(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchData]);

    return (
        <>
            <ToastContainer />
            <>
                <div className="absolute top-5 left-5">
                    <IconBtn
                        icon="../icons/arrow_gray_50.svg"
                        onClick={() => history.goBack()}
                    />
                </div>

                <BackgroundShape type="medium" position="bottom" />
                <section className="flex justify-between items-center mx-5 pt-12 pb-16">
                    <div className="text-center flex flex-col items-start ">
                        <h3 className="m-0 color-green99">Mis ganancias </h3>
                        <IconBtn icon="../icons/point_black.svg" />
                    </div>
                    <ProfileSection
                        token={currentToken}
                        titleColor="white"
                        picture={
                            "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                        }
                        open={open}
                        setOpen={setOpen}
                    />
                </section>
                <MonthlyProfitsCard pay={currentPay} />
                <div className="mt-8 payment-container w-10/12 md:w-96  mx-auto flex flex-col items-center ">
                    <div className="w-full overflow-y-auto mx-auto ">


                        {isLoading ? (
                            <div className="relative flex flex-col p-3 my-4  bold color-batman text-sm rounded-md rounded border bg-white border-gray-1700">
                                <div className="lds-dual-ring-small mx-auto overflow-hidden	" />
                            </div>
                        )
                            : (payData.length > 0 ? (
                                payData.map((profit) => (
                                    <>
                                    {console.log(profit)}
                                    <ProfitsCards
                                        key={profit}
                                        payment={profit}
                                        dispatch={dispatchData}
                                    />
                                    </>
                                ))
                            ) : (
                                <div className="w-full h-20 flex items-center rounded-md rounded border bg-white border-gray-1700 justify-center mt-10">
                                    <b className="color-gray50">Sin información para mostrar</b>
                                </div>
                            ))}
                    </div>
                    {payData.length > 0 &&
                        <small className=" color-batman">Esta información puede tardar hasta 5 minutos en actualizarse.</small>
                    }

                </div>

                <div className=" absolute left-6 bottom-3 w-6/12 flex text-start justify-start">
                    <button
                        onClick={() => setOpenModal(true)}
                        className="faqBtn"
                    >
                        Preguntas frecuentes
                    </button>
                </div>
            </>
            {open && (
                <SideMenu
                    setOpen={setOpen}
                    token={currentToken}
                    open={open}
                    picture="https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                />
            )}
            <ModalWrapper modal={openModal} setModal={setOpenModal}>
                <div className="p-4 py-10">
                    <strong className={`bold color-green99 underline my-3 text-center `}>
                        Preguntas frecuentes
                    </strong>
                    <p className="color-batman  mt-3 ">
                        En caso de dudas, sugerencias o reclamos, contactar a{" "}
                        <a className="bold color-green99" href="mailto:atencion@punto99.mx">
                            atencion@punto99.mx
                        </a>
                    </p>
                    <Divider percentage={90} spacing={0.5} />
                    {pintarPreguntas(Preguntas)}
                </div>
            </ModalWrapper>
        </>
    );
};

export default ProfitsPage;