const TermsText = () => {
  return (
    <>
      <div className="w-full text-center">
        <p className="text-center">
          <strong>
            Términos y Condiciones de Uso de la Plataforma Punto 99
            <br />
            (los “Términos y Condiciones”)
          </strong>
        </p>
        <br />
        <br />
      </div>
      <div className="terms">
        <p>
          Los presentes Términos y Condiciones han sido preparados por Godo
          Parsa, S.A.P.I. de C.V. (en lo sucesivo “99 Minutos”), los cuales
          rigen la relación de prestación de servicios entre 99 Minutos y los
          Puntos de Recolección (conjuntamente referidas como las “Partes” e
          individualmente como la “Parte”).
        </p>
        <br />
        <p>
          Al acceder, usar o registrarse para una cuenta en Punto 99, usted
          reconoce y consiente que ha leído y aceptado estos Términos y
          Condiciones en su totalidad y sin modificaciones.
        </p>
        <br />
        <p>
          Estos Términos y Condiciones constituyen un contrato vinculante entre
          las Partes con respecto al uso de Punto 99 y la relación comercial
          derivada de la misma. Además, acepta estar sujeto al Aviso de
          Privacidad de 99 Minutos (según se establece más adelante).
        </p>
        <br />
        <p>
          Tal como se utiliza en estos Términos y Condiciones, las palabras
          "usted" y "su" se refieren a usted personalmente o, si accede a Punto
          99 en nombre de una persona jurídica o entidad, esa persona jurídica o
          entidad, según corresponda.
        </p>
        <br />
        <p>
          La Plataforma de Punto 99 esta sujeta a cambios en cualquier momento.
          Por lo que, 99 Minutos se reserva el derecho de, en cualquier momento
          y cuando lo considere conveniente, realizar correcciones, adiciones,
          mejoras, actualizaciones o modificaciones a estos Términos y
          Condiciones, al contenido, presentación, información, servicios,
          áreas, bases de datos y demás elementos de la Plataforma, sin
          necesidad de aviso al Punto de Recolección ni responsabilidad alguna.
        </p>
        <br />
        <p>
          Al continuar usando la Plataforma después de dicha modificación, usted
          acepta estar sujeto a la versión más reciente de los Términos y
          Condiciones y de la Plataforma y su contenido.
        </p>
        <br />
        <p>Por lo anterior:</p>
        <br />
        <p>
          Considerando que 99 Minutos es parte de un grupo económico que cuenta
          con una plataforma bajo el dominio Punto99 y a través de la aplicación
          Punto99 (la “Plataforma”), a través de la cual ofrece un entorno
          virtual que, entre otras actividades, presta los siguientes servicios:
          (i) la contratación de Puntos de Recolección para la prestación de
          servicios de recepción y entrega de Paquetes Calificados, y (ii) la
          gestión de los pagos que Punto99 realizará a los Puntos de Recolección
          a causa de dichos servicios.
        </p>
        <br />
        <p>
          Considerando que el Punto de Recolección tiene interés en prestar
          servicios de recepción y entrega de Paquetes Calificados desde sus
          establecimientos comerciales.
        </p>
        <br />
        <p>
          Considerando que 99 Minutos tiene una amplia experiencia en la gestión
          y exploración comercial en la actividad de intermediación, la cual
          implica la recepción y entrega de Paquetes Calificados.
        </p>
        <br />
        <p>
          Por lo tanto, las Partes manifiestan su aceptación en sujetarse
          conforme a lo establecido en los presentes Términos y Condiciones,
          mismos que se regirán por las siguientes:
        </p>
        <br />
        <div className="w-full text-center">
          <p className="text-center">
            <strong>CLÁUSULAS</strong>
          </p>
          <br />
          <br />
        </div>
        <p>
          <strong>
            <u>1. Definiciones.</u>{" "}
          </strong>
          Para efecto de estos Términos y Condiciones, a menos que se indique lo
          contrario en el mismo, las palabras y expresiones establecidas tendrán
          los siguientes significados:
        </p>
        <br />
        <p>
          <strong>AGENTE AUTORIZADO:</strong> cualquier persona registrada como
          representante de un Punto de Recolección, ya sea un empleado, agente,
          contratista o subcontratista del Punto de Recolección.
        </p>
        <br />
        <p>
          <strong>DATOS DE RECIPIENTES:</strong> datos del Destinatario que
          serán informados en la Plataforma, que se generarán y enviarán a los
          Puntos de Recolección cuando se genere una Orden de Servicio, tales
          como: (i) nombre completo y (ii) CURP o RFC.
        </p>
        <br />
        <p>
          <strong>DESTINATARIO:</strong> persona física o moral a la que va
          dirigido el paquete.
        </p>
        <br />
        <p>
          <strong>DÍA LABORABLE:</strong> todos los días que no sean sábados,
          domingos, marcados como festivos conforme a ley u otro día cuando los
          bancos comerciales estén obligados o autorizados a cerrar en la
          localidad de la prestación de servicios.
        </p>
        <br />
        <p>
          <strong>ESPECIFICACIONES DE PAQUETE CALIFICADO:</strong> datos que
          deben ser reportados en la Plataforma para ser generados y enviados a
          los Puntos de Recolección cuando se genere una Orden de Servicio,
          tales como: (i) nombre completo y CURP o RFC del remitente; (ii)
          nombre completo y CURP o RFC del Destinatario; (iii) dirección de
          entrega completa, y (iv) el tiempo de entrega.
        </p>
        <br />
        <p>
          <strong>MERCANCÍAS PERMITIDAS:</strong> cualquier mercancía que no
          esté calificada como Mercancía Prohibida.
        </p>
        <br />
        <p>
          <strong>MERCANCÍAS PROHIBIDAS:</strong> se refiere a los siguientes
          bienes: (i) monedas, billetes de banco, documentos negociables,
          títulos de crédito tales como cheques, pagarés, etc.; (ii) productos
          con un valor superior a $11,300.00 M.N. (once mil trescientos pesos
          00/100 Moneda Nacional); (iii) productos ilícitos; (iv) plantas,
          personas u órganos, seres humanos o animales; (v) armas de fuego o
          municiones; (vi) materiales peligrosos, inflamables, corrosivos o
          combustibles; (vii) drogas y narcóticos; (viii) explosivos; (ix)
          joyas, metales preciosos, piedras preciosas o semipreciosas, carbones
          o diamantes industriales; (x) alimentos y perecederos en general; (xi)
          medicamentos; (xii) antigüedades, piezas de arte; (xiii) licores,
          aguardientes y vinos; (xiv) materiales obscenos, ofensivos o
          denigrantes; (xv) artículos no reemplazables o piezas únicas; y, (xvi)
          cualquier otro material, mercancía o producto cuyo transporte esté
          prohibido o restringido por las leyes o por las disposiciones
          administrativas federales o locales.
        </p>
        <br />
        <p>
          <strong>ORDEN DE SERVICIO:</strong> documento que sirve para registrar
          toda la información relacionada con las solicitudes de Servicios.
        </p>
        <br />
        <p>
          <strong>PERIODO DE SERVICIOS:</strong> cualquier solicitud de Servicio
          y entrega de bienes generados por la Plataforma que contengan el
          Servicio deseado, las Especificaciones de Paquetes Calificados y los
          Datos del Destinatario.
        </p>
        <br />
        <p>
          <strong>PERIODO DE SERVICIOS:</strong> cualquier solicitud de Servicio
          y entrega de bienes generados por la Plataforma que contengan el
          Servicio deseado, las Especificaciones de Paquetes Calificados y los
          Datos del Destinatario.
        </p>
        <br />
        <p>
          <strong>PAQUETE CALIFICADO:</strong> paquete debidamente identificado,
          sellado y empaquetado que contiene una Mercancía Permitida que debe
          ser entregada por 99 Minutos directamente o por un tercero contratado
          por ella, al Punto de Recolección a los efectos de los Servicios y no
          superior a los 50 kg (cincuenta kilogramos) además de no exceder 100
          cm (cien centímetros) de cualquiera de los lados y no superior a 200
          cm (doscientos centímetros), considerada la suma de los lados.
        </p>
        <br />
        <p>
          <strong>PUNTO DE RECOLECCIÓN:</strong> cualquier persona jurídica
          registrada en la Plataforma como proveedor de los servicios de
          recepción y entrega de los Paquetes Calificados.
        </p>
        <br />
        <p>
          <strong>RECOLECCIÓN PROPIA:</strong> a la acción por virtud de la cual
          el Punto de Recolección declara y reconoce como propios los paquetes,
          los cuales carga a través de su propia cuenta en la aplicación de
          Punto 99.
        </p>
        <br />
        <p>
          <strong>SELLER:</strong> persona física encargada de enviar el paquete
          y generar la guía correspondiente.
        </p>
        <br />
        <p>
          <strong>SERVICIOS:</strong> servicios por los cuales 99 Minutos
          contratará a los Puntos de Recolección a través de la Plataforma,
          tales como: (i) el recibimiento de Paquetes Calificados por Sellers o
          Destinatarios, y/o (ii) la disposición de estos Paquetes Calificados
          para retirada por parte de transportistas o Destinatarios, siguiendo
          las disposiciones de estos Términos y Condiciones y de la Orden de
          Servicio.
        </p>
        <br />
        <p>
          <strong>TABLA DE VALOR DE REFERENCIA:</strong> Se refiere a la tabla
          de referencia de los valores adeudados por 99 Minutos en relación con
          la contratación de los servicios de recepción y entrega, los cuales se
          enviaran de forma personalizada previo a la prestación del Servicio.
        </p>
        <br />
        <p>
          <strong>
            <u>2. Objeto.</u>{" "}
          </strong>
          El objeto de estos Términos y Condiciones consiste en acotar, regular
          y dirigir la prestación de servicios de recepción y entrega de
          Paquetes Calificados que serán realizados por los Puntos de
          Recolección, a través de sus establecimientos comerciales.
        </p>
        <br />
        <p>
          El Punto de Recolección realizará el Servicio de conformidad con los
          plazos establecidos en la Orden de Servicio y de conformidad con las
          normas y procedimientos para el uso de la Plataforma previstos en
          estos Términos y Condiciones.
        </p>
        <br />
        <p>
          <strong>
            <u>3. De las Obligaciones del Punto de Recolección.</u>{" "}
          </strong>
        </p>
        <p>
          De conformidad con los presentes Términos y Condiciones, el Punto de
          Recolección acepta y se compromete a:
        </p>
        <br />
        <p>
          (i) Adherirse y seguir todas y cada una de las disposiciones de estos
          Términos y Condiciones.
        </p>
        <br />
        <p>
          (ii) Realizar el Servicio contratado dentro del periodo previsto en la
          Orden de Servicio disponible en la Plataforma, de conformidad con la
          Orden de Servicio y estos Términos y Condiciones.
        </p>
        <br />
        <p>
          (iii) Proporcionar el Servicio con el cuidado necesario y requerido,
          con el objetivo de que el alojamiento y la integridad de los paquetes
          se encuentren en perfectas condiciones en todo momento.
        </p>
        <br />
        <p>
          (iv) Ser responsable de las acciones u omisiones de cualquiera de sus
          empleados, terceros contratados o subcontratados, en la prestación de
          los Servicios.
        </p>
        <br />
        <p>
          (v) También es responsabilidad total y absoluta del Punto de
          Recolección, en relación con cualquiera de sus empleados, agentes,
          terceros contratados o subcontratados: (a) disponer de los activos,
          equipos técnicos y operativos necesarios para llevar a cabo los
          Servicios; (b) asumir todos y cada uno de los gastos, costos, tasas,
          impuestos y contribuciones relacionados con el mantenimiento de los
          activos de equipo mencionado anteriormente y el desempeño del
          Servicio; (c) disponer y operar el equipo técnico y operativo
          necesario para acceder a la Plataforma como el modelo de dispositivo
          de telefonía celular compatible con la Plataforma, conexión a internet
          móvil, paquete de datos/ GPS, entre otros; (d) Cumplir con todas las
          obligaciones requeridas por la ley en relación con la prestación de
          los Servicios; poseer todas las autorizaciones, permisos y licencias
          necesarias para proporcionar los Servicios de acuerdo con la ley
          aplicable, y (e) mantener completamente actualizados sus datos de
          registro y a sus Agentes Autorizados, así como cualquier documento
          requerido por 99 Minutos en relación con sus Agentes Autorizados,
          quedará a la espera de la recepción de pedidos de Servicio a través de
          la Plataforma hasta la actualización de dichos datos y la recepción de
          dichos documentos. La misma documentación de no ser actualizada, se
          considerará para condicionar el pago de las gestiones vigentes, hasta
          su recepción.
        </p>
        <br />
        <p>
          (vi) Para utilizar la Plataforma y aceptar directamente una Orden de
          Servicio el Punto de Recolección debe estar debidamente registrado en
          la Plataforma. Cabe señalar que el propio registro no genera ningún
          cargo.
        </p>
        <br />
        <p>
          (vii) El Punto de Recolección, en este acto, autoriza expresamente a
          los Agentes Autorizados registrados por ella en la prestación de los
          Servicios. Por lo que, manifiesta que la aceptación de cualquier Orden
          de Servicio realizada por un Agente Autorizado se considerará
          realizada a nombre y directamente por el Punto de Recolección para
          todos los fines y propósitos de estos Términos y Condiciones.
        </p>
        <br />
        <p>
          (viii) El Punto de Recolección declara ser plenamente consciente de
          que 99 Minutos no se hace responsable en caso de cualquier problema de
          conexión, cuando finalmente el sitio web, la aplicación o la
          Plataforma están inactivos, en mantenimiento o incluso en el caso de
          que no haya ninguna Orden de Servicio. Además, el Punto de Recolección
          debe ser responsable de proporcionar medios de conexión que permitan
          la ejecución completa y satisfactoria de los Servicios y el acceso a
          la aplicación.
        </p>
        <br />
        <p>
          (ix) El Punto de Recolección se compromete a no utilizar la Plataforma
          para proporcionar ningún Servicio con respecto a cualquier Mercancía
          Prohibida o cualquier paquete que no esté clasificado como un Paquete
          Calificado.
        </p>
        <br />
        <p>
          (x) El Punto de Recolección declara ser plenamente consciente de que,
          a través de este acuerdo, 99 Minutos no proporciona ninguna garantía
          al Punto de Recolección, incluyendo, sin limitación el resultado o
          número mínimo de pedidos de Servicios enviados o realizados.
        </p>
        <br />
        <p>
          (xi) Como empresa independiente y que se adhiere a la Plataforma por
          su voluntad única y exclusiva, el Punto de Recolección declara que los
          Servicios realizados a través de estos Términos y Condiciones no son
          esenciales para el desarrollo de sus actividades económicas y que no
          existe ninguna relación jerárquica, de dependencia, subordinación o
          mano de obra entre, por un lado, el Punto de Recolección o cualquiera
          de sus Agentes Autorizados y, por otro lado, 99 Minutos. El Punto de
          Recolección puede libre y sin interferencia de 99 Minutos, realizar
          sus servicios, incluyendo otras actividades económicas distintas a los
          Servicios.
        </p>
        <br />
        <p>
          (xii) El Punto de Recolección se compromete a cumplir con todas las
          leyes fiscales aplicables, incluso respecto a la emisión de los
          documentos fiscales pertinentes, sin que 99 Minutos tenga ninguna
          responsabilidad. 99 Minutos podrá solicitar, en cualquier momento, la
          presentación de los datos de registro del Punto de Recolección, así
          como la prueba de recaudación de los impuestos aplicables a estos
          Términos y Condiciones, momento en el que el Punto de Recolección
          deberá presentarlos en un plazo máximo de 48 (cuarenta y ocho) horas.
        </p>
        <br />
        <p>
          (xiii) El Punto de Recolección podrá contratar un seguro de la
          propiedad y un seguro adicional centrado en los riesgos de su negocio.
        </p>
        <br />
        <p>
          (xiv) El Punto de Recolección será directamente responsable de todos y
          cada uno de los daños causados a 99 Minutos como resultado de una
          conducta culpable o intencional durante la prestación de Servicios,
          momento en el que 99 Minutos podrá cobrarse por los daños causados o
          deducir la cantidad correspondiente del pago que efectúa al Punto de
          Recolección, mediante previa comunicación.
        </p>
        <br />
        <p>
          (xv) El Punto de Recolección deberá acondicionar los Paquetes
          Calificados en lugares seguros y secos, y que tengan preferiblemente
          el sistema de cámara, para su adecuada conservación.
        </p>
        <br />
        <p>
          <strong>
            <u>4. De las Obligaciones de 99 Minutos. </u>{" "}
          </strong>
          De conformidad con los presentes Términos y Condiciones, 99 Minutos
          acepta y se compromete a:
        </p>
        <br />
        <p>
          (i) Ser responsable del cumplimiento de todas sus obligaciones
          fiscales que le corresponda derivadas de la relación comercial entre
          las partes generada por virtud de los presentes Términos y
          Condiciones, en los términos de las disposiciones legales aplicables.
        </p>
        <br />
        <p>
          (ii) Notificar al Punto de Recolección con al menos 48 (cuarenta y
          ocho) horas de antelación, cuando sea necesario, la prestación de
          servicios los sábados, domingos y días festivos.
        </p>
        <br />
        <p>
          <strong>
            <u>5. Del Procedimiento de Uso de la Plataforma.</u>{" "}
          </strong>
          Tras el registro del Punto de Recolección y sus Agentes Autorizados en
          la Plataforma y su aceptación por 99 Minutos a través de la
          Plataforma, se creará una cuenta en la Plataforma y su respectivo
          panel de control, accesible a través de login y contraseña al Punto de
          Recolección y a cada uno de sus Agentes Autorizados. El Punto de
          Recolección, y sus correspondientes Agentes Autorizados, se
          comprometen a no informar y a no compartir a terceros su cuenta de
          usuario y a mantener la confidencialidad de la información de inicio
          de sesión de su cuenta, siendo plenamente responsables del uso de su
          cuenta y de los datos facilitados.
        </p>
        <br />
        <p>
          El Punto de Recolección podrá, en cualquier momento y a su entera
          discreción, dar de baja de la Plataforma a cualquiera de sus Agentes
          Autorizados.
        </p>
        <br />
        <p>
          99 Minutos podrá, en cualquier momento y a su entera discreción,
          independientemente de cualquier justificación, descartar cualquier
          Punto de Recolección y/o cualquiera de sus Agentes Autorizados,
          únicamente comunicando dicha decisión por correo electrónico dentro de
          las 24 (veinticuatro) horas siguientes, y sin responsabilidad alguna.
        </p>
        <br />
        <p>
          El Paquete Calificado se considerará debidamente entregado a la
          dirección del Punto de Recolección tras la confirmación del Punto de
          Recolección realizada a través de la Plataforma en el momento de la
          entrega, observando que el Punto de Recolección sólo estará obligado a
          emitir dicha confirmación si el Paquete Calificado cumple con las
          especificaciones informadas por 99 Minutos en la Orden de Servicio.
        </p>
        <br />
        <p>
          Es responsabilidad del Punto de Recolección que sus empleados o
          Agentes Autorizados de los que se valga para brindar el Servicio
          cuenten con dispositivos electrónicos con conexión de red continua,
          descarguen y utilicen la aplicación, y scaneen/registren cada Paquete
          Calificado en el momento de su llegada y salida.
        </p>
        <br />
        <p>
          En caso de imposibilidad, incluso temporal, de acceso a la Plataforma,
          el Punto de Recolección deberá llevar a cabo la Orden de Servicio por
          otros medios puestos a disposición por 99 Minutos en la Orden de
          Servicio, tales como el teléfono y el correo electrónico, por lo que
          en ningún momento dicha circunstancia eximirá de cumplimiento al Punto
          de Recolección.
        </p>
        <br />
        <p>
          El Punto de Recolección no podrá cancelar una Orden de Servicio a
          menos que el usuario de la Plataforma o el transportista no cumpla con
          los plazos establecidos o si el Paquete Calificado o cualquiera de los
          Datos del Destinatario no cumplen con la información contenida en la
          Orden de Servicio.
        </p>
        <br />
        <p>
          En caso de retraso en la entrega del Paquete Calificado, por culpa,
          negligencia, fraude o cualquier actividad imputable al Punto de
          Recolección o a cualquiera de sus Agentes Autorizados, 99 Minutos
          podrá rescindir este acuerdo a su entera y exclusiva discreción, y sin
          responsabilidad alguna.
        </p>
        <br />
        <p>
          En caso de pérdida o daño causado al Paquete Calificado, por culpa,
          negligencia, engaño, fraude o cualquier actividad imputable al Punto
          de Recolección o a cualquiera de sus empleados, agentes, terceros
          contratados o subcontratados, el monto del paquete perdido o dañado se
          deducirá del valor mensual del Servicio o se cobrará al Punto de
          Recolección, en caso de que durante la deducción no sea suficiente
          para cubrir la cantidad adeudada, entonces se procederá con su cobro,
          esto con la finalidad de que la deuda se pague en su totalidad.
        </p>
        <br />
        <p>
          El Punto de Recolección se obliga a comunicar a 99 Minutos con la
          mayor celeridad posible y dentro de las 24 (veinticuatro) horas
          siguientes a que tuvo conocimiento, todos y cada uno de los casos de
          robo, hurto, extravío o retención de un Paquete Calificado por la
          autoridad.
        </p>
        <br />
        <p>
          En caso de ocurrido un robo o un evento de retención de Paquetes
          Calificados por una autoridad, el Punto de Recolección deberá enviar
          vía correo electrónico, inmediatamente y dentro de las 24
          (veinticuatro) horas siguientes de ocurrido el robo o la retención,
          una comunicación dirigida al correo electrónico de 99 Minutos indicado
          en estos Términos y Condiciones, misma que deberá incluir como mínimo
          la siguiente información:
        </p>
        <br />
        <p>
          (i) Número de identificación del paquete, shippment ID y tracking
          number.
        </p>
        <br />
        <p>(ii) Fecha del evento ocurrido respecto de cada paquete.</p>
        <br />
        <p>(iii) Descripción del evento reportado que afectó a cada paquete.</p>
        <br />
        <p>
          (iv) Lugar de ocurrencia del evento que afectó a cada paquete
          identificando si se trata de robo o retención.
        </p>
        <br />
        <p>
          (v) Copia de la denuncia de cada evento que afectó a cada paquete ante
          la autoridad que corresponda o del acta emitida por la autoridad.
        </p>
        <br />
        <p>
          El Punto de Recolección deberá entregar toda la documentación descrita
          anteriormente, y la omisión de alguno de dichos documentos implicará
          que el estado de dicho paquete pasará automáticamente a “extraviado”,
          debiendo el Punto de Recolección responsabilizarse por el mismo en su
          totalidad de acuerdo con lo estipulado en los presentes Términos y
          Condiciones.
        </p>
        <br />
        <p>
          En caso de incumplimiento con el envío de la comunicación señalada en
          los tiempos anteriormente establecidos, en forma adicional a cualquier
          responsabilidad que le ataña al Punto de Recolección, el Punto de
          Recolección deberá pagar a 99 Minutos una penalidad equivalente al
          Precio del Servicio respecto del paquete reportado inadecuadamente o
          fuera del plazo acordado.
        </p>
        <br />
        <p>
          Estos Términos y Condiciones pueden, a discreción exclusiva de 99
          Minutos, sufrir cambios periódicos, ya sea por razones legales o
          estratégicas exclusivas de 99 Minutos. El Punto de Recolección acepta
          y reconoce que es su única responsabilidad la verificación periódica
          de los Términos y Condiciones en la Plataforma.
        </p>
        <br />
        <p>
          99 Minutos y/o sus sociedades subsidiarias, filiales, controladas y
          controladoras son propietarias de todos los derechos de propiedad
          intelectual sobre la Plataforma incluyendo, sin limitación, los
          contenidos de las pantallas, como así también los programas, bases de
          datos, redes, archivos, etc. Que permiten al Punto de Recolección
          acceder y usar la Plataforma. 99 Minutos y sus sociedades vinculadas
          se reservan todos los derechos sobre la Plataforma y su Propiedad
          Intelectual (según este término se define más adelante) mismos que en
          ningún momento y bajo ningún supuesto se le otorgan al Punto de
          Recolección por estos Términos y Condiciones.
        </p>
        <br />
        <p>Usos no permitidos.</p>
        <br />
        <p>
          Excepto por los usos y permisos expresamente previstos en éstos
          Términos y Condiciones, el Punto de Recolección no podrá, directa o
          indirectamente, por sí o a través de terceros, usar la Plataforma o
          los productos y servicios de 99 Minutos para fines ilegales, realizar
          cualquier tipo de ingeniería inversa u obras derivadas, utilizar
          herramientas de búsqueda o de extracción de datos y contenidos de la
          Plataforma o plataformas de 99 Minutos y/o sus sociedades vinculadas
          para su reutilización y/o crear bases de datos propias que incluyan en
          todo o en parte el contenido de 99 Minutos y/o sus sociedades
          vinculadas sin expresa autorización. Está también prohibido el uso
          indebido, sin autorización y/o contrario a la normativa vigente;
          copiar, modificar, adaptar, alterar o traducir la Plataforma;
          reproducir, distribuir, conceder o permitir el uso a terceros,
          realizar explotación comercial; realizar declaraciones en nombre de 99
          Minutos; así como el uso que genere confusión o implique uso
          denigratorio y/o que le cause perjuicio, daños o pérdidas a 99 Minutos
          y/o a sus sociedades vinculadas.
        </p>
        <br />
        <p>
          99 Minutos no aplicará penalidades por incumplimientos ocasionados en
          la fallas técnicas o imposibilidad de uso de la Plataforma, siempre y
          cuando éstas no sean imputables al Punto de Recolección y/o tercero.
          Sin embargo, la falta de conexión que imposibilite al Punto de
          Recolección el uso de la Plataforma será considerada imputable al
          Punto de Recolección y no eximirá de su responsabilidad de cumplir con
          la prestación del Servicio.
        </p>
        <br />
        <p>
          <strong>
            <u>
              6. Del Procedimiento de Recibir Paquetes Calificados de Clientes
              de 99 Minutos.
            </u>{" "}
          </strong>
          En relación específicamente con los Paquetes Calificados relacionados
          con los clientes de 99 Minutos, el Punto de Recolección está obligado
          a cumplir con los siguientes procedimientos:
        </p>
        <br />
        <p>
          (i) Abrir la aplicación Punto99 en su teléfono móvil y hacer clic
          conforme dice el Manual (mismo que será proporcionado al Punto de
          Recolección al inicio de la prestación de Servicios).
        </p>
        <br />
        <p>
          (ii) Acceder al icono a través del correo electrónico/teléfono
          proporcionado a 99 Minutos y validado.
        </p>
        <br />
        <p>
          (iii) Escanear los Paquetes Calificados entregados al Punto de
          Recolección.
        </p>
        <br />
        <p>
          (iv) Confirmar la recepción de los Paquetes Calificados a través de la
          aplicación.
        </p>
        <br />
        <p>
          En caso de cualquier duda con respecto al proceso de recepción, el
          Punto de Recolección debe ponerse en contacto con los canales de
          servicio de 99 Minutos o buscar un consultor especializado.
        </p>
        <br />
        <p>
          En caso de existir paquetes escaneados que no sean entregados a la
          recolecta de 99 Minutos en un lapso no mayor a 48 (cuarenta y ocho)
          horas se cobrará una penalidad de $200.00 M.N. (doscientos pesos
          00/100 Moneda Nacional) por paquete, y además dichos paquetes pasaran
          al estatus de perdidos robados siendo imputables al Punto de
          Recolección, de tal forma que además se cobrará el costo del paquete.
        </p>
        <br />
        <p>
          Los Puntos de Recolección podrán elegir y declarar a los paquetes como
          externos o como de Recolección Propia, por lo que dicha declaración
          deberá ser efectuada a responsabilidad del Punto de Recolección y bajo
          protesta de decir verdad, en caso de que 99 Minutos determine que
          dicha declaración es falsa o inexacta, 99 Minutos se reserva el
          derecho para tomar todas aquellas acciones que considere pertinentes
          para su remediación.
        </p>
        <br />
        <p>
          Queda estrictamente prohibido a los Puntos de Recolección, sobre
          aquellos paquetes declarados como de Recolección Propia, escanearlos y
          registrarlos para contabilizarlos e incorporarlos a la operación de
          Punto 99, a través del apartado de “Recolección Externa”, ya que esto
          representa obtención de ventaja económica respecto de los precios
          previamente establecidos con 99 Minutos. En caso de que el supuesto
          anteriormente mencionado se actualice y dichas guías sean detectadas
          por el sistema de 99 Minutos, las mismas no se tomarán en cuenta para
          el pago mensual de Punto 99 y se dará una amonestación al Punto de
          Recolección por ejercer práctica indebida. Asimismo, en caso de
          reincidencias 99 Minutos se reserva el derecho de valorar, decidir y
          ejecutar la desafiliación de dicho Punto de Recolección.
        </p>
        <br />
        <p>
          Lo anterior no será aplicable, cuando el Punto de Recolección escanee
          sus paquetes de Recolección Propia dentro del apartado de
          funcionalidad específica que se desplegará en la Plataforma, mismo que
          fue creado única y exclusivamente para efectos de “Recolección
          Propia”.
        </p>
        <br />
        <p>
          <strong>
            <u>7. Delivery Cell. </u>{" "}
          </strong>
          La presente cláusula será aplicable únicamente a los Punto de
          Recolección que cuenten con el servicio activo del “Delivery Cell”.
          Por lo tanto, los Puntos de Recolección recibirán conjunto de paquetes
          (los “Sacos”), para ser repartidos por ese mismo Punto de Recolección.
        </p>
        <br />
        <p>
          El Punto de Recolección una vez recibido el Saco, contará con un plazo
          de 1 (una) hora y media para iniciar la ruta donde se repartirán los
          paquetes contenidos en el Saco. En caso contrario si el Punto de
          Recolección inicia ruta después del plazo anteriormente mencionado,
          cambiará el estatus a demorado y se le aplicará una penalización
          equivalente a $200.00 M.N. (doscientos pesos 00/100 Moneda Nacional)
          por Saco demorado.
        </p>
        <br />
        <p>
          Asimismo, en caso de que por causas directamente imputables al Punto
          de Recolección: (i) se extravíen los paquetes; (ii) no se entreguen
          los paquetes al destinatario final; o (iii) no se devuelvan los
          paquetes cuando aplique devolución, se entenderá como paquete
          extraviado y se cobrará al Punto de Recolección el valor declarado de
          la mercancía o en caso de no estar declarado su valor, se cobrará el
          total de 30 (treinta) UMAS por paquete.
        </p>
        <br />
        <p>
          <strong>
            <u>8. Del Procedimiento de Devolución de Paquetes Calificados.</u>{" "}
          </strong>
          El Punto de Recolección se compromete a seguir los siguientes
          procedimientos al recibir Paquetes Calificados relativos a las
          operaciones de devolución:
        </p>
        <br />
        <p>
          (i) Abrir la aplicación Punto99 en su teléfono móvil y hacer click en
          el icono “Retorno”.
        </p>
        <br />
        <p>
          (ii) Escanear el “Código QR” de los Paquetes Calificados devueltos o
          introduzca el número de identificación presentado por el usuario (en
          caso de ser aplicable).
        </p>
        <br />
        <p>
          (iii) Pegar la etiqueta de devolución en el paquete elegible y
          escanear el “Código QR” en la aplicación para registrar la operación
          de devolución (en caso de ser aplicable).
        </p>
        <br />
        <p>
          En caso de cualquier duda con respecto al proceso de devolución, el
          Punto de Recolección debe ponerse en contacto con los canales de
          servicio de 99 Minutos o buscar un consultor especializado.
        </p>
        <br />
        <p>
          <strong>
            <u>9. Condiciones de Pago. </u>{" "}
          </strong>
          La aceptación de la Orden de Servicio presupone el acuerdo completo
          del Punto de Recolección con respecto al importe negociado y pactado
          con 99 Minutos.
        </p>
        <br />
        <p>
          El valor por paquete podrá oscilar entre $4.50 M.N. (cuatro pesos
          50/100 Moneda Nacional) y $5.80 M.N. (cinco pesos 50/100 Moneda
          Nacional), por lo que dependerá de lo negociado y pactado entre las
          Partes, previo a la prestación de los Servicios. Asimismo, dicha
          negociación deberá en todo momento constar por escrito, incluyendo de
          manera enunciativa mas no limitativa correos electrónicos (el “Valor
          del Paquete”).
        </p>
        <br />
        <p>
          El Punto de Recolección acepta y reconoce que el Valor del Paquete
          puede, a discreción exclusiva de 99 Minutos, sufrir cambios periódicos
          por cualquier motivo, sin necesidad de aviso y sin responsabilidad
          alguna, por lo que es responsabilidad del Punto de Recolección
          verificar periódicamente con 99 Minutos el Valor del Paquete.
        </p>
        <br />
        <p>
          Asimismo, el Punto de Recolección manifiesta y acepta que de todos
          aquellos paquetes declarados como de Recolección Propia , su costo
          será de cero pesos, es decir, no generarán cargo alguno.
        </p>
        <br />
        <p>
          99 Minutos pagará al Punto de Recolección los importes de los
          Servicios efectivamente prestados cada mes hasta el 10º (décimo) Día
          Laborable inmediatamente siguiente al cierre del mes en cuestión,
          basado en la suma de los importes relacionados con los Servicios
          efectivamente prestados respecto del mes de facturación
          correspondiente. Los importes serán pagados por 99 Minutos al Punto de
          Recolección tras el depósito de fondos inmediatamente disponibles en
          la cuenta corriente del Punto de Recolección entregada a 99 Minutos y
          validada respecto al RFC y beneficiario, donde ambos coincidan.
        </p>
        <br />
        <p>
          El Punto de Recolección emitirá, una factura/CFDI de servicio a favor
          de 99 Minutos que contenga el importe total de los Servicios
          realizados en el período en cuestión. La fecha límite para el envío de
          la factura del Punto de Recolección a 99 Minutos será el día 25
          (veinticinco) de cada mes, sin excepción alguna.
        </p>
        <br />
        <p>
          El Punto de Recolección tendrá acceso a cualquier y todas las Órdenes
          de Servicio proporcionadas por ella en cualquier momento, cualquier
          incongruencia o necesidad de cambios que se hayan producido dentro del
          mes de la prestación del Servicio debe ser informado a 99 Minutos,
          contando con 2 (dos) días a partir del cierre del cálculo mensual,
          entendiéndose como fecha límite la establecida en el párrafo anterior.
          Por lo que, el silencio del Punto de Recolección conforme a lo
          anteriormente mencionado o fuera del plazo establecido, se considerará
          como consentimiento amplio y total en cuanto a la información
          contenida en la Plataforma en relación con su facturación.
        </p>
        <br />
        <p>A. Demora en la facturación de Servicios.</p> <br />
        <p>
          El Punto de Recolección podrá excepcionalmente incluir en las
          facturas, Servicios efectivamente prestados y no facturados, siempre
          que la fecha de prestación de dichos Servicios no exceda 1 mes de
          antigüedad respecto de la fecha de emisión de la factura. En caso de
          que el Punto de Recolección incluyera en una factura Servicios
          prestados con una fecha anterior a 1 mes de la fecha de emisión de la
          factura, 99 Minutos no estará obligada a pagar dicha factura ni dichos
          Servicios.
        </p>
        <br />
        <p>B. Plazos de impugnación de las facturas.</p> <br />
        <p>
          En caso de existir cualquier observación respecto de cualquiera de los
          Servicios consignados en cada factura, 99 Minutos tendrá 15 (quince)
          días corridos para presentar por escrito (incluyendo sin limitación,
          el correo electrónico como medio de comunicación) dichas observaciones
          ante el Punto de Recolección, plazo durante el cual no serán
          consideradas cuentas liquidadas.
        </p>
        <br />
        <p>
          Una vez presentadas las observaciones, las Partes tendrán un plazo de
          diez (10) días corridos para llegar a un acuerdo.
        </p>
        <br />
        <p>
          En caso de que las Partes lograran resolver las diferencias en el
          plazo mencionado y correspondiera pagar un monto menor al
          originalmente facturado, el Punto de Recolección deberá emitir la nota
          de crédito correspondiente y presentarla a 99 Minutos con un mínimo de
          5 (cinco) días de anticipación a la fecha prevista para el pago, en
          caso de no haber existido impugnación, como condición para que 99
          Minutos pueda proceder al mismo.
        </p>
        <br />
        <p>
          Sin embargo, en caso de que las Partes no lograran resolver las
          diferencias en el plazo anteriormente mencionado y correspondiera
          pagar un monto menor al originalmente facturado por el Punto de
          Recolección según las observaciones practicadas por 99 Minutos, el
          Punto de Recolección, aun sin reconocer hechos ni derechos, deberá
          emitir una nota de crédito por el monto cuestionado por 99 Minutos
          como condición para recibir el pago del monto no cuestionado.
        </p>
        <br />
        <p>
          El Punto de Recolección deberá presentar a 99 Minutos la nota de
          crédito con un mínimo de 5 (cinco) días de anticipación a la fecha
          prevista para el pago, como condición para que 99 Minutos pueda
          proceder al mismo. Dicha nota de crédito se compensará contra la
          factura del mes que hubiera sido cuestionada. Si esto no fuera posible
          por algún motivo, 99 Minutos podrá compensar la nota de crédito contra
          cualquier deuda que tenga con el Punto de Recolección. Sin perjuicio
          de ello, las Partes continuarán negociando sobre el monto en
          discrepancia. En el momento en que la discrepancia tenga una
          resolución final, las Partes emitirán los documentos que correspondan
          y harán los pagos acordados en los plazos convenidos.
        </p>
        <br />
        <p>
          El Punto de Recolección reconoce y acepta que 99 Minutos no tendrá
          obligación de pago alguna a favor del Punto de Recolección hasta que
          haya recibido las notas de crédito emitidas por éste de conformidad
          con lo establecido en el presente documento. Una vez recibidas las
          notas de crédito que correspondan, 99 Minutos tendrá 5 (cinco) días
          hábiles para efectuar los pagos que correspondan.
        </p>
        <br />
        <p>
          De no emitir el Punto de Recolección la nota de crédito antes del
          vencimiento de la condición de pago de la factura a la que debió
          adjuntarse la misma, 99 Minutos podrá compensar automáticamente y/o
          retener el pago de facturas posteriores hasta por el total del monto
          que corresponda emitir en concepto de nota de crédito, considerándose
          los pagos realizados bajo estas modalidades como pagos íntegros.
        </p>
        <p>
          Todas las notas de crédito o débito que emita el Punto de Recolección
          deberán ser acompañadas por un detalle idéntico al previsto para las
          facturas.
        </p>
        <br />
        <p>
          A los efectos del pago de las facturas, el Punto de Recolección le
          deberá informar a 99 Minutos los datos de una cuenta bancaria de su
          titularidad donde 99 Minutos le transferirá oportunamente el pago.
        </p>
        <br />
        <p>
          <strong>
            <u>10. Privacidad y Divulgación de Información.</u>{" "}
          </strong>
          En el proceso de verificación de la correcta prestación de Servicios
          por el Punto de Recolección, así como para el desempeño de sus
          actividades, 99 Minutos debe recopilar, almacenar y transmitir
          internamente para sus áreas legales, operativas, fiscales y/o poner a
          disposición datos e información facilitados por el Punto de
          Recolección en el momento del registro. Sin embargo, toda la
          información del Punto de Recolección y sus Agentes Autorizados quedará
          protegida conforma a lo establecido en la Ley Federal de Protección de
          Datos Personales en Posesión de los Particulares, su Reglamento y
          demás disposiciones aplicables en materia de privacidad (la “Ley de
          Datos”).
        </p>
        <br />
        <p>
          El Punto de Recolección y sus Agentes Autorizados son conscientes de
          que los Destinatarios pueden evaluar el Servicio prestado y el propio
          Punto de Recolección y/o sus Agentes Autorizados, y dichas
          evaluaciones y comentarios pueden ser divulgados en la Plataforma.
          Dichas evaluaciones y comentarios formarán parte de la información
          almacenada y estarán sujetas a las mismas reglas aquí establecidas con
          respecto a ellas.
        </p>
        <br />
        <p>
          99 Minutos no tiene ninguna responsabilidad directa o indirecta por
          cualquier comentario o evaluación que pueda ponerse a disposición en
          la Plataforma y, por lo tanto, el Punto de Recolección y sus Agentes
          Autorizados eximirán a 99 Minutos de cualquier reclamo, daño o pérdida
          que surja de dichos cometarios o evaluaciones y/o su divulgación según
          lo dispuesto en el presente documento.
        </p>
        <br />
        <p>
          El Punto de Recolección y sus Agentes Autorizados, en este acto,
          aceptan la cesión a 99 Minutos de los derechos de uso de su imagen
          eventualmente utilizados en campañas y eventos producidos y/o
          patrocinados por 99 Minutos, atestiguando que entienden como imagen
          cualquier forma de representación, incluyendo fotografía, así como el
          proceso audiovisual que resulta de la fijación de imágenes con o sin
          sonido, que tiene como finalidad cerrar, a través de su reproducción,
          la impresión de movimiento, independientemente de los procesos de su
          captura, el soporte utilizado inicial o posteriormente para fijarlo,
          así como los medios utilizados para su transmisión. El Punto de
          Recolección y sus Agentes Autorizados también declaran que esta
          asignación se realiza a nivel universal, total y definitivo, por un
          período indefinido y gratuito, produciendo efectos no sólo en México,
          sino también en cualquier lugar fuera de las fronteras nacionales.
        </p>
        <br />
        <p>
          El Punto de Recolección y sus Agentes Autorizados también autorizan a
          99 Minutos a utilizar la imagen transferida, en México o en el
          extranjero, sin limitación de tiempo o modo de uso, sin ninguna
          participación en el posible beneficio económico que 99 Minutos obtenga
          directa o indirectamente, y este ajuste surtirá efecto incluso en
          relación con los eventuales herederos y sucesores del Punto de
          Recolección y sus Agentes Autorizados.
        </p>
        <br />
        <p>
          <strong>
            <u>11. Propiedad Intelectual. </u>{" "}
          </strong>
          99 Minutos y/o sus sociedades relacionadas son propietarias de todos
          los derechos de propiedad intelectual sobre la Plataforma, sus sitios,
          todo su contenido, pantallas, servicios, productos, marcas, nombres
          comerciales, logos, diseños, imágenes, frases publicitarias, derechos
          de autor, dominios, programas de computación, códigos, desarrollos,
          software, bases de datos, información, tecnología, patentes y modelos
          de utilidad, diseños y modelos industriales, secretos comerciales,
          entre otros (la “Propiedad Intelectual”) y declaran que se encuentran
          protegidos por leyes nacionales e internacionales. 99 Minutos no
          otorga al Punto de Recolección ningún derecho para usar o explotar la
          Propiedad Intelectual, total o parcialmente, con excepción de las
          autorizaciones de uso conferidas expresamente al respecto en estos
          Términos y Condiciones, o las que resulten como condición necesaria
          para el cumplimiento de las obligaciones asumidas por el Punto de
          Recolección. La utilización de la Plataforma y los productos y
          servicios de 99 Minutos tampoco implica la autorización para usar la
          propiedad intelectual de terceros que pueda estar involucrada.
        </p>
        <br />
        <p>
          El Punto de Recolección que se registra en la Plataforma y está
          interesado en divulgarlo también puede recibir, a discreción única y
          exclusiva de 99 Minutos y en la cantidad determinada por ella, a su
          entera discreción, ciertos materiales, tales como adhesivos,
          bolígrafos, keyplayers y otros. El Punto de Recolección atestigua ser
          consciente de que es el único responsable del uso y conservación de
          dichos materiales, y el uso es opcional. Para efectos de lo
          establecido en el presente párrafo deberá contar con autorización
          previa y por escrita por parte de 99 Minutos para efectuar la
          actividad en comento, de lo contrario se entenderá como violación a
          los derechos de Propiedad Intelectual y 99 Minutos podrá ejercer las
          acciones que considere pertinentes para solventar.
        </p>
        <br />
        <p>
          Toda la Propiedad Intelectual relacionada con la Plataforma es
          propiedad exclusiva y de control de 99 Minutos y está sujeta a los
          derechos de propiedad intelectual previstos en la legislación
          mexicana, así como en los tratados y convenciones internacionales de
          los que México es signatario.
        </p>
        <br />
        <p>
          <strong>
            <u>12. Validez, Terminación Anticipada y Rescisión. </u>{" "}
          </strong>
          Este acuerdo entrará en vigor en la fecha del registro efectivo del
          Punto de Recolección en la Plataforma y estará en vigor por un período
          indefinido, y podrá ser rescindido: (a) por cualquiera de las Partes
          en cualquier momento, o (b) de mutuo acuerdo entre las Partes.
        </p>
        <br />
        <p>
          El Punto de Recolección que desee cancelar inmediatamente su registro
          en 99 Minutos y, por ende, cesar el uso de la Plataforma y la
          prestación de los Servicios, podrá hacerlo en cualquier momento a
          través del correo electrónico: atencion@punto99.mx, mediante envió de
          notificación con 15 (quince) días de antelación.
        </p>
        <br />
        <p>
          La terminación anticipada presentada por el Punto de Recolección dará
          lugar a la cancelación automática de la cuenta de sus respectivos
          Agentes Autorizados.
        </p>
        <br />
        <p>
          99 Minutos podrá, a su entera discreción, deshabilitar, bloquear o
          rescindir unilateralmente el acceso al Punto de Recolección y/o a
          cualquiera de sus Agentes Autorizados a la Plataforma, sin perjuicio
          de las medidas legales aplicables, y sin responsabilidad alguna, si se
          identifican: (i) cualquier violación legal y/o violación a las
          disposiciones de estos Términos y Condiciones; (ii) la práctica de
          actos fraudulentos mediante el uso de la Plataforma, ya sea directa o
          indirectamente; (iii) si el Punto de Recolección o cualquiera de sus
          Agentes Autorizados causa algún daño directo o indirecto a 99 Minutos,
          a otros Puntos de Recolección o a terceros; o (iv) si 99 Minutos lo
          considera necesario y a su entera discreción, por otros casos tales
          como: por violación de las normas legales, incompatibilidad con la
          política de la Plataforma, entre otros.
        </p>
        <br />
        <p>
          En caso de terminación, 99 Minutos seguirá siendo responsable del pago
          de los importes correspondientes de los Puntos de Recolección por
          cualquier Servicio ya contratado o realizado y aún no pagado, vencido
          o adeudado en el momento de la terminación, que permanecerán vencidos
          y se pagarán, de conformidad con lo establecido en estos Términos y
          Condiciones.
        </p>
        <br />
        <p>
          En caso de rescisión o terminación de la prestación de los Servicios
          por cualquier motivo, 99 Minutos podrá mantener indefinidamente los
          datos y el historial de las Ordenes de Servicio aceptadas por el Punto
          de Recolección, y que ya están expresamente autorizadas por el Punto
          de Recolección y sus Agentes Autorizados.
        </p>
        <br />
        <p>
          <strong>
            <u>13. Responsabilidad Laboral.</u>{" "}
          </strong>
          No se considerará que esta relación de prestación de Servicios
          establezca relación laboral alguna entre las Partes ni entre sus
          empleados y subcontratistas, quienes actuarán como partes
          independientes. Las Partes son independientes una de la otra y ninguna
          de las estipulaciones del presente instrumento serán consideradas como
          relación de agente, mandatario, patrón o empleado entre 99 Minutos y
          el Punto de Recolección.
        </p>
        <br />
        <p>
          El Punto de Recolección con su propio personal o personal
          subcontratado prestará los Servicios a 99 Minutos ya que cuenta con el
          personal necesario y calificado para llevar a cabo la prestación de
          los Servicios objeto de estos Términos y Condiciones.
        </p>
        <p>
          El Punto de Recolección en su carácter de patrón, es responsable único
          de las obligaciones que la ley establece a su cargo con dicho carácter
          y de las prestaciones con relación a sus empleados y trabajadores que
          utiliza para el desempeño de los Servicios, por lo que responderá ante
          ellos y/o ante cualquier autoridad, de cualquier reclamación sea
          individual o colectiva en el orden laboral, administrativo y/o fiscal,
          que derive de dicha relación laboral que los empleados y trabajadores
          del Punto de Recolección llegasen a presentar en contra de 99 Minutos,
          por lo tanto el Punto de Recolección deberá mantener en paz y a salvo
          a 99 Minutos de cualquier reclamación o demanda de sus empleados y/o
          trabajadores y de las autoridades que pudiesen derivar de la
          prestación de los Servicios que el Punto de Recolección se obliga a
          prestar en virtud de los presentes Términos y Condiciones y, en su
          caso, se obliga a reembolsar en forma inmediata cualquier gasto de
          carácter legal o de cualquier otra naturaleza que 99 Minutos llegase a
          erogar con motivo de la reclamación o proceso en cuestión.
        </p>
        <br />
        <p>
          Igualmente, el Punto de Recolección reconoce ser responsable del
          cumplimiento de todas las leyes, reglamentos y demás disposiciones
          fiscales, laborales o de cualquier otra índole, que sean aplicables a
          la ejecución de las obligaciones que contrae con 99 Minutos. Por lo
          tanto, el Punto de Recolección acepta cumplir, por sí mismo, por sus
          empleados, trabajadores y/o subcontratistas, todas las obligaciones
          que le impongan las disposiciones jurídicas vigentes que tengan
          relación directa o indirecta con lo estipulado en este documento.
        </p>
        <br />
        <p>
          En virtud de que entre el personal del Punto de Recolección
          involucrado en la prestación de los Servicios objeto del presente y de
          99 Minutos no existirá vinculación contractual o de ninguna
          naturaleza, pues en todo momento son y serán empleados del Punto de
          Recolección, éste se obliga a cubrir todas las prestaciones, derechos,
          impuestos, cuotas y cualquier otro concepto que corresponda frente al
          Instituto Mexicano del Seguro Social, Instituto Nacional del Fondo
          para la Vivienda de los Trabajadores, Ley de Impuesto Sobre la Renta y
          cualesquiera otras existentes o que llegaren a establecerse en el
          futuro. Las responsabilidades del Punto de Recolección en relación con
          las cuestiones laborales, demandas o reclamaciones de carácter
          laboral, incluyen todas aquellas obligaciones derivadas de la Ley
          Federal del Trabajo, Ley del Seguro Social, Ley del Instituto del
          Fondo Nacional de la Vivienda para los Trabajadores, Ley del Impuesto
          Sobre la Renta, Leyes Estatales y Federales relativas a Impuestos
          Sobre Nóminas, Leyes Sanitarias, así como cualquier otra legislación o
          reglamentación aplicable y vigente en México.
        </p>
        <br />
        <p>
          El Punto de Recolección mantendrá libre a 99 Minutos de cualquier
          reclamación o demanda intentada en contra de 99 Minutos, incluyendo
          aquellas reclamaciones o demandas relativas a la determinación de 99
          Minutos como patrón substituto intentadas por el personal del Punto de
          Recolección.
        </p>
        <br />
        <p>
          Para efectos de lo estipulado en los artículos 15 y 15 A de la Ley del
          Seguro Social y 15-A de la Ley Federal del Trabajo, la relación de
          prestación de Servicios objeto del presente documento no constituye
          una “Relación de Subcontratación “Laboral” o de “Outsourcing”, toda
          vez que el personal destinado a prestar los Servicios de estos
          Términos y Condiciones en las instalaciones del Punto de Recolección,
          estarán bajo la supervisión, responsabilidad y dirección de ésta, por
          lo que en este sentido ninguna de las Partes estará obligada a
          informar del contenido del presente documento al Instituto Mexicano
          del Seguro Social o Instituto del Fondo Nacional de la Vivienda para
          los Trabajadores.
        </p>
        <br />
        <p>
          <strong>
            <u>14. Confidencialidad. </u>{" "}
          </strong>
          Toda información técnica, administrativa, financiera, comercial o
          cualquier información de cualquier naturaleza, en cualquier forma, que
          la parte propietaria y reveladora de la información (la “Parte
          Reveladora”) marque o no, o designe de otra manera como información
          confidencial será considerada por las Partes de naturaleza
          estrictamente confidencial (la “Información Confidencial”), y se
          negará su reproducción y su divulgación en cualquier momento durante
          el período de vigencia de este acuerdo y por un período adicional de 1
          (un) año contado a partir de su terminación, por cualquier motivo.
        </p>
        <br />
        <p>
          La Parte Receptora de la Información Confidencial (la “Parte
          Receptora”) mantendrá la misma bajo los más altos estándares de
          confidencialidad, y no podrá, sin el consentimiento previo por escrito
          de la Parte Reveladora: (a) utilizar ninguna parte de la Información
          Confidencial para ningún propósito que no sea el cumplimiento de sus
          obligaciones bajo estos Términos y Condiciones; o (b) divulgar
          cualquier parte de la Información Confidencial a cualquier persona o
          entidad, excepto a sus empleados que efectivamente necesitan acceso a
          la Información Confidencial para cumplir con las disposiciones de
          estos Términos y Condiciones, mismos que estarán sujetos y obligados
          por las obligaciones de confidencialidad bajo los mismo términos del
          presente.
        </p>
        <br />
        <p>
          La Parte Receptora no será responsable de la divulgación de la
          Información Confidencial o de cualquier parte de la misma si puede
          probar que dicha Información Confidencial: (a) estaba en el dominio
          público en el momento en que fue recibida; (b) eran conocidos o eran
          propiedad de la parte Receptora en el momento de la recepción; o (c)
          era conocida por una fuente distinta de la Parte Reveladora sin
          incumplimiento de una obligación de confidencialidad.
        </p>
        <br />
        <p>
          En caso de cualquier acción o proceso legal o requisito legal para la
          divulgación de la Información Confidencial proporcionada de
          conformidad con estos Términos y Condiciones, la Parte Receptora
          notificará inmediatamente, y dentro de las 24 (veinticuatro horas)
          contadas a partir de dicho acontecimiento, a la Parte Reveladora y, a
          petición y a expensas de la Parte Reveladora, cooperará con la Parte
          Reveladora para impugnar legalmente la obligación de dicha
          divulgación.
        </p>
        <br />
        <p>
          Las Partes convienen en adoptar, todas las medidas precautorias
          posibles a afecto de evitar la revelación de la Información
          Confidencial a terceras personas.
        </p>
        <br />
        <p>
          <strong>
            <u>15. Auditar. </u>{" "}
          </strong>
          99 Minutos puede monitorear o auditar, incluso con el apoyo de un
          auditor externo, el cumplimiento por parte del Punto de Recolección de
          las disposiciones de estos Términos y Condiciones, siempre y cuando
          sea necesario. Durante dicho seguimiento o auditoría, el Punto de
          Recolección: (i) proporcionará a 99 Minutos (o a su representante
          autorizado) acceso, cuando sea razonable, a sus instalaciones y
          registros (y a los de sus partes relacionadas); y (ii) permitirá que
          99 Minutos (o su representante autorizado) entreviste a las partes
          relacionadas en el Punto de Recolección a petición de 99 Minutos. El
          Punto de Recolección aplicará todas las recomendaciones derivadas de
          dicha supervisión o auditoría en el tiempo mutuamente acordado con 99
          Minutos.
        </p>
        <br />
        <p>
          <strong>
            <u>16. Ética Empresarial.</u>{" "}
          </strong>
          El Punto de Recolección se compromete a cumplir con todas las leyes.
          normas y reglamentos de cualquier jurisdicción que estén relacionadas
          con el soborno o la corrupción (las “Leyes Anticorrupción”), y a no
          llevará a cabo ninguna conducta que pueda causar que 99 Minutos
          infrinja cualquier ley aplicable.
        </p>
        <br />
        <p>
          El Punto de Recolección declara y garantiza expresamente a 99 Minutos
          que, actualmente y en los últimos 5 (cinco) años contados a partir de
          esta fecha:
        </p>
        <br />
        <p>
          (i) No ha infringido las disposiciones de las Leyes Anticorrupción y
          cualquier legislación conexa.
        </p>
        <br />
        <p>
          (ii) No ha sido multado, condenado o relacionado de otra manera con la
          corrupción; y que no ha sido ni está siendo investigado por ninguna
          autoridad gubernamental por posible violación de las Leyes
          Anticorrupción.
        </p>
        <br />
        <p>
          El Punto de Recolección también se compromete a indemnizar a 99
          Minutos en contra de cualquier responsabilidad que resulte de
          cualquier demanda, causa, investigación o proceso judicial o
          administrativo en relación con el incumplimiento por parte del Punto
          de Recolección a las Leyes Anticorrupción. En el evento de que el
          Punto de Recolección no cumpla con lo asentado en los párrafos que
          anteceden, 99 Minutos puede terminar la relación de prestación de
          Servicios objeto del presente documento de manera inmediata y sin
          responsabilidad alguna.
        </p>
        <br />
        <p>
          El Punto de Recolección se compromete a respetar y garantizar la no
          participación de sus empleados y/o subcontratistas en la práctica de
          la violación de derechos humanos, especialmente: (i) no explotar
          ningún tipo de trabajo infantil y evitar, de ninguna manera, la
          contratación de servicios de personas físicas o jurídicas que
          practique o estén vinculadas de cualquier manera a la explotación de
          niños o adolescentes; (ii) explotar, directa o indirectamente, el
          trabajo infantil en cualquier localidad; (iii) que exploten cualquier
          forma de trabajo forzoso o esclavitud, así como contratar o adquirir,
          incluso beneficiarse de, cualquier forma de trabajo forzoso, o trabajo
          similar a un esclavo.
        </p>
        <br />
        <p>
          <strong>
            <u>17. Cláusula Penal y Multas. </u>{" "}
          </strong>
          Cualquier incumplimiento grave de las obligaciones consagradas en
          estos Términos y Condiciones por parte del Punto de Recolección, no
          subsanado cabalmente dentro de los 15 (quince) días calendario
          siguientes a la fecha en que 99 Minutos le notifique por cualquier
          medio de comunicación señalado en este documento, requiriendo el
          cumplimiento, faculta a 99 Minutos a exigir de manera automática a
          título de pena, el pago de una suma correspondiente al 15% (quince por
          ciento) del valor correspondiente a la factura mensual de la Orden de
          Servicio sin perjuicio de las demás acciones, derechos y recursos
          legales a que haya lugar. Se entiende como incumplimiento grave aquel
          que afecte la operación de 99 Minutos de manera directa o indirecta,
          entre otras y a mero título enunciativo, cualquier incumplimiento que
          imposibilite la ejecución del objeto previsto en el presente
          documento; aquellos incumplimientos relacionados con la Propiedad
          Intelectual y/o los incumplimientos en materia de protección de datos
          personales.
        </p>
        <br />
        <p>
          El Punto de Recolección autoriza expresamente a 99 Minutos, con previo
          aviso por parte de 99 Minutos, para retener o descontar los valores
          que llegare a deber por concepto de la cláusula penal y de multas de
          las sumas adeudadas a su favor por ejecución del objeto del presente
          documento. El pago de la multa no extingue la obligación principal.
        </p>
        <br />
        <p>
          <strong>
            <u>18. Datos Personales.</u>{" "}
          </strong>
          Respecto al tratamiento de datos personales, las Partes aceptan y
          declaran lo siguiente:
        </p>
        <br />
        <p>A. Tratamiento de la Información Personal.</p>
        <p>
          - “Información Personal”: se refiere a cualquier información relativa
          a una persona identificada o identificable, incluida, sin que deba
          entenderse limitada, en archivos electrónicos y archivos impresos
          tratados, directa o indirectamente, por el Punto de Recolección o sus
          subcontratistas en nombre de o por orden de 99 Minutos. Esta
          información, incluye Datos Sensibles (según definición establecida en
          esta cláusula) y puede comprender: nombre o iniciales, domicilio u
          otras direcciones físicas, número de teléfono móvil o fijo, correo
          electrónico, dirección IP, número de tarjeta de crédito, fotografías o
          cualquier otro dato o información sujeta a las Leyes de Datos.
        </p>
        <br />
        <p>
          - “Datos”: se refiere a toda Información Confidencial y/o Personal
          (definido por 99 Minutos), en posesión o tenencia (por cualquier
          título) de 99 Minutos, sus Afiliadas y/ o terceros, almacenada, o
          procesada por el Punto de Recolección, o que llegue a estar a
          disposición de éste en el marco del Servicio, tanto en su formato
          original como en otro formato en el que se halle dicha información
          revisada, rediseñada, manipulada, transformada, compilada o en el modo
          en que se hubiere generado mediante el Uso Autorizado de la misma.
        </p>
        <br />
        <p>
          - “Leyes de Protección de Datos Personales”: Se refiere a todas las
          leyes, normas, reglamentos y órdenes que guarden relación con la
          privacidad, seguridad, confidencialidad y/o integridad de los Datos
          Personales que sean de aplicación a las operaciones, los servicios o
          productos del Punto de Recolección y de 99 Minutos, incluidas, pero no
          limitadas a la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares, su Reglamento y las demás leyes y
          disposiciones aplicables en materia de privacidad.
        </p>
        <br />
        <p>
          - “Tratar, tratamiento de Datos Personales”: se refiere a cualquier
          forma de manejo de los Datos Personales llevada a cabo por cualquier
          medio, incluyendo de manera enunciativa mas no limitativa, la
          recopilación, consulta, recepción, uso, transferencia, recuperación,
          manipulación, grabación, organización, almacenamiento, mantenimiento,
          hospedaje, adaptación, alteración, posesión, revelación, divulgación
          (mediante transmisión, difusión o puesta a disposición de cualquier
          otra forma), bloqueo, supresión, destrucción, venta o concesión bajo
          licencia.
        </p>
        <br />
        <p>
          - “Uso”: se refiere al procesamiento, almacenamiento, mantenimiento,
          acceso, recuperación, manipuleo, inspección, examen, observación,
          revisión, revisación, modificación, traducción, extracción, redacción,
          compendio, resumen, ampliación, descripción, comentario, enumeración,
          compilado, recopilación, incorporación, agrupamiento, adaptación,
          rediseño, gestión, transformación, comunicación, transmisión,
          distribución, publicación, transferencia, divulgación, entrega, envío,
          disociación, asociación, clasificación, presentación, ofrecimiento,
          exhibición, difusión, presentación, eliminación supresión,
          destrucción, descarte, tratamiento u otra acción respecto a los Datos
          Personales.
        </p>
        <br />
        <p>
          - “Uso Autorizado”: se refiere al uso de los servidores y/o sistemas
          de 99 Minutos por parte del Punto de Recolección exclusivamente en
          relación con la operatoria normal necesaria para que el Punto de
          Recolección preste el Servicio y a sus efectos pertinentes, según lo
          definido en los Términos y Condiciones, excluyéndose cualquier otro
          uso.
        </p>
        <br />
        <p>
          En el supuesto que el presente documento incluya el tratamiento de
          Información Personal, la misma será utilizada por el Punto de
          Recolección por cuenta y orden de 99 Minutos. 99 Minutos será el
          responsable del tratamiento de la Información Personal y el Punto de
          Recolección el encargado del tratamiento.
        </p>
        <br />
        <p>
          En virtud de ello, el Punto de Recolección tendrá las siguientes
          obligaciones:
        </p>
        <br />
        <p>
          (i) Tratar la Información Personal exclusivamente para la realización
          del objeto del presente documento, de acuerdo con las indicaciones que
          al respecto le proporcione 99 Minutos.
        </p>
        <br />
        <p>
          (ii) Tratar la Información Personal de conformidad con las normas
          relativas a la Protección de Datos que resulten aplicables, incluyendo
          la Ley de Datos.
        </p>
        <br />
        <p>
          (iii) Implementar las medidas de seguridad técnicas, administrativas,
          legales y organizativas necesarias previo al procesamiento de la
          Información Personal, para evitar la adulteración, pérdida, consulta o
          tratamiento no autorizado de los datos, y que permitan detectar
          desviaciones, intencionales o no, ya sea que los riesgos provengan de
          la acción humana o del medio técnico utilizado verificando que no sean
          inferiores a las dispuestas por la normativa vigente, de manera tal
          que garanticen el nivel de seguridad apropiado a los riesgos que
          entraña el tratamiento y a la naturaleza de los datos que han de
          protegerse.
        </p>
        <br />
        <p>
          (iv) Guardar el más estricto deber de confidencialidad con relación a
          la Información Personal, objeto del tratamiento, la cual subsistirá
          incluso después de la terminación del presente. Abstenerse de
          comunicar, divulgar, transferir o ceder la Información Personal a
          terceros, salvo que: (i) cuente con la autorización previa, expresa y
          por escrito de 99 Minutos y/o los titulares de los datos, o (ii) la
          comunicación de los datos obedezca a una orden de autoridad competente
          con base en una ley. En este último caso, previamente a la
          transferencia o comunicación de la Información Personal, el Punto de
          Recolección deberá informarlo inmediatamente, y dentro de las 24
          (veinticuatro) horas contadas a partir de la recepción de la orden, a
          99 Minutos, para que éste último tome las medidas pertinentes.
        </p>
        <br />
        <p>
          (v) Llevar un registro pormenorizado del tratamiento de la Información
          Personal que realice por cuenta y nombre de 99 Minutos y se compromete
          a guardar el más estricto deber de confidencialidad con relación a la
          Información Personal, el cual subsistirá incluso después de la
          terminación del presente documento.
        </p>
        <br />
        <p>
          En caso de incumplimiento, 99 Minutos tendrá derecho a suspender y/o
          rescindir los Términos y Condiciones hasta que no se acredite el
          cumplimiento efectivo de esta obligación.
        </p>
        <br />
        <p>B. Personal del Punto de Recolección.</p>
        <p>
          El Punto de Recolección llevará a cabo el tratamiento de datos por
          personal calificado, autorizado y capacitado para ello, estableciendo
          niveles de acceso y claves, quienes cumplirán con el deber de
          confidencialidad y seguridad y protección de la Información Personal,
          suscribiendo convenios para tales fines. Estos Agentes Autorizados
          dependientes del Punto de Recolección deberán recibir adecuado
          entrenamiento en la materia de protección de datos personales y
          privacidad. A su vez, deberá notificar a 99 Minutos de cualquier
          cambio en el estado de los Agentes Autorizados dependientes del Punto
          de Recolección que tengan acceso a los sistemas de 99 Minutos (por
          ejemplo, jubilación, renuncia, despido, etc.) con la finalidad de
          eliminar dicho acceso. Sólo se notificará el cambio de estado, sin
          identificar las razones de este, de manera tal de que no se vulnere la
          legislación laboral aplicable.
        </p>
        <br />
        <p>C. Notificaciones.</p>
        <p>
          El Punto de Recolección deberá notificar inmediatamente a 99 Minutos
          sobre: (i) cualquier solicitud de una autoridad de aplicación
          jurídicamente vinculante que tuviere como objeto la divulgación de los
          datos personales a los que tuviera acceso a menos que la ley lo
          prohíba, como por ejemplo una prohibición de la ley penal para
          preservar la confidencialidad de una investigación; (ii) cualquier
          incidente de seguridad que afecte información de carácter personal,
          como, por ejemplo, un acceso no autorizado o accidental; y, (iii)
          cualquier solicitud o queja por parte de los titulares respecto de la
          Información Personal, debiendo abstenerse de contestarle al titular
          hasta que 99 Minutos le indique lo contrario, y en todo caso, la
          contestación deberá realizarla en la manera que le señale 99 Minutos.
          El Punto de Recolección deberá efectuar la comunicación inicial con 99
          Minutos incluyendo en dicha comunicación cualquier información que
          pudiera ser relevante para resolver la solicitud o la queja.
        </p>
        <br />
        <p>D. Solicitudes de 99 Minutos.</p>
        <p>
          El Punto de Recolección deberá resolver con prontitud y correctamente
          todas las solicitudes de 99 Minutos relacionadas con el tratamiento de
          datos personales incluyendo solicitudes de rectificación o
          actualización de la Información Personal que le realice 99 Minutos, y
          dentro de las 24 (veinticuatro) horas contadas a partir de dicha
          solicitud. Llevar un registro pormenorizado del tratamiento de la
          Información Personal que realice por cuenta y nombre de 99 Minutos.
        </p>
        <br />
        <p>E. Destrucción de la Información Personal.</p>
        <p>
          Suprimir inmediatamente la Información Personal en cualquiera de los
          siguientes casos, salvo que exista algún impedimento legal para ello:
          (i) cuando haya terminado la relación jurídica con 99 Minutos; (ii)
          por instrucciones expresas y por escrito de este último. En cualquier
          caso, previamente a la eliminación de la Información Personal, el
          Punto de Recolección deberá haber hecho entrega de la misma a 99
          Minutos. Asimismo, el Punto de Recolección utilizará un borrado seguro
          y permanente, y le proporcionará a 99 Minutos una certificación por
          escrito de que ha cumplido con el presente párrafo; dicha
          certificación estará firmada por un representante autorizado del Punto
          de Recolección.
        </p>
        <br />
        <p>F. Transferencia de datos por parte del Punto de Recolección.</p>
        <p>
          Si el cumplimiento de este acuerdo requiere que el Punto de
          Recolección transfiera a 99 Minutos Datos Personales, el Punto de
          Recolección deberá garantizar que ha obtenido el consentimiento
          apropiado para el tratamiento de los Datos Personales. En este caso,
          el Punto de Recolección se compromete a informar a los titulares de
          los datos sobre el tratamiento de sus Datos Personales por parte de 99
          Minutos, o a garantizar el anonimato de dichos Datos Personales antes
          de su transferencia a 99 Minutos.
        </p>
        <br />
        <p>
          Asimismo, las Partes se obligan a respetar todas las obligaciones que
          pudieran corresponderles como encargado del tratamiento con arreglo a
          las disposiciones de la Ley de Datos.
        </p>
        <br />
        <p>
          En cuanto a los Datos Personales que las Partes pudieren llegar a
          adquirir en términos de la relación de prestación de Servicios objeto
          del presente documento, cada Parte, según corresponda, será la única
          responsable en todo momento de cumplir con las disposiciones de la Ley
          de Datos, en el entendido de que será plena y exclusiva
          responsabilidad de la misma el incluir en los medios que sean
          necesarios, y hacer que sean aceptados incondicionalmente por sus
          titulares, los avisos de privacidad que sean necesarios
        </p>
        <br />
        <p>
          Por lo anterior, ponemos a su disposición nuestro Aviso de Privacidad
          para Proveedores, mismo que podrá consultar en la página web de:
          99minutos.com, para conocer nuestras prácticas de privacidad a
          detalle.
        </p>
        <br />
        <p>
          Por lo tanto, el Punto de Recolección en este acto se obligan a sacar
          en paz y a salvo a 99 Minutos, así como a sus sociedades relacionadas,
          de cualquier demanda, denuncia, acción o procedimiento iniciado por
          terceros o autoridades derivadas del incumplimiento a lo establecido
          en el presente apartado.
        </p>
        <br />
        <p>
          <strong>
            <u>19. Avisos y Notificaciones. </u>{" "}
          </strong>
          Las notificaciones y/o avisos que se hagan las Partes se enviarán por
          correo electrónico a las direcciones que se establecen más abajo.
          Todas las notificaciones, avisos o comunicaciones que las Partes se
          dirijan con relación a estos Términos y Condiciones se entenderán
          recibidas al día inmediato siguiente a la fecha de su entrega. Las
          Partes señalan como sus direcciones de correo electrónico para efectos
          de lo anterior, las siguientes:
        </p>
        <br />
        <p>99 minutos: atencion@punto99.mx</p>
        <p>El Remitente: el establecido y proporcionado en la Plataforma.</p>
        <br />
        <p>
          Las Partes podrán cambiar sus respectivas direcciones, siempre y
          cuando: (i) se efectúe el cambio en los datos de registro de la
          Plataforma, en el caso del Punto de Recolección, y (ii) se efectúe la
          notificación por correo electrónico al Punto de Recolección, en el
          caso de 99 Minutos.
        </p>
        <br />
        <p>
          <strong>
            <u>20. Cesión. </u>{" "}
          </strong>
          Ninguna de las Partes podrá ceder o transferir, directa o
          indirectamente, total o parcialmente, los derechos y obligaciones que
          surjan de este documento sin el consentimiento previo por escrito de
          la otra Parte, excepto por 99 Minutos, quien podrá ceder o transferir,
          directa o indirectamente, total o parcialmente, dichos derechos y
          obligaciones a cualquiera de sus sociedades subsidiarias, filiales,
          controladas o controladoras, sin necesidad de previo aviso ni
          responsabilidad alguna.
        </p>
        <br />
        <p>
          <strong>
            <u>21. Resolución de Disputas. </u>{" "}
          </strong>
          Para la interpretación, ejecución y cumplimiento de los presentes
          Términos y Condiciones, las Partes se someten expresamente a las leyes
          federales aplicables en los Estados Unidos Mexicanos y a la
          jurisdicción y competencia de los tribunales federales competentes
          ubicados en la Ciudad de México, renunciando expresamente al fuero
          que, por razón de sus domicilios, presentes o futuros, o por cualquier
          otra causa, pudiere corresponderles.
        </p>
        <br />
        <p>
          Toda controversia o diferencia generada con ocasión de los presentes
          Términos y Condiciones o su ejecución, se intentará resolver en
          primera instancia mediante arreglo directo entre las Partes. Si
          transcurridos 30 (treinta) días calendario de surgida la controversia
          y ésta no se ha resuelto, las Partes se someterán a los tribunales
          federales competentes de la Ciudad de México.
        </p>
        <br />
        <p>
          Sin perjuicio de los términos de esta cláusula, 99 Minutos se reserva
          el derecho de entablar una acción individual para buscar medidas
          cautelares o de otro tipo en un tribunal de jurisdicción competente en
          relación con la infracción de la Propiedad Intelectual.
        </p>
        <br />
        <p>
          <strong>
            <u>22. Condiciones de Uso de la Plataforma. </u>{" "}
          </strong>
          Al ingresar y/o utilizar esta Plataforma, cuyo nombre de dominio es:
          www.punto99.com propiedad de 99 Minutos, usted está aceptando los
          Términos y Condiciones contenidos en este instrumento y declara
          expresamente su aceptación utilizando para tal efecto medios
          electrónicos, en términos de lo dispuesto por el artículo 1803 del
          Código Civil Federal.
        </p>
        <br />
        <p>
          Si usted no acepta en forma absoluta y completa los Términos y
          Condiciones de este documento, deberá abstenerse de acceder, usar y
          ver la Plataforma. Sin embargo, en caso de que usted use la Plataforma
          dicha acción se considerará como su absoluta y expresa aceptación a
          los Términos y Condiciones aquí estipulados.
        </p>
        <br />
        <p>
          <strong>
            <u>23. Licencia. </u>{" "}
          </strong>
          Por virtud de la aceptación de los presentes Términos y Condiciones,
          99 Minutos otorga y concede a usted el derecho no exclusivo, revocable
          y no transferible de ver y usar la Plataforma de conformidad con los
          Términos y Condiciones que se estipulan.
        </p>
        <br />
        <p>
          Usted sólo podrá imprimir y/o copiar cualquier información contenida o
          publicada en la Plataforma exclusivamente para su uso personal, no
          comercial. Usted acuerda que todas las fotografías, obras de arte y
          catálogos de las mismas, se encuentran debidamente protegidas bajo
          derechos de autor. En caso de ser persona moral se estará a lo
          dispuesto por el artículo 148, fracción IV de la Ley Federal del
          Derecho de Autor.
        </p>
        <br />
        <p>
          La reimpresión, re-publicación, distribución, asignación, sublicencia,
          venta, reproducción electrónica o por cualquier otro medio de
          cualquier información, documento o gráfico de o que aparezca en la
          Plataforma en todo o en parte, para cualquier uso distinto al personal
          le está expresamente prohibido, a menos que 99 Minutos le haya
          otorgado su autorización previamente y por escrito.
        </p>
        <br />
        <p>
          <strong>
            <u>24.Disposiciones Generales.</u>{" "}
          </strong>
          El incumplimiento por cualquier Parte de cualquier derecho relacionado
          con estos Términos y Condiciones o la tolerancia al incumplimiento de
          las obligaciones asumidas por la otra Parte no importará en la
          novación en cuanto a sus términos y, por lo tanto, no se interpretará
          como una renuncia o retiro para ejercer cualquier derecho o para
          exigir el cumplimiento de las disposiciones de estos Términos y
          Condiciones.
        </p>
        <br />
        <p>
          Estos Términos y Condiciones revocan y reemplazan cualquier otro
          documento firmado entre las Partes y que contenga el mismo objeto.
        </p>
        <br />
        <p>
          Usted reconoce y acepta que la Plataforma puede no cumplir con sus
          requisitos o expectativas, puede que no se ejecute de manera adecuada
          o eficiente en su dispositivo, y puede no estar libre de errores o
          defectos.
        </p>
        <br />
        <p>
          99 Minutos no se hace responsable por la pérdida que pudiera surgir
          por basarse en información o materiales publicados en la Plataforma, o
          de aquel contenido que sea o pueda ser proporcionada por sus filiales
          o un tercero, incluso en relación con cualquier imprecisión u omisión
          en la Plataforma.
        </p>
        <br />
        <p>
          99 Minutos hace esfuerzos comercialmente razonables para hacer que la
          Plataforma esté disponible con la mayor cantidad de tiempo de
          actividad posible. Sin embargo, 99 Minutos no se hace responsable de
          las interrupciones, demoras o detenciones en la disponibilidad de la
          Plataforma, incluyendo aquella causada por motivo de problemas
          técnicos imputables a los sistemas de comunicación y transmisión de
          datos.
        </p>
        <br />
        <p>
          Usted acepta indemnizar y mantener a 99 Minutos y a sus propietarios,
          funcionarios, agentes, socios y empleados, indemnes ante cualquier
          pérdida, responsabilidad o reclamo, realizado por cualquier tercero
          debido a, o derivada de, su incumplimiento de estos Términos y
          Condiciones, su uso de la Plataforma y su violación a cualquier
          legislación, reglamento o disposición vigente, así como a responder
          por daños y/o perjuicios que dicho incumplimiento hubiere generado, y
          se obliga a reponer los gastos legales de honorarios de abogados,
          gastos y costas judiciales que haya erogado 99 Minutos por tal efecto.
        </p>
        <br />
        <p>
          Las Partes declaran que este acuerdo está consentido y aceptado para
          la libre conveniencia de ambas Partes, con respecto al principio de
          libre contratación.
        </p>
        <br />
        <p>
          La presentación de los Servicios de conformidad con este documento no
          establecerá ninguna relación o vínculo de carácter corporativo,
          laboral, ni ninguna asociación, agencia, consorcio, representación
          comercial o responsabilidad conjunta y solidaria entre las Partes.
        </p>
        <br />
        <p>
          Cada Parte declara y garantiza a la otra Parte que: (i) es una empresa
          mexicana debidamente constituida y válidamente existente bajo las
          leyes de los Estados Unidos Mexicanos, según corresponda; (ii) las
          obligaciones asumidas en el presente documento son válidas,
          vinculantes y exigibles para ellos de acuerdo con sus términos, y no
          se requiere autorización de ningún tercero; (iii) ni la celebración de
          este acuerdo, ni el cumplimiento de sus obligaciones en virtud de este
          documento violan o entran en conflicto con ninguna ley, ordenanza,
          regla, regulación, licencia o permiso, juicio u orden de cualquier
          juicio u otra autoridad gubernamental o reglamentaria a la que estén
          vinculados, y (iv) tienen plenos poderes y autoridad suficiente para
          celebrar este acuerdo y los demás actos establecidos en el presente y
          cumplir con las obligaciones asumidas en el presente.
        </p>
        <br />
        <p>
          Estos Términos y Condiciones obligan a las Partes y a sus respectivos
          sucesores de cualquier manera.
        </p>
        <p>
          Si alguna disposición de estos Términos y Obligaciones se vuelve nula
          o ineficaz, la validez o efectividad de las disposiciones restantes no
          se verán afectadas, permaneciendo en pleno vigor y efecto y, en tal
          caso, las Partes entablarán negociaciones de buena fe con miras a
          sustituir la disposición ineficaz por otra que, en la medida de lo
          posible y de manera razonable, logre el propósito y los efectos
          originalmente deseados.
        </p>
        <br />
        <p>
          Estos Términos y Condiciones se celebran con carácter irrevocable,
          constituyendo obligaciones legales, válidas y vinculantes, obligantes
          y en vigor en beneficio de las Partes y sus respectivos sucesores.
        </p>
        <br />
        <p>
          Tras el registro del Punto de Recolección en la Plataforma, se
          considera que las Partes están totalmente de acuerdo con los presentes
          Términos y Condiciones.
        </p>
        <br />
        <p>
          <strong>
            HE LEÍDO ESTOS TÉRMINOS Y CONDICIONES Y ACEPTO TODAS LAS
            DISPOSICIONES CONTENIDAS ANTERIORMENTE Y CUALQUIER REVISIÓN MISMA A
            CONTINUACIÓN. AL REGISTRARME EN LA PLATAFORMA DE PUNTO 99, ENTIENDO
            QUE ESTOY CREANDO UNA FIRMA DIGITAL, QUE PRETENDO QUE TENGA LA MISMA
            FUERZA Y EFECTO QUE SI HUBIERA FIRMADO MI NOMBRE MANUALMENTE.
           </strong>
        </p>
        <br />
      </div>
    </>
  );
};

export default TermsText;
