

type Props = {
    loading:boolean,
    color:string,
    numbers: number,
    changeTab:()=>void,
    title:string,
    isActive:boolean,
}

const StatusTab = ({loading, isActive, color, numbers, changeTab, title}: Props) => {

  return (
    <button
    onClick={changeTab}
    className={`flex flex-col h-20 w-26 px-2 bg-white justify-center border-2 border${
      isActive? color:'-white'
    } rounded-md shadow-lg`}
  >
    <span className={`text-sm font-semibold text${color}`}>
       {title}
    </span>
    {loading ? <div className="lds-dual-ring-small"/> : <span className="text-xl font-bold">
      {numbers}
    </span> }
  </button>
  )
}

export default StatusTab