// @ts-nocheck
import { useEffect, useState, useContext, useMemo, useCallback} from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";
import ProfileSection from "../components/HomePage/ProfileSection";
import RecolectedPackages from "../components/PackagesPage/RecolectedPackages";
import DeliveredPackages from "../components/PackagesPage/DeliveredPackages";
import ReturnedPackages from "../components/PackagesPage/ReturnedPackages";
import { ContextWrapper } from "../context/AppWrapper";
import { getNumbers, getPackages } from "../utils/requests";
import EvidenceModal from "../components/PackagesPage/EvidenceModal";
import ExternalPackageDetails from "../components/PackagesPage/ExternalPackageDetails";
import VoluntaryPackageDetails from "../components/PackagesPage/VoluntaryPackageDetails";
import StatusTab from "../components/PackagesPage/StatusTab";
import ReferenceCard from "../components/PackagesPage/ReferenceCard";
import { toast } from "react-toastify";
import useDebounce from "../utils/useDebounce";
import InfiniteScroll from 'react-infinite-scroll-component';

type ActivePackage = {
  id: number;
  dispatch_service: string;
  assignment?: any;
  deliver_to: string;
  order_status_id: string;
  created_at: string;
  client_phone:string;
  profits: number;
  evidences?: any;
  origin_email:string,
};

function PackagesPage() {
  const { userData, userToken } = useContext(ContextWrapper);
  const [user] = userData;
  const [token] = userToken;
  const [activeTab, setActiveTab] = useState(0);
  const [evidenceUrl, setEvidenceUrl] = useState('');
  const [isOpenEvidenceModal, setIsOpenEvidenceModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSiderOpen, setIsSiderOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [loadingTab, setLoadingTab] = useState(false);
  const { push } = useHistory();

  const [total, setisLast] = useState(true);
  const [refresh, setRefresh] = useState(false);


  const [query, setQuery] = useState({
    reference:'',
    type:'local',
    selfpickupType:'external,fraudulent',
    page: 1,
    nextPage:1,
  });

  const [numbers, setNumbers] = useState({
    local: 0,
    deliver: 0,
    onDeliver: 0,
    localSelf: 0,
    deliveredSelf: 0,
  });

  const [activePackage, setactivePackage] = useState<ActivePackage>({
    id: 1234567890,
    assignment: null,
    dispatch_service: "...",
    deliver_to: "...",
    order_status_id: "...",
    created_at: "...",
    profits: 15,
    client_phone:'...',
    origin_email:'...'
  });


  const setNumbersFunct= async () => {
    if(user){
      const { data, error, message } = await getNumbers(user.current_dispatch_place.id, query.selfpickupType);
      if (error)
        return toast.error(
          message ||
            "Ocurrio un error obteniendo stats, intenta de nuevo más tarde"
        );
        let stats= query.selfpickupType==='external,fraudulent'
        ? {local: data.in_dispatch_place, deliver: data.delivered, onDeliver: data.to_be_returned}
        : {localSelf: data.in_dispatch_place+data.to_be_returned, deliveredSelf: data.delivered}
        
        setNumbers(stats);

    }

    }

    useEffect(() => {
      if(user){
        setNumbersFunct();
      }
  }, [user, query.selfpickupType, query.type]);

  
  const setPackages = async (
    o,
  ) => {
    setLoading(true)
    if(user){
      const { data, message, error } = await getPackages(
        o.type,
        user,
        localStorage.getItem("userToken"),
        o.page ,
        o.reference,
        o.selfpickupType, 
        query.type==='onDeliver' && numbers.local+numbers.onDeliver
      );
      if (error) {
        setLoading(false)
        return alert(
          message ||
            "Ocurrió un error obteniendo tu lista de paquetes, porfavor intenta más tarde."
        );
      }
      // If user is refreshing, not scrolling, then you have to find new orders from page 1
      // but if you want to keep scrolling you need the last page searched
      const isNextPage= o.isTop? query.nextPage: data.orders.length<10? o.page: o.page+1

      isNextPage!==query.nextPage && setQuery({...query, nextPage:isNextPage})
      
      //si no estan en la lista, se agregan
      let newOrders= data.orders.filter(o => orderList.every(s => s.id !== o.id))

      newOrders= newOrders.length>0? newOrders: []
      setOrderList(o.isTop?[...newOrders , ...orderList ] :[...orderList, ...newOrders ])
      
      setisLast(query.type==='onDeliver'? !([...orderList, ...newOrders ].length===data.orders.length): !([...orderList, ...newOrders ].length===data.metadata.total_items))
      setLoadingTab(false)
      setLoading(false)

    }
  }
  const debounceReference=useDebounce(query.reference,1000)

  useEffect( () => {
    if (user) {
        setPackages({...query, type:query.type,  selfpickupType:query.selfpickupType, page: 1})
    }
  }, [query.type, user, query.selfpickupType,debounceReference]);
  
 
   
  useMemo( () => {
    if (user) {
        setOrderList([])
        setQuery({...query, reference:query.reference, page: 1})
    }
  }, [debounceReference]);

  const changeTab = (tab) => {
    setOrderList([])
    setIsOpen(false);
    setLoadingTab(true)

    if(!loading){
      if(query.type==='local' || query.type==='localSelf')  setActiveTab(0 )
      if(query.type==='deliver' || query.type==='deliveredSelf')setActiveTab(1 )
      if(query.type==='onDeliver') setActiveTab(2)
  
      if(tab.selfpickupType==='voluntary'&& query.type==='onDeliver'){
        setQuery({...query, type:'localSelf', selfpickupType: tab.selfpickupType, active: activeTab})
      }else{
        setQuery({...query, type: tab.type, selfpickupType:tab.selfpickupType, active: activeTab})
      }
    }

  };




  


  return (
    <>
      <div
        className={` h-screen w-screen overflow-x-hidden fixed"
        }`}
      >
        <EvidenceModal
          evidenceUrl={evidenceUrl}
          modal={isOpenEvidenceModal}
          setModal={setIsOpenEvidenceModal}
        >
          <div className="flex-col  w-full flex justify-center items-center mx-10 mt-10 mb-5">
            <strong>
              Evidencia de orden: {activePackage.external_reference}
            </strong>
            <div>
              <img src={evidenceUrl} alt='evidence'/>
            </div>
          </div>
        </EvidenceModal>
        <BackgroundShape type="small" position="bottom" />
        {query.selfpickupType==='voluntary'? (
        <VoluntaryPackageDetails 
          setIsOpen={setIsOpen}  
          activePackage={activePackage} 
          isOpen={isOpen} 
          setEvidenceUrl={setEvidenceUrl}
          setIsOpenEvidenceModal={setIsOpenEvidenceModal} 
          user={user}
          activeTab={query.type==='local' || query.type==='localSelf'}
          setOrderList={setOrderList}
          orderList={orderList}
          />
          
        ): (
        <ExternalPackageDetails 
          setIsOpen={setIsOpen} 
          activeTab={activeTab} 
          activePackage={activePackage} 
          isOpen={isOpen}
          setEvidenceUrl={setEvidenceUrl}
          setIsOpenEvidenceModal={setIsOpenEvidenceModal} 
          user={user}
          setOrderList={setOrderList}
          orderList={orderList}
          />
        )}
        <div id="back-button" className=" mt-5 ml-5">
          <div onClick={()=>push('/home')}>
            <ArrowRightIcon rotate={"90"} />
          </div>
        </div>
        <div className="px-3">
          <div id="body-pakcages">
            <div className="flex justify-between items-center mt-1 ml-4 mr-2 mb-8">
              <ProfileSection
                token={token}
                title={"Mis paquetes"}
                picture={
                  !user || user.avatar_url === "NA"
                    ? "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                    : user.avatar_url
                }
                open={isSiderOpen}
                setOpen={setIsSiderOpen}
              />
            </div>

            <div className={`flex flex-row  h-auto mt-3 w-full ${query.selfpickupType ==='voluntary'? ' justify-evenly':'justify-between'}  md:w-96 m-auto`}>
              <StatusTab 
                loading={loadingTab} 
                color="-purple-100" 
                numbers={query.selfpickupType==='voluntary'? numbers.localSelf: numbers.local} 
                isActive={query.type==='local' || query.type==='localSelf'}
                title='En mi local'
                changeTab={() => changeTab({...query, type:query.selfpickupType==='voluntary'?'localSelf':'local', active:0})
                }
                />
              <StatusTab 
                loading={loadingTab} 
                color="-green-400"
                numbers={query.selfpickupType==='voluntary'? numbers.deliveredSelf: numbers.deliver} 
                isActive={query.type==='deliver' || query.type==='deliveredSelf'}
                title='Entregados'
                changeTab={() => changeTab({...query, type:query.selfpickupType==='voluntary'?'deliveredSelf':'deliver',  active:1})
                } 
                />
              {query.selfpickupType!=='voluntary' &&(
              <StatusTab 
                loading={loadingTab} 
                color="-red-200"
                numbers={query.selfpickupType!=='voluntary' &&  numbers.onDeliver} 
                title='A devolver'
                isActive={query.type==='onDeliver'}
                changeTab={() => changeTab({...query, type:'onDeliver',  active:2})} 
                />
              )}
            </div>
              
            <div className="w-full flex flex-col h-auto items-center justify-center mt-4">
              {{
                0: <RecolectedPackages lastPackage={orderList[0]} />,
                1: <DeliveredPackages lastPackage={orderList[0]} />,
                2: <ReturnedPackages lastPackage={orderList[0]} />,
              }[activeTab] || <div>No reached</div>}
            </div>
          </div>
        </div>
        { user && user.current_dispatch_place && ( user.current_dispatch_place?.is_self_pickup=== 1 || user.current_dispatch_place?.is_self_pickup) ?
        <div className="bg-white inputOrders overflow-y-auto w-10/12 md:w-96 mx-auto mb-3 w-10/12 md:w-96">
          <button className={`tab-type py-2 w-6/12 rounded-l-md ${query.selfpickupType==='external,fraudulent'?'tab-type-unactive':'tab-type-active'}`} 
          onClick={()=>{
            changeTab({...query, selfpickupType:'voluntary'})}
            }> Paquetes propios</button>
          <button className={`tab-type py-2 w-6/12 rounded-r-md ${query.selfpickupType==='voluntary'?'tab-type-unactive':'tab-type-active'}`} 
          onClick={()=>{
            changeTab({...query, selfpickupType:'external,fraudulent'})}
            }> Paquetes externos</button>
        </div>
        :''}
        <div className="w-full flex flex-col justify-center items-center">
        <input
            className=" search_reference bg-white inputOrders  rounded-t-md overflow-y-auto w-10/12 md:w-96 mx-auto pl-4 py-2 w-10/12 md:w-96"
            onChange={(e) => {
              setQuery({
                ...query,
                reference: e.target.value.replace(
                  /[`~!@#$%^&*()_|\-=?;:'",.<>{}[\]\\/]/gi,
                  ""
                ),
              })}
            }
            style={{border:'0.15px solid var(--gray25)'}}
            value={query['reference']}
            placeholder='Buscar por n° de orden'
          />
          <div id="scrollableDiv" 
              className=" bg-white 	inputOrders overflow-y-scroll w-10/12 md:w-96 h-45v md:h-30v xl:h-25v 2xl:h-20v">
            {refresh &&<div className="w-full flex justify-center my-3"><div className="lds-dual-ring-small" /></div> }
          <InfiniteScroll
              dataLength={orderList.length} //This is important field to render the next data
              next={()=>setPackages({reference:query.reference, type:query.type,  selfpickupType:query.selfpickupType, page: query.nextPage, isTop:false})}
              hasMore={total || query.isTop}
              loader={<div className="w-full flex justify-center my-3"><div className="lds-dual-ring-small" /></div>}
              endMessage={
                <div className="color-gray50 my-5 flex flex-col" style={{ textAlign: 'center' }}>
                    {!loading ? <p>No hay más paquetes para mostrar</p>: <div className="w-full flex justify-center my-3"><div className="lds-dual-ring-small" /></div>}
                </div>
              }
              // below props only if you need pull down functionality
              refreshFunction={async ()=>{
                setRefresh(true)
                await setPackages({reference:query.reference, type:query.type,  selfpickupType:query.selfpickupType, page: 1, isTop:true})
                setRefresh(false)
              }}
              pullDownToRefresh={true}
              scrollableTarget="scrollableDiv"
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                <div className="w-full flex justify-center my-3"><b className="w-full color-gray50 my-2 mx-auto" style={{ textAlign: 'center' }}>Desliza hacia abajo para actualizar.</b></div>
              }
              releaseToRefreshContent={
                <div className="w-full flex justify-center my-3"><b className="color-gray50 my-2" style={{ textAlign: 'center' }}>Suelta para actualizar.</b></div>
              }
            >
          { orderList.length> 0&& orderList.map((_: any, idx) => {
            return (
              <ReferenceCard setIsOpen={setIsOpen} packages={_} index={idx} setactivePackage={setactivePackage} key={_.id} />
            );
          })}
         
         </InfiniteScroll>
         </div>
         </div>

        </div>
        
     
    </>
  );
}

export default PackagesPage;
