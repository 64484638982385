import {  RecolectionIcon } from "../../assets/icons";

const ToReceiveBags = () => {

  return (
    <>
      <RecolectionIcon />
      <span className="text-sm font-normal text-gray-200">
        Estos son los <span className="font-bold">sacos a recibir</span>
      </span>
      
    </>
  );
};

export default ToReceiveBags;
