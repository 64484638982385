import React from "react";
import styles from "./SpeechCloudTooltip.module.css";

interface Props {
  modal: boolean;
  children?:any;
  side?:string;
}

const SpeechCloudTooltip: React.FC<Props> = ({ children, modal, side }) => {
  const chooseSide =side==='left'? styles.left:styles.rigth
  return (
    <>

      {modal && (
    <div className="relative w-5/12">
          <div className={styles.triangle}/>
          <div className={`${styles.sliderContainer} ${chooseSide}`} >
            <div > {children}</div>
          </div>
          </div>

      )}
        </>

  );
};

export default SpeechCloudTooltip;
