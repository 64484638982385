import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../CustomButton";
interface Props {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Screen2 = ({ setIndex }: Props) => {
  let history = useHistory();

  const skipOnboarding = () => {
    localStorage.setItem("intro", "true");
    history.push("/telefono");
  };

  return (
    <div className=" w-full h-screen">
      <div className="relative w-full h-4/6 Vxxs:h-7/12">
        <div className=" upperSectionOnboarding">
          <div className="titleSection">
            <h6 className="m-0 bold color-batman">¡Sin inversión!</h6>
            <h4 className="m-0 color-white bold ">Gana en exposición</h4>
            <img
              className="icon"
              src="../icons/point_yellow.svg"
              alt="makeMoney"
            />
          </div>
          <img
            className="mainImage Vxxs:h-30"
            src="../icons/onboarding2.svg"
            alt="makeMoney"
          />
        </div>
      </div>
      <div className="relative b-0 flex flex-col items-center w-full">
        <div className="w-9/12 text-center flex flex-col items-center">
          <div>
            <span className="body3 semi-bold">Ten la posibilidad de </span>
            <span className="body3 bold">
              aumentar tu ticket promedio de ventas
            </span>
          </div>
        </div>
        <div className="w-2/12 flex justify-evenly my-3">
          <button
            className="unactiveSlider"
            onClick={() => setIndex(0)}
          ></button>
          <button className="currentSlider"></button>
          <button
            className="unactiveSlider"
            onClick={() => setIndex(2)}
          ></button>
        </div>
        <CustomButton onClick={() => setIndex(2)} text="Siguiente" type="primary" />
        <button className="jumpBtn mt-2" onClick={skipOnboarding}>
          Saltar
        </button>
      </div>
    </div>
  );
};

export default Screen2;
