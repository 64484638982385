import { RecolectionIcon } from "../../assets/icons";
import moment from 'moment'
const ReturnedPackages = ({lastPackage}: any) => {
  return (
    <>
    <div className='w-6 h-6 mt-2'>
      <RecolectionIcon />
      </div>
      <span className="text-sm font-normal text-gray-200">
        Estos son tus <span className="font-bold">paquetes a devolver</span>
      </span>
      <span className="text-sm font-light text-gray-800 mb-5 small leading-3">
      Último paquete devuelto:{lastPackage?.created_at ? moment(lastPackage?.created_at).format('DD/MM/yyyy • HH:mm') + ' hrs' : ''}
      </span>
    </>
  );
};

export default ReturnedPackages;
