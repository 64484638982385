import React, { useState} from 'react'
import { toast } from 'react-toastify'
import { CloseIcon } from '../../assets/icons'
import { updateOrder, updateOrderPayment } from '../../utils/requests'
import AgaveButton from '../AgaveButton'
import ModalWrapper from '../ModalWrapper'
import ReturnedVeloz from './ReturnedVeloz'

type Props = {
    setIsOpen:React.Dispatch<React.SetStateAction<boolean>>,
    activeTab:number,
    activePackage: any,
    isOpen:boolean,
    setIsOpenEvidenceModal:React.Dispatch<React.SetStateAction<boolean>>,
    setEvidenceUrl:React.Dispatch<React.SetStateAction<boolean>>,
    user:any,
    isActive:boolean,
    setOrderList:React.Dispatch<React.SetStateAction<any>>,
    orderList:any
}

const ExternalPackageDetails = ({setIsOpen, activeTab ,activePackage, isOpen, setIsOpenEvidenceModal, setEvidenceUrl,user, setOrderList, orderList}: Props) => {
  const [modal, setModal]=useState(false)

  
    const statusByCode: { [index: number]: any } = {
        1: "Por recibir",
        3: "En mi local",
        5: "En mi local",
        4: "Entregada",
        6: "Entregada",
        7: "Por devolver",
        10: "Cancelada",
      };
    
    
      const getColorByStatus: { [index: number]: any }={
        1: "Por recibir",
        3: "purple-100",
        5: "purple-100",
        4: "green-400",
        6: "green-400",
        7: "red-200",
        10: "red-200",
      }

      const orderAreForReturn = (order: any) => {
        let isForReturn = false;
        const permissiveOnDeliverStatus = [10, 11];
        order.logs.forEach((log: { new_status_id: number }) => {
          if (permissiveOnDeliverStatus.indexOf(log.new_status_id) !== -1) {
            isForReturn = true;
          }
        });
        return isForReturn;
      };


      const updateSelfPickupOrder =async (self_pickup_type:string, order: any, cost:number) => { 
       return await updateOrder(
          order.id,
           {
               ...order,
               self_pickup_type,
               cost
           },
         ).then((success) => {
          if (!success.data.success) {
           toast.error('Error al actualizar la orden ');
           return false
          } 
          let list=orderList
          const newList= list.filter((o:any)=> o.id !== success.data.body.id)
          setOrderList(newList);
          return true
        });
     };

     const getPaymentOrdersByInternalId = async (self_pickup_type:string, order: any, cost:number) => { 
      return await updateOrderPayment(
        order.id,
        self_pickup_type,
        cost
       ).then((success) => {
         if (!success.error) {
           return success.data
         } else {
            toast.error('Error al activar modalidad de recolección propia, favor de intentar nuevamente. ');
           return
         }
       });
   };
    


      const updateToVoluntary = async ()=>{
        const success= await updateSelfPickupOrder('voluntary',activePackage, 0)
        if(success){
          await getPaymentOrdersByInternalId('voluntary',activePackage, 0)
          setModal(false)
          setIsOpen(false)
        }else{
          toast.error('Error al actualizar el pago de la orden ');

        }
      }
    

  return (
    <>
    <div
          style={{
            transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
            height: "min-content",
            zIndex: 6,
            overflowY: 'auto',
            maxHeight: '90vh'
          }}
          className={`absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green mx-auto right-0 md:w-10/12 lg:w-5/12 ${
            isOpen ? "slide-open" : "slide-close"
          }`}
        >
          <button
            onClick={() => setIsOpen(false)}
            className="flex w-full pt-5 pr-5 justify-end"
          >
            <CloseIcon />
          </button>

          <div className="flex flex-col items-center px-10">
            {activePackage.assignment &&
              ([11, 12, 13].indexOf(activePackage.order_status_id) === -1 ||
                (activePackage.order_status_id === 3 &&
                  !orderAreForReturn(activePackage))) && (
                <ReturnedVeloz
                  driverPersonalData={activePackage.assignment.driver_info}
                  deliveryDate={
                    activePackage.assignment.eta
                      ? activePackage.assignment.eta
                      : "Sin fecha estimada de llegada"
                  }
                />
              )}
            <span className="text-white font-semibold">
              Detalle del paquete
            </span>
            <div className="flex flex-col w-full">
              <div className="flex flex-row">
                <span className="flex flex-row items-center w-1/4 text-white text-sm font-semibold">
                  Nº
                </span>
                <span className="text-white text-lg font-thin">
                  {activePackage.external_reference}
                </span>
              </div>
              <div className="flex flex-row">
                <span className="flex flex-row items-center w-1/4 text-white text-sm font-semibold">
                  Cliente
                </span>
                <span className="text-white text-lg font-thin">
                  {activePackage.deliver_to}
                </span>
              </div>
            </div>

            <div className="w-full bg-white my-5 px-3 py-1 rounded-md">
              <div className="flex flex-row w-full border-gray-1700 border-b">
                <div className="w-1/3 px-5 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-xs">
                    Estado
                  </span>
                </div>
                <div className="px-5 py-2 ">
                  <div
                    className={`px-4 py-1 rounded-md bg-opacity-15 bg-${getColorByStatus[activePackage.order_status_id]
                    }`}
                  >
                    <span
                      className={`font-semibold text-${getColorByStatus[activePackage.order_status_id]}`}
                    >
                      {statusByCode[activePackage.order_status_id]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full border-gray-1700 border-b">
                <div className="w-1/3 px-5 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-xs">
                    Tipo de orden
                  </span>
                </div>
                <div className="px-5 py-3">
                  <span className="text-gray-800 font-thin text-md ">
                    {(activePackage.order_status_id === 3 &&
                      orderAreForReturn(activePackage)) ||
                    (activePackage.order_status_id === 12 && activeTab !== 0) ||
                    (activePackage.order_status_id === 13 &&
                      activePackage.dispatch_service === "DELIVERY_TO_CORE")
                      ? "Devolucion a cliente"
                      :
                      activePackage.order_status_id === 11 ? 'Devolucion a cliente' : activePackage.order_status_id === 13 &&
                        activePackage.dispatch_service ===
                          "DELIVERY_TO_CUSTOMER"
                      ? "Entrega a cliente"
                      : activePackage.dispatch_service === "DELIVERY_TO_CORE"
                      ? "Entrega a veloz"
                      : "Entrega a cliente"}
                  </span>
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="w-1/3 px-5 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-xs">
                    Fecha
                  </span>
                </div>
                <div className="px-5 py-3">
                  <span className="text-gray-800 font-thin text-md ">
                    {activePackage.created_at.slice(0, 10)}
                  </span>
                </div>
              </div>
              </div>

              <div className="my-5 w-full">
              <span className="text-white text-lg font-semibold">
                Evidencias:
              </span>
              {activePackage.evidences?.length > 0 ? (
                activePackage.evidences?.map((evidence: { type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; img: any }) => {
                  return (
                    <div className="w-full my-3 flex flex-row justify-between items-center">
                      <span className="text-white">{evidence.type}</span>
                      <AgaveButton type="primary" text="Ver evidencia" onClick={() => {setEvidenceUrl(evidence.img); setIsOpenEvidenceModal(true)}} />
                    </div>
                  )
                })
              ): <span className="text-white ml-3">Aún no hay evidencias.</span>}
            </div>
              </div>
              {([2,3,5,11,12].includes(activePackage.order_status_id) && user?.current_dispatch_place?.is_self_pickup===1) &&
                <div className='w-full flex justify-center'>
                  <button onClick={()=>setModal(true)} className='color-white border border-white w-9/12 rounded-3xl py-2 mb-3 '><p className='bold'>Cambiar a recolección propia</p></button>
                </div>
              } 
              </div>
              <div className='fixed z-50'>
                  <ModalWrapper setModal={setModal} modal={modal}>
                    <b className='mt-10 px-6 text-center color-batman'> ¿Quieres actualizar esta orden como recolección propia?</b>
                    <div className='flex mt-5 justify-evenly w-full'>
                      <button onClick={()=>setModal(false)} className='agave-button-thin ag-button-secondary'><p className='bold'>Cancelar</p></button>
                      <button onClick={updateToVoluntary} className='agave-button-thin ag-button-primary'><p className='bold'>Continuar</p></button>
                    </div>
                </ModalWrapper>
                </div>
            </>
  )
}

export default ExternalPackageDetails