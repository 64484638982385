import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
  CheckIcon,
  CodeIcon,
  UserIcon,
} from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";

function ConfirmReturnPage() {
  const { goBack, push } = useHistory();

  const [confirm, setConfirm] = useState(false);

  return (
    <div className="h-screen w-screen overflow-y-hidden fixed">
      <BackgroundShape type={"quarter"} position="bottom" />
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          <div
            onClick={goBack}
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={"90"} />
          </div>
          <div className="flex w-full justify-center">
            <span className="text-xl text-center font-semibold text-green-100">
              Devolución del paquete
            </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot />
          </div>
          <div className="flex items-center justify-center mt-14 w-full">
            <span className="text-md text-gray-200 font-light text-center">
              Revisa que los datos coincidan
              <br />
              con el paquete
            </span>
          </div>
          {!confirm && (
            <div className="px-8 w-full mt-10">
              <div
                style={{ boxShadow: "0 10px 20px 0 rgba(128, 128, 128, 0.31)" }}
                className="w-full bg-white rounded-md"
              >
                <div className="flex flex-row items-center px-5 py-4 border-b border-gray-1800">
                  <div className="flex items-center justify-center w-1/12">
                    <UserIcon />
                  </div>
                  <span className="ml-5 font-bold text-gray-200">
                    Maria Lopez
                  </span>
                </div>
                <div className="flex flex-row items-center px-5 py-4">
                  <div className="flex items-center justify-center w-1/12">
                    <CodeIcon />
                  </div>
                  <span className="ml-5">345672345678</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col w-full items-center">
          {confirm && (
            <div className="flex flex-col items-center w-full px-10">
              <div>
                <CheckIcon />
              </div>
              <div className="font-bold text-white">
                ¡Gracias! Tu devolución es correcta
              </div>
              <div className="font-bold text-lg text-white text-center mt-5">
                Estaremos gestionando
                <br />
                La devolución de tu
                <br />
                Paquete.
              </div>
            </div>
          )}
          <div className="flex flex-col items-center w-full my-12">
            <div
              onClick={() => {
                confirm ? push("/home") : setConfirm(true);
              }}
              className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
            >
              <span className="text-lg text-green-100 font-semibold">
                {confirm ? "Finalizar devolución" : "Devolver paquete"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmReturnPage;
