import IconBtn from "../components/IconBtn";
import { useHistory } from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import TermsText from "../components/TermsText";

function LegalTerms() {
  let history = useHistory();

  return (
    <div className="h-screen w-full">
      <BackgroundShape type={"small"} position="bottom" />
      <BackgroundShape type={"small"} position="top" />
      <div className="upperSection flex flex-col">
        <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />
        </div>

        <div className=" flex flex-col items-center">
          <h4 className=" m-0 semi-bold color-white"> Términos y condiciones </h4>
          <img className="icon" src="../icons/point_black.svg" alt="map" />
        </div>
      </div>

      {/* form starts*/}
      <div className="form mx-auto mt-24 p-5">
      <TermsText/>
      </div>
      <div className="downSection"></div>
    </div>
  );
}

export default LegalTerms;
