import { useState, useMemo, useContext } from "react";
import IconBtn from "../components/IconBtn";
import { ContextWrapper } from "../context/AppWrapper";
import { useHistory } from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import AgaveButton from "../components/AgaveButton";
import { getDispatch, updateDispatch } from "../utils/axiosRequest";
import ScheduleInput from "../components/ScheduleInput";
import ScheduleFixed from "../components/ScheduleFixed";

function SchedulePage() {
  const { userData, load } = useContext(ContextWrapper);
  const [user] = userData;
  const [reload, setReload] = load;
  const [error, setError] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const timeFormat = {
    monday: { opens: "08:00", closes: "19:00", closed: false },
    tuesday: { opens: "08:00", closes: "19:00", closed: false },
    wednesday: { opens: "08:00", closes: "19:00", closed: false },
    thursday: { opens: "08:00", closes: "19:00", closed: false },
    friday: { opens: "08:00", closes: "19:00", closed: false },
    saturday: { opens: "08:00", closes: "19:00", closed: false },
    sunday: { opens: "08:00", closes: "19:00", closed: false },
    time_zone: "America/Mexico_City",
  };
  const [originalTime, setOriginalTime] = useState(timeFormat);
  const [hora, setHora] = useState(timeFormat);
  const format = {
    schedule: {},
    public_holiday: 1,
  };
  const [dispatchData, setDispatchData] = useState(format);
  const [input, setInput] = useState(format);
  let history = useHistory();
  let isOpenHoliday = input["public_holiday"] === 0 ? "Sí" : "No";



  useMemo(() => {
    const userToken = localStorage.getItem("userToken");
    console.log(user);
    if (user) {
      getDispatch(user.current_dispatch_place_id, userToken).then((success) => {
        if (success) {
          let timeParsed = JSON.parse(success.data.schedule);
          setHora(timeParsed);
          setOriginalTime(timeParsed);
          setInput({
            ...success.data,
            //send schedule as object, else 422 error
            schedule: timeParsed,
          });
          setDispatchData(success.data);
        } else {
          history.push('/home')
          setError(true);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reload]);

  const cancelEdit = () => {
    setIsEditable(false);
    setError(false);
    setHora(originalTime);
    setInput(dispatchData);
  };
  const updateUserData = () => {
    const userToken = localStorage.getItem("userToken");
    updateDispatch(
      {
        ...input,
        schedule: hora,
      },
      user.current_dispatch_place_id,
      userToken
    ).then((success) => {
      if (success) {
        setIsEditable(false);
        setError(false);
        setReload(!reload);
      } else {
        setError(true);
      }
    });
  };

  return (
    <div
      className={`h-screen w-full visible ${
        !isEditable && "overflow-y-hidden fixed"
      } `}
    >
      <BackgroundShape type={"small"} position="bottom" />
      <BackgroundShape type={"small"} position="top" />
      <div className="upperSection flex flex-col">
        <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />
        </div>

        <div className=" flex flex-col items-center">
          <h4 className="m-0 semi-bold color-white">
            {" "}
            Mis horarios de atención{" "}
          </h4>
          <img className="icon" src="../icons/point_black.svg" alt="map" />
        </div>
      </div>

      {/* form starts*/}
      <div className="form mx-auto mt-24 pt-5">
        <div className="w-full px-3 pb-6 flex flex-col items-center justify-center w-full">
          {/* <div className="w-full flex mr-1 justify-end">
            <IconBtn
              icon="../icons/icon_edit.svg"
              onClick={() => setIsEditable(true)}
            />
          </div> */}
          {/* set schedule */}
          {isEditable ? (
            <div
              className="flex w-full flex-col"
              style={{ maxWidth: "fit-content" }}
            >
              <div className="flex w-full justify-start mt-5">
                <div className="w-1/4 flex justify-center mr-3">
                  <p className="bold batman">Día</p>
                </div>
                <div className="w-7/12 flex justify-start">
                  <div className="flex justify-start w-5/12 mx-1">
                    <p className="bold batman">Apertura</p>
                  </div>
                  <div className="flex justify-start w-5/12 mx-1">
                    <p className="bold batman">Cierre</p>
                  </div>
                </div>
              </div>

              <ScheduleInput
                day={"Lunes"}
                inputDay={"monday"}
                setHora={setHora}
                //alert if empty
                required={!hora["monday"].closes || !hora["monday"].opens}
                hora={hora}
                value={hora["monday"]}
              />
              <ScheduleInput
                day={"Martes"}
                inputDay={"tuesday"}
                //alert if empty
                required={!hora["tuesday"].closes || !hora["tuesday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["tuesday"]}
              />
              <ScheduleInput
                day={"Miércoles"}
                inputDay={"wednesday"}
                //alert if empty
                required={!hora["wednesday"].closes || !hora["wednesday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["wednesday"]}
              />
              <ScheduleInput
                day={"Jueves"}
                inputDay={"thursday"}
                //alert if empty
                required={!hora["thursday"].closes || !hora["thursday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["thursday"]}
              />
              <ScheduleInput
                day={"Viernes"}
                inputDay={"friday"}
                //alert if empty
                required={!hora["friday"].closes || !hora["friday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["friday"]}
              />
              <ScheduleInput
                day={"Sábado"}
                inputDay={"saturday"}
                //alert if empty
                required={!hora["saturday"].closes || !hora["saturday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["saturday"]}
              />
              <ScheduleInput
                day={"Domingo"}
                inputDay={"sunday"}
                //alert if empty
                required={!hora["sunday"].closes || !hora["sunday"].opens}
                setHora={setHora}
                hora={hora}
                value={hora["sunday"]}
              />
            </div>
          ) : (
            <ScheduleFixed schedule={hora} />
          )}
          <div className="mt-8 w-full " style={{ maxWidth: "fit-content" }}>
            <div className="flex">
              <img
                className="icon"
                src="../icons/icon_calendar.svg"
                alt="icon"
              />
              <strong className={`bold color-batman`}>
                Atiende días feriados
              </strong>
            </div>
            {isEditable ? (
              <div className="flex mt-2 justify-start items-center flex-wrap">
                <div className="inputRadioContainer flex justify-start items-center my-1">
                  <input
                    className="inputRadio"
                    type="radio"
                    id="si"
                    name="si"
                    onChange={() => setInput({ ...input, public_holiday: 0 })}
                    checked={input["public_holiday"] === 0}
                  />

                  <label htmlFor="Sí" className="inputRadio">
                    Sí
                  </label>
                </div>
                <div className="inputRadioContainer flex justify-start items-center  my-1">
                  <input
                    className="inputRadio"
                    type="radio"
                    name="No"
                    onChange={() => setInput({ ...input, public_holiday: 1 })}
                    checked={input["public_holiday"] === 1}
                  />
                  <label htmlFor="No" className="inputRadio">
                    No
                  </label>
                </div>
              </div>
            ) : (
              <p style={{ paddingLeft: "1.8em" }}>{isOpenHoliday}</p>
            )}
          </div>
          {error && (
            <p className="w-full text-center text-red-400">
              Error al guardar la información
            </p>
          )}
          {isEditable && (
            <div className="w-full flex justify-end my-6 flex-wrap">
              <button onClick={cancelEdit} className="mr-5 my-1">
                <AgaveButton type="secondary" text="Cancelar" />
              </button>

              <button
                className="agave-button ag-button-primary mr-5 my-1"
                onClick={updateUserData}
              >
                Guardar
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="downSection"></div>
    </div>
  );
}

export default SchedulePage;
