// @ts-nocheck
import {  useState, useContext} from "react";
import { useHistory } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowRightIcon, BlackDot } from "../assets/icons";
import { getOrderInfo, saveEvidence } from "../utils/requests";
import Input from "../components/Input";
import MultipleOrdersContext from "../context/MultipleOrdersContext/context";

function ScannerDeliverPage() {
  const { goBack, push, location } = useHistory();
  const [loading, setLoading] = useState(false);
  const [smsCode, setSmsCode] = useState({text:''});
  const { setEvidenceURLDelivery } = useContext(MultipleOrdersContext)

  const scanSuccessful=()=>{
    const audio = new Audio('/Sonido2.mp3');
    audio.play()
        
    if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }
    window.navigator.vibrate(300);
  }

  const removeEvidence=()=>{
    setEvidenceURLDelivery('')
    sessionStorage.removeItem('evidenceDelivery')
  }

  const captureVideo = (video: any) => {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var canvasContext = canvas.getContext("2d");
      //@ts-ignore
      canvasContext.drawImage(video, 0, 0);
      return canvas.toDataURL("image/png");
    } catch (e) {
      console.log(e);
    }
  };



  const onScan = async (result: any, errorScan: any) => {
    if (errorScan || !result) return
    const getFrame = captureVideo(document.getElementById("qrreader"));
    setLoading(true);
    scanSuccessful()
    const { error, message, data } = await getOrderInfo(
      result.text
    );

    if (error) {
      toast.error(message || "Something went\nwrong obtaining \n order data");
      setLoading(false);
      return;
    }
    
    if (data.verification_code.replace(/\s/g,'') !== location.state.verification_code.replace(/\s/g,'')) {
      toast.error("La clave de este paquete no coincide con el qr");
      setLoading(false);
      return;
    }
    if (
      data.order_status_id !== 5 &&
      data.order_status_id !== 11 &&
      data.order_status_id !== 12
    ) {
      toast.error(message || "Something went\nwrong obtaining \n order data");
      setLoading(false);
      return;
    }

    push("/confirm-deliver", {
      orderInfo: data,
      orderNumber:  result.text
    });
    setLoading(false);

    const image = await saveEvidence({
      type: "deliver",
      orderNumber: result.text,
      image: getFrame,
    });

    if(!image.error){
      setEvidenceURLDelivery(image.data)
      sessionStorage.setItem('evidenceDelivery', image.data)
    }else if(image.error){
      goBack()
   }
    return
  };


  const onKeyUp = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      onScan(smsCode, false);
    }
  };

  return (
      <div className="h-screen w-screen gradient-green overflow-y-hidden fixed ">
        <ToastContainer />
        <div
          onClick={()=>{
            removeEvidence()
            goBack()
          }
          }
          className="px-4 py-8 xs:px-2 xs:py-4"
          id="back-button"
        >
          <ArrowRightIcon rotate={"90"} tint={"white"} />
        </div>
        <div className="h-full w-screen px-8 xs:px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="Vxxs:text-md text-center font-bold text-xl xs:text-lg text-white mb-3">
              Estás entregando un paquete
            </span>
            <div className=" Vxxs:hidden">
            <BlackDot />
            </div>
          </div>
          
          <div className="Vxxs:my-0 my-3 text-center font-thin text-medium xs:text-lg text-white ">
            <span className="Vxxs:text-sm text-center text-white">
              Escanea el código
              <span className="font-bold"> QR del paquete</span>
            </span>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="w-3/4">
              <Input
                onKeyDown={onKeyUp}
                textAlign="center"
                placeholder="Número de guía"
                onChange={(text)=>setSmsCode({text})}
                value={smsCode.text}
              />
            </div>
            {/* <div className="w-28 h-28 bg-red-100" onClick={() => onScan(smsCode, false)} /> */}
          </div>
          <div className="flex items-center justify-center w-full mt-14 Vxxs:mt-3">
          <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
          <div style={{ width: '101%', height: '101%', display: loading ? 'none' : '' }} className="absolute z-10">
            </div>
            {loading ?
            <div className={`bg-white absolute w-full h-full flex items-center justify-center  `}>
              <div className="lds-dual-ring"></div>
            </div>:
            <QrReader
              videoId={"qrreader"}
              onResult={onScan}
              containerStyle={{ width: '100%', display: 'flex', height: '100%',justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%', objectFit: 'fill'  }}
              videoContainerStyle={{ display: 'flex',padding:'0', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={100}
            />}
            </div>
          </div>

          <div className="flex w-full justify-center mt-5 Vxxs:mt-2 ">
          <span className="Vxxs:text-sm  text-md font-thin text-white ">
            Enfoca el código QR dentro del recuadro
          </span>
        </div>
        </div>
      </div>
  );
}

export default ScannerDeliverPage;
