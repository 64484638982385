import React from "react";
import PreguntasPagos from "../ProfitsPage/preguntas"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "¿Cómo funciona?": [
        {
            label: "Quiero el manual de usuario de Punto99",
            children:
                (<React.Fragment>
                    <p>
                        Para conocer el <a href="/Manual_Instructivo_de_Punto99.pdf" target="_blank" rel="noreferrer">Manual de Punto99 📓 haz click <span className="color-green99 bold">aquí</span></a>
                    </p>
                </React.Fragment>),
        },
        {
            label: "¿Cómo inicio sesión en el app?",
            children: (
                <React.Fragment>
                    <p>
                        Para poder iniciar sesión deberás haberte registrado como punto99
                        previamente con un número de celular válido.
                    </p>
                    <p>
                        <b>1- </b>Ingresa a la app e introduce el número de teléfono con el
                        que registraste tu punto99.
                    </p>
                    <p>
                        <b>2- </b>Solicita un código de verificación por medio de un SMS o Whatsapp.
                    </p>
                    <p>
                        <b>3- </b>Recibirás un código de verificación por el medio que hayas seleccionado.
                    </p>
                    <p>
                        <b>4- </b>Introduce el código de verifcación para ingresar a la app.
                    </p>
                    <br />
                    <p>
                        ¡Enhorabuena, has iniciado sesión correctamente! Ahora podrás
                        recibir y entregar paquetes. ✅
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué información puedo encontrar en la pagina “Mis paquetes“ - Paquetes externos?",
            children: (
                <React.Fragment>
                    <span style={{ textDecoration: "underline" }}> 📌 En mi local: </span>
                    Encontrarás el listado de órdenes que están en tu local, es decir, las ordenes que haz recolectado y debes entregar.
                    <span style={{ textDecoration: "underline" }}>📌 Entregados: </span>
                    Encontrarás el listado total de órdenes que haz entregado como punto99.
                    <span style={{ textDecoration: "underline" }}>📌 Por devolver: </span>
                    Encontrarás el listado de órdenes inactivas, canceladas, rechazadas por clientes, que deben ser entregadas quien los dejó en tu punto99.                </React.Fragment>
            ),
        },
        {
            label: "¿Qué información puedo encontrar en la pagina “Mis paquetes“ - Paquetes propios?",
            children: (
                <React.Fragment>
                    <span style={{ textDecoration: "underline" }}>📌 En mi local </span>
                    Encontrarás el listado de órdenes que están en tu local, es decir, las órdenes que haz recolectado y debes entregar.                    
                    <span style={{ textDecoration: "underline" }}>📌 Entregados </span>
                    Encontrarás el listado total de órdenes que haz entregado como punto99.
                    </React.Fragment>
            ),
        },
        {
            label: "¿Qué información puedo encontrar en la sección “Detalle del paquete“- Paquetes externos?",
            children: (
                <React.Fragment>
                    <p>
                    📲 En esta sección podrás encontrarás toda la información relacionada las guías de tus clientes:
                    </p>
                    <span > - Número de guía.</span>
                    <span > - Nombre del cliente.</span>
                    <span > - Estado del paquete (si este se se encuentra en tu local, si ya fue entregado o si se tiene que devolver).</span>
                    <span > - Tipo de orden (entrega a cliente, entrega a veloz o devolución a cliente).</span>
                    <span > - Evidencias del proceso de recolección y de entrega.</span>
                    <span > - Información del veloz, una vez que este haya sido asignado (nombre, forma de contacto, fecha y hora estimada en la que se acercará a tu punto99).</span>
                 
                </React.Fragment>
            ),
        },
        {
            label: '¿Qué información puedo encontrar en la sección "Detalle del paquete" - Paquetes propios?',
            children: (
                <React.Fragment>
                    <p>
                    📲 Aquí encontrarás toda la información relacionada con las guías generadas por ti:
                    </p>
                    <span > - Número de guía.</span>
                    <span > - Nombre del cliente.</span>
                    <span > - Estado del paquete (si este se se encuentra en tu local, si ya fue entregado o si se tiene que devolver).</span>
                    <span > - Tipo de orden (entrega a cliente, entrega a veloz o devolución a cliente).</span>
                    <span > - Evidencias del proceso de recolección y de entrega.</span>
                    <span > - Información del veloz, una vez que este haya sido asignado (nombre, forma de contacto, fecha y hora estimada en la que se acercará a tu punto99).</span>
                <br/>
                   <p>
                   Para los paquete propios, encontrarás el nombre del cliente destinatario, así como métodos para contactarlo. También podrás ver el estado del paquete, es decir si este se se encuentra en tu local o entregado. Adicional puedes encontrar el historial de estados con su evidencia. Y lo mejor de todo, podrás encontrar un botón para rastrear tu paquete desde el live tracking de 99minutos.
                   </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Dónde y cómo puedo buscar una guía?",
            children: (
                <React.Fragment>
                    <p>
                    📲 En la sección “Mis paquetes” hay un buscador donde, por número de guía, puedes buscar tanto tus “paquetes externos” como tus “paquetes propios”.
                    </p>
                </React.Fragment>
            ),
        }
    ],
    "¿Cómo funciona? - Recolecciones": [
        {
            label: "Quiero el manual de recoleccion de paquetes propios y externos de Punto99  ",
            children: (
                <React.Fragment>
                    <p>
                    Para conocer el manual de recolección de paquetes propios y externos de Punto99 📓 haz click <a className="color-green99 bold" href="/auto-recoleccion-manual.pdf">aquí</a>.                    
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué es una recolección de paquetes externos? ",
            children: (
                <React.Fragment>
                    <p>
                    Cuando recolectas un paquete, a través de la plataforma, que una persona deja en tu tienda y luego entregas a un veloz (repartidor) para que lo lleve a su punto de destino. 🚚
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué es una recolección de paquete propio? ",
            children: (
                <React.Fragment>
                    <p>
                    Cuando recolectas un paquete que pertenece a una guía generada por ti (un producto tuyo) para enviarla a través del servicio de 99minutos. 📦   
                    ❗ También se consideran “paquetes propios” las guías que generas en Shopee, HoundExpress, SkyDropX, entre otros.   
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Cómo recolectar una orden?",
            children: (
                <React.Fragment>
                    <p>
                        <b>1- </b>Seleccionar{" "}
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/icon_recoleccion.svg"
                            alt="icon recolección"
                        />{" "}
                        en la página de inicio para ingresar a la sección de "Recolección".
                    </p>
                    <p>
                        <b>2- </b>
                        Para continuar el proceso tendrás dos opciones:
                    </p>
                    <li className="ml-2">
                        {"  "}📌 Escanear el código QR que se encuentra en el paquete que
                        estés recolectando.{" "}
                    </li>
                    <li className="ml-2">
                        {"  "}📌 Ingresar el número de guía de la orden.{" "}
                    </li>

                    <p>
                        <b>3- </b>Verifica que la información del nombre del remitente y el
                        número de guía sea correcta. De ser así, deberás seleccionar la
                        opción "Recolectar paquete".
                    </p>
                    <p>
                        <b>4- </b>Si la recolección fue exitosa, recibirás una notificación
                        confirmando que el paquete fue recolectado.
                    </p>
                    <p>
                        <br />
                        ¡Enhorabuena, has completado el proceso de recolección! Ahora podrás
                        ver esta orden en la página "Mis paquetes"
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        , en la sección de "En mi local". ✅
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué debo hacer si tengo más de un paquete para recolectar?",
            children: (
                <React.Fragment>
                    <p>
                    Una vez que hayas escaneado el QR o ingresado el número de guía del paquete 1, te aparecerá la opción “Quiero recolectar más paquetes”. Da clic en el botón y podrás recolectar el siguiente. 📲
Puedes hacer esto las veces que sea necesario hasta que hayas recolectado todos los paquetes. Y cuando termines deberás dar clic en “Finalizar”.
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "Si en el futuro quiero empezar a hacer recolecciones de mis paquetes, ¿lo puedo hacer?",
            children: (
                <React.Fragment>
                    <p>
                    Si, claro, puedes empezar cuando lo desees. Solo tienes que seleccionar la opción “Paquetes propios” cuando ingresas a “Recolecciones”. De esta forma el sistema nos avisará y quedará asentado. ✅
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué sucede si me confundo y recolecto un paquete externo como propio, o viceversa?",
            children: (
                <React.Fragment>
                    <p>
                    Puedes comunicarte a atencion@punto99.mx para dar aviso detallando: n° de guía, fecha de escaneo del paquete y un número telefónico al cual poder comunicarnos en caso de que sea necesario. 📨
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Cómo puedo saber quién y cuándo va a pasar por la orden?",
            children: (
                <React.Fragment>
                    <p>
                        En la página de “Mis paquetes“
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        podrás ver las órdenes que ya has recolectado, las cuales deberán
                        ser entregadas a un cliente o a un veloz. Para distinguirlos deberás
                        fijarte en lo siguiente:
                    </p>
                    <li>
                        <span style={{ textDecoration: "underline" }}>
                        📌 Entrega a veloz:
                        </span>
                        {" "}Al seleccionar una orden, podrás ver que el tipo de orden es
                        “entrega a veloz“ y la información del veloz asignado y la fecha y
                        hora en la cual se acercará a tu local a recolectar los paquetes.
                    </li>
                    <li>
                        <span style={{ textDecoration: "underline" }}>
                        📌 Entrega a cliente:
                        </span>
                        {" "}Al seleccionar una orden, podrás ver que el tipo de orden es “entrega a cliente“ o “devolución a cliente“.
                    </li>
                </React.Fragment>
            ),
        },
        {
            label: "¿99minutos también pasará a recolectar mis paquetes propios?",
            children: (
                <React.Fragment>
                    <p>
                    Si, estos paquetes también están incluidos en el servicio de envíos de 99minutos, y serán recolectados y entregados normalmente. 🚚
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Cómo entregar una orden a un veloz?",
            children: (
                <React.Fragment>
                    <p>
                        En la página de "Mis paquetes"{" "}
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        , en la sección "En mi local", podrás ver el listado de órdenes por
                        entregar a veloz. Tendrán la etiqueta "Entrega a veloz" en tipo de orden. 
                    </p>
                    <p>
                        <b>1- </b>Selecciona una orden. Asegúrate que el tipo de orden sea
                        “entrega a veloz“.
                    </p>
                    <p>
                        <b>2- </b>Deberás entregarle las órdenes y él se encargará de
                        escanearlos. Asegúrate que el veloz la escanee.
                    </p>

                    <br />
                    <p>
                        Ahora podrás ver esta orden en la página "Mis paquetes"
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        , en la sección de "Entregadas".
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Cómo entregar una orden a un cliente?",
            children: (
                <React.Fragment>
                    <p>
                    Para identificar las ordenes a entregar a clientes puedes entrar a la página de ‘Mis paquetes', en la sección 'En mi local'.  Tendrán la etiqueta 'Entrega a cliente' o 'Devolución a originador' en tipo de orden. 📲
                    </p>
                    <p>
                        <br />
                        Existen dos formas de entregar un paquete a un cliente:
                    </p>
                    <b>📌 Entrega con código QR o código de verificación</b>
                    <p>
                        <b>1- </b>Seleccionar el ícono de entrega{" "}
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/icon_entrega.svg"
                            alt="icon recolección"
                        />{" "}
                        en la página de inicio para ingresar a la sección de "Entrega".
                    </p>
                    <p>
                        <b>2- </b>Esto automáticamente activará la cámara de tu dispositivo para continuar la validación de entrega, en la cuál tendremos dos opciones diferentes:
                    </p>
                    <li className="ml-2">
                        {"  "} Escaneando el código QR que el cliente deberá de compartir contigo .{" "}
                    </li>
                    <li className="ml-2">
                        {"  "} Ingresando el código de verificación que el cliente deberá de compartir contigo.{" "}
                    </li>
                    <p>
                        <b>3- </b>A continuación podrás ver el nombre del cliente y el
                        número de guía del paquete. Usa el número de guía para identificar
                        el paquete a entregar.
                    </p>
                    <p>
                        <b>4- </b>Selecciona "Escanear paquete" o ingresa el número de guía.
                    </p>
                    <p>
                        <b>5- Si el paquete coincide: </b>Si el paquete a entregar coincide con el paquete del cliente, llegarás a una pantalla para confirmar la entrega o rechazar el paquete.
                    </p>
                    <li className="ml-2">
                        <span style={{ textDecoration: "underline" }}>
                            Entregar paquete:
                        </span>
                        Al seleccionar "Entregar paquete" recibirás una notificación de
                        entrega exitosa y podrás ver esta orden en la página "Mis paquetes"
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        , en la sección de "Entregadas".
                    </li>
                    <li className="ml-2">
                        <span style={{ textDecoration: "underline" }}>
                            Rechazar el paquete:
                        </span>
                        Si el cliente rechaza el paquete, ver sección de “¿Qué hago si
                        inicié el proceso de entrega pero un cliente rechazó el paquete?“
                    </li>

                    <p>
                        <b>5- Si el paquete NO coincide: </b> ¡Atención! Si el paquete no
                        coincide recibirás una notificación informando que no hay
                        coincidencia entre cliente y paquete, y no deberás proceder con la
                        entrega del paquete.
                    </p>
                    <br />

                    <b>📌 Entrega con número de guía</b>
                    <p>
                        <b>1- </b>Seleccionar {" "}
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/icon_entrega.svg"
                            alt="icon recolección"
                        />{" "}
                        en la página de inicio para ingresar a la sección de "Entrega".
                    </p>
                    <p>
                        <b>2- </b>Seleccionar la opción "Entregar con número de guía".
                    </p>
                    <p>
                        <b>3- </b>A continuación aparecerá un recuadro en donde deberás ingresar el número de guía. Para continuar,
                        selecciona "entregar con número de guía".
                    </p>
                    <p>
                        <b>4- </b>En caso de ser exitoso, verás una pantalla con la información del cliente. ¡Atento! Cuando la guía es devolución a originador
                        verás el nombre del originador. Pero si es entrega al cliente, verás el nombre del destinatario. Para continuar, selecciona "Entregar paquete"
                    </p>
                    <p>
                        <b>5-</b>Automaticamente se activará la cámara. Deberás tomar una fotografía de la orden junto a la identificación oficial del cliente.
                    </p>
                    <p>
                        <b>6-</b>Podrás volver a ver la fotografía que acabas de tomar. Si es clara y de buena calidad, selecciona "Aceptar". De otra forma,  vuelve a tomar la foto hasta que consideres que es aceptable.
                    </p>
                    <p>
                        <b>7-</b>Finalmente tendrás que volver a ingresar el número de guía. Podrás hacerlo escaneando el código QR o ingresando manualmente el número de guía.
                    </p>
                    <br />
                    <p>
                        ¡Listo! Has concluido exitosmamente la entrega al cliente. ✅
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Qué hago si inicié el proceso de entrega pero un cliente rechazó el paquete?",
            children: (
                <React.Fragment>
                    <p>
                        <b>1- </b>Selecciona la opción de "Paquete rechazado".
                    </p>
                    <p>
                        <b>2- </b>Ingresar el motivo por el cual el paquete fue rechazado.
                    </p>
                    <p>
                        <b>3-</b> Selecciona "Solicitar devolución".
                    </p>
                    <p>
                        <b>4- </b> Recibirás una notificación de devolución exitosa.
                    </p>
                    <br />
                    <p>
                        Esto concluye el proceso de rechazo, podrás ver esta orden en la
                        página "Mis paquetes"
                        <img
                            className="mx-1 w-5 inline-block"
                            src="../icons/paquetes.svg"
                            alt="icon recolección"
                        />
                        , en la sección de "Por devolver" y "En mi local". Un veloz se
                        acercará a recolectar este paquete.📦
                    </p>
                </React.Fragment>
            ),
        },
        {
            label: "¿Cómo identifico a un veloz?",
            children: (
                <React.Fragment>
                    <p>
                        El veloz deberá de presentar su aplicación con sus datos e información personal.
                    </p>

                </React.Fragment>
            ),
        },
        {
            label: "¿Cuánto tiempo tardará en llegar el veloz?",
            children: (
                <React.Fragment>
                    <p>
                        El tiempo dependerá del momento en que hayas escaneado un paquete. Todo lo que es escaneado en tu
                        local <b>antes de las 13:30 hrs</b> podrá ser recolectado ese mismo día; mientras que todo lo escaneado <b> después
                            de las 13:30 hrs</b> pasará a programarse para recolecta al día siguiente. 🕑
                    </p>

                </React.Fragment>
            ),
        },
        {
            label: "¿Qué tipo de paquetes puedo recibir?",
            children: (
                <React.Fragment>
                    <p>
                        Como Punto99 puedes recibir paquetes con las siguientes especificaciones:
                    </p>
                    <span style={{ textDecoration: "underline" }}>
                        Tamaño 📦
                    </span>{" "}
                    <li>
                        XS
                    </li>
                    <li>
                        S
                    </li>
                    <li>
                        M
                    </li>
                    <li>
                        L
                    </li>
                    <li>
                        XL
                    </li>
                    <span style={{ textDecoration: "underline" }}>
                        Tipo de servicio 🚚
                    </span>{" "}
                    <li>
                        Same Day
                    </li>
                    <li>
                        Next Day
                    </li>
                    <li>
                        CO2 Free
                    </li>
                    <li>
                        Retorno
                    </li>
                    <br />
                    <p>
                    ❗Te recordamos que no debes aceptar paquetes que excedan más de 25 kg volumétricos (El peso volumétrico en centímetros alto × ancho × largo ÷ 5000, si el resultado de esa operación rebasa como numero en 25, el paquete no debe ser aceptado).                        
                    </p>

                </React.Fragment>
            ),
        }
    ],
    "Errores y asistencia": [
        {
            label: "¿Cómo puedo solicitar asistencia y usar el chatbot?",
            children: (
                <React.Fragment>
                    <p>
                    Solicitar asistencia, reportar errores  o hacer sugerencias es muy sencillo. Puedes hacerlo enviando un correo electrónico a atencion@punto99.mx o también por medio del chatbot. 🤖 
                        
                    El chatbot se encuentra en el extremo inferior derecho de la pantalla<img
                            className="mx-1 w-5 inline-block"
                            src="../chatbot.png"
                            alt="icon recolección"
                        />.
                       Para conocer más sobre el uso del chatbot, te invitamos a leer <a href="https://www.punto99.com/chatbot_punto99_v01.pdf" className="color-green99 bold">este manual</a>
                    </p>
                </React.Fragment>
            )
        },
        {
            label: "¿Puedo cambiar de teléfono para usar la app?",
            children: (
                <React.Fragment>
                    <p>
                    ¡Claro que sí! Deberás usar el chatbot en tu aplicación o enviando un correo a atencion@punto99.mx y te respoderemos con prontitud. Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado '¿Cómo puedo solicitar asistencia?'. 📲
                    </p>
                </React.Fragment>
            )
        },
        {
            label: "Entregué paquetes al veloz y los sigo viendo en mi inventario, ¿qué puedo hacer para que se actualice?",
            children: (
                <React.Fragment>
                    <p>
                    Hay ocasiones en las que la información puede tardar hasta un día en actualizarse, si después de un día sigues viendo el paquete en el inventario, por favor solicita asistencia por medio del chatbot o al correo atencion@punto99.mx y rápidamente nos encargaremos de actualizar el estado de la orden para que no aparezca en tu inventario. Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado '¿Cómo puedo solicitar asistencia?'. 📲
                    </p>
                </React.Fragment>
            )
        },
        {
            label: "Veo mis datos con errores, ¿cómo lo puedo corregir?",
            children: (
                <React.Fragment>
                    <p>
                    Desde la aplicación en la sección 'Mi perfil' puedes editarlos. Si hay algún dato que no puedes editar, puedes solicitar asistencia por el medio del chatbot o enviando un correo a atencion@punto99.mx. Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado '¿Cómo puedo solicitar asistencia?'. 📲                    </p>
                </React.Fragment>
            )
        },
        {
            label: "La aplicación me manda error al escanear.",
            children: (
                <React.Fragment>
                    <p>
                        Es importante que valides lo siguiente:
                    </p>
                    <p>
                        <b>1- </b> Verifica que el código que estás  ingresando o escaneando sea correcto.
                    </p>
                    <p>
                        <b>2- </b> Verifica que cuentes con conexión a internet.
                    </p>
                    <p>
                        <b>3- </b>Revisa el letrero emergente para identificar el motivo.
                    </p>

                    <p>
                        Si ninguna de estas opciones funciona, solicita asistencia por medio del chatbot o enviado un correo a atencion@punto99.mx.
                        Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado "¿Cómo puedo solicitar asistencia?".📲
                    </p>
                </React.Fragment>
            )
        },
        {
            label: "El sistema marca error cuando estoy entregando.",
            children: (
                <React.Fragment>
                    <p>
                        Es importante que valides lo siguiente:
                    </p>
                    <p>
                        <b>1- </b>Revisa que el código de recolección sea el correcto.
                    </p>
                    <p>
                        <b>2- </b> Verifica que cuentes con conexión a internet.
                    </p>
                    <p>
                        <b>3- </b>Revisa que estés escaneando la guía correcta para entrega.
                    </p>
                    <p>
                        Si ninguna de estas opciones funciona, solicita asistencia por medio del chatbot o enviado un correo a atencion@punto99.mx.
                        Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado "¿Cómo puedo solicitar asistencia?".📲
                    </p>
                </React.Fragment>
            )
        },
        {
            label: "¿Puedo darme de baja temporalmente?",
            children: (
                <React.Fragment>
                    <p>
                    ¡Claro que sí! Deberás realizar una solicitud indicando el periodo de ausencia. Podrás hacerlo enviando un correo electrónico a atencion@punto99.mx o también usando el chatbot. 🤖
Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado '¿Cómo puedo solicitar asistencia?'. 📲
                    </p>

                </React.Fragment>
            )
        }
    ],
    "Pagos": PreguntasPagos
}