
type FetchResultType = { error: boolean, message: string, data: any, extra?: any, meta?:any }
type requestEvidenceBodyType = {
  orderNumber: string;
  type: string;
  image?: string;
};

// export const productionURL = 'https://snowy-sydney-1gshtg43w4jo.vapor-farm-c1.com/api'
// const productionURL = 'https://beautiful-beach-kwel0ptv5gey.vapor-farm-c1.com/api
const productionURL = process.env.REACT_APP_API_BASE_URL

export async function saveEvidence(
  requestEvidenceBody: requestEvidenceBodyType
): Promise<FetchResultType> {
  return await fetch(
    "https://us-central1-point-99-mx.cloudfunctions.net/uploadImage",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(requestEvidenceBody),
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
      if (res.status !== "success") {
        return { error: true, message: res.message, data: {} };
      }
      return { error: false, message: res.message, data: res.imageURL };
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'error uploading image', data: {} }
    });
}

export async function getOrderInfo( orderReference: string ): Promise<FetchResultType> {
  return await fetch(
    `${ productionURL }/order/reference/${ orderReference }/tenant/123456`,
    {
      method: "GET",
      headers: { "Authorization": `Bearer ${ localStorage.getItem('userToken') }` },
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
      console.log(res, 'res de peticion')
      if (res.success !== true) {
        if (res.body && res.body.errors) {
          const finalErrors: any = []
          Object.keys(res.body.errors).forEach(( key ) => {
            const errorsBody = res.body.errors[key].split('|')
            finalErrors.push(...errorsBody)
          })
          return { error: true, message: res.message + " Razones:\n" + finalErrors.join('\n'), data: {} }
        }
        return { error: true, message: res.message, data: {} };
      }
      if (res.message.includes('Multiple orders found')) {
        return { error: false, message: 'multipleOrders', data: res.body }
      }
      return { error: false, message: res.message, data: res.body };
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'error getting order info', data: {} };
    });
}



export async function changeOrderStatus(evidenceURL: string, orderReference: string, type: 'deliver_to_core' | 'deliver_to_customer' | 'recollect_from_seller' | 'recollect_from_driver' | 'return_to_originator', deliverTo?: string, isNoVerificationCode?: boolean, ): Promise<FetchResultType> {
  // console.log(orderReference, evidenceURL, type,deliverTo, 'changeOrderStatus')
  const fetchData = {
    deliver_to_core: '/order/recollect/core/',
    deliver_to_customer: '/order/delivery/customer/',
    recollect_from_seller: '/order/recollect/core/',
    recollect_from_driver: '/order/recollect/delivery/',
    return_to_originator: '/order/returnToOriginator/'
  }
  const bodyOrder: { orderReference: string, tenantId: string, evidenceUrl: string, deliveredTo?: string} = {
    orderReference,
    tenantId: '123456',
    evidenceUrl: JSON.stringify([ evidenceURL ]),
  }
  if (type === 'deliver_to_customer') {
    bodyOrder.deliveredTo = isNoVerificationCode ? deliverTo + ' | Entrega de paquete con número de guía - sin código de verificación' : deliverTo  || 'LEONIDES ZAPATA'
  }

  return await fetch(
    `${ productionURL }${ fetchData[type] }`,
    {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-type": 'application/json',
        "Authorization": `Bearer ${ localStorage.getItem('userToken') }`
      },
      body: JSON.stringify(bodyOrder)
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
      
      if (res.success !== true) {
        return { error: true, message: res.message, data: {} };
      }
      if (res.code === 404) return { error: true, message: res.message, data: {} }
      if (res.code === 500) return {
        error: true,
        message: res.message || 'Order is not ready to be delivered',
        data: {}
      }
      return { error: false, message: '1', data: res };
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'error getting order info', data: {} };
    });
}

export async function verifyUser( authorizatinCode: string ): Promise<FetchResultType> {
  return await fetch(
    `${ productionURL }/order/code/${ authorizatinCode }/tenant/123456`,
    {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-type": 'application/json',
        "Authorization": `Bearer ${ localStorage.getItem('userToken') }`
      },
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
      // if (res.status !== "success") {
      //   return { error: true, message: 'error getting order info', data: {} };
      // }
      if (res.code === 404) return { error: true, message: res.message, data: {} }
      return { error: false, message: '1', data: res };
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'error getting order info', data: {} };
    });
}

// status 1 pasa 3 [recoleccion] deliver_to_core from seller
// status 1 pasa 5 [recoleccion] deliver_to_customer from driver

// status 5 pasa 6 [entrega] deliver_to_customer

export async function makePackageDevolution( evidenceUrl: string, reason: string, orderReference: string ): Promise<FetchResultType> {
  return await fetch(
    `${ productionURL }/order/reject/customer`,
    {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-type": 'application/json',
        "Authorization": `Bearer ${ localStorage.getItem('userToken') }`
      },
      body: JSON.stringify({
        orderReference,
        reason,
        evidenceUrl: JSON.stringify([ evidenceUrl ]),
        tenantId: "123456"
      })
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
      // if (res.status !== "success") {
      //   return { error: true, message: 'error getting order info', data: {} };
      // }
      if (res.code === 404) return { error: true, message: res.message, data: {} }
      if (res.code === 500) return {
        error: true,
        message: res.message || 'Order is not ready to be delivered',
        data: {}
      }
      if (!res.id) return { error: true, message: res.message || 'Server error, please try again later.', data: {} }
      return { error: false, message: '1', data: res };
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'error getting order info', data: {} };
    });
}

export async function getToRecollectPackages(): Promise<FetchResultType> {
  return await fetch(`${ productionURL }/statistics/recollect`, {
    method: 'GET',
    headers: { "authorization": `Bearer ${ localStorage.getItem('userToken') }` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.to_recollect === undefined) return {
        error: true,
        message: 'Something went wrong obtaining toRecollect orders',
        data: '...'
      }
      return { error: false, message: '', data: res.to_recollect }
    })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'Something went wrong obtaining toRecollect orders', data: '...' }
    })
}

const getOnDeliverOrders = ( orders: any[] ) => {
  const permissiveOnDeliverStatus = [ 7, 8, 10, 11 ]
  const onDeliverOrders: any[] = []
  orders.forEach(( order: any ) => {
    for (let i = 0; i < order.logs.length; i += 1) {
      console.log(permissiveOnDeliverStatus.indexOf(order.logs[i].new_status_id))
      if (permissiveOnDeliverStatus.indexOf(order.logs[i].new_status_id) !== -1) {
        onDeliverOrders.push(order)
        break
      }
    }
  })
  console.log(orders, onDeliverOrders, 'what?')
  return onDeliverOrders
}



export async function getPackages( type: "local" | "deliver" | "onDeliver"|"localSelf"|"deliveredSelf", user: any, userToken: string, page: number, reference:string|undefined, self?:string|undefined, totalLocal?:number): Promise<FetchResultType> {

  const statusesDict = {
    local: ["2","3","5","12"],
    deliver: [ "4", "6", "13" ],
    onDeliver: [ "3", "11" ],
    localSelf: ["2","3","5","11","12" ],
    deliveredSelf: [ "4", "6", "13" ],
  }
  const dpId=user? `&filter[point]=${user.current_dispatch_place_id}`:''
  const external=reference? `&filter[external_reference]=${reference}`:''
  const status=type ?`&filter[status]=${statusesDict[type].toString()}`:''
  const selfType=self ?`&filter[self_pickup_type]=${self}`:''
  const local=totalLocal ?`&page[size]=${totalLocal}`:'&page[size]=10'

  
  
  return await fetch(
    `${ productionURL }/v1/PWA/orders?page[number]=${page}${local}&sort=-id${dpId}${external}${status}${selfType}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + userToken,
      },
    }
  )
    .then(( res ) => res.json())
    .then(( res ) => {
        if (res.success){ 
      const orders = type === 'onDeliver' ? getOnDeliverOrders(res.body && res.body.data? res.body.data:[]) : res.body.data
          const {current_page, per_page, total, last_page}=res.body
  
          return {
            error: false,
            message: "",
            data: { metadata: {current_page, page_size: Number(per_page), total_items:total, total_pages: last_page}, orders:res.body && res.body.data? orders:[] },
          }
          
         } else {
            return { error: true, message: 'no data', data: [] }
  
          }
      });
  };

export async function getNumbers(id:number, type:string): Promise<FetchResultType> {
  const userToken = localStorage.getItem("userToken");
  return await fetch(`${ productionURL }/v1/PWA/points99/${id}/stats?filter[self_pickup_type]=${type}`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + userToken,
    },
  })
    .then(( res ) => res.json())
    .then(( res ) => {
      if (res.success) {
        return { error: false, message: res.message, data: res.body }
      }
      return { error: true, message: res.message, data: {} }
    })
    .catch(( e ) => {
      console.log(e);
      return { error: true, message: 'Ocurrió un error obteniendo tus estadísticas', data: {} }
    });
}


  export const getDispatchAndOrdersByIdAndDate=(p99:any ,paginated:boolean, page?:number, perPage?:number) => {
    const pageNum=paginated?`page=${page}`:''
    const isPaginated= paginated?`&pagination=true`:''
    const inPage= paginated?`&per_page=${perPage||20}`:''
  
    return fetch(`${process.env.REACT_APP_DB_PAYMENT_URL}/orderPayment/orders?${pageNum}${inPage}&dispatch_place=${p99.dispatch_place}&id=${p99._id}${isPaginated}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.error){
          return {
            error: true,
            data:[],
            message:'Error al recuperar las órdenes'
          };
        }
        return {
          error: false,
          data: res.body,
          message:'Órdenes recuperadas con éxito.'
        };
      })
      .catch((e) => {
        return { error: true, message: e, data:[] };
      });
  }
  
export async function getContainerInfo( containerID: string ): Promise<FetchResultType> {
  return await fetch(`${ process.env.REACT_APP_API_DELIVERY_CELL_LARAVEL }/app/containers/${containerID}/info`, {
    method: 'GET',
  }).then( res => res.json()
  ).then(async res => {
    if(res.success){
      return { error: false, message: res.message, data: res }

    }
    return { error: true, message: res.message || 'Error al obtener el saco.', data: res }
  })
    .catch(e => {
      return { error: true, message: 'Error 505: Se encontró un error en el servidor', data: {} }
    })
}

export async function getContainersList( dispatchPlaceId?: string ,number?: number , size?: number , isLocal?:boolean, isDelivered?:boolean, reference?:string, isToReceive?:boolean): Promise<FetchResultType> {
  const isPaginated= `page[number]=${number || 1}&page[size]=${size||10}`
  const local=isLocal? `&filter[status]=1`:''
  const delivered= isDelivered?`&filter[status]=2,3`:''
  const toReceive= isToReceive?`&filter[status]=4`:''

  const isReference= reference?`&filter[reference]=${reference}`:''
  const point= dispatchPlaceId?`filter[point]=${ dispatchPlaceId }&`:''


  return fetch(`${ process.env.REACT_APP_API_DELIVERY_CELL_LARAVEL}/app/containers?${point}${isPaginated}&sort=-created_at${local}${delivered}${toReceive}${isReference}`, {
    method: 'GET',
  }).then(res => res.json()).then(res => {
    if(res.success){
      return { error: !res.success, message: res.message, data: res.body.data.length===0?[{empty:true}] :res.body.data , meta:res.body.meta }
    }else{
      return { error: res.success, message: 'Se encontró un error al obtener la información', data: res }

    }
  }).catch(e=>{
    return { error: true, message:'501: Error al obtener contenedores', data: [] }
  })
}




export async function recollectBagApi( data: { reference: string; evidences: { type: string; url: string; }[]; point_id: any; } ): Promise<FetchResultType> {
  return fetch(`${ process.env.REACT_APP_API_DELIVERY_CELL_LARAVEL }/app/containers`, {
    method: 'POST',
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then(res => {
    if(res.success){
      return { error: false, message: res.message, data: res.body.data }
    }
    return { error: true, message: '', data: res }
  })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'No se pudo recolectar el saco', data: {} }
    })
}

export async function deliverBagApi( body: { evidences: { type: string; url: any; }[]; } , containerId:string): Promise<FetchResultType> {
  return fetch(`${ process.env.REACT_APP_API_DELIVERY_CELL_LARAVEL }/app/containers/${containerId}/release`, {
    method: 'PATCH',
    headers: { "Content-type": "application/json" },
    body:  JSON.stringify(body),
  })
  .then(res => res.json())
  .then((res) => {
    console.log(res.success)
    if(res.success){
      return { error:false, message: res.message, data: res.body.data }
    }
    return { error: true, message: '', data: res }
  })
    .catch(e => {
      console.log(e)
      return { error: true, message: '', data: '' }
    })
}


export async function updateOrder( orderId: string, order: any ): Promise<FetchResultType> {
  
  return fetch(`${ productionURL }/v1/PWA/orders/${orderId}`, {
    // mode: 'cors',
    method: 'PUT',
    headers: {
      "Accept": 'application/json',
      'Content-Type': 'application/json',
      Authorization:`Bearer ${localStorage.getItem('userToken')}`,

    },
    body: JSON.stringify(order),
  })
  .then(( res ) => res.json())
  .then(res => {
    return { error: false, message: '', data: res }
  })
    .catch(e => {
      console.log(e)
      return { error: true, message: '', data: '' }
    })
}

export async function updateOrderPayment( orderId: string, self_pickup_type: string, cost:number ): Promise<FetchResultType> {
  
  return fetch(`${process.env.REACT_APP_DB_PAYMENT_URL}/orderPayment/${orderId}`, {
    method: 'PUT',
    headers: {
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({self_pickup_type, cost}),
    
  })
  .then(( res ) => res.json())
  .then(res => {
    return { error: false, message: res.message, data: res.orders, payment: res.payment }
  })
    .catch(e => {
      console.log(e)
      return { error: true, message: 'No se ha podido actualizar', data: '' }
    })
}
