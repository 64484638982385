// @ts-nocheck
import {  useState } from "react";
import { useHistory } from "react-router-dom";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { ArrowRightIcon, BlackDot } from "../assets/icons";
import Input from "../components/Input";
import {
  getOrderInfo,
  verifyUser,
} from "../utils/requests";
import EvidenceModal from "../components/PackagesPage/EvidenceModal";
import { toast, ToastContainer } from "react-toastify";

function ScannerUserPage() {
  const { goBack, push } = useHistory();
  const [smsCode, setSmsCode] = useState({text:''});
  const [loading, setLoading] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [guide, setGuide] = useState("");
  const [guideLoad, setGuideLoad] = useState(false);

  const scanSuccessful=()=>{
    const audio = new Audio('/Sonido2.mp3');
    audio.play()
    if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }
    window.navigator.vibrate(300);
  }

  
  const onScan = async (result: any, errorScan: any) => {
    if (errorScan  || !result) return;
    setLoading(true)
    scanSuccessful()
    const { error, message, data } = await verifyUser(
      result.text
    );
    if (error) {
      toast.error(message || 'Something went wrong')
      setLoading(false);
      return;
    }else{
      push("/match-user", {
        verification_code: result.text,
        deliverTo: data.deliver_to,
        orderNumber: data.external_reference,
        orderInternalReferece: data.internal_reference,
        orderInfo: data
      });
      setLoading(false);
      return
    }

  };

  const onKeyUp = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      onScan(smsCode, false);
    }
  };


  const deliverWithGuide = async () => {
    if (guide.length < 1) return;
    setGuideLoad(true)
    const { error, message, data } = await getOrderInfo(guide);
    if (error) {
      message.length > 1
        ? toast.error(message)
        : toast.error("Something went wrong");
      setGuideLoad(false);
      return;
    }
    console.log(error, message, data)
    if (
      (data.order_status_id === 5 && data.dispatch_service === 'DELIVERY_TO_CUSTOMER') ||
      (data.order_status_id === 11 && data.dispatch_service === 'DELIVERY_TO_CORE') ||
      (data.order_status_id === 12 && data.dispatch_service === 'RETURN_ORIGINATOR')
    ) {
      push("/confirm-deliver", {
        orderInfo: data,
        imageURL: '',
        orderNumber: guide,
        isNoVerificationCode: true
      })
    } else {
      toast.error("Orden en status no permitido o tipo servicio no permitido para entrega", {
        theme: "colored",
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setGuideLoad(false);
      return;
    }
  };

  const onKeyUpGuide = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      deliverWithGuide();
    }
  };

  const showOpenGuideModal = () => {
    setLoading(true);
    setOpenGuideModal(true);
  };

  return (
    <div className="h-screen w-screen gradient-green overflow-auto	">
    <ToastContainer />
      <EvidenceModal modal={openGuideModal} setModal={(open) => {setOpenGuideModal(open); setLoading(open)}}>
        <div className="flex-col  w-11/12  flex justify-center items-center mx-10 mt-10 mb-5">
          <strong>Ingrese número de guía</strong>
          <Input
            disabled={guideLoad}
            border=""
            inputClassNames="text-black border-b border-black"
            onKeyDown={onKeyUpGuide}
            textAlign="center"
            placeholder="Número de guía"
            onChange={setGuide}
            value={guide}
          />
          <button
            onClick={deliverWithGuide}
            className="mt-3 rounded border border-green-100 text-green-100 px-2 py-1"
          >
            { guideLoad ? (
              <div className="">
                <div className="lds-dual-ring-small"></div>
              </div>
            ) : 'Entregar con número de guía'}
          </button>
        </div>
      </EvidenceModal>
      <div className="h-screen w-screen gradient-green overflow-y-hidden fixed Vxxs:overflow-y-auto Vxxs:relative">
        <div
          onClick={goBack}
          className="px-4 py-4 xs:px-2 xs:py-4"
          id="back-button"
        >
          <ArrowRightIcon rotate={"90"} tint={"white"} />
        </div>
        <div className="h-full w-screen px-8 xs:px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="Vxxs:text-md font-bold text-xl xs:text-lg text-white mb-3">
              Estás entregando un paquete
            </span>
            <div className=" Vxxs:hidden">
          <BlackDot />
          </div>
          </div>
          <div className="Vxxs:my-0 flex mt-7 xs:mt-4 items-center justify-center">
            <span className="Vxxs:text-sm text-center text-white">
              Escanea el código
              <span className="font-bold"> QR del cliente</span>
              <br />ó ingresa su
              <span className="font-bold"> código de verificación</span>
            </span>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="w-3/4">
              <Input
                onKeyDown={onKeyUp}
                textAlign="center"
                placeholder="Código de verificación"
                onChange={(text)=>setSmsCode({text})}
                value={smsCode.text}
              />
            </div>
            {/* <div className="w-28 h-28 bg-red-100" onClick={() => onScan(smsCode, false)} /> */}
          </div>
          <div className="flex items-center justify-center w-full mt-10 Vxxs:mt-3">
          <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
          <div style={{ width: '101%', height: '101%', display: loading ? 'none' : '' }} className="absolute z-10">
            </div>
            {loading? 
            <div  className={`bg-white absolute w-full h-full flex items-center justify-center  `}>
              <div className="lds-dual-ring"></div>
            </div>:
            <QrReader
              videoId={"qrreader"}
              onResult={onScan}
              containerStyle={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%',objectFit: 'fill' }}
              videoContainerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%',  objectFit: 'fill',  padding:'0'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={500}
            />}
            </div>
          </div>

          <div className="flex flex-col w-full justify-center items-center mt-3 Vxxs:mt-2">
            <span className="Vxxs:text-sm text-lg font-thin text-white">
              Enfoca el código QR en el recuadro
            </span>
            <div className="mt-4">
              <button
                onClick={showOpenGuideModal}
                className="rounded border border-gray-100 text-gray-100 px-2 py-1 "
              >
                Entregar con número de guía
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScannerUserPage;
