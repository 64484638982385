// @ts-nocheck
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
  CodeIcon,
  UserIcon,
} from "../../assets/icons";
import BackgroundShape from "../../components/BackgroundShape";

function DeliverInfoVeloz() {
  const { goBack, push, location } = useHistory();

  const [ loading, setloading ] = useState(false);


  const confirmRecollection = async ( bagInfo: BagType ) => {
    push("/exchange-point/confirm-deliver", { ...bagInfo });
  };

  interface BagType {
    props: any
  }


  return (
    <div className="h-screen w-screen overflow-y-hidden fixed">
      <BackgroundShape direction="revert" type={ "quarter" } position="bottom"/>
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          <div
            onClick={ goBack }
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={ "90" }/>
          </div>
          <div className="flex w-full justify-center">
                        <span className="text-xl text-center font-semibold text-green-100">
                            Estás entregando
                            <br/>
                            <span className="text-gray-200">un saco</span>
                        </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot/>
          </div>
          <div className="flex items-center justify-center mt-7 w-full">
                        <span className="text-md text-gray-600 font-medium text-center">
                        ¡El veloz escaneo
                        <br/>
                            <strong>exitosamente</strong> el saco!
                        </span>
          </div>
          <div className="px-8 w-full mt-10">
            <div
              style={ { boxShadow: "0 10px 20px 0 rgba(128, 128, 128, 0.31)" } }
              className="w-full bg-white rounded-md"
            >
              <div className="flex flex-row items-center px-5 py-4 border-b border-gray-1800">
                <div className="flex items-center justify-center w-1/12">
                  <UserIcon/>
                </div>
                <span className="ml-5 font-medium text-gray-200">
                    {location?.state?.veloz?.name || 'Sin información disponible' }
                </span>
              </div>
              <div className="flex flex-row items-center px-5 py-4 border-b border-gray-1800">
                <div className="flex items-center justify-center w-1/12">
                  <img src={ "../icons/paquetes.svg" } alt='paquetes' className="w-6"/>
                </div>
                <span
                  className="ml-5 font-bold text-gray-200">{ location?.state?.orders?.length } { location?.state?.orders?.length > 1 ? 'paquetes' : 'paquete' }</span>
              </div>
              <div className="flex flex-row items-center px-5 py-4">
                <div className="flex items-center justify-center w-1/12">
                  <CodeIcon/>
                </div>
                <span className="ml-5 font-medium text-gray-200">{ `${ location?.state?.tag }` }</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center w-full mb-16">
            <div
              onClick={ () => (loading ? () => {
              } : confirmRecollection({ ...location?.state })) }
              className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
            >
              <span className="text-lg text-green-100 font-semibold">
                Entregar saco
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliverInfoVeloz;
