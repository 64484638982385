import IconBtn from "./IconBtn";
interface Props {
  schedule: any;
}

const ScheduleFixed = ({ schedule }: Props) => {
  return (
    <div className="w-full flex flex-col" style={{ maxWidth: "fit-content" }}>
      <IconBtn label="Horario" icon="../icons/icon_direccion.svg" />
      <div className="flex w-full justify-between ">
        <div className="w-24  ml-5  mt-4 flex flex-col  justify-center">
          <p className="bold batman">Día</p>
          <p className="semi-bold color-gray100 mt-1">Lunes</p>
          <p className="semi-bold color-gray100 mt-1 ">Martes</p>
          <p className="semi-bold color-gray100 mt-1">Miércoles</p>
          <p className="semi-bold color-gray100 mt-1">Jueves</p>
          <p className="semi-bold color-gray100 mt-1">Viernes</p>
          <p className="semi-bold color-gray100 mt-1">Sábado</p>
          <p className="semi-bold color-gray100 mt-1">Domingo</p>
        </div>
        <div className="w-9/12 flex justify-evenly">
          <div className="flex flex-col justify-start mt-4 ">
            <p className="bold batman">Apertura</p>

            <p className="color-batman mt-1">
              {schedule.monday.closed ? "Cerrado" : schedule.monday.opens}
            </p>

            <p className="color-batman mt-1">
              {schedule.tuesday.closed ? "Cerrado" : schedule.tuesday.opens}
            </p>
            <p className="color-batman mt-1">
              {schedule.wednesday.closed ? "Cerrado" : schedule.wednesday.opens}
            </p>
            <p className="color-batman mt-1">
              {schedule.thursday.closed ? "Cerrado" : schedule.thursday.opens}
            </p>
            <p className="color-batman mt-1">
              {schedule.friday.closed ? "Cerrado" : schedule.friday.opens}
            </p>
            <p className="color-batman mt-1">
              {schedule.saturday.closed ? "Cerrado" : schedule.saturday.opens}
            </p>
            <p className="color-batman mt-1">
              {schedule.sunday.closed ? "Cerrado" : schedule.sunday.opens}
            </p>
          </div>
          <div className="flex flex-col justify-start mt-4">
            <p className="bold batman">Cierre</p>

            <p className="color-batman mt-1 ">
              {schedule.monday.closed ? "Cerrado" : schedule.monday.closes}
            </p>

            <p className="color-batman mt-1">
              {schedule.tuesday.closed ? "Cerrado" : schedule.tuesday.closes}
            </p>
            <p className="color-batman mt-1">
              {schedule.wednesday.closed
                ? "Cerrado"
                : schedule.wednesday.closes}
            </p>
            <p className="color-batman mt-1">
              {schedule.thursday.closed ? "Cerrado" : schedule.thursday.closes}
            </p>
            <p className="color-batman mt-1">
              {schedule.friday.closed ? "Cerrado" : schedule.friday.closes}
            </p>
            <p className="color-batman mt-1">
              {schedule.saturday.closed ? "Cerrado" : schedule.saturday.closes}
            </p>
            <p className="color-batman mt-1">
              {schedule.sunday.closed ? "Cerrado" : schedule.sunday.closes}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleFixed;
