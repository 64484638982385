
interface Props {
  number_packages: null| number| string ;
}

const ToCollectCard = ({ number_packages }: Props) => {

  return (
    <div className="profitCardContainer relative mx-auto Vxs:my-1 Vsm:my-1 Vmd:my-10 Vlg:my-10 Vxl:mt-20" >
      <img
        src="../icons/icon_ganado.svg"
        alt="icon"
        className="rounded-pic profitCardIcon"
      />
      <div className="text-center">
        <p className="color-batman bold">¡Buen día!</p>
        { number_packages===0 ?
          <div className="w-60">
          <h6 className="m-0 color-batman bold">
          No tienes sacos por recibir
          </h6>
        </div>
        :
        <div className="my-3 flex flex-col justify-center items-center">

          <h6 className="color-batman bold ">Hoy vas a recibir</h6>
            <h6 className="color-green99 flex bold leading-4">
            {!number_packages? <div className="lds-dual-ring-small" /> : number_packages} {number_packages===1? 'saco': 'sacos'} 
            </h6>
          </div>

        }
      </div>
    </div>
  );
};

export default ToCollectCard;
