// @ts-nocheck
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowRightIcon, BlackDot, ScannerFrame } from "../../assets/icons";
import Input from "../../components/Input";
import {getContainerInfo, getOrderInfo, saveEvidence} from "../../utils/requests";
import { sleep } from "../../utils/sleep";
import MultipleOrdersContext from "../../context/MultipleOrdersContext/context";

function ScannerRecollectPage() {
  const { goBack, push } = useHistory();

  const { setMultipleOrders } = useContext(MultipleOrdersContext)

  const [loading, setLoading] = useState(false);

  let text = ""

  const setText = (textTo: string) => {
    text = textTo
  }

  const captureVideo = (video: any) => {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var canvasContext = canvas.getContext("2d");
      //@ts-ignore
      canvasContext.drawImage(video, 0, 0);
      return canvas.toDataURL("image/png");
    } catch (e) {
      console.log(e);
    }
  };

  const onScan = async (result: any, errorScanner: any) => {
    if (errorScanner) return
    if (text.length > 1) return
    setLoading(true)
    setText(typeof result === "string" ? result : result.getText())
    const getFrame = captureVideo(document.getElementById("qrreader"));
    await sleep(1000)
    const { error, message, data } = await getContainerInfo(typeof result === "string" ? result : result.getText(), 'recollect')
    if(error) {
      toast.error(message || 'Ocurrio un error obteniendo informacion del contenedor')
      setLoading(false)
      return
    }
    push('/exchange-point/recollect-info', { containerID: typeof result === "string" ? result : result.getText(), containerNumber: typeof result === "string" ? result : result.getText(), ...data})
  };

  const [smsCode, setSmsCode] = useState("");

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onScan(smsCode, false)
    }
  }

  return (
    <div className="h-screen w-screen gradient-green">
      <ToastContainer />
      <div
        onClick={goBack}
        className="px-4 py-8 xs:px-2 xs:py-4"
        id="back-button"
      >
        <ArrowRightIcon rotate={"90"} tint={"white"} />
      </div>
      <div className=" w-screen px-8 xs:px-4">
        <div className="flex flex-col items-center justify-center">
          <span className="text-center font-bold text-xl xs:text-lg text-white mb-3">
            Estás recolectando
            <br />
            <span className="text-gray-200">un saco</span>
          </span>
          <BlackDot />
          <span className="mt-3 text-center font-thin text-medium xs:text-lg text-white mb-3">
            <span className="text-center text-white">
              Escanea el código
              <span className="font-bold"> QR<br />del saco</span>
            </span>
          </span>
        </div>
        
        <div className="flex items-center justify-center w-full mt-10">
            <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
              <div
                style={{
                  width: "101%",
                  height: "101%",
                  display: loading ? "none" : "",
                }}
                className="absolute z-10"
              >
              </div>
              <div
                style={{ zIndex: loading ? 10 : -10 }}
                className={`absolute bg-white w-full h-full flex items-center justify-center`}
              >
                <div className="lds-dual-ring"></div>
              </div>
            <QrReader
              videoId={"qrreader"}
              onResult={onScan}
              containerStyle={{ width: '100%',  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%', objectFit: 'fill' }}
              videoContainerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding:'0'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={100}
            />
          </div>
        </div>

        <div className="flex w-full justify-center mt-10">
          <span className="text-lg font-thin text-white">
            Enfoca el código QR en el recuadro
          </span>
        </div>
      </div>
    </div>
  );
}

export default ScannerRecollectPage;
