import IconBtn from "../../components/IconBtn";
import Divider from "../../components/Divider";
import { PropsAccordion, default as Accordion } from "../../components/Accordion";
import { useHistory } from "react-router-dom";
import Preguntas from './preguntas'
import BackgroundShape from "../../components/BackgroundShape";

function HowItWorks() {


  const pintarPreguntas = (preguntas: { [key: string]: Array<PropsAccordion> }) => {
    const html: any = [];
    let actual = 0;
    const estilosTítulo = `bold color-green99 mb-3 text-center`;
    for (let sección in preguntas) {
      html[actual] = [];
      html[actual].push(<strong className={estilosTítulo}>{sección}</strong>);
      // eslint-disable-next-line no-loop-func
      preguntas[sección].forEach((props: any) => {
        html[actual].push(<Accordion {...props} />)
        html[actual].push(<Divider percentage={90} spacing={0.5} />)
      })
      actual++;
    }

    return html
  }

  let history = useHistory();

  return (
    <div className="h-screen w-full">
      <BackgroundShape type={"small"} position="bottom" />
      <BackgroundShape type={"small"} position="top" />
      <div className="upperSection flex flex-col">
        <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />
        </div>

        <div className=" flex flex-col items-center">
          <h4 className=" m-0 semi-bold color-white"> Preguntas frecuentes </h4>
          <img className="icon" src="../icons/point_black.svg" alt="map" />
        </div>
      </div>

      {/* form starts*/}
      <div className="form mx-auto mt-24 p-5">
        <p className="body2 my-3" style={{ textAlign: "center" }}>
          <b className="color-green99">Queremos ayudarte</b> a que tengas una
          excelente experiencia como punto99
        </p>

        {pintarPreguntas(Preguntas)}

      </div>
      <div className="downSection"></div>
    </div>
  );
}

export default HowItWorks;
