import {useState} from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatDate } from '../utils/functions';

// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    rowsTable: {
      flexDirection: 'row',
        paddingTop: 20,
        flexGrow: 1
      },
      rows: {
        flexDirection: 'row',
          marginBottom: 10,
          flexGrow: 1
        }
  });

const PaymentReport = ({data, name, payment}) => {

  

    return (
        <>
    <Document >
      <Page size="A4" style={styles.page}>
         <Text style={{ color: '#85c440', textAlign: 'center', fontSize:'18px'}}>{name}</Text>
         <Text style={{ color: '#262927', textAlign: 'center',  fontSize:'17px'}} >Del {  formatDate(payment.start_date)} al {formatDate(payment.end_date)}</Text>
         <View >
          <Text style={{ color: '#9697af', textAlign: 'left', fontSize:'14px', marginTop:25, marginLeft:20}}>Folio fiscal:  {data.length>0? data[0].fiscal_key: '...'}</Text>
          <Text style={{ color: '#9697af', textAlign: 'left', fontSize:'14px', marginLeft:20}}>Clave de rastreo de pago:   {data.length>0?data[0].tracking_key: '...'}</Text>
          <Text  style={{ color: '#9697af', textAlign: 'left', fontSize:'14px', marginLeft:20}}>Fecha y hora de transacción de pago:  {data.length>0?data[0].payment_date: '...'}</Text>
         </View>
         <View style={styles.page}>
         <View  style={styles.rowsTable} >
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}} >Número de guía</Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}} >Fecha</Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}}>Cliente</Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}}>Tipo de entrega</Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}}> Ganancia </Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}}> Tipo de recolección </Text>
            <Text style={{ color: '#262927', textAlign: 'center', fontSize:'13px', width: '20%'}}> Tipo de operación </Text>


          </View> 
        {data.map((order)=> (
            <>
          <View  style={styles.rows} >
            <Text style={{ color: '#85c440', textAlign: 'center', fontSize:'12px', width: '20%'}} >{order.order_id}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}} >{order.collected_in_p99 ? order.collected_in_p99: "No disponible"}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}}>{order.client? order.client : "No disponible"}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}}> {order.type}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}} >${order.cost}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}} >{order.self_pickup_type}</Text>
            <Text style={{ color: '#12344a', textAlign: 'center', fontSize:'12px', width: '20%'}} >{order.provider || '99Min'}</Text>


          </View> 
          </>
        ))}
          </View>
      </Page>
    </Document>
    </>
    )
        }

export default PaymentReport;


