import RoundImage from "../components/RoundImage";
import { useContext, useEffect,  useState} from "react";
import ProfitsCard from "../components/HomePage/ProfitsCard";
import BackgroundShape from "../components/BackgroundShape";
import { useHistory } from "react-router-dom";
import ProfileSection from "../components/HomePage/ProfileSection";
import { ContextWrapper } from "../context/AppWrapper";
import { getToRecollectPackages } from "../utils/requests";
import GreenModalWrapper from "../components/GreenModalWrapper";
import SpeechCloudTooltip from "../components/SpeechCloudTooltip";
import SelfPickupOnboarding from "../components/SelfPickupOnboarding";


const HomePage = (props: any) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [toRecollectCount, setToRecollectCount] = useState(null);
  const [typeRecollection, setTypeRecollection] = useState(false);
  const [onBoardingRecollect, setOnBoardingRecollect] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);


  const [height, setHeight] = useState<any>(null);
  const { userData, userToken } = useContext(ContextWrapper);
  const [ user, setUser ] = userData;
  const [ token ] = userToken;
  

const recollectPackages=()=>{
  const canSelfRecolect=user?.current_dispatch_place?.is_self_pickup===1 || user?.current_dispatch_place?.is_self_pickup===true
  return canSelfRecolect ? setTypeRecollection(true): history.push({pathname:"/scanner-recollect/external"}, {...user, userToken:token})
} 

  useEffect(() => {
    const currentToken = localStorage.getItem("userToken");
    if( props.location.state){
        setUser( props.location.state)
     } else if(!currentToken){
         history.push('/telefono') 
        }

    getToRecollectPackages().then(( { error, message, data } ) => {
      if (error) return alert(message);
      setToRecollectCount(data);
    });

    const hasDoneSelfOnboarding= localStorage.getItem("selfPickupIntro");
    if(!hasDoneSelfOnboarding) {
       setOnBoardingRecollect(true)
    }
  }, []);

  useEffect(() => {
    if (document.getElementById("height")) {
      setHeight(document.getElementById("height")!.clientHeight);

    }
  }, [document.getElementById("height")]);

  return (
    <>
      <div className="h-screen w-full overflow-y-auto relative " id="height">
        <BackgroundShape type={height<500? "quarter" : "large" } position="bottom" />

        <section
          className="flex justify-between items-center mx-5 h-1/6 "

        >
          <ProfileSection
            token={ token }
            title={
              user ? user?.current_dispatch_place?.business_name : "...cargando"
            }
            picture={
              !user || user.avatar_url === "NA"
                ? "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                : user.avatar_url
            }
            open={ open }
            setOpen={ setOpen }
          />
        </section>
        <section className="relative h-5/6 flex flex-col justify-between">
          <ProfitsCard number_packages={toRecollectCount} />
          <div className="w-full h-4/5 flex flex-col items-center justify-between">
            <p
              className="body2 color-white w-5/12 h-1/6 text-center semi-bold  Vxl:pt-3 "
              style={ { margin: "1vh auto" } }
            >
              { " " }
              Recibe y entrega paquetes ahora
            </p>
            <div className=" flex  justify-between h-3/5 w-10/12 ">
              <div className="flex flex-col items-center w-4/12 h-full">
              <div className="flex flex-col items-center  h-full ">

                <RoundImage
                  image="../icons/icon_recoleccion.svg"
                  size={height<500?3.5 : 4.5 }
                  alt="recolección"
                  onClick={ recollectPackages}
                  backgroundColor="white"
                  padding={ 1 }
                  shadow={ true }
                />
                <p className="bold color-white "> Recolección</p>
              </div>

                <div className="flex flex-col items-center  h-full ">
                <RoundImage
                 image="../icons/icon_tusganancias.svg"
                 size={height<500?3.5 : 4.5  }
                 alt="ganancias"
                 onClick={() => history.push("/ganancias")}
                 backgroundColor="white"
                 shadow={true}
                />
                <p className="bold color-white ">Mis ganancias </p>
              </div>
              </div>
              { user.current_dispatch_place?.is_delivery_cell === 1 && (
              <div className="flex flex-col items-center w-4/12 h-full relative justify-center">
                  <RoundImage
                    image="../icons/paquetes.svg"
                    size={ height<500?3.5 : 4.5  }
                    alt="paquetes"
                    onClick={ () => history.push("/packages") }
                    backgroundColor="white"
                    padding={ 1 }
                    shadow={ true }
                  />
                  <p className="bold color-white text-center">Mis paquetes</p>
              </div>
              )}
              <div className="flex flex-col items-center w-4/12 h-full ">
              <div className="flex flex-col items-center  h-full ">

                  <RoundImage
                  image="../icons/icon_entrega.svg"
                  size={ height<500?3.5 : 4.5  }
                  alt="recolección"
                  onClick={ () => history.push("/scanner-user") }
                  backgroundColor="white"
                  padding={ 1 }
                  shadow={ true }
                />
                <p className="bold color-white ">Entrega</p>
                </div>

                { user.current_dispatch_place?.is_delivery_cell === 1 ? (
                  <div className="flex flex-col items-center h-full ">
                    <RoundImage
                      image="../icons/icon_sacos.svg"
                      size={ height<500?3.5 : 4.5  }
                      alt="recolección"
                      onClick={ () => history.push("/exchange-point/home") }
                      backgroundColor="white"
                      padding={ 0.8 }
                      objectFit={ true }
                      shadow={ true }
                    />
                    <p className="bold color-white ">Mis sacos</p>
                  </div>
              ) 
              :(
                  <div className="flex flex-col items-center h-full ">
                      <RoundImage
                        image="../icons/paquetes.svg"
                        size={ height<500?3.5 : 4.5  }
                        alt="paquetes"
                        onClick={ () => history.push("/packages") }
                        backgroundColor="white"
                        padding={ 1 }
                        shadow={ true }
                      />
                      <p className="bold color-white text-center">Mis paquetes</p>
              </div>
              ) }
              </div>
              
            </div>
              <section className="w-full h-auto pt-10  Vxxs:pt-5 flex justify-center">
                <button
                  onClick={() => window.location.reload()}
                  disabled={false}
                  className={`flex items-center btn-plain buttonLegal mb-3`}
                >
                  <img
                    className="icon"
                    src="../icons/icon_refrescar.svg"
                    alt="Términos legales"
                  />
                  <p className="color-white normal ">Refrescar app</p>
                </button>
              </section>
          </div>
        </section>
      </div>
      <GreenModalWrapper setModal={setTypeRecollection} modal={typeRecollection} colorClose='white' canClose={true}>
        <div className="text-center h-96 flex flex-col justify-center items-center w-9/12">
        <b className="color-white body1 semi-bold Vxxs:text-sm ">¿Qué tipo de recolecciones quieres hacer?</b>
        <div className="flex w-full justify-between my-5 relative">
        <div className="flex flex-col items-center w-4/12 h-full relative ">
                <RoundImage
                 image="../icons/icon_paqueteExterno.svg"
                 size={height<500? 2.5 : 4.2  }
                 alt="ecternal"
                 onClick={() => history.push({pathname: "/scanner-recollect/external"}, {...user,userToken:token})}
                 backgroundColor="white"
                 shadow={true}
                />
                <p className="bold color-white ">Paquetes externos</p>
                <div className="relative">
                <button className="w-6	color-white bold mt-5" style={{borderRadius:'50%', backgroundColor:'var(--gray100)'}} onClick={()=>{
                  setTooltip(!tooltip)
                  !tooltip && tooltip2 && setTooltip2(false)
                  
                  }}>?</button>
                <div className="absolute">
                <SpeechCloudTooltip  modal={tooltip}  side={'left'}>
                  <div className="flex flex-col mt-0 pt-0">
                  <p className="bold mt-0 mb-1">¿Qué son los paquetes externos?</p>
                  <p>Son aquellos paquetes que recibes de otros usuarios y que los dejan en tu tienda para que tu los entregues al Veloz o al cliente.</p>
                  <button className="color-green99 mt-1 bold" onClick={()=>setTooltip(false)}>¡Entendido!</button>
                  </div>
                </SpeechCloudTooltip>
                </div>

                </div>
                
               
              </div>
              <div className="flex flex-col items-center w-4/12 h-full ">
                <RoundImage
                 image="../icons/icon_paquetePropio.svg"
                 size={height<500?2.5 : 4.2  }
                 alt="ganancias"
                 onClick={() => history.push({pathname: "/scanner-recollect/own"}, {...user, userToken:token})}
                 backgroundColor="white"
                 shadow={true}
                />
                  <p className="bold color-white ">Paquetes propios</p>
                <div className="relative">
                <button className="w-6	color-white bold mt-5" style={{borderRadius:'50%', backgroundColor:'var(--gray100)'}} onClick={()=>{
                  setTooltip2(!tooltip2)
                  !tooltip2 && tooltip && setTooltip(false)
                  }}>?</button>
                <div className="absolute">
                
                <SpeechCloudTooltip  modal={tooltip2} side={'right'}>
                <div className="flex flex-col mt-0 pt-0">
                  <p className="bold mt-0 mb-1">¿Qué son los paquetes propios?</p>
                  <p>Son aquellos paquetes que contienen tus productos y que tú nos entregas para enviarles a tus clientes.</p>
                  <button className="color-green99 mt-1 bold" onClick={()=>setTooltip2(false)}>¡Entendido!</button>
                  </div>
                </SpeechCloudTooltip>
                </div>
              </div>
          </div>
        </div>
        </div>
      </GreenModalWrapper>
     <GreenModalWrapper canClose={false }  setModal={setOnBoardingRecollect} modal={onBoardingRecollect} scroll={false}>
        <SelfPickupOnboarding setModal={setOnBoardingRecollect} user={{...user, userToken: token}}/>
       </GreenModalWrapper>
    </>
  )
};

export default HomePage;
