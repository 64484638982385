import React from 'react'

function CloseIcon({tint = '#fff'}) {
  return (
    <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect id="Rectangle_1445" data-name="Rectangle 1445" width="16" height="16" fill="none"></rect>
      <path id="Union_3" data-name="Union 3" d="M7.778,9.192,1.414,15.557,0,14.142,6.364,7.778,0,1.414,1.414,0,7.778,6.364,14.142,0l1.415,1.414L9.192,7.778l6.364,6.364-1.415,1.415Z" fill={tint}></path>
    </svg>
  )
}

export default CloseIcon
