
function CustomButton({className, type, onClick = () => {}, disabled, text, style }: any) {
  const colors: {[key: string]: any} = {
    primary: 'green-100',
    secondary: 'transparent',
    disabled: 'transparent',
    white: 'white'
  }

  const callBack = () => {
    if (disabled === true) return
    onClick()
  }

  return (
    <button style={{ ...style }} onClick={callBack} className={`w-auto h-min px-4 py-2 bg-${colors[type]} ${type === 'white' ? 'text-green-200' : 'text-white'} rounded-full ${className}`}>
      {text}
    </button>
  )
}

export default CustomButton
