import React from "react";

type Type = "plain" | "shadow" | "unselected";

interface Props {
  icon: string;
  label?: string;
  type?: Type;
  onClick?: (event?: any) => void;
}

const IconBtn = ({ icon, label, type = "plain", onClick }: Props) => {
  let cursorType = onClick ? "pointer" : "auto";
  return (
    <button
      onClick={onClick}
      className={`flex items-center btn-${type}`}
      style={{ cursor: cursorType }}
    >
      <img className="icon" src={icon} alt={label} />
      <strong>{label}</strong>
    </button>
  );
};

export default IconBtn;
