import { useState, useMemo } from 'react'
import { createContext } from 'react';
import { getCompleteUserData } from "../utils/axiosRequest";

const ContextWrapper = createContext();


function AppWrapper({ children }) {
    const [reload, setReload] = useState(false)
    const [user, setUser]= useState('')
    const [token, setTokens]= useState('')

    const currentToken= localStorage.getItem("userToken");
  

const setUserAuth=(info)=>{
  setUser(info)
  setTokens(currentToken)
}
    useMemo(()=>{     
      getCompleteUserData(currentToken)
      .then((info) => {
        if (info) {
          info.data && setUserAuth(info.data)
        }
      })
      .catch((error) => {
        console.error('auth',error)
      });
    },[ ])
    
  return (
    <ContextWrapper.Provider value={{
      load:[reload, setReload],
      userData: [user, setUser],
      userToken: [token, setTokens],
      }}>
      {children}
    </ContextWrapper.Provider>
  );
}
export { ContextWrapper, AppWrapper }