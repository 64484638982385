// @ts-nocheck
import {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ArrowRightIcon, BlackDot } from "../../assets/icons";
import { deliverBagApi, getContainersList} from "../../utils/requests";
import "react-toastify/dist/ReactToastify.css";

function ScannerVeloz() {
  const { goBack, push, location } = useHistory();

  let intervalHook = null

  const listenToVelozScanner = async () => {
    const { error, message, data } = await getContainersList(undefined, null, 1, false, false, location?.state?.containerID )
    
    if(error) {
      toast.error(message || 'No se pudo escanear paquete')
      return
    }else if(data.length===0){
      toast.error(message || `No se encontró ningún paquete con ${location.state.containerID}`)
    }else if (data[0].status_id === 3) {
      clearInterval(intervalHook)
      push("/exchange-point/deliver-info-veloz", { ...location?.state, evidenceType: 'deliverExchangePoint', veloz: { ...data } });
    }
  }

 let evidence= {
    "evidences": [
        {
            "type": "RELEASE",
            "url": location?.state?.evidenceUrl
        }
    ]
}
  const freeBagToVeloz = async () => {
    const { error, message, data } = await deliverBagApi(evidence, location?.state?.containerID)
    console.log(data);
    if (error) {
      toast.error(message || 'Ocurrió un error liberando el saco')
    }
    toast.success('Saco liberado correctamente para escaneo de veloz.')
  }

  const cleanVelozInterval = () => {
    clearInterval(intervalHook)
  }
  
  useEffect(() => {
    freeBagToVeloz()
  }, [])

  useEffect(() => {
    intervalHook = setInterval(() => {
      listenToVelozScanner()
    }, 5000)
    return cleanVelozInterval
  }, [])

  return (
      <div className="h-screen w-screen gradient-green overflow-y-hidden fixed">
        <ToastContainer />
        <div
          onClick={goBack}
          className="px-4 py-8 xs:px-2 xs:py-4"
          id="back-button"
        >
          <ArrowRightIcon rotate={"90"} tint={"white"} />
        </div>
        <div className="h-full w-screen px-8 xs:px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="font-bold text-center text-xl xs:text-lg text-white mb-3">
              Estás entregando<br /><span className="text-gray-200"> un saco</span>
            </span>
            <BlackDot />
          </div>
          <div className="flex mt-7 xs:mt-4 items-center justify-center">
            <span className="text-center text-white">
             <span className="font-bold">Pide al veloz escanear el saco</span><br />
              y te avisaremos cuando puedas <br /><span className="font-bold">completar la entrega</span>
            </span>
          </div>

          <div className="flex items-center justify-center w-full mt-10">
            <div className="sm:w-100 sm:h-100 w-72 h-72 relative flex items-center justify-center">
                <div className="lds-dual-ring-white"></div>
              </div>
          </div>

          <div className="flex w-full justify-center mt-10">

          </div>
        </div>
      </div>
  );
}

export default ScannerVeloz;
