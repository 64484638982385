import { useLocation } from "react-router-dom";

interface Props {
  number_packages: null| number| string ;
}

const ProfitsCard = ({ number_packages }: Props) => {
  const location = useLocation();

  return (
    <div className="profitCardContainer relative mx-auto Vxs:my-1 Vsm:my-1 Vmd:my-10 Vlg:my-10 Vxl:mt-20" >
      <img
        src="../icons/icon_ganado.svg"
        alt="icon"
        className="rounded-pic profitCardIcon"
      />
      <div className="text-center">
        <p className="color-batman bold">¡Buen día!</p>
        { number_packages===0 ?
          <div className="w-60">
          <h6 className="m-0 color-green99 semi-bold">
          No tienes paquetes por recolectar
          </h6>
        </div>
        :
        <>
          <p className="color-batman">Tienes por recolectar</p>
            <h4 className="m-0 color-green99 semi-bold flex">
           <div className="flex justify-center items-center"> {!number_packages? <div className="lds-dual-ring-small" /> : number_packages} {location.pathname==='/home' ? 'paquetes' : 'sacos'}</div>
            </h4>
        </>

        }
      </div>
    </div>
  );
};

export default ProfitsCard;
