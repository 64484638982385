// @ts-nocheck
import { useContext, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowRightIcon } from "../assets/icons";
import { getOrderInfo, saveEvidence } from "../utils/requests";
import MultipleOrdersContext from "../context/MultipleOrdersContext/context";
import QRScanner from "../components/QRScanner";

function ScannerRecollectPage(props) {
  const { goBack, push } = useHistory();
  const {pathname} = useLocation();
  const [smsCode, setSmsCode] = useState('');
  const { setMultipleOrders, setEvidenceURLRecollect } = useContext(MultipleOrdersContext)
  const [loading, setLoading] = useState(false);


  const scanSuccessful=async()=>{
    const audio = new Audio('/Sonido2.mp3');
    audio.play()
    
    if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }

  window.navigator.vibrate(300);
  }

  

  const onScan = async (result: string, errorScanner: string, getFrame:string) => {
    if (errorScanner || !result) return
    setLoading(true)
    scanSuccessful()
    const { error, message, data } = await getOrderInfo(result)
    
    if (error) {
      toast.error(message || 'Something went\nwrong obtaining \n order data')
      setLoading(false);
      return
    }
    
    if (message === 'multipleOrders') {
      setMultipleOrders(data)
      push('/multiple-orders')
      setLoading(false)
      return
    }
    const validStatus = [1, 3, 4.1, 4.2, 6, 8, 10, 12, 14, 14.1, 14.4, 16, 17, 100, 102, 110, 104, 120]
    // status validos para recoleccion status 1 100 110 102 104 120 3 4.1 4.2
    if(validStatus.indexOf(data.order_status_id) === -1) {
      toast.error(`Orden ${result} ya recolectada, actualmente en status ${data.order_status_id}.`)
      setLoading(false)
      return
    }
    push(`/confirm-recollect/${pathname==='/scanner-recollect/external'?'external':'own'}` , { orderInfo: data, orderNumber:result});
    setLoading(false)
    await saveEvidence({ type: 'recollect', orderNumber: result, image: getFrame }).then(async(evidence)=>{
      if(!evidence.error){
        sessionStorage.setItem('evidence', evidence.data)
        setEvidenceURLRecollect(evidence.data)
      }else if(evidence.error){
         goBack()
      }
    })
    return
  };

  const onKeyUp = (e: any, image:string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onScan(smsCode, false, image)
    }
  }

  return (
    <div className="h-screen w-screen gradient-green overflow-auto	">
      <ToastContainer />
      <div
        onClick={goBack}
        className="px-4 py-8 xs:px-2 xs:py-4"
        id="back-button"
      >
        <ArrowRightIcon rotate={"90"} tint={"white"} />
      </div>
      
      <QRScanner
        title='Estás recolectandos'
        onKeyUp={onKeyUp}
        text={smsCode} 
        onScan={onScan}
        loading={loading}
        setText={setSmsCode}
      />
      
      <button className="text-sm	absolute rounded-3xl color-white py-2 px-4 border-2 border-solid border-white bottom-7 left-5" onClick={()=>push(`/scanner-recollect/${pathname==='/scanner-recollect/external' ?'own': 'external'}`)} >
      Recolectar paquetes {pathname==='/scanner-recollect/external' ?'propios': 'externos'}
      </button>
      
    </div>
  );
}

export default ScannerRecollectPage;
