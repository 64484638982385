import { useState, useMemo,useContext } from "react";
import VerificationInput from "react-verification-input";
import { useHistory } from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import {
  loginUser,
  generateCode,
  generateWhatsappCode,
} from "../utils/axiosRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCompleteUserData } from "../utils/axiosRequest";
import DateObject from "react-date-object";
import { ContextWrapper } from "../context/AppWrapper";

const CodeVerification = () => {
  let history = useHistory();
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { userData } = useContext(ContextWrapper);
  const [ _, setUser ] = userData;
  //global context

  useMemo(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const currentUser = localStorage.getItem("userGeneral");
  const parsedUser = JSON.parse(currentUser);

  const isNewUser=(token) => {
    getCompleteUserData(token)
      .then(( info ) => {
        let dateCreationDate= new Date(info.data.current_dispatch_place.created_at)
        const dateCreationDP= info && new DateObject(dateCreationDate)
        const startingDate=  new DateObject('2022-06-06T12:00:00.000Z')
        // if terms details is false (null), was created before t&C implementation, is inactive
        console.log( info, !info.data.current_dispatch_place.terms_details , dateCreationDP>startingDate ,dateCreationDP.toDate(), startingDate.toDate() , info.data.current_dispatch_place.is_active===0);
        if (info &&  !info.data.current_dispatch_place.terms_details && dateCreationDP>startingDate&& info.data.current_dispatch_place.is_active===0) { 
          localStorage.setItem("tempToken", token);
          history.push({
            pathname: '/terminos-y-condiciones',
            state: {...info, token } 
          });
        }
        else{
          setUser(info.data);
          localStorage.setItem("userToken", token);
          history.push({
            pathname: '/home',
            state:  info.data 
          });
        }
      })
      .catch(( error ) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

  const login = async () => {
    setIsLoading(true)
    loginUser(parsedUser.id, code)
      .then(async (info) => {
        if (info) {
          setIsLoading(false)
          isNewUser(info.accessToken)
        } else {
          setIsLoading(false)
          setError(true);
        }
      })
      .catch((errors) => {
        console.error(errors)
        setIsLoading(false)
      });
  };

  const sendWhatsappCode = (tel, name, fmtCode) => {
    generateWhatsappCode(tel, name, fmtCode).then((success) => {
      if (success.error) {
        toast.error("Error al envíar código por Whatsapp");
      } else if (!success.error && success.data.calixta.status !== "success") {
        toast.error("Error al envíar código por Whatsapp");
      } else if (!success.error && success.data.calixta.status === "success") {
        toast.success("Se te ha enviado un nuevo código por Whatsapp");
      }
    });
  };

  const createNewCodeSMS = () => {
    generateCode(parsedUser.id, false).then((success) => {
      if (success.error) {
        toast.error(success.message.message);
      } else if (!success.error && !success.data.success) {
        toast.error(success.message);
      } else if (!success.error && success.data.success) {
        toast.success("Se te ha enviado un nuevo código por SMS");
      }
    });
    setTimer(60);
  };

  const createWhatsappCode = () => {
    generateCode(parsedUser.id, true).then((success) => {
      if (success.error) {
        toast.error(success.message.message);
      } else if (!success.error && !success.data.success) {
        toast.error(success.message);
      } else if (!success.error && success.data.success) {
        const body = success.data.body.code;
        const fmtCode = body.slice(2, 7);
        sendWhatsappCode(parsedUser.phone, parsedUser.name, fmtCode);
      }
    });
    setTimer(60);
  };

  return (
    <div className=" w-full h-screen overflow-y-hidden fixed">
      <ToastContainer />
      <div className=" upperSectionOnboardingPhone pt-28">
        <BackgroundShape type={"quarter"} position="top" />
        <div className="titleSectionPhone">
          <h6 className="m-0 bold color-white">
            Ingresa el código de verificación
          </h6>
          <img
            className="icon mt-2"
            src="../icons/point_yellow.svg"
            alt="yellow_circle"
          />
        </div>
        <div className="flex flex-col items-center mt-5">
          <p className="body3 color-white mt-5">
            {" "}
            {`Has recibido un ${
              parsedUser.send ? "SMS" : "WhatsApp"
            } con tu código`}
          </p>
          <VerificationInput
            validChars={/'0-9'/}
            length={5}
            value={code}
            onChange={setCode}
            removeDefaultStyles
            classNames={{
              container: "container",
              character: "character",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
          />
          {error && (
            <p className="mt-3 semi-bold" style={{ color: "#990000" }}>
              Código incorrecto
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center w-full absolute bottom-24">
        <button
          disabled={(code && code.length === 5 ? false : true) || isLoading}
          className="flex items-center justify-centermy-2 agave-button  ag-button-primary"
          onClick={login}
          style={{ border: "solid 2px white" }}
        >
          {isLoading ? <div className="lds-dual-ring-small-white" /> : 'Continuar'}
        </button>
        {timer !== 0 ? (
          <b className="color-gray50 mt-4">
            Reenviar código en 00:
            {timer.toString().length <= 1 ? `0${timer}` : timer}
          </b>
        ) : (
          <button
            disabled={isLoading}
            className="color-gray100 underline mt-4 "
            onClick={parsedUser.send ? createNewCodeSMS : createWhatsappCode}
          >
            Reenviar código
          </button>
        )}
      </div>
    </div>
  );
};

export default CodeVerification;
