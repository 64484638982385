
interface Props {
  text: string | number;
  bgColor: string;
  txtColor: string;
  borderRadius?: string;
}

const ColorTag = ({ text, bgColor, txtColor, borderRadius = "25%" }: Props) => {
  
  return (
    <div
      className="py-0.5 px-2"
      style={{
        backgroundColor: `${bgColor}`,
        color: txtColor,
        borderRadius: borderRadius,
      }}
    >
      <small className='bold'> {text}</small>
      
    </div>
  );
};

export default ColorTag;
