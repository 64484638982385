import { useState, useContext, useMemo } from "react";
import IconBtn from "../components/IconBtn";
import IconLabel from "../components/IconLabel";
import { useHistory } from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import AgaveButton from "../components/AgaveButton";
import { getDispatch, updateDispatch } from "../utils/axiosRequest";
import { ContextWrapper } from "../context/AppWrapper";

function PayInfoPage() {
  let history = useHistory();
  const { userData, load } = useContext(ContextWrapper);
  const [reload, setReload] = load;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [user] = userData;
  const format = {
    clabe: "...",
    beneficiary_name: "...",
    bank_brand: "...",
    apply_invoice: null,
    product_key: "...",
    voucher: "...",
    use_cfdi: "...",
  };
  const [dispatchData, setDispatchData] = useState(format);
  const [input, setInput] = useState(format);



  useMemo(() => {
    const userToken = localStorage.getItem("userToken");
    if (user) {
      getDispatch(user.current_dispatch_place_id, userToken).then((success) => {
        if (success) {
          let timeParsed = JSON.parse(success.data.schedule);
          setInput({
            ...success.data,
            //send schedule as object, else 422 error
            schedule: timeParsed,
          });
          setDispatchData(success.data);
        } else {
          history.push('/home')
          setError(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reload]);

  const cancelEdit = () => {
    setIsEditable(false);
    setError(false);
    setInput(dispatchData);
  };

  const updateUserData = () => {
    const userToken = localStorage.getItem("userToken");
    updateDispatch(
      {
        ...input,
      },
      user.current_dispatch_place_id,
      userToken
    ).then((success) => {
      if (success) {
        setIsEditable(false);
        setError(false);
        setReload(!reload);
      } else {
        setError(true);
      }
    });
  };

  return (
    <div className="h-screen w-full overflow-y-hidden fixed">
      <BackgroundShape type={"small"} position="bottom" />
      <BackgroundShape type={"small"} position="top" />
      <div className="upperSection flex flex-col">
        <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />{" "}
        </div>

        <div className=" flex flex-col items-center">
          <h4 className="m-0 semi-bold color-white"> Mis datos bancarios </h4>
          <img className="icon" src="../icons/point_black.svg" alt="map" />
        </div>
      </div>

      {/* form starts*/}
      <div className="form mx-auto mt-32 ">
        <div className="w-full px-5 flex flex-col items-center justify-center w-full">
          {/* <div className="w-full flex mr-1 mt-5 justify-end">
            <IconBtn
              icon="../icons/icon_edit.svg"
              // onClick={() => setIsEditable(true)}
            />
          </div> */}
          <div className="mt-5 mb-2 w-full">
            <IconLabel

              label="Factura"
              icon="../icons/icon_web.svg"
              text={!input["apply_invoice"]? '...': input["apply_invoice"]===1? 'Sí': 'No'}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["apply_invoice"]===1? 'Sí': 'No'}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  clabe: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-5 mb-3 w-full">
            <IconLabel
              label="Clabe"
              icon="../icons/icon_clabe.svg"
              text={input["clabe"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["clabe"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  clabe: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="my-3 w-full">
            <IconLabel
              label="Beneficiario"
              icon="../icons/icon_user.svg"
              text={input["beneficiary_name"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["beneficiary_name"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  beneficiary_name: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="my-3 w-full">
            <IconLabel
              label="Banco receptor"
              icon="../icons/icon_bank.svg"
              text={input["bank_brand"]}
              textColor="var(--gray100)"
              padding={1.8}
              edit={isEditable}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  bank_brand: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
              input={input["bank_brand"]}
            />
          </div>
          <div className="my-3 w-full">
            <IconLabel
              label="Uso CFDI"
              icon="../icons/icon_rubro.svg"
              text={input["use_cfdi"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["use_cfdi"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  clabe: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>
          <div className="my-3 w-full">
            <IconLabel
              label="Efecto de Comprobante"
              icon="../icons/icon_telefono.svg"
              text={input["voucher"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["voucher"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  clabe: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>
          <div className="mt-3 mb-5 w-full">
            <IconLabel
              label="Clave de producto"
              icon="../icons/icon_bank.svg"
              text={input["product_key"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["product_key"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  clabe: e.replace(
                    /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>
          {isEditable && (
            <div className="w-full flex justify-end my-6 flex-wrap">
              <button onClick={cancelEdit} className="mr-5 my-1">
                <AgaveButton type="secondary" text="Cancelar" />
              </button>
              <button onClick={updateUserData} className="mr-5 my-1">
                <AgaveButton type="primary" text="Guardar" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="downSection"></div>
    </div>
  );
}

export default PayInfoPage;
