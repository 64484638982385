import React from "react";

export default function Arrow({ tint = '#bababa', rotate = '180deg' }) {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ transform: `rotate(${rotate})` }}
    >
      <path
        id="Path_10"
        data-name="Path 10"
        d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
        transform="translate(16 16) rotate(180)"
        fill={tint}
      >

      </path>
    </svg>
  );
}
