export default function Arrow({  rotate = '180deg' }) {
    return (
  
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        style={{ transform: `rotate(${rotate})` }}
      >
        <path 
        id="Path_3470" 
        data-name="Path 3470"
        d="M1878,51l5,5,5-5" 
        transform="translate(-1876.586 -49.586)" 
        stroke="#888"
        fill="none"
        stroke-linecap="round" 
        strokeLinejoin="round" 
        stroke-width="2"
        >
        </path>
      </svg>
    );
  }
