// @ts-nocheck
import { QrReader } from "@blackbox-vision/react-qr-reader";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  ArrowRightIcon,
  BlackDot,
  CheckIcon,
  CloseIcon,
  CodeIcon,
  UserIcon,
} from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";
import Input from "../components/Input";
import EvidenceModal from "../components/PackagesPage/EvidenceModal";
import {
  changeOrderStatus,
  getOrderInfo,
  makePackageDevolution,
  saveEvidence,
} from "../utils/requests";
import { sleep } from "../utils/sleep";
import MultipleOrdersContext from "../context/MultipleOrdersContext/context";


function ConfirmDeliverPage() {
  const { goBack, push, location } = useHistory();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const { cleanState , state, setEvidenceURLDelivery} = useContext(MultipleOrdersContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openEvidenceModal, setOpenEvidenceModal] = useState(false);
  const [verifyingModalView, setVerifyingModalView] = useState("photo"); // photo | verifyPhoto | verifyOrder | success
  const [evidencePhoto, setEvidencePhoto] = useState(null);

  const scanSuccessful=()=>{
    const audio = new Audio('/Sonido2.mp3');
    audio.play()
        
    if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }
    window.navigator.vibrate(300);
  }
  const removeEvidence=()=>{
    setEvidenceURLDelivery('')
    sessionStorage.removeItem('evidenceDelivery')
  }

  const confirmDeliver = async () => {
    setLoading(true);
    const { error, message } = await changeOrderStatus(
      state.evidenceDel|| sessionStorage.getItem('evidenceDelivery'),
      location.state?.orderNumber,
      location.state?.orderInfo?.order_status_id === 11 ||
        location.state?.orderInfo?.order_status_id === 12
        ? "return_to_originator"
        : "deliver_to_customer",
      location.state?.orderInfo.deliver_to,
      location.state?.isNoVerificationCode
    );
    if (error) {
      setLoading(false);
      return alert(
        message ||
          "Ocurrió un error al intentar entregar esta orden, codigo de error CDP29COS"
      );
    }
    setConfirm(true);
    setOpenEvidenceModal(false);
    cleanState();
    removeEvidence()
    toast.success("Orden entregada correctamente!");
  };

  const makeDevolution = async () => {
    const { error, message } = await makePackageDevolution(
      state.evidenceDel || sessionStorage.getItem('evidenceDelivery'),
      reason,
      location.state?.orderNumber
    );
    if (error)
      return alert(
        message ||
          "Ocurrio un error al intentar entregar esta orden, codigo de error CDP46MPD"
      );
    setIsOpen(false);
    await sleep(1000);
    removeEvidence()
    push("/home");
    
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setReason(e.target.value);
  };



  const captureVideo = (video: any) => {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var canvasContext = canvas.getContext("2d");
      //@ts-ignore
      canvasContext.drawImage(video, 0, 0);
      return canvas.toDataURL("image/png");
    } catch (e) {
      console.log(e);
    }
  };

  const takePhoto = async () => {
    const getFrame = captureVideo(document.getElementById("qrreader"));
    setVerifyingModalView("verifyPhoto");
    setEvidencePhoto(getFrame);
  };

  const retakePhoto =  () => {
    setVerifyingModalView("photo");
    setEvidencePhoto(null);
  };

  const VerifyPhotoView = () => {
    const acceptPic=async ()=>{
      setVerifyingModalView("verifyOrder")
      setLoading(true)
      if (location.state?.isNoVerificationCode) {
        const evidenceURL = await saveEvidence({
          type: "deliver",
          orderNumber: location.state?.orderNumber,
          image: evidencePhoto,
        });

        if(!evidenceURL.error){
          setEvidenceURLDelivery(evidenceURL.data)
          sessionStorage.setItem('evidenceDelivery', evidenceURL.data)
          setLoading(false)
       
        }else if(evidenceURL.error){
          toast.error('Favor de volver a tomar la evidencia.')
          setVerifyingModalView("photo");
          setEvidencePhoto(null);
          setLoading(false)

       }

      }
    }

    return (
      <div className="max-w-lg">
        <strong>La fotografía es clara?</strong>
        <img src={evidencePhoto} alt='evidencia' style={{maxHeight:'35vh', objectFit: 'contain'}} />
        <div className="flex w-full flex-row justify-between items-center ">
          <button
            onClick={acceptPic}
            className="mt-3 w-4/12 h-min rounded border border-green-100 text-white px-2 py-1 bg-green-100"
          >
            Aceptar
          </button>
          <button
            onClick={retakePhoto}
            className="mt-3 w-4/12 h-min rounded border border-red-100 text-white px-2 py-1 bg-red-100"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  };

  const TakingPhotoView = () => {

    return (
      <>
        <strong className="text-center">
          Toma una fotografía del paquete junto a la identificación
          del cliente.
        </strong>
        <div className="flex items-center justify-center w-full mt-10 Vxxs:mt-3">
          <div className="content sm:w-100  w-72 h-56  Vxxs:w-4/6 Vxxs:h-30	 relative bg-whiteflex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
          <div style={{ width: '101%', height: '101%', display: loading ? 'none' : '' }} className="absolute z-10">
            </div>
            {loading ?
            <div className={`bg-white absolute w-full h-full flex items-center justify-center`}>
              <div className="lds-dual-ring"></div>
            </div>:
              <QrReader
              videoId={"qrreader"}
              onResult={()=>{}}
              containerStyle={{ width: '100%',height: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%', objectFit: 'fill' }}
              videoContainerStyle={{ display: 'flex',padding:'0', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={100}
            />}
          </div>
        </div>

        <button
          onClick={takePhoto}
          className="mt-3 rounded border border-green-100 text-white px-2 py-1 bg-green-100"
        >
          Tomar fotografía
        </button>
      </>
    );
  };

  const VerifyOrderView = () => {
    const [text, setText] = useState({text: ""});

    const onScan = async (result: any, errorScan: any) => {
      if (errorScan || !result) return
      setLoading(true);
      scanSuccessful()
      const { error, message, data } = await getOrderInfo(
        result.text
      );
      if (error) {
        toast.error(message||"Something went wrong");
        setLoading(false);
        return;
      }
      if (data.external_reference !== location.state?.orderNumber) {
        toast.error(
          "El número que se ingresó al inicio es distinto al qr del paquete o el numero ingresado."
        );
        setLoading(false)
        return;
      }
      confirmDeliver();
    };

    const onKeyUp = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        onScan(text, false);
      }
    };


    return (
      <>
        <strong className="text-center">
          Vuelve a escanear o ingresar el número de guía para confirmar los
          datos.
        </strong>
        <Input
          border=""
          inputClassNames="text-black border-b border-black"
          onKeyDown={onKeyUp}
          textAlign="center"
          placeholder="Número de guía"
          onChange={ (text)=>setText({text})}
          value={text.text}
        />
        
        <div className="flex items-center justify-center w-full mt-10 Vxxs:mt-3">
          <div className="content sm:w-100 sm:h-52 w-72 h-56  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
          <div style={{ width: '101%', height: '101%', display: loading ? 'none' : '' }} className="absolute z-10">
            </div>

            {loading ? <div className={`bg-white absolute w-full h-full flex items-center justify-center`}>
              <div className="lds-dual-ring"></div>
            </div>:
              <QrReader
              videoId={"qrreaderorder"}
              onResult={onScan}
              containerStyle={{ width: '100%', display: 'flex',  height: '100%', justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%' , objectFit: 'fill' }}
              videoContainerStyle={{ display: 'flex', padding:'0', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={100}
            />
            }
          </div>
        </div>
        <button
            disabled={loading}
            onClick={() => onScan(text, false)}
            className="mt-3 rounded border border-green-100 text-white px-2 py-1 bg-green-100"
          >
            {loading ? <div className="lds-dual-ring-small-white" /> : "Confirmar"}
          </button>
      </>
    );
  };

  const SuccessView = () => {
    return (
      <>
        <strong>
          Los datos son correctos, presiona "Realizar entrega" para finalizar.
        </strong>

        <button
          onClick={takePhoto}
          className="mt-3 rounded border border-green-100 text-green-100 px-2 py-1"
        >
          Realizar entrega
        </button>
      </>
    );
  };

  return (
    <div>
      <ToastContainer />
      <EvidenceModal modal={openEvidenceModal} setModal={setOpenEvidenceModal}>
        <div className="flex-col  w-11/12  flex justify-center items-center mx-10 mt-10 mb-5">
          {{
            photo: <TakingPhotoView />,
            verifyPhoto: <VerifyPhotoView />,
            verifyOrder: <VerifyOrderView />,
            success: <SuccessView />,
          }[verifyingModalView] || <div>No reached</div>}
        </div>
      </EvidenceModal>
      <div className="h-screen w-screen overflow-y-hidden fixed">
        <div
          style={{
            transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
            height: "min-content",
            zIndex: 6,
          }}
          className={`absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green ${
            isOpen ? "slide-open" : "slide-close"
          }`}
        >
          <div
            onClick={() => setIsOpen(false)}
            className="flex w-full pt-5 pr-5 justify-end"
          >
            <CloseIcon />
          </div>

          <div className="flex flex-col items-center px-10">
            <span className="font-bold text-white text-lg text-center">
              Motivo por el que no aceptó
              <br />
              su paquete
            </span>
            <textarea
              value={reason}
              onChange={onChange}
              className="w-full rounded-lg h-32 px-4 py-2 mt-5"
              placeholder="Escribe el motivo"
            />
            <div
              onClick={makeDevolution}
              className="flex items-center justify-center my-5 px-4 py-2 w-2/3 bg-white rounded-full"
            >
              <span className="text-lg text-center text-green-100 font-medium">
                Solicitar devolución
              </span>
            </div>
          </div>
        </div>

        <BackgroundShape type={"quarter"} position="bottom" />
        <div className="flex flex-col justify-between h-full w-full">
          <div>
            <div
              onClick={()=>{
                goBack()}}
              className="px-4 py-8 xs:px-2 xs:py-4"
              id="back-button"
            >
              <ArrowRightIcon rotate={"90"} />
            </div>
            {confirm ? (
              <div className="flex flex-col items-center w-full px-10 mt-10">
                <div>
                  <CheckIcon color="rgb(133, 196, 64)" />
                </div>
                <div className="font-bold text-green-100 Vxxs:text-white">
                  ¡Gracias por entregar el paquete!
                </div>
                <div className="font-bold text-lg text-green-100 text-center mt-5">
                  {/* Con este paquete
                <br /> ganaste $15 */}
                </div>
              </div>
            ) : (
              <>
                <div className="flex w-full justify-center">
                  <span className="text-xl text-center font-semibold text-green-100">
                    Estás por entregar el paquete
                    <br />
                    <span className="text-gray-200">al cliente</span>
                  </span>
                </div>
                <div className="flex items-center justify-center w-full mt-2">
                <div className=" Vxxs:hidden">
                  <BlackDot />
                  </div>
                </div>
                <div className="flex items-center justify-center Vxxs:my-0 mt-7 w-full">
                  {location.state?.isNoVerificationCode === true ? (
                    
                      <span className=" text-sm text-red-200 font-light text-center w-10/12 bold">
                        Por favor solicita el documento de identidad al cliente
                        y valida que coincida con el nombre desplegado en esta
                        pantalla. Si el nombre coincide, has click en "Entregar
                        paquete", de lo contrario has click en "Cancelar".
                      </span>
                  ) : (
                    <>
                      <span className="text-md text-gray-200 font-light text-center">
                        Revisa que los datos coincidan con
                        <br />
                        el paquete que vas a entregar
                      </span>
                    </>
                  )}
                </div>
              </>
            )}
            {!confirm && (
              <div className="px-8 w-full mt-10 Vxxs:my-2">
                <div
                  style={{
                    boxShadow: "0 10px 20px 0 rgba(128, 128, 128, 0.31)",
                  }}
                  className="w-full bg-white rounded-md"
                >
                  <div className="flex flex-row items-center px-5  Vxxs:py-2 py-4 border-b border-gray-1800 ">
                    <div className="flex items-center justify-center w-1/12">
                      <UserIcon />
                    </div>
                    <span className="ml-5">
                      {[11, 12].includes(
                        location.state?.orderInfo?.order_status_id
                      )
                        ? location.state?.orderInfo?.origin_name !== null
                          ? location.state?.orderInfo?.origin_name
                          : location.state?.orderInfo?.client_id
                        : location.state?.orderInfo?.deliver_to}
                    </span>
                  </div>
                  <div className="flex flex-row items-center px-5 Vxxs:py-2 py-4">
                    <div className="flex items-center justify-center w-1/12">
                      <CodeIcon />
                    </div>
                    <span className="ml-5">
                      {location.state?.orderInfo?.external_reference}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col w-full items-center">
            {confirm ? (
              <>
                <div className="flex flex-col items-center w-full mb-12">
                  <div
                    onClick={() => push("/home")}
                    className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                  >
                    <span className="text-lg text-green-100 font-semibold">
                      Finalizar
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col items-center w-full Vxxs:mt-4 mt-12">

                {location.state?.isNoVerificationCode ?
                 <button
                    disabled={loading }
                    onClick={() =>setOpenEvidenceModal(true)}
                    className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                  >
                    {loading ? (
                      <div className="lds-dual-ring-small"></div>
                    ) : (
                      <span className="text-lg text-green-100 font-semibold">
                        Entregar paquete
                      </span>
                    )}
                  </button>
                  :
                  <button
                    disabled={loading || (!state.evidenceDel && !sessionStorage.getItem('evidenceDelivery'))}
                    onClick={() =>confirmDeliver()}
                    className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                  >
                    {(loading || (!state.evidenceDel && !sessionStorage.getItem('evidenceDelivery')))? (
                      <div className="lds-dual-ring-small"></div>
                    ) : (
                      <span className="text-lg text-green-100 font-semibold">
                        Entregar paquete
                      </span>
                    )}
                  </button>}
                  <button
                    onClick={() => {
                      location.state?.isNoVerificationCode === undefined
                        ? setIsOpen(true)
                        : push("/home");
                    }}
                    className="flex items-center justify-center my-5 px-4 py-2 w-2/3  rounded-full"
                  >
                    <span className="text-lg text-white font-thin">
                      {location.state?.isNoVerificationCode === undefined
                        ? "Paquete rechazado"
                        : "Cancelar"}
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeliverPage;
