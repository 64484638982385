function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.365"
      height="19.045"
      viewBox="0 0 13.365 19.045"
    >
      <g
        id="Group_4409"
        data-name="Group 4409"
        transform="translate(-51.253 -244.203)"
      >
        <circle
          id="Ellipse_585"
          data-name="Ellipse 585"
          cx="3.419"
          cy="3.419"
          r="3.419"
          transform="translate(54.244 245.203)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></circle>
        <path
          id="Path_3377"
          data-name="Path 3377"
          d="M6,20.682V18.788A3.788,3.788,0,0,1,9.788,15h3.788a3.788,3.788,0,0,1,3.788,3.788v1.894"
          transform="translate(46.253 241.566)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
      </g>
    </svg>
  );
}

export default UserIcon;
