// @ts-nocheck
import {  useState } from "react";
import { useHistory } from "react-router-dom";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { ToastContainer, toast } from "react-toastify";
import { ArrowRightIcon, BlackDot } from "../../assets/icons";
import { getContainersList} from "../../utils/requests";
import "react-toastify/dist/ReactToastify.css";

function ScannerDeliverPage() {
  const { goBack, push } = useHistory();
  const [loading, setLoading] = useState(false);
  let text = "";

  const setText = (textTo: string) => {
    text = textTo;
  };



  const onScan = async (result: any, errorScan: any) => {
    if (errorScan) return;
    if (text.length > 1) return;
    setLoading(true);
    setText(typeof result === "string" ? result : result.getText());

    // dispatchPlaceId?: string ,number?: number , size?: number , isLocal?:boolean, isDelivered?:boolean, reference?:string
    const { error, message, data } = await getContainersList(undefined, null, 1, false, false, result.getText() )
    if(error) {
      toast.error(message || 'No se pudo escanear paquete')
      setLoading(false)
      return
    }else if(data.length===0){
      toast.error(message || `No se encontró ningún paquete con ${result.getText()}`)
      setLoading(false)
    }else if(data[0].status_id!==1){
    // console.log(data[0].status_id, 'container data')
    toast.error(`El contenedor no puede ser entregado en status '${data[0].status}'`)
    setLoading(false)
  }
  else{
    push("/exchange-point/deliver-info", { containerID: typeof result === "string" ? result : result.getText(), containerNumber: typeof result === "string" ? result : result.getText(), ...data[0] });
  }
  };

  return (
      <div className="h-screen w-screen gradient-green overflow-y-hidden fixed">
        <ToastContainer />
        <div
          onClick={goBack}
          className="px-4 py-8 xs:px-2 xs:py-4"
          id="back-button"
        >
          <ArrowRightIcon rotate={"90"} tint={"white"} />
        </div>
        <div className="h-full w-screen px-8 xs:px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="font-bold text-center text-xl xs:text-lg text-white mb-3">
              Estás entregando<br /><span className="text-gray-200">un saco</span>
            </span>
            <BlackDot />
          </div>
          <div className="flex mt-7 xs:mt-4 items-center justify-center">
            <span className="text-center text-white">
              Escanea el código
              <span className="font-bold"> QR<br/>del saco</span>
            </span>
          </div>

          <div className="flex items-center justify-center w-full mt-10">
            <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
              <div
                style={{
                  width: "101%",
                  height: "101%",
                  display: loading ? "none" : "",
                }}
                className="absolute z-10"
              >
              </div>
              <div
                style={{ zIndex: loading ? 10 : -10 }}
                className={`absolute bg-white w-full h-full flex items-center justify-center`}
              >
                <div className="lds-dual-ring"></div>
              </div>
              <QrReader
                    videoId={"qrreader"}
                    onResult={onScan}
                    containerStyle={{ width: '100%',  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    videoStyle={{width: '300%', height: '100%', objectFit: 'fill' }}
                    videoContainerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding:'0'}}
                    constraints={{ facingMode: "environment" }}
                    scanDelay={100}
                />
            </div>
          </div>

          <div className="flex w-full justify-center mt-10">
            <span className="text-lg font-thin text-white">
              Enfoca el código QR en el recuadro
            </span>
          </div>
        </div>
      </div>
  );
}

export default ScannerDeliverPage;
