import RoundImage from "../../components/RoundImage";
import { useContext, useEffect, useMemo, useState } from "react";
import BackgroundShape from "../../components/BackgroundShape";
import { useHistory } from "react-router-dom";
import ProfileSection from "../../components/HomePage/ProfileSection";
import { ContextWrapper } from "../../context/AppWrapper";
import { getCompleteUserData } from "../../utils/axiosRequest";
import { getContainersList } from "../../utils/requests";
import { ArrowRightIcon } from "../../assets/icons";
import Preguntas from './preguntas';
import ModalWrapper from "../../components/ModalWrapper";
import Divider from "../../components/Divider";
import { default as Accordion, PropsAccordion } from "../../components/Accordion";
import ToCollectCard from "../../components/ExchangePoint/ToCollectCard";
import { toast } from "react-toastify";

const ExchangePointHome = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [toRecollectCount, setToRecollectCount] = useState("...");
  const { userData, userToken } = useContext(ContextWrapper);
  const [user, setUser] = userData;
  const [token] = userToken;
  const [openModal, setOpenModal] = useState(false);

  useMemo(() => {
    const currentToken = localStorage.getItem("userToken");
    getCompleteUserData(currentToken)
      .then((info) => {
        if (info && info.data) {
          setUser(info.data)
        } else if (!info.data) {
          history.push("/telefono")
        }
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(user){
      getContainersList(user.current_dispatch_place.id, 1, 1, false,false, '', true).then(({ error, message,meta, data }) => {
        if (error) return toast.error(message);
        
        setToRecollectCount(meta.total);
      });
    }
   
  }, [user]);
  const pintarPreguntas = (preguntas: Array<PropsAccordion>) => {
    return preguntas.map((props) => {
      return (
        <>
          <Accordion {...props} />
          <Divider percentage={90} spacing={0.5} />
        </>
      )
    })

  }

  return (
    <>
      <div className="h-screen w-full overflow-y-hidden fixed">
        <BackgroundShape type={"large"} position="bottom" />

        <section
          className="flex justify-between items-center mx-5 mt-10 "
          style={{
            marginBottom: "5vh",
          }}
        >
          <ProfileSection
            BackButton={<div onClick={() => history.push('/home')}><ArrowRightIcon rotate={"90"} /></div>}
            token={token}
            title={
              user ? user?.current_dispatch_place?.business_name : "...cargando"
            }
            picture={
              !user || user.avatar_url === "NA"
                ? "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                : user.avatar_url
            }
            open={open}
            setOpen={setOpen}
          />
        </section>
        <section className="relative h-4/6 flex flex-col justify-center">
          <ToCollectCard number_packages={toRecollectCount} />
          <div className="  w-full flex flex-col items-center">
            <div className=" flex  justify-between h-30 w-10/12 ">
              <div className="flex flex-col items-center w-4/12 h-full">
                <RoundImage
                  image="../icons/icon_recoleccion.svg"
                  size={4.5}
                  alt="recolección"
                  onClick={() => history.push("/exchange-point/scanner-recollect")}
                  backgroundColor="white"
                  padding={1}
                  shadow={true}
                />
                <p className="bold color-white "> Recolección</p>
              </div>
              <div className="flex flex-col items-center w-4/12 h-full relative">
                <div className="absolute top-10">
                  <RoundImage
                    image="../icons/icon_sacos.svg"
                    size={4.5}
                    alt="bags"
                    backgroundColor="white"
                    onClick={() => history.push("/exchange-point/bags")}
                    padding={0.8}
                    shadow={true}
                />
                <p className="bold color-white text-center"> Mis sacos</p>
                </div>
              </div>
              <div className="flex flex-col items-center w-4/12 h-full ">
                <RoundImage
                  image="../icons/icon_entrega.svg"
                  size={4.5}
                  alt="recolección"
                  onClick={() => history.push("/exchange-point/scanner-deliver")}
                  backgroundColor="white"
                  padding={1}
                  shadow={true}
                />
                <p className="bold color-white ">Entrega</p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              onClick={() => setOpenModal(true)}
              className="bold  py-3 px-4 ml-3 text-xs md:text-md color-batman  h-15  "
              style={{
                borderRadius: "40px",
                border: "2px solid white",
              }}
            >
              Preguntas frecuentes
            </button>
          </div>
        </section>
        <section className="w-full h-15 absolute bottom-4 flex justify-center">
          <button
            onClick={() => window.location.reload()}
            disabled={false}
            className={`flex items-center btn-plain buttonLegal`}
          >
            <p className="color-white normal ">Refrescar app</p>
          </button>
        </section>
      </div>
      <ModalWrapper modal={openModal} setModal={setOpenModal}>
        <div className="p-4 py-10">
          <strong className={`bold color-green99 underline my-3 text-center `}>
            Preguntas frecuentes
          </strong>
          <p className="color-batman  mt-3 ">
            En caso de dudas, sugerencias o reclamos, contactar a{" "}
            <a className="bold color-green99" href="mailto:atencion@punto99.mx">
              atencion@punto99.mx
            </a>
          </p>
          <Divider percentage={90} spacing={0.5} />
          
          {pintarPreguntas(Preguntas)}
        </div>
      </ModalWrapper>
    </>
  );
};

export default ExchangePointHome;
