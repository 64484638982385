import { useRef } from "react";
import Input from "./Input";
import { BlackDot } from "../assets/icons";
import { toast } from 'react-toastify';
import BarcodeScannerComponent from "./BarcodeScannerComponent";

const QRScanner = ({title, onKeyUp, text, onScan, loading, setText}) => {
  const videoRef = useRef(null);

  const insertCode=async(e)=>{
    try{
      if(!videoRef.current)return
      const getFrame=videoRef.current?.getScreenshot();
      if(getFrame) return onKeyUp(e, getFrame)
    }catch(e){
      console.log(e)
      toast.error('Error al esceanear')
    }
  }

  const onScanSuccessful=async (text, image)=>{
    try{
      await onScan(text.replace(/\s/g, ''),false, image)
    }catch(e){
      console.log(e)
      toast.error('Error al esceanear')
    }
  }
  
  return (
    <div>
        <div className="h-full w-screen px-8 xs:px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="Vxxs:text-md text-center font-bold text-xl xs:text-lg text-white mb-3">
            {title}
            </span>
            <div className=" Vxxs:hidden">
            <BlackDot />
            </div>
          </div>
           <div className="Vxxs:my-0 my-3 text-center font-thin text-medium xs:text-lg text-white ">
           <span className="Vxxs:text-sm text-center text-white">
             Escanea el código
             <span className="font-bold"> QR o de barras del paquete</span>
           </span>
         </div>
          {onKeyUp &&
          <div className="flex flex-row items-center justify-center">
            <div className="w-3/4">
              <Input
                onKeyDown={insertCode}
                textAlign="center"
                placeholder="Número de guía"
                onChange={(e)=>setText(e)}
                value={text}
              />
            </div>
          </div>
          }
          <div className="flex items-center justify-center w-full mt-10 Vxxs:mt-10 h-36 " >
            {loading ?
            <div className={`bg-white absolute w-full h-full flex items-center justify-center  `}>
              <div className="lds-dual-ring"></div>
            </div>:
            <>
            <BarcodeScannerComponent
                delay={500}
                width='15rem'
                height='10rem'
                webcamRef={videoRef}
                environment='environment'
                onUpdate={(err, result, image) => {
                if (result && image) onScanSuccessful(result.text, image);
                }}
                onError={()=> toast.error('Por favor, concede los permisos necesarios a tu cámara para poder continuar.')}
            />
            </>
            }
            </div>
          </div>
          <div className="flex w-full justify-center mt-5 Vxxs:mt-2 flex flex-col items-center">
          <span className="text-center Vxxs:text-md  text-md font-thin mb-2 text-white ">
            Enfoca el código de barras o QR dentro del recuadro
          </span>
        </div>
    </div>
  )
}
export default QRScanner;
