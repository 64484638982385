import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateExchangePointRoute from "../components/PrivateExchangePointRoute";
import HowItWorks from "../screens/HowItWorks";
import OnBoarding from "../screens/OnBoarding";
import PayInfoPage from "../screens/PayInfoPage";
import ProfilePage from "../screens/ProfilePage";
import SchedulePage from "../screens/SchedulePage";
import PackagesPage from "../screens/PackagesPage";
import HomePage from "../screens/HomePage";
import ProfitsPage from "../screens/ProfitsPage/index";
import CodeVerification from "../screens/CodeVerification";
import PhoneVerification from "../screens/PhoneVerification";
import ScannerRecollectPage from "../screens/ScannerRecollectPage";
import ScannerDeliverPage from "../screens/ScannerDeliverPage";
import ConfirmRecollectPage from "../screens/ConfirmRecollectPage";
import ConfirmDeliverPage from "../screens/ConfirmDeliverPage";
import ScannerReturnPage from "../screens/ScannerReturnPage";
import ConfirmReturnPage from "../screens/ConfirmReturnPage";
import { NotFound } from "../screens/NotFound";
import PrivateRoute from "../components/PrivateRoute";
import UserNotAllowed from "../components/UserNotAllowed";
import MatchUserPackage from "../screens/MatchUserPackage";
import ScannerUserPage from "../screens/ScannerUserPage";
import MultipleOrders from "../screens/MultipleOrders";
import LegalTerms from "../screens/LegalTerms";
import ExchangePointHome from "../screens/exchangePoint/ExchangePointHome";
import ScannerRecollectBags from '../screens/exchangePoint/ScannerRecollect';
import ScannerDeliverBags from '../screens/exchangePoint/ScannerDeliver';
import Bags from '../screens/exchangePoint/Bags';
import RecollectInfo from "../screens/exchangePoint/RecollectInfo";
import TakeBagEvidence from "../screens/exchangePoint/TakeBagEvidence";
import ConfirBagRecollect from '../screens/exchangePoint/ConfirmRecollect'
import DeliverInfo from "../screens/exchangePoint/DeliverInfo";
import ScannerVeloz from "../screens/exchangePoint/ScannerVeloz";
import ConfirmDeliver from "../screens/exchangePoint/ConfirmDeliver";
import DeliverInfoVeloz from "../screens/exchangePoint/DeliverInfoVeloz";
import AcceptTermsConditions from "../screens/AcceptTermsConditions";



export default function Navigator() {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/packages" component={PackagesPage} />
        <PrivateRoute
          path="/scanner-recollect/:type"
          component={ScannerRecollectPage}
        />

        <PrivateRoute
          exact
          path="/scanner-deliver"
          component={ScannerDeliverPage}
        />

        <PrivateRoute
          exact
          path="/scanner-return"
          component={ScannerReturnPage}
        />

        <PrivateRoute
          exact
          path="/confirm-recollect/:type"
          component={ConfirmRecollectPage}
        />

        <PrivateRoute
          exact
          path="/confirm-deliver"
          component={ConfirmDeliverPage}
        />

        <PrivateRoute
          exact
          path="/confirm-return"
          component={ConfirmReturnPage}
        />
        <PrivateRoute exact path="/match-user" component={MatchUserPackage} />
        <PrivateRoute exact path="/scanner-user" component={ScannerUserPage} />
        <PrivateRoute
          exact
          path="/multiple-orders"
          component={MultipleOrders}
        />


        {/* exchange point routes */}

        <PrivateExchangePointRoute
          exact
          path="/exchange-point/home"
          component={ExchangePointHome}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/scanner-recollect"
          component={ScannerRecollectBags}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/recollect-info"
          component={RecollectInfo}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/take-bag-evidence"
          component={TakeBagEvidence}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/confirm-recollect"
          component={ConfirBagRecollect}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/scanner-deliver"
          component={ScannerDeliverBags}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/deliver-info"
          component={DeliverInfo}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/scanner-veloz"
          component={ScannerVeloz}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/deliver-info-veloz"
          component={DeliverInfoVeloz}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/confirm-deliver"
          component={ConfirmDeliver}
        />
        <PrivateExchangePointRoute
          exact
          path="/exchange-point/bags"
          component={Bags}
        />

        {/* exchange point routes */}

        <PrivateRoute exact path="/profile" component={ProfilePage} />

        <PrivateRoute exact path="/pagos" component={PayInfoPage} />

        <PrivateRoute exact path="/horario" component={SchedulePage} />

        <Route exact path="/faq" component={HowItWorks} />

        <Route exact path="/terminos-legales" component={LegalTerms} />


        <Route exact path="/terminos-y-condiciones" component={AcceptTermsConditions} />

        <Route exact path="/bienvenida" component={OnBoarding} />

        <PrivateRoute exact path="/home" component={HomePage} />

        <PrivateRoute exact path="/ganancias" component={ProfitsPage} />

        <UserNotAllowed exact path="/telefono" component={PhoneVerification} />

        <Route exact path="/codigo" component={CodeVerification} />

        <Route exact path="/">
          <Redirect to="/bienvenida" />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}
