// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
  CheckIcon,
} from "../../assets/icons";
import BackgroundShape from "../../components/BackgroundShape";
import {recollectBagApi} from "../../utils/requests";
import {toast} from "react-toastify";
import {ContextWrapper} from "../../context/AppWrapper";

function RecollectInfo() {
  const { goBack, push, location } = useHistory();

  const [confirm, setConfirm] = useState(false);
  const [loading, setloading] = useState(true);

  const { userData } = useContext(ContextWrapper);

  const [user] = userData;

let parseCollectInfo=  {
    'reference': location?.state?.containerID,
    'evidences': [
        {
            type: "COLLECTION",
            url: location?.state?.evidenceUrl
        }
    ],
    'point_id': user.current_dispatch_place_id,
}

  const deliverBag = async () => {
      const { error, message } = await recollectBagApi(parseCollectInfo)
    if (error) {
      setloading(false)
      toast.error(message || 'No se pudo completar la recolección')
      return
    }
    setConfirm(true)
    setloading(false)
  }

  useEffect(() => {
    if(user){
      deliverBag()
    }
  }, [user])


  return (
    <div className="h-screen w-screen overflow-y-hidden fixed">
      <BackgroundShape direction="revert" type={"quarter"} position="bottom" />
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          <div
            onClick={goBack}
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={"90"} />
          </div>
          <div className="flex w-full justify-center">
            <span className="text-xl text-center font-semibold text-green-100">
              Has recolectado
              <br />
              <span className="text-gray-200">un saco</span>
            </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot />
          </div>
          {!loading ? (
            <div className="flex flex-col items-center justify-center mt-20 w-full">
              <CheckIcon color={"#85c440"}/>
              <span className="text-gray-600 font-semibold">¡Excelente!</span>
              <span className="text-gray-200 font-bold">Tienes 1 saco recolectado</span>
              <span className="text-gray-600 font-semibold mt-5">Guarda el saco<span className="font-normal"> hasta<br />que vengan a retirarlo</span></span>
            </div>
          ) : (
            <div className="w-full mt-10 flex items-center justify-center">
              <div className="lds-dual-ring" />
            </div>
          )}
        </div>

        <div className="flex flex-col w-full items-center">
          {confirm && (
            <div className="flex flex-col items-center w-full px-10">
              <div className="font-bold text-white text-center">
                Hemos avisado que
                <br />
                pasen a retirar tu saco
              </div>
            </div>
          )}
          {confirm ? (
            <>
              <div className="flex flex-col items-center w-full mt-10">
                <div
                  onClick={() => push("/exchange-point/home")}
                  className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                >
                  <span className="text-lg text-green-100 font-semibold">
                    Finalizar
                  </span>
                </div>
                <div
                  onClick={() => push("/exchange-point/scanner-recollect")}
                  className="flex items-center justify-center my-5 px-4 py-2 w-2/3  rounded-full"
                >
                  <span className="text-lg text-white font-thin">
                    Hay más sacos
                  </span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecollectInfo;
