import React from "react";

interface Props {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Screen1 = ({ setIndex }: Props) => {


  return (
    <>
    <div className="w-full fixed left-0 right-0 title-animation"  >
      <p className="color-white semi-bold fixed top-20 right-0 left-0 flex justify-center"  >¡Hicimos unas</p>
      <b className="color-green99  fixed top-24 right-0 left-0 flex justify-center "  >mejoras para ti!</b>
      </div>
      <div className=" w-full p-2 flex flex-col  items-center mt-4">
        <p className="bold color-white text-center"> ¿Eres un punto99 que recolecta paquetes externos de otras personas y además haces recolecciones de tus propios productos?</p>
        <p className="bold color-batman mt-6"> Ahora puedes:</p>
        <div className="text-left w-10/12">
        <p><li >Recolectar y visualizar por separado las órdenes de tus paquetes y las órdenes de tus paquetes externos.</li></p>
        <p><li >Ver estadísticas.</li></p>
        <p><li >Rastrea tus paquetes en tiempo real.</li></p>
        <p><li >Buscar órdenes por número de guía.</li></p>
        </div>
        
        <div className=" w-full p-2 flex flex-col">
        <button className="color-white bold mt-5" onClick={() => setIndex(1)}>¡Entendido!</button>
        {/* <div
          className="w-full flex justify-center my-3"
        >
        <button className="currentSlider m-1"/>
          <button
            className="unactiveSlider m-1"
            onClick={() => setIndex(1)}
          />
          <button
            className="unactiveSlider m-1"
            onClick={() => setIndex(2)}
          />
        </div> */}
      </div>
      </div>

    </>

  );
};

export default Screen1;
