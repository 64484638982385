import React, { useState, useMemo } from "react";
import Switch from "./Switch";

interface Props {
  day: string;
  hora: any;
  setHora: any;
  inputDay: string;
  value?: any;
  required?: boolean;
}

const ScheduleInput = ({
  day,
  hora,
  setHora,
  inputDay,
  value,
  required = false,
}: Props) => {
  const [time, setTime] = useState({
    opens: value.opens,
    closes: value.closes,
    closed: value.closed,
  });

  useMemo(() => {
    setHora({ ...hora, [inputDay]: time });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleSwitch = () => {
    setTime({ ...time, closed: !time["closed"] });
  };

  return (
    <div className="flex w-full justify-start m-1">
      <div className="w-3/12 flex flex-col justify-center items-center ">
        <p className=" bold color-gray100">{day}</p>
      </div>
      <div className=" flex w-9/12 justify-start items-center">
        {/* first column */}
        {!value["closed"] ? (
          <input
            className={`input-border inputSchedule w-5/12 flex justify-center mx-1`}
            onChange={(e) => setTime({ ...time, opens: e.target.value })}
            type="time"
            id="opens"
            value={value.opens}
            name={inputDay}
            min="00:00"
            max="12:00"
            required={required}
          />
        ) : (
          <div className="input-border inputSchedule  flex justify-center m-1 w-5/12">
            <p className="color-gray100">Cerrado</p>
          </div>
        )}
        {/* second column */}
        {!value["closed"] ? (
          <input
            className={`input-border inputSchedule w-5/12 m-1 flex justify-center`}
            onChange={(e) => setTime({ ...time, closes: e.target.value })}
            placeholder="8:00Am"
            type="time"
            id="opens"
            value={value.closes}
            name={inputDay}
            min="12:00"
            max="00:00"
            required={required}
          />
        ) : (
          <div className=" input-border inputSchedule flex justify-center m-1 w-5/12">
            <p className=" color-gray100">Cerrado</p>
          </div>
        )}
        <div className="mx-2">
          <Switch
            isChecked={!value["closed"]}
            handleOnChange={handleSwitch}
            isDisabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleInput;
