
export default function ArrowPlain({ tint = '#bababa', rotate = '180deg' }) {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="12.829" 
    height="7.414" 
    viewBox="0 0 12.829 7.414"
    style={{ transform: `rotate(${rotate})` }}
    >
    <path 
    id="Path_3470" 
    data-name="Path 3470" 
    d="M1878,51l5,5,5-5" 
    transform="translate(-1876.586 -49.586)" 
    fill="none" 
    stroke={tint} 
    stroke-linecap="round" 
    strokeLinejoin="round" 
    stroke-width="2"/>
  </svg>
  
  );
}
