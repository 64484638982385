import { DeliveredIcon } from "../../assets/icons";
import { formatDate } from "../../utils/functions";

const DeliveredBags = ({lastPackage}: any) => {

  return (
    <>
      <DeliveredIcon />
      <span className="text-sm font-normal text-gray-200">
        Estos son tus <span className="font-bold">sacos entregados</span>
      </span>
      {lastPackage &&
      <div className="flex">
      <span className="text-sm font-light text-gray-800">
         Último saco:
      </span>
      <span className="text-sm font-light text-gray-800">
        {formatDate(lastPackage) }
      </span>
      </div>
      }
    </>
  );
};

export default DeliveredBags;
