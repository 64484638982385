import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../CustomButton";

interface Props {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Screen3 = ({ setIndex }: Props) => {
  let history = useHistory();

  const skipOnboarding = () => {
    localStorage.setItem("intro", "true");
    history.push("/telefono");
  };

  return (
    <div className=" w-full h-screen">
      <div className="relative w-full h-4/6 Vxxs:h-7/12">
        <div className=" upperSectionOnboarding">
          <div className="titleSection">
            <h6 className="m-0 bold color-batman m-0"> ¡Comienza ahora!</h6>
            <h4 className="color-white bold m-0">Atrae nuevos clientes</h4>
            <img
              className="icon"
              src="../icons/point_yellow.svg"
              alt="makeMoney"
            />
          </div>
          <img
            className="mainImage  Vxxs:h-30"
            src="../icons/onboarding3.svg"
            alt="makeMoney"
          />
        </div>
      </div>
      <div className="relative b-0 flex flex-col items-center w-full">
        <div className="w-9/12 text-center flex flex-col items-center">
          <div>
            <span className="semi-bold body3">
              Aumenta tus ganancias y circulación de
            </span>
            <span className="bold body3">
              {" "}
              potenciales clientes en tu local
            </span>
          </div>
        </div>
        <div className="w-2/12 flex justify-evenly my-3">
          <button
            className="unactiveSlider"
            onClick={() => setIndex(0)}
          ></button>
          <button
            className="unactiveSlider"
            onClick={() => setIndex(1)}
          ></button>
          <button className="currentSlider"></button>
        </div>
        <CustomButton onClick={skipOnboarding} text="Comienza" type="primary" />
      </div>
    </div>
  );
};

export default Screen3;
