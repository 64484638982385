import React from "react";

function CodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="20.5"
      viewBox="0 0 20.5 20.5"
    >
      <g id="Group_4408" data-name="Group 4408" transform="translate(-57 -582)">
        <path
          id="Path_3459"
          data-name="Path 3459"
          d="M4,7.469V6.313A2.313,2.313,0,0,1,6.313,4H8.625"
          transform="translate(54 579)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
        <path
          id="Path_3460"
          data-name="Path 3460"
          d="M4,17v1.156a2.313,2.313,0,0,0,2.312,2.313H8.625"
          transform="translate(54 581.031)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
        <path
          id="Path_3461"
          data-name="Path 3461"
          d="M16,4h2.313a2.312,2.312,0,0,1,2.313,2.313V7.469"
          transform="translate(55.875 579)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
        <path
          id="Path_3462"
          data-name="Path 3462"
          d="M16,20.469h2.313a2.313,2.313,0,0,0,2.313-2.312V17"
          transform="translate(55.875 581.031)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
        <rect
          id="Rectangle_3251"
          data-name="Rectangle 3251"
          width="1.156"
          height="2.313"
          transform="translate(59.156 591.094)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></rect>
        <line
          id="Line_1132"
          data-name="Line 1132"
          y2="2.313"
          transform="translate(64.938 591.094)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></line>
        <rect
          id="Rectangle_3252"
          data-name="Rectangle 3252"
          width="1.156"
          height="2.313"
          transform="translate(69.563 591.094)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></rect>
        <line
          id="Line_1133"
          data-name="Line 1133"
          y2="2.313"
          transform="translate(75.344 591.094)"
          fill="none"
          stroke="#5ecfb7"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></line>
      </g>
    </svg>
  );
}

export default CodeIcon;
