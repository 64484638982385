import ColorTag from "../ColorTag";
import DateObject from "react-date-object";
import xlsx from "json-as-xlsx";
import { getDispatchAndOrdersByIdAndDate } from "../../utils/requests";
import { useMemo, useState } from "react";
import Divider from "../Divider";
import { CloseIcon } from "../../assets/icons";
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import PaymentReport from "../PaymentReport";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { saveAs } from 'file-saver';

interface Props {
  payment:any,
  dispatch:string
  key?:any
}

type Orders={
  order_id:String,
  client: String,
  collected_in_p99:String,
  type:String,
  cost:String,
  fiscal_key:String,
  tracking_key:String,
  payment_date:String,
  self_pickup_type:String,
  provider:string
 }[]

const ProfitsCards = ({payment, dispatch, key}: Props) => {
  const[ordersModal, setOrdersModal]=useState(false)
  const[orderWPaymentData, setOrderWPaymentData]=useState<Orders>([])

  const[loading, setLoading]=useState({excel:false, pdf:false, orders:false})

  const[pagination, setPagination]=useState<{page:number|undefined, total:number}>({page:1, total:1})


  let payStatus: string;
  let color;
  const formatDate = (date: string) => {
    let dateFormat = new DateObject(date)
    dateFormat.setFormat('DD/MM/YYYY');
    return dateFormat.toString()
  }

  const parseDateTime = (date: string) => {
    const parsed = date.split(/[-T.]+/)
    return `${parsed[2]}/${parsed[1]}/${parsed[0]} ${parsed[3]}`
  }

  let completeDispachData = { ...payment, dispatch_general: dispatch }
  switch (payment.payment_status) {
    case "OPEN":
      payStatus = "Actual";
      color = "#5f9ea0";
      break;
    case "TO_MANAGE":
      payStatus = payment.apply_invoice ? 'Por facturar' : 'Pago pendiente'
      color = payment.apply_invoice ? "#ff9966" : "#f1b248"
      break;
    case "IN_PROCESS":
      payStatus = payment.apply_invoice ? 'Factura en validación' : 'En proceso de pago'
      color = payment.apply_invoice ? "#61cba1" : "#FACB83"
      break;
    case "ACCOUNT_PAYABLE":
      payStatus = 'En proceso de pago'
      color = "#FACB83"
      break;
    case "UNPAID":
      payStatus = 'Pago pendiente';
      color = "#f1b248";
      break;
    case "PAID":
      payStatus = 'Pagado';
      color = "var(--green99)";
      break;
    case "PAID-ADJUSTED":
      payStatus = "Pagado-Ajuste"
      color = "#b2e449"
      break;
    case "PENDING-DISABLED":
      payStatus = "Pago Pendiente - BAJA"
      color =  "#F07E6C"
      break;
    case "PAY-OVERDUE":
      payStatus = "Pago vencido"
      color =  "#e96d1f"
      break;
    default:
      payStatus = "Sin definir";
      color = "var(--red)";
      break;
  }

  let settings = {
    fileName: `${completeDispachData.dispatch_general.business_name}-${formatDate(payment.start_date)}-${formatDate(payment.end_date)}`, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };


  const parseOrderType = (order: string) => {
    if (order.includes('drop')) {
      return 'A veloz'
    } else if (order.includes('pick') || order.includes('backlog')) {
      return 'A cliente'
    } else{
      const provider:any= {
        dc_meli:'A veloz',
        do_meli:'A veloz',
        pu_meli:'A cliente',
        pu_amazon:'A cliente',
        scdc:'Saco',
        odc:'A cliente'
      }
      return provider[order] || order
    }

  }

  const parsePickupType=(type:string)=>{
    
    if(['external', 'fraudulent'].includes(type)){
      return 'Externa'
    }else if(type==='voluntary'){
      return'Propia'
    }else{
      return type
    }
  }

  const parseProvider=(prov:string)=>{
    
    const provider:any={
      AM:'Amazon',
      ML:'Mercado Libre',
      SCDC:'Delivery Cell',
      ODC:'Delivery Cell',
    }
    return provider[prov] || 'Punto99'
  }


  const downloadDocs=(orderWPayment:any)=>{
      const headers = [
        {
          sheet: completeDispachData.dispatch_general.business_name,
          columns: [
            { label: "No. de guía", value: "order_id" },
            {
              label: "Cliente", value: (o: any) =>
                o.client ? o.client : "No disponible",
            },
            {
              label: "Fecha de recolección",
              value: 'collected_in_p99'
            },
            { label: "Tipo de orden", value: 'type' },
            { label: "Ganancia", value: "cost" },
            { label: "Folio fiscal", value: 'fiscal_key' },
            { label: "Clave de rastreo de pago", value: 'tracking_key' },
            {
              label: "Fecha y hora de transacción de pago",
              value: 'payment_date'
            },
            {
              label: "Tipo de recolección",
              value: "self_pickup_type"
            },
            {
              label: "Tipo de operación",
              value: "provider"
            },
          
          ],
          content: orderWPayment,
        },

      ]

      xlsx(headers, settings);
    }

  const getOrders = async (isPaginated:boolean, page?:number) => {
    return getDispatchAndOrdersByIdAndDate(payment, isPaginated, page, 20).then((orders) => {
       if(isPaginated ){
         setPagination({page, total:orders.data.total_pages})
        }
      if (!orders.error) {

        let orderWPayment: any = [];
        const {fiscal_key,payment_date,tracking_key,apply_invoice,payment_status} = orders.data.orders
        orders.data.orders.payment_orders.map((o: any) => {
          orderWPayment.push({
            ...o,
            order_id: o.order_id,
            client: o.client ? o.client : "No disponible",
            collected_in_p99: o.collected_in_p99 ? formatDate(o.collected_in_p99) : "No disponible",
            type: parseOrderType(o.type.toLowerCase()),
            fiscal_key: !fiscal_key && !apply_invoice && (payment_status === 'PAID' || payment_status === 'PAID-ADJUSTED') ? 'Beneficio Sindical Punto99' : !fiscal_key ? 'Sin información' : fiscal_key,
            tracking_key: tracking_key || 'Sin información',
            payment_date: payment_date ? parseDateTime(payment_date) : 'Sin información',
            self_pickup_type: parsePickupType(o.self_pickup_type),
            provider: parseProvider(o.provider),
            
          }
        )}
        )
        return orderWPayment

      } else {
        toast.error('Se encontró un error al descargar las órdenes ')
        return []

      }
      
    });
  };


  const viewOrders=async()=>{
    setLoading({...loading, orders:true})
    setOrdersModal(true)
    const ordersPaginated= await getOrders(true, pagination.page)
    setOrderWPaymentData(ordersPaginated)
    setLoading({...loading, orders:false})
  }
 
  const handlePageChange=async(e: { selected: number; })=>{
    setLoading({...loading, orders:true})
    const orders= await getOrders(true, e.selected + 1)
    setOrderWPaymentData(orders)
    setLoading({...loading, orders:false})
  }

  const submitForm = async (event: { preventDefault: () => void; }) => {
    setLoading({...loading, pdf:true})

    event.preventDefault(); // prevent page reload
    const o= await getOrders(false)
    const blob = await pdf(
      <PaymentReport data={o} name={completeDispachData.dispatch_general.business_name} payment={payment} />
    ).toBlob();

    saveAs(blob, completeDispachData.dispatch_general.business_name);
    setLoading({...loading, pdf:false})

}


  return (
    <>
      <div className="relative flex flex-col p-3 my-4  bold color-batman text-sm rounded-md rounded border bg-white border-gray-100">
        <div className="w-full flex flex-col items-center justify-between">
          <div className="py-2 flex w-full border-b border-gray-100">
            <div className="w-6/12 flex flex-col  justify-between">
              <div className="w-full flex items-center justify-between">
                <p className="color-gray100">Inicio</p>
                <p className="color-batman bold leading-3 text-center">{formatDate(payment.start_date)}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <p className="color-gray100">Cierre</p>
                <p className="color-batman bold leading-3 text-center">
                  {' '} {formatDate(payment.end_date)}
                </p>
              </div>
            </div>
            <div className="w-5/12 flex items-center justify-between m-auto">
              <div className="w-max text-center m-auto flex flex-col justify-between">
                <p className="color-gray100">Estado de pago</p>
                <ColorTag text={payStatus} bgColor={color} txtColor='white' borderRadius='5px' />
              </div>
            </div>
          </div>

          <div className="flex w-full ">
            <div className="flex flex-col w-6/12">
              <div className="flex justify-between items-center py-1">
                <p className="color-gray100 w-10/12">Paquetes a pagar</p>
                <p className="bold text-align-center text-center">{payment.packages_collected}</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="color-gray100">Total (MXN)</p>
                <p className="bold color-green99 ">${payment.total.toFixed(2)}</p>
              </div>
            </div>
            <div className="w-6/12 flex justify-center items-center ">

              <button onClick={async() => {
                  viewOrders()
                 
              }}
                className='color-green99 semi-bold'
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
          height: "min-content",
          zIndex: 6,
          overflowY: 'auto',
          maxHeight: '90vh'
        }}
        className={`h-96 absolute rounded-t-xl  left-0 bottom-0 flex flex-col w-full gradient-green mx-auto right-0 md:w-10/12 lg:w-5/12 ${ordersModal ? "slide-open" : "slide-close"
          }`}
      >
        <button
          onClick={() =>{
             setOrdersModal(false)}}
          className="flex w-full pt-3 pr-5 justify-end "
        >
          <CloseIcon tint="white" />
        </button>

        <div className=" flex items-center flex-col  pb-5 mt-2 mx-5 bg-white rounded-md">
         <b className="color-green99 mt-2">{completeDispachData.dispatch_general.business_name}</b>
         <p className="color-gray100 bold">Del {  formatDate(payment.start_date)} al {formatDate(payment.end_date)}</p>
         <Divider percentage={90} spacing={0.5}/>
         <div className=" w-11/12 flex  flex-col justify-start text-xs pb-3">
          <p className="color-gray100 flex 	leading-5">Folio fiscal: <p className="color-batman semi-bold text-xs leading-5" > {orderWPaymentData.length>0? orderWPaymentData[0].fiscal_key: '...'}</p></p>
          <p className="color-gray100 flex leading-5">Clave de rastreo de pago:  <p className="color-batman semi-bold text-xs leading-5" > {orderWPaymentData.length>0?orderWPaymentData[0].tracking_key: '...'}</p></p>
          <p className="color-gray100 flex leading-5">Fecha y hora de transacción de pago:  <p className="color-batman semi-bold text-xs leading-5" >  {orderWPaymentData.length>0?orderWPaymentData[0].payment_date: '...'}</p></p>
         </div>
         <div className="w-11/12 h-80 overflow-auto">
        {orderWPaymentData.length<1 || loading.orders? <div className=" w-full h-full flex items-center justify-center "><div className="lds-dual-ring" /></div> :orderWPaymentData.map((order)=> (
          <>
         <Divider percentage={90} spacing={.4}/>
          <div className="flex text-center w-full justify-between align-center color-gray100">
            <div className="flex flex-col w-4/12">
            <small className="bold  hover:text-clip color-green99">{order.order_id}</small>
            <small className="color-batman truncate hover:text-clip">{order.collected_in_p99 ? order.collected_in_p99: "No disponible"}</small>
            </div>
            
            <div className="flex flex-col w-5/12 text-left">
            <small className=" truncate hover:text-clip">Cliente: <small className="semi-bold color-batman">{order.client? order.client : "No disponible"}</small></small>
            <small className="truncate hover:text-clip ">Tipo de entrega: <small className="semi-bold color-batman">{order.type}</small></small>
            <small className="truncate hover:text-clip ">Tipo de operación: <small className="semi-bold color-batman">{order.provider}</small></small>
            </div>
            <div className="flex flex-col w-4/12">
            <div className="flex flex-row ">
                <small className="truncate hover:text-clip "> Recolección :</small>
                <small className="semi-bold color-green99">{order.self_pickup_type}</small>
              </div>
              <div className="flex flex-row  ">
                <small className=""> Ganancia: </small>
                <small className="semi-bold color-green99">${order.cost}</small>
              </div>
              
            </div>
          </div>
          </>
        ))}
          </div>
          <section className="w-9/12">
                        <ReactPaginate
                          previousLabel={ "<-" }
                          nextLabel={ "->" }
                          breakLabel={ "..." }
                          breakClassName={ "break-me" }
                          pageCount={ pagination.total }
                          marginPagesDisplayed={ 2 }
                          pageRangeDisplayed={ 2 }
                          onPageChange={ async(e:any)=> {
                            handlePageChange(e)
                             
                          }}
                          containerClassName={ "paginationPayments" }
                          activeClassName={ "active" }
                        />
                      </section>

            <div className="w-10/12 flex text-center flex-col m-auto">
              <p className="my-2 semi-bold">Descarga tu reporte en:</p>

              <div className="w-full flex justify-evenly">
                <button
                  className="agave-button-thin ag-button-primary "
                  onClick={async () => {
                    setLoading({...loading, excel:true})
                    const o= await getOrders(false)
                    downloadDocs(o)
                    setLoading({...loading, excel:false})

                  }}
                >
                 {loading.excel? <div className="lds-dual-ring-small-white" />: <>Excel</> }
                </button>
                <button
                  className="agave-button-thin ag-button-primary "
                  onClick={submitForm}
                >
                 {loading.pdf? <div className="lds-dual-ring-small-white" />: <>PDF</> }
                  
                </button>
              </div>
            </div>

        </div>
      </div>
    </>
  );
};

export default ProfitsCards;
