import { Route, Redirect } from "react-router-dom";
import {  useEffect, useState } from 'react'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentToken= localStorage.getItem("userToken");
  const [allowed, setAllowed]= useState(true)
  
  useEffect(()=>{    
    !currentToken && setAllowed(false)
  })
  

  return (
    <Route
      {...rest}
      render={(props) =>
        //if there is a token and the user is active, allow in
        allowed ? <Component {...props} /> : <Redirect to="/telefono" />
      }
    />
  );
};

export default PrivateRoute;
