import { useEffect, useState } from "react";

interface Props {
  pay:any
}

const DailyProfitsCard = ({pay}: Props) => {
  const[payment, setPayment]=useState( null)
  useEffect(()=>{
    pay && setPayment(pay.toFixed(2))
  },[pay])
  
  
  return (
    <div className='relative flex flex-col justify-center items-center '>
      <img
        src="../icons/icon_ganado.svg"
        alt="icon"
        className="rounded-pic profitCardIcon "
      />
    <div className="profitCardContainer relative text-center">
      {pay===0?
          <div className="w-60">
            <p className="color-batman pb-1 ">¡Buen día!</p>
            <b className="m-0 color-green99 semi-bold mx-auto">Aún no tienes ganancias en este periodo.</b>
            </div>
          :
          <div className="text-center flex flex-col pt-2 ">
            <p className="color-batman pb-1 ">¡Enhorabuena!</p>
            <b className="color-batman">Este periodo llevas ganado</b>
            <h2 className="m-0 color-green99 semi-bold mx-auto"> {payment?`$${payment}`: <div className="lds-dual-ring-small" />}</h2>
        </div>
        }
      </div>
    </div>
  );
};

export default DailyProfitsCard;
