import { RecolectionIcon } from "../../assets/icons";
import { formatDate } from "../../utils/functions";

const RecolectedBags = ({lastPackage}: any) => {
  return (
    <>
    <div className='w-7 h-7'>

      <RecolectionIcon />
      </div>
      <span className="text-sm font-normal text-gray-200">
        Estos son tus <span className="font-bold">sacos recolectados</span>
      </span>
      {lastPackage &&
      <div className="flex">
      <span className="text-sm font-light text-gray-800">
        { 'Último saco:'}
      </span>
      <span className="text-sm font-light text-gray-800">
        { formatDate(lastPackage) }
      </span>
      </div> 
      }
    </>
  );
};

export default RecolectedBags;
