import React, { useImperativeHandle, useRef } from "react";

export type Type = "border" | "underline";

interface Props {
  inputClassNames?: string;
  icon?: string;
  alt?: string;
  label?: string;
  placeholder?: string;
  border?: Type;
  input?: any;
  textAlign: "right" | "left" | "center";
  value: string | number;
  onChange: (value: string | number | any) => void;
  ref?: any;
  onKeyDown: (e: any) => void;
}

const Input = ({
  inputClassNames = '',
  textAlign = "left",
  icon,
  alt = "icon",
  label,
  placeholder = "Insertar texto",
  border = "underline",
  onChange,
  onKeyDown = (e) => {},
  ref,
  value,
}: Props) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    onChange(e.target.value);
  };

  return (
    <>
      <div className="flex items-center input-label">
        {icon && <img className="icon" src={icon} alt={alt} />}
        <label htmlFor={label} className="subtitle2">
          {label}
        </label>
      </div>

      <input
        ref={(inputRef) => {
          ref = inputRef;
        }}
        style={{ textAlign }}
        className={`${inputClassNames} input-${border} body2`}
        name={`input-${label}`}
        type="text"
        id={`input-${label}`}
        placeholder={placeholder}
        value={value}
        onKeyDown={onKeyDown}
        onChange={handleChange}
      />
    </>
  );
};

export default Input;
