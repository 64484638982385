import React from 'react'
import ArrowPlain from '../../assets/icons/ArrowPlain';


type ActivePackage = {
    id: number;
    dispatch_service: string;
    assignment?: any;
    deliver_to?: string;
    order_status_id: string;
    created_at: string;
    client_phone:string;
    profits: number;
    evidences?: any;
    origin_email:string,
    logs: any,
  };
  type Props = {
    setIsOpen:React.Dispatch<React.SetStateAction<boolean>>,
     packages: any,
     index: number,
     setactivePackage:React.Dispatch<React.SetStateAction<any>>,
}

const ReferenceCard = ({setIsOpen, packages, index, setactivePackage}: Props) => {


    const setActivePackageWithEvidence = (order: ActivePackage) => {
        const evidences: { type: string; img: string }[] = [];
        order.logs.forEach((log: { action: string; evidence_url: string; }) => {
          if (log.action === "ORDER_RECOLLECTED_DP") {
            evidences.push({
              type: "Recoleccion",
              img: JSON.parse(log.evidence_url)[0],
            });
          }
          if (log.action === "ORDER_DELIVERED_TO_CORE_FLOW") {
            evidences.push({
              type: "Entrega a veloz",
              img: JSON.parse(log.evidence_url)[0],
            });
          }
        });
        setactivePackage({
          ...order,
          evidences,
        });
    
      };
    
    
  return (
    <div
                onClick={() => {
                  setActivePackageWithEvidence(packages);
                  setIsOpen(true)
                }}
                className="flex flex-row justify-between px-3 py-2 border-b border-gray-1700"
                style={{border:'0.20px solid var(--gray25)'}}

              >

                <span className="font-extralight text-base text-gray-1800">
                  {index + 1}
                  <span className="text-gray-1900 font-light ml-2 text-sm">
                    {packages?.deliver_to}
                  </span>
                </span>
                <div className=" flex items-center text-sm">
                <span className=" font-normal text-green-200  mr-2">
                  {packages?.external_reference.slice(0, 6)}

                  <span className="font-bold">
                    {packages?.external_reference.slice(6, packages?.external_reference?.length)}
                  </span>
                </span>
                <ArrowPlain rotate="270deg"/>
                </div>
                

              </div>
  )
}

export default ReferenceCard