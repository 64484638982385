import { useMemo, useState, useContext } from "react";
import { useHistory} from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import { getCompleteUserData, updateDispatch } from "../utils/axiosRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsText from "../components/TermsText";
import { ContextWrapper } from "../context/AppWrapper";

function AcceptTermsConditions(props) {
const [accept, setAccept]=useState(false)
const [userLocal, setUserLocal]=useState(props.location.state ? {...props.location.state.data, current_dispatch_place:{ ...props.location.state.data.current_dispatch_place, accepted_terms:0, terms_details:null } }:false)
const userToken = localStorage.getItem("tempToken");
  let history = useHistory();
  const { userData } = useContext(ContextWrapper);
const [ user, setUser ] = userData;

useMemo(()=>{
  if(!props.location.state){
    getCompleteUserData(userToken)
    .then((info) => {
      if (info) {
        info.data && setUserLocal(info.data)
      }
    })
    .catch((error) => {
      console.error('auth',error)
    });
  }
},[])

const allowUser = () => {
  const today= new Date()
  updateDispatch(
    {
        ...userLocal.current_dispatch_place,
        is_active:1,
        accepted_terms:1,
        terms_details:`${userLocal.name} ${userLocal.lastname}-${userLocal.phone_number}-${today}`,
        schedule: JSON.parse(userLocal.current_dispatch_place.schedule)
    },
    userLocal.current_dispatch_place_id,
    userToken
  ).then((success) => {
    if (success) {
      setUser({...userLocal,current_dispatch_place:success.data })
      localStorage.setItem("userToken", userToken);
      history.push( {
        pathname: '/home',
        state: {...userLocal, current_dispatch_place:success.data  } 
      })
      localStorage.removeItem("tempToken")
    } else {
      toast.error('No se han podido actualizar los términos y condiciones, favor de contactar a soporte');
    }
  });
};

  return (
    <div className="h-screen w-full flex flex-col ">
      <ToastContainer />
      <BackgroundShape type={"large"} position="top" />
        {/* <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />
        </div> */}

        <h4 className=" mx-auto h-10 mt-5 mb-10 semi-bold color-white"> Términos y condiciones </h4>

      <div className='w-10/12 h-4/5  mx-auto flex flex-col'>

      {/* form starts*/}
      <div className="bg-white rounded-md mx-auto p-5 overflow-auto shadow-md ">
       <TermsText/>
      </div>

        <div className="flex  flex-col justify-evenly items-center mt-4 ">
        <div className=" flex justify-start items-center ml-4 my-4">
                  <input
                    className="inputRadio"
                    type="checkbox"
                    id="si"
                    name="si"
                    onChange={()=>setAccept(!accept)}
                    checked={accept}
                  />

                  <label htmlFor="Sí" className="inputRadio">
                  He leído y acepto los términos y condiciones
                  </label>
                </div>
                <button
              disabled={!accept || !userLocal}
              className="agave-button ag-button-secondary w-10/12"
              onClick={allowUser}
            >
              Continuar
            </button>
             
        </div>
       </div> 

      <div className="downSection"></div>
    </div>
  );
}

export default AcceptTermsConditions;
