import { DeliveredIcon } from "../../assets/icons";
import moment from 'moment'
const DeliveredPackages = ({lastPackage}: any) => {
  

  return (
    <>
    <div className='w-6 h-6 mt-2'>
      <DeliveredIcon />
      </div>
      <span className="text-sm font-normal text-gray-200">
        Estos son tus <span className="font-bold">paquetes entregados</span>
      </span>
      <span className="text-sm font-light text-gray-800 mb-5 small leading-3">
      Último paquete entregado:{lastPackage?.created_at ? moment(lastPackage?.created_at).format('DD/MM/yyyy • HH:mm') + ' hrs' : ''}
      </span>
    </>
  );
};

export default DeliveredPackages;
