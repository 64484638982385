import React from "react";
import { CloseIcon } from "../../assets/icons"
import styles from "./ModalWrapper.module.css";

interface Props {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children?:any;
}

const ModalWrapper: React.FC<Props> = ({ children, modal, setModal }) => {
  return (
    <>
      {modal && (
        <>
          <div
            className={styles.backgroundBlur}
            onClick={() => setModal(false)}
          >
            {" "}
          </div>
          <div className={styles.sliderContainer}>
            <button
              className={styles.closeButton}
              onClick={() => setModal(false)}
            >
            <CloseIcon tint='var(--gray50)'/>
            </button>
            <div className={styles.innerContainer}>{children}</div>
          </div>
        </>
      )}
    </>
  );
};

export default ModalWrapper;
