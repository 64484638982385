import { useState } from "react";
import "../Carousel/bootstrap.min.css";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Carousel from "react-bootstrap/Carousel";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  user:any[],
}

function SelfPickupOnboarding({setModal, user}:Props) {
  //we use index to control the slide
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };


  return (
    <div className="w-full ">
      
        <Carousel
          wrap={false}
          controls={false}
          interval={null}
          variant="dark"
          activeIndex={index}
          onSelect={handleSelect}
          touch={true}
        >
          <Carousel.Item className="absolute">
            <Screen1 setIndex={setIndex} />
          </Carousel.Item>
          <Carousel.Item>
            <Screen2 setIndex={setIndex} />
          </Carousel.Item>
          <Carousel.Item>
            <Screen3 setModal={setModal} user={user}/>
          </Carousel.Item>
        </Carousel>
   </div>
  );
}

export default SelfPickupOnboarding;
