function CheckIcon({ color = '#fff'}) {
  return (
    <svg
      id="Group_4540"
      data-name="Group 4540"
      xmlns="http://www.w3.org/2000/svg"
      width="75.154"
      height="75.154"
      viewBox="0 0 75.154 75.154"
    >
      <path
        id="Path_3598"
        data-name="Path 3598"
        d="M0,0H75.154V75.154H0Z"
        fill="none"
      ></path>
      <circle
        id="Ellipse_610"
        data-name="Ellipse 610"
        cx="27.792"
        cy="27.792"
        r="27.792"
        transform="translate(9.785 9.785)"
        fill="none"
        stroke={color || '#fff'}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      ></circle>
      <path
        id="Path_3599"
        data-name="Path 3599"
        d="M9,16.263l6.263,6.263L27.789,10"
        transform="translate(19.183 21.314)"
        fill="none"
        stroke={color || '#fff'}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      ></path>
    </svg>
  );
}

export default CheckIcon;
