import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        label: "¿Qué es “Delivery Cell”?",
        children: (
            <React.Fragment>
                <p>
                    Es una nueva funcionalidad de Punto 99 en la que  recolectarás “sacos”
                    que contengan varios paquetes.  Los sacos serán entregados y recolectados únicamente
                    por los repartidores de 99minutos.
                </p>
            </React.Fragment>
            )
    },
    {
        label: "¿Cómo entro a la página de “Delivery Cell”?",
        children: (
            <React.Fragment>
                <p>
                    En la pantalla de inicio verás un botón con la leyenda “Mis sacos”.  Al seleccionarlo, habrás ingresado a la funcionalidad de Delivery Cell. Ahí tendrás 3 opciones:
                    <li className="ml-2">{"  "}Recolección</li>
                    <li className="ml-2">{"  "}Mis sacos</li>
                    <li className="ml-2">{"  "}Entrega</li>
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo hago una recolección de un saco?",
        children: (
            <React.Fragment>
                <p>
                    Para recolectar un saco deberás:
                </p>
                <p>
                    <b>1- </b>Asegúrate de que te encuentras en la página de Delivery Cell, después deberás entrar a la pantalla de Recolección.
                </p>
                <p>
                    <b>2- </b> Escanear el código QR que se encuentra pegado en el saco.
                </p>
                <p>
                    <b>3- </b>En pantalla podrás ver la información del saco, es necesario verificar que la información que se muestra en pantalla coincida con la del saco.
                </p>
                <p>
                    <b>4- </b>Toma una foto del saco para verificar las condiciones en las que se te entrega.
                </p>
                <p>
                    <b>5- </b> Comprueba que la foto sea clara y muestre el saco con el sello intacto, de no ser así vuelve a tomar la foto.
                </p>
                <p>
                    <b>6- </b>¡Listo, has recolectado un saco! Selecciona “finalizar” si es el único o en “Hay más sacos” para repetir el proceso.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Puedo abrir los sacos?",
        children: (
            <React.Fragment>
                <p>
                    Tú, como Punto99, no debes abrir el saco por ninguna razón.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo hago una entrega de un saco?",
        children: (
            <React.Fragment>
                <p>
                    Para entregar un saco deberás:
                </p>
                <p>
                    <b>1- </b>Escanear el codigo QR que se encuentra en la etiqueta impresa del saco.
                </p>
                <p>
                    <b>2- </b> Verificar que el tag del saco que se muestra en pantalla es el mismo impreso en la etiqueta.
                </p>
                <p>
                    <b>3- </b>Debes tomar una fotografía clara.
                </p>
                <p>
                    <b>4- </b> ¿Tomaste bien la foto? Continua, si no puedes reintentar.
                </p>
                <p>
                    <b>5- </b> Pide al veloz escanear el saco y te avisaremos cuando puedas completar la entrega.
                </p>
                <p>
                    <b>6- </b>Confirma la entrega del saco.
                </p>
                <p>
                    <b>7- </b> ¡Enhorabuena, has entregado un saco!
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿En dónde veo la información de mis sacos?",
        children: (
            <React.Fragment>
                <p>
                    Deberás ingresar a la página de “Mis sacos”. Ahí verás tres opciones:<br/>
                        <b>📌 A recibir: </b>En esta sección podrás visualizar los sacos que recibirás en tu punto99. Al ingresar en alguno de los sacos podrás ver los detalles de este, el tag, el status, fecha de recepción y cantidad de paquetes.<br/>
                        <b>📌 En mi local: </b> En esta sección podrás visualizar los sacos que se encuentran actualmente en tu local y los que haz entregado. Al  ingresar en alguno de los sacos podrás ver los detalles de este, el estado de la recolección, la fecha de recolección, el status y la evidencia. Podrás consultar los estado de la recolección de cada saco, estos son: 
                        <li className="ml-2 ">{"  "}A tiempo: El saco esta a tiempo de ser recolectado por el veloz. </li>
                        <li className="ml-2">{"  "}Demorado: El veloz se ha demorado en recolectar el saco (puedes comunicarte con un asesor de Punto 99 para que envíen un veloz a tu Punto99) . </li>
                        <b>📌 Entregados </b> Podrás ver los sacos que haz entregado a un veloz de 99minutos. Al ingresar en alguno de los sacos podrás ver los detalles de este, el status, la fecha de entrega, la evidencia de recolección y entrega, y la información del veloz al cual le entregaste el saco.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si un día no voy a operar y no voy a poder recibir sacos de la operación de Delivery Cell?",
        children: (
            <React.Fragment>
                <p>
                    Si por alguna razón un día no vas a abrir tu Punto99, debes notificar sobre esta novedad al menos con dos días de anticipación a los correos ricardo.castro@99minutos.com y fernando.rios@99minutos.com.
                </p>
                <p>
                    <b>¡ATENCIÓN!</b> La sanción monetaria será de un descuento de $200 en caso de no avisar.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si el veloz no quiere escanear el costal?",
        children: (
            <React.Fragment>
                <p>
                    Deberás comentarle al veloz que <b>tú no puedes entregarle el saco hasta ser escaneado</b>. Si el veloz no quiso en ningún momento escanear el saco deberán informar inmediatamente por correo a ricardo.castro@99minutos.com y fernando.rios@99minutos.com detallando el problema.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si alguno de los sacos que he recolectado se encuentra en estado ‘Demorado’?",
        children: (
            <React.Fragment>
                <p>
                    Si uno de tus sacos está en estado ‘Demorado’ puedes comunicarte con un asesor de Punto99 a los correos ricardo.castro@99minutos. com y fernando.rios@99minutos.com indicando el nombre de tu Punto99 y el tag del saco en cuestión. Con esto el equipo asignará un veloz a la recolecta del saco en tu Punto99.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si un veloz retorna a mi Punto99 con paquetes que corresponden a un saco que previamente recolecté?",
        children: (
            <React.Fragment>
                <p>
                    <b>1- </b>¡Atención!  NO deberás escanear los paquetes
                </p>
                <p>
                    <b>2- </b> Deberás revisar que los paquetes están en buenas condiciones (no maltratados o violado el empaque) para poder ser aceptados.
                </p>
                <p>
                    <b>3- </b> En caso de que no estén en buenas condiciones, deberán de realizar un reporte vía correo a ricardo.castro@99minutos.com y fernando.rios@99minutos.com, detallando por qué se están rechazando los paquetes devueltos, incluyendo  el reporte el número de guía, nombre del veloz, detalle de cómo llegan los paquetes (también se pueden incluir fotografías).
                </p>
                <p>
                    <b>4- </b> El veloz te entregará los paquetes y tú como el encargado del P99 deberá de llenar el “formato de devolución” en el apartado “Devolución” con los datos que se solicitan (en caso de que no sean rechazados).
                </p>
                <p>
                    <b>5- </b> Debes guardar en tu Punto 99 los paquetes devueltos.
                </p>
                <p>
                    <b>6- </b>Al día siguiente, cuando el veloz se presente de nuevo para recolectar otro saco, deberá de ser llenado este mismo “formato de devolución” (llenado el día anterior) en el apartado donde dice “Segundo intento” con los datos que se solicita. El apartado de “Segundo intento”, es para documentar que el paquete está siendo recolectado del P99 por el veloz para realizar un segundo intento de entrega.
                </p>
                <p>
                    <b>7- </b> Tú, como responsable del Punto 99, debes de informar por correo que el/los paquete/s han sido recolectados por el veloz y que han salido de tu P99 a los correos ricardo. castro@99minutos.com y fernando.rios@99minutos.com.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si los paquetes que me van a devolver no están en perfectas condiciones?",
        children: (
            <React.Fragment>
                <p>
                    Avisar inmediatamente por correo a ricardo.castro@99minutos.com y fernando.rios@99minutos.com detallando el problema que se presenta con los paquetes y adjuntando imágenes del producto.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué hago si el veloz no quiere firmar de que se está llevando los paquetes de nuevo a ruta?",
        children: (
            <React.Fragment>
                <p>
                    Avisar inmediatamente por correo a ricardo.castro@99minutos.com y fernando.rios@99minutos.com detallando el problema.
                </p>
            </React.Fragment>
        )
    },
]