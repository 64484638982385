type Font = "bold" | "semi-bold" | "normal";
interface Props {
  icon?: string;
  label?: string;
  labelColor?: string;
  labelWeight?: string;
  labelDecoration?: string;
  padding?: string | number;
  text?: string | number;
  required?: boolean;
  textColor?: string;
  textDecoration?: string;
  textWeight?: Font;
  input?: any;
  setInput?: any;
  edit?: boolean;
  placeholder?: string;
  type?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
}

const IconLabel = ({
  required = false,
  icon,
  label,
  labelColor,
  labelWeight,
  labelDecoration,
  padding,
  text,
  textDecoration,
  edit,
  textColor = "#12344a",
  textWeight = "normal",
  setInput,
  input,
  type = "text",
  min = "1",
  max,
  placeholder = "Escriba aquí...",
  maxLength,
}: Props) => {
  return (
    <div>
      <div className="flex items-center">
        {/* only shows image if there is an icon props */}
        {icon && <img className="icon" src={icon} alt={text?.toString()} />}
        <strong
          className={`${labelWeight} color-batman`}
          style={{
            color: labelColor,
            textDecoration: labelDecoration,
          }}
        >
          {label}
        </strong>
      </div>
      {/* show text if edit=true, if false then show input */}
      {edit ? (
        <div className="flex items-center">
          <input
            required={required}
            className="input-underline-gray input"
            style={{
              paddingLeft: `${padding}em`,
            }}
            name={`input-${label}`}
            maxLength={maxLength}
            type={type}
            min={min}
            max={max}
            id={`input-${label}`}
            onChange={(e) => setInput(e.target.value)}
            value={input}
            placeholder={placeholder}
          />
        </div>
      ) : (
        <p
          className={`flex flex-col my-025 ${textWeight}`}
          style={{
            color: textColor,
            textDecoration: textDecoration,
            paddingLeft: `${padding}em`,
          }}
        >
          {text}&nbsp;
        </p>
      )}
    </div>
  );
};

export default IconLabel;
