/* eslint-disable no-useless-escape */
import { useState, useContext, useMemo } from "react";
import IconBtn from "../components/IconBtn";
import IconLabel from "../components/IconLabel";
import { useHistory } from "react-router-dom";
import BackgroundShape from "../components/BackgroundShape";
import { ContextWrapper } from "../context/AppWrapper";
import IconLabelSelect from "../components/IconLabelSelect";
import PointType from "../components/UserInfo/PointType";
import { getDispatch, updateDispatch } from "../utils/axiosRequest";

function ProfilePage() {
  let history = useHistory();
  const { userData, load } = useContext(ContextWrapper);
  const [user] = userData;
  const [reload, setReload] = load;
  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState(false);

  const format = {
    business_name: "...",
    responsible_name: "...",
    description: "...",
    service_type: "...",
    address_line: "...",
    business_line: "...",
    website: "...",
    email: "...",
    //new
    responsible_phone_number: "...",
    phone_number: "...",
    postal_code: "..",
    country: "",
    neighborhood: "",
    municipality: "",
    additional_address_info: "",
    state: "",
    latitude: null,
    longitude: null,
    owner_name: "",
    owner_lastname: "",
    max_capacity: 0,
    rfc: "",
  };
  const timeFormat = {
    monday: { opens: "08:00", closes: "19:00", closed: false },
    tuesday: { opens: "08:00", closes: "19:00", closed: false },
    wednesday: { opens: "08:00", closes: "19:00", closed: false },
    thursday: { opens: "08:00", closes: "19:00", closed: false },
    friday: { opens: "08:00", closes: "19:00", closed: false },
    saturday: { opens: "08:00", closes: "19:00", closed: false },
    sunday: { opens: "08:00", closes: "19:00", closed: false },
    time_zone: "America/Mexico_City",
  };

  const [input, setInput] = useState(format);
  const [dispatchData, setDispatchData] = useState(format);
  const [scheduleSaved, setScheduleSaved] = useState(timeFormat);
  


  useMemo(() => {
    const userToken = localStorage.getItem("userToken");
    if (user) {

      getDispatch(user.current_dispatch_place_id, userToken).then((success) => {
        if (success) {
          
          let timeParsed = JSON.parse(success.data.schedule);
          setScheduleSaved(timeParsed);
          setInput({
            ...success.data,
            //send schedule as object, else 422 error
            schedule: timeParsed,
          });
          setDispatchData(success.data);
        } else {
          history.push('/home')
          setError(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reload]);

  const cancelEdit = () => {
    setIsEditable(false);
    setError(false);
    let formated = { ...dispatchData, schedule: scheduleSaved };
    setInput(formated);
  };

  const updateUserData = () => {
    const userToken = localStorage.getItem("userToken");
    updateDispatch(
      {
        ...input,
      },
      user.current_dispatch_place_id,
      userToken
    ).then((success) => {
      if (success) {
        setIsEditable(false);
        setError(false);

        setReload(!reload);
      } else {
        setError(true);
      }
    });
  };

  return (
    <div className="overflow-y-visible">
      <BackgroundShape type={"small"} position="bottom" />
      <BackgroundShape type={"small"} position="top" />
      <div className="upperSection flex flex-col">
        <div className="backArrow">
          <IconBtn
            icon="../icons/arrow_white.svg"
            onClick={() => history.goBack()}
          />
        </div>

        <div className=" flex flex-col items-center">
          <h4 className="m-0 semi-bold color-white"> Tu información </h4>
          <img className="icon" src="../icons/point_black.svg" alt="map" />
        </div>
      </div>
      {/* form starts*/}
      <div className="form  mx-auto">
        {/* input section */}
        <div className="p-6 flex flex-col items-center justify-center w-full">
          <div className="w-full flex justify-end">
            <IconBtn
              icon="../icons/icon_edit.svg"
              onClick={() => setIsEditable(true)}
            />
          </div>

          <div className="mt- w-full">
            <IconLabel
              label="Nombre del Punto99"
              //alert if empty
              required={!input["business_name"]}
              icon="../emails/icon_name.svg"
              text={input["business_name"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={false}
              input={input["business_name"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  business_name: e.replace(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Responsable"
              //alert if empty
              required={!input["responsible_name"]}
              icon="../icons/icon_user.svg"
              text={input["responsible_name"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["responsible_name"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  responsible_name: e.replace(
                    /[`~!@#$%^&*()_|+\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Celular"
              //alert if empty
              required={!input["responsible_phone_number"]}
              icon="../icons/icon_telefono.svg"
              text={input["responsible_phone_number"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={false}
              input={input["responsible_phone_number"]}
              setInput={(e: any) =>
                setInput({ ...input, responsible_phone_number: e })
              }
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="RFC"
              icon="../icons/icon_rubro.svg"
              text={input["rfc"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              maxLength={13}
              input={input && input["rfc"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  rfc: e.replace(
                    /[`~!@#$%^&*()_|+\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="Nombre(s) del dueño"
              //alert if empty
              required={!input["owner_name"]}
              icon="../icons/icon_user.svg"
              textColor="var(--gray100)"
              padding={1.8}
              text={input["owner_name"]}
              edit={isEditable}
              input={input && input["owner_name"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  owner_name: e.replace(
                    /[`~!@#$%^&*()_|+\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Apellido del dueño"
              //alert if empty
              required={!input["owner_lastname"]}
              icon="../icons/icon_user.svg"
              textColor="var(--gray100)"
              padding={1.8}
              text={input["owner_lastname"]}
              edit={isEditable}
              input={input && input["owner_lastname"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  owner_lastname: e.replace(
                    /[`~!@#$%^&*()_|+\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Breve descripción"
              icon="../icons/icon_descripcion.svg"
              text={input["description"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["description"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  description: e.replace(
                    /[`~!@#$%^&*()_|+\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Dirección"
              icon="../icons/icon_direccion.svg"
              //alert if empty
              required={!input["address_line"]}
              text={input["address_line"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={false}
              input={input["address_line"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  address_line: e.replace(
                    /[`~!@$%^&*()_|+\=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="Información adicional / Referencias"
              icon="../icons/icon_direccion.svg"
              placeholder="Entre calle Novelistas y Manuel Payno"
              textColor="var(--gray100)"
              edit={isEditable}
              padding={1.8}
              text={input["additional_address_info"]}
              maxLength={120}
              input={input["additional_address_info"]}
              setInput={(e: any) =>
                setInput({ ...input, additional_address_info: e })
              }
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabelSelect
              label="Rubro"
              icon="../icons/icon_rubro.svg"
              text={input["business_line"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              setInput={(e: any) => setInput({ ...input, business_line: e })}
              input={input["business_line"]}
              opciones={["No aplica"]}
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="Teléfono"
              icon="../icons/icon_telefono.svg"
              text={input["phone_number"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              maxLength={11}
              input={input["phone_number"]}
              setInput={(e: any) =>
                setInput({
                  ...input,
                  phone_number: e.replace(
                    /[`~!@#$%^&*()_|\-=?¿¡;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  ),
                })
              }
            />
            {input["phone_number"].length > 11 && isEditable && (
              <small style={{ color: "red" }}>
                Ingrese máximo 11 dígitos ({11 - input["phone_number"].length})
              </small>
            )}
          </div>

          <div className="mt-4 w-full ">
            <PointType
              edit={isEditable}
              input={input["service_type"]}
              setInput={(e: any) => setInput({ ...input, service_type: e })}
            />
          </div>

          <div className="mt-4 w-full">
            <IconLabel
              label="Sitio web"
              icon="../icons/icon_web.svg"
              text={input["website"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["website"]}
              setInput={(e: any) => setInput({ ...input, website: e })}
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="Email"
              icon="../icons/icon_email.svg"
              text={input["email"]}
              padding={1.8}
              textColor="var(--gray100)"
              edit={isEditable}
              input={input["email"]}
              setInput={(e: any) => setInput({ ...input, email: e })}
            />
          </div>
          <div className="mt-4 w-full">
            <IconLabel
              label="Capacidad máxima de paquetes"
              //alert if empty
              required={!input["max_capacity"]}
              icon="../icons/icon_bank.svg"
              type="number"
              min="1"
              max={1000}
              textColor="var(--gray100)"
              text={input["max_capacity"]}
              padding={1.8}
              edit={false}
              input={input["max_capacity"]}
              setInput={(e: any) => setInput({ ...input, max_capacity: e })}
            />
            {input["max_capacity"] >= 1001 && (
              <small style={{ color: "red" }}>
                {" "}
                La cantidad máxima es de 1000 paquetes
              </small>
            )}
          </div>
        </div>

        {error && <p className="w-full text-center text-red-400">Error</p>}
        {isEditable && (
          <div className="w-full flex justify-end my-6 flex-wrap">
            <button
              className="agave-button ag-button-secondary mr-5 my-1"
              onClick={cancelEdit}
            >
              Cancelar
            </button>

            <button
              disabled={
                !input["business_name"] ||
                !input["responsible_name"] ||
                !input["responsible_phone_number"] ||
                !input["address_line"] ||
                !input["postal_code"] ||
                !input["owner_name"] ||
                !input["owner_lastname"] ||
                !input["max_capacity"] ||
                input["max_capacity"] >= 1001
              }
              className="agave-button ag-button-primary mr-5 my-1"
              onClick={updateUserData}
            >
              Guardar
            </button>
          </div>
        )}
      </div>

      <div className="downSection"></div>
    </div>
  );
}

export default ProfilePage;
