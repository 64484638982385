// @ts-nocheck
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
  CheckIcon,
  CloseIcon,
  CodeIcon,
  UserIcon,
} from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";

function MatchUserPackage() {
  const { goBack, push, location } = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirm, setConfirm] = useState(false);

  const goToScanPackage = () => {
    push("/scanner-deliver", { ...location.state });
  };

  const [isOpen, setIsOpen] = useState(false);


  return (
    <div className="h-screen w-screen overflow-y-hidden fixed">
      <div
        style={{
          transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
          height: "min-content",
          zIndex: 6,
        }}
        className={`absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green ${
          isOpen ? "slide-open" : "slide-close"
        }`}
      >
        <div
          onClick={() => setIsOpen(false)}
          className="flex w-full pt-5 pr-5 justify-end"
        >
          <CloseIcon />
        </div>

        <div className="flex flex-col items-center px-10">
          <span className="font-bold text-white text-lg text-center">
            Motivo por el que no aceptó
            <br />
            su paquete
          </span>
          <textarea
            className="w-full rounded-lg h-32 px-4 py-2 mt-5"
            placeholder="Escribe el motivo"
          />
          <div className="flex items-center justify-center my-5 px-4 py-2 w-2/3 bg-white rounded-full">
            <span className="text-lg text-center text-green-100 font-medium">
              Solicitar devolución
            </span>
          </div>
        </div>
      </div>

      <BackgroundShape type={"medium"} position="bottom" />
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          <div
            onClick={goBack}
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={"90"} />
          </div>
          <div className="flex w-full justify-center">
            <span className="text-xl text-center font-semibold text-green-100">
              Estás por entregar el paquete
              <br />
              <span className="text-gray-200">al cliente</span>
            </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot />
          </div>
          <div className="flex items-center justify-center mt-7 w-full">
            <span className="text-md text-gray-200 font-light text-center">
              Revisa que los datos coincidan con
              <br />
              el paquete que vas a entregar
            </span>
          </div>
          {!confirm && (
            <div className="px-8 w-full mt-10">
              <div
                style={{ boxShadow: "0 10px 20px 0 rgba(128, 128, 128, 0.31)" }}
                className="w-full bg-white rounded-md"
              >
                <div className="flex flex-row items-center px-5 py-4 border-b border-gray-1800">
                  <div className="flex items-center justify-center w-1/12">
                    <UserIcon />
                  </div>
                  <span className="ml-5">
                    {[11, 12].includes(
                      location.state?.orderInfo?.order_status_id
                    )
                      ? location.state?.orderInfo?.origin_name !== null
                        ? location.state?.orderInfo?.origin_name
                        : "No hay informacion disponible"
                      : location.state?.orderInfo?.deliver_to}
                  </span>
                </div>
                <div className="flex flex-row items-center px-5 py-4">
                  <div className="flex items-center justify-center w-1/12">
                    <CodeIcon />
                  </div>
                  <span className="ml-5">{location.state?.orderNumber}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col w-full items-center">
          {confirm && (
            <div className="flex flex-col items-center w-full px-10">
              <div>
                <CheckIcon />
              </div>
              <div className="font-bold text-white">
                ¡Gracias por entregar el paquete!
              </div>
              <div className="font-bold text-lg text-white text-center mt-5">
                Con este paquete
                <br /> ganaste $15
              </div>
            </div>
          )}

          {confirm ? (
            <>
              <div className="flex flex-col items-center w-full mt-12">
                <div
                  onClick={() => push("/home")}
                  className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                >
                  <span className="text-lg text-green-100 font-semibold">
                    Ver tus ganancias
                  </span>
                </div>
                <div
                  onClick={() => push("/home")}
                  className="flex items-center justify-center my-5 px-4 py-2 w-2/3  rounded-full"
                >
                  <span className="text-lg text-white font-thin">
                    Finalizar
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center w-full mb-12">
                <div
                  onClick={goToScanPackage}
                  className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                >
                  {/* todo make another page grabing user qr code data and passing to confirmdeliver page for verification and confirm */}
                  <span className="text-lg text-green-100 font-semibold">
                    Escanear paquete
                  </span>
                </div>
                {/* <div
                  onClick={() => setIsOpen(true)}
                  className="flex items-center justify-center my-5 px-4 py-2 w-2/3  rounded-full"
                >
                  <span className="text-lg text-white font-thin">
                    Paquete rechazado
                  </span>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MatchUserPackage;
