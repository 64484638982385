import moment from 'moment'
import { RecolectionIcon } from "../../assets/icons";

const RecolectedPackages = ({lastPackage}: any) => {
  return (
    <>
    <div className='w-6 h-6 mt-2'>
    <RecolectionIcon />
    </div>
      <span className="text-sm font-normal text-gray-200">
        Estos son tus <span className="font-bold">paquetes escaneados</span>
      </span>
      <span className="text-sm font-light text-gray-800 mb-5 small leading-3">
        Último paquete recolectado:{lastPackage?.created_at ? moment(lastPackage?.created_at).format('DD/MM/yyyy • HH:mm') + ' hrs' : ''}
        {/* 01/07/2021 • 13:45 hrs */}
      </span>
    </>
  );
};

export default RecolectedPackages;
