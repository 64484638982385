const Switch = ({ isChecked, handleOnChange, isDisabled = false }) => {
  return (
    <label className="switch">
      <input
        onChange={handleOnChange}
        checked={isChecked}
        disabled={isDisabled}
        type="checkbox"
        name="switch"
        id="stich"
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
