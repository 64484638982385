import React from "react";
import RoundImage from "../RoundImage";
interface Props {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Screen2 = ({ setIndex }: Props) => {
 
  return (
    <>
      <div className="fixed  text-center w-full  fixed left-0 right-0 title-animation " >
      <p className="color-white semi-bold  fixed top-20 right-0 left-0 flex justify-center ">Ahora existen</p>
      <b className="color-green99 fixed top-24 right-0 left-0 flex justify-center">dos tipos de recolecciones</b>
      </div>
      <div className=" w-full p-2 flex flex-col  items-center mt-4">
      <RoundImage
                  image="../icons/icon_recoleccion.svg"
                  size={ 3 }
                  alt="recolección"
                  backgroundColor="white"
                  shadow={ true }
                  padding={0.8}
                />
                <p className="bold color-white "> Paquetes externos</p>
                
                <p className="w-9/12 text-center mb-4">Son aquellos <span className="bold">paquetes que recibes de otros usuarios</span> y que los dejan en tu tienda para que tú los entregues al Veloz o al cliente.</p>
                <RoundImage
                  image="../icons/icon_recoleccion.svg"
                  size={ 3}
                  alt="recolección"
                  backgroundColor="white"
                  shadow={ true }
                  padding={0.8}

                />
                <p className="bold color-white "> Paquetes propios</p>
                
                <p className="w-9/12 text-center"><span className="bold ">Son aquellos paquetes con tus productos</span> y que tú nos entregas para enviarle a tus clientes, a través del servicio de 99minutos.</p>
                <p className="color-white text-center mt-5 w-9/12">Si tienes dudas puedes ir a <span className="bold">preguntas frecuentes</span> en tu perfil.</p>
        
        <div className=" w-full p-2 flex flex-col">
        <button className="color-white bold mt-4" onClick={() => setIndex(2)}>¡Entendido!</button>
        {/* <div
          className="w-full flex justify-center my-3"
        >
        <button className="currentSlider m-1"/>
          <button
            className="unactiveSlider m-1"
            onClick={() => setIndex(0)}
          />
          <button
            className="unactiveSlider m-1"
            onClick={() => setIndex(2)}
          />
        </div> */}
      </div>
      </div>

    </>
  );
};

export default Screen2;
