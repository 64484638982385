import React, { useState } from "react";
import Arrow from "../assets/icons/Arrow";

export interface PropsAccordion {
  icon?: string;
  label: string;
  open?: boolean | undefined;
  children: React.ReactNode;
}

const Accordion = (props: PropsAccordion) => {

  const [open, setOpen] = useState(Boolean(props.open));

  const toggleVisibility = () => { setOpen(!open) }


  return (
    <div className="w-full" >
      <div className="flex items-center justify-between" onClick={toggleVisibility}>
        {/* only shows image if there is an icon props */}
        <div className="w-11/12">
          {props.icon && <img className="icon" src={props.icon} alt="icon" />}
          <strong className={`bold color-batman `}>{props.label}</strong>
        </div>
        {open? <Arrow />: <Arrow rotate="0deg"/>}

      </div>
      {/* show text if edit=true, if false then show input */}
      {open && (
        <div
          className="flex items-center my-1 "
          style={{ position: "relative" }}
        >
          <p
            className={`flex flex-col my-1 normal color-gray100`}
            style={{
              paddingLeft: `1.2em`,
              paddingRight: `2em`,

            }}
          >
            {props.children}&nbsp;
          </p>
        </div>
      )}
    </div>
  )

}

export default Accordion
