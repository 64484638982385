export const formatDate = ( date, timeZone = 'America/Mexico_City' ) => {
  return new Date( date ).toLocaleString( "es-MX", {
    timeZone,
  } );
};

export const formatDateWithoutTime = ( date, timeZone = 'America/Mexico_City' ) => {
  const dateParse= new Date( date ).toLocaleString( "es-MX", {
    timeZone,
  } );
  return dateParse.split(',')[0]
};