import { useState, useContext } from "react";
import "react-phone-number-input/style.css";
import BackgroundShape from "../components/BackgroundShape";
import { useHistory } from "react-router-dom";
import { ContextWrapper } from "../context/AppWrapper";
import Input from "react-phone-number-input/input";
import { getCountryCallingCode } from "react-phone-number-input";
import {
  getUserByPhone,
  generateCode,
  setCookies,
  generateWhatsappCode,
} from "../utils/axiosRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//'161|jQqH0I2O2eDOeKRFvbGiDCDG3aEV7bXii4kI7SsW'
const PhoneVerification = () => {
  let history = useHistory();
  const [celular, setCelular] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  //global context
  const { userData } = useContext(ContextWrapper);
  // eslint-disable-next-line no-unused-vars
  const [_, setUser] = userData;

  const getUserData = (isSms) => {
    setIsLoading(true)
    let code = getCountryCallingCode("MX");
    let codeLength = code.length + 1;
    let phoneNum = celular.slice(codeLength);
    getUserByPhone(phoneNum)
      .then((success) => {
        if (success.error) {
          setIsLoading(false)
          setError(true);
        } else if (!success.error && !success.data.id) {
          setIsLoading(false)
          setError(true);
        } else if (!success.error && success.data.id) {
          setError(false);
          let userGeneral = { ...success.data, phone: phoneNum, send: isSms };
          setUser(userGeneral);
          let stringifyUser = JSON.stringify(userGeneral);
          localStorage.setItem("userGeneral", stringifyUser);
          setCookies();

          isSms && createNewCodeSMS(success.data, phoneNum);
        }
      })
      .catch((errors) => {
        console.error(errors)
    setIsLoading(false)

  });
};

const createNewCodeSMS = (data) => {
  generateCode(data.id, false).then((success) => {
    if (success.error) {
          setIsLoading(false)
      toast.error(success.message.message);
    } else if (!success.error && !success.data.success) {
          setIsLoading(false)
      toast.error(success.message);
    } else if (!success.error && success.data.success) {
          setIsLoading(false)
      history.push("/codigo");
      toast.success("Se te ha enviado un código por SMS");
    }
  });
};

const createNewCodeWhats = (data, phoneNum) => {
  generateCode(data.id, true).then((success) => {
    if (success.error) {
          setIsLoading(false)
      toast.error(success.message.message);
    } else if (!success.error && !success.data.success) {
          setIsLoading(false)
      toast.error(success.message);
    } else if (!success.error && success.data.success) {
      const body = success.data.body.code;
      const fmtCode = body.slice(2, 7);
      createWhatsappCode(phoneNum, data.name, fmtCode);
      history.push("/codigo");
    }
  });
};
const createWhatsappCode = (tel, name, fmtCode) => {
  generateWhatsappCode(tel, name, fmtCode).then((success) => {
    if (success.error) {
          setIsLoading(false)
      toast.error("Error al envíar código por Whatsapp");
    } else if (!success.error && success.data.calixta.status !== "success") {
          setIsLoading(false)
      toast.error("Error al envíar código por Whatsapp");
    } else if (!success.error && success.data.calixta.status === "success") {
          setIsLoading(false)
      toast.success("Se te ha enviado un código por  WhatsApp");
    }
  });
};

return (
  <div className=" w-full h-screen flex flex-col items-center overflow-y-hidden fixed">
    <ToastContainer />
    <div className=" upperSectionOnboardingPhone pt-28">
      <BackgroundShape type={"quarter"} position="top" />
      <div className="titleSectionPhone">
        <h6 className="m-0 bold color-white">Ingresa tu número de celular</h6>
        <img
          className="icon mt-2"
          src="../icons/point_black.svg"
          alt="cirlce"
        />
      </div>
      <div className="flex flex-col items-center mt-5">
        <p className="body3 color-white mt-5 z-10">
          {" "}
          Te enviaremos un código de verificación
        </p>
        <Input
          className={`input-none inoutPhone body2 input ${error && 'error'}`}
          country="MX"
          international
          maxlength={16}
          withCountryCallingCode
          value={celular}
          onChange={setCelular}
        />
      </div>
      {error && (
        <p
          className="mt-3 semi-bold text-center"
          style={{ color: "#990000" }}
        >
          El número telefónico no se encuentra registrado
        </p>
      )}
      
    </div>
    <div className="flex flex-col justify-center absolute bottom-24  text-center">
      {/* <div className="flex flex-col justify-center w-10/12 mx-auto  mt-2"> */}
        <button
          disabled={(celular && celular.length === 13 ? false : true) || isLoading}
          className="flex items-center justify-center my-2 agave-button  ag-button-primary"
          onClick={() => getUserData(true)}
        >
          {isLoading ? <div className="lds-dual-ring-small-white" /> : 'Enviar por SMS'}
        </button>
      </div>
      {/* <button 
          disabled={(celular && celular.length === 13 ? false : true) || isLoading}
          onClick={() => getUserData(true)} 
          className={`w-full mt-3 z-10 text-center m-auto ${!celular || celular.length !== 13 ? 'color-gray50': 'color-green'} `}>
          {isLoading ? <div className="lds-dual-ring-small-white" /> : '¿No tienes WhatsApp? Te lo enviamos por SMS'}
      
      </button> */}
    {/* </div> */}
  </div>
);
};

export default PhoneVerification;
