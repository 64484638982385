import React from "react";
import { CloseIcon } from "../../assets/icons"
import styles from "./GreenModalWrapper.module.css";

interface Props {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children?:any;
  canClose?:boolean; 
  colorClose?:string;
  scroll?:boolean;
  backgroundColor?:string;
}

const GreenModalWrapper: React.FC<Props> = ({ children, modal, setModal, canClose=true , colorClose='var(--gray50)', scroll=true, backgroundColor='linear-gradient(355deg, rgba(0,210,182,1) 1%, rgba(133,196,64,1) 100%)'}) => {
  return (
    <>
      {modal && (
        <>

          <div
            className={styles.backgroundBlur}
            onClick={canClose ? () => setModal(false): () => setModal(true)}
          >
            {" "}
          </div>

          <div className={styles.sliderContainer} style={{ maxHeight: scroll? '50vh': 'min-content',  
            background:backgroundColor }}>
            <button
              className={styles.closeButton}
              onClick={() => setModal(false)}
            >
             {canClose && <CloseIcon tint={colorClose} />} 
            
            </button>
            <div className={styles.innerContainer}>{children}</div>
          </div>
        </>
      )}
    </>
  );
};

export default GreenModalWrapper;
