// @ts-nocheck
import { QrReader } from "@blackbox-vision/react-qr-reader";
import {  useState } from "react"; 
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
} from "../../assets/icons";
import BackgroundShape from "../../components/BackgroundShape";
import { deliverBagApi, saveEvidence } from "../../utils/requests";
import { toast } from "react-toastify";

function TakeBagEvidence() {
  const { goBack, push, location } = useHistory();
  const { state } = location;

  const [confirm, setConfirm] = useState(false);
  const [loading, setloading] = useState(false);
  const [evidencePhoto, setEvidencePhoto] = useState(null);

  const freeBagToVeloz = async (evidenceURL) =>
  { // added as hotfix to allow release without scanning , remove when fixed
    let evidence= {
      "evidences": [
          {
              "type": "RELEASE",
              "url": evidenceURL
          }
      ]
  }
    const { error, message, data } = await deliverBagApi(evidence, state?.containerID)
    if (error) {
      toast.error(message || 'Ocurrió un error liberando el saco')
    }
    toast.success('Saco liberado correctamente para escaneo de veloz.')
  }

  const confirmRecollection = async () => {
    setloading(true);
    const evidenceURL = await saveEvidence({
      type: state?.evidenceType,
      orderNumber: state?.containerID || state?.body.tag || 'tests-evidence',
      image: evidencePhoto,
    });
    await freeBagToVeloz(evidenceURL.data)
    const routes: {[key: string]: string} = {
      // deliverExchangePoint: '/exchange-point/scanner-veloz', 
      deliverExchangePoint: "/exchange-point/deliver-info-veloz",// added as hotfix to allow release without scanning, remove when fixed
      recollectExchangePoint: '/exchange-point/confirm-recollect',
    }
    setloading(false)
    setConfirm(true);
    push(routes[state?.evidenceType], { ...state, evidenceUrl: evidenceURL.data , evidenceType: 'deliverExchangePoint'})
  };



  const captureVideo = (video: any) => {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var canvasContext = canvas.getContext("2d");
      //@ts-ignore
      canvasContext.drawImage(video, 0, 0);
      return canvas.toDataURL("image/png");
    } catch (e) {
      console.log(e);
    }
  };

  const takePhoto = () => {
    const getFrame = captureVideo(document.getElementById("qrreader"));
    setConfirm(true);
    setEvidencePhoto(getFrame);
  };

  const retakePhoto = () => {
    setConfirm(false);
    setEvidencePhoto(null);
  };

  return (
    <div className="h-screen w-screen overflow-y-auto fixed">
      <BackgroundShape direction="revert" type={"quarter"} position="bottom" />
      <div className="flex flex-col h-full w-full">
        <div>
          <div
            onClick={goBack}
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={"90"} />
          </div>
          <div className="flex w-full justify-center">
            <span className="text-xl text-center font-semibold text-green-100">
              Toma de 
              <br />
              <span className="text-gray-200">evidencia</span>
            </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot />
          </div>
        </div>

        <div className="flex-col  w-full  flex justify-center items-center mt-10 mb-5">
          {confirm ? (
            <>
              <span className="text-gray-600 text-thin" >¿La fotografía es <span className="font-bold">clara</span>?</span>
              <div className="sm:w-100  w-72 mt-10">
                <img style={{ width: '100%', maxHeight: '40vh' }} src={evidencePhoto} alt='evidence'/>
              </div>
              <div className="flex sm:w-100 w-72  Vxxs:mt-5 mt-10 flex-row justify-between items-center">
                <button
                    onClick={retakePhoto}
                    className="mt-3 h-min rounded-full border-2 border-white text-black px-5 py-2"
                >
                  Reintentar
                </button>
                <button
                disabled={loading}
                  onClick={() => {confirmRecollection()}}
                  className="flex items-center justify-center mt-3 h-min rounded-full border-2 border-white bg-white font-bold text-green-200 px-5 py-2"
                >
                  {loading ? <div className="lds-dual-ring-small" /> : 'Aceptar'}
                </button>
              </div>
            </>
          ) : (
            <>
              <span className="text-center text-gray-600 text-thin">
                <span className="font-bold">Por favor toma una fotografía del saco</span><br />donde muestre el sello intacto.
              </span>
              <div className="flex items-center justify-center w-full mt-10  Vxxs:mt-4 ">
                <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
                  <div
                    style={{
                      width: "101%",
                      height: "101%",
                      display: loading ? "none" : "",
                    }}
                    className="absolute z-10"
                  >
                  </div>
                  <div
                    style={{ zIndex: loading ? 10 : -10 }}
                    className={`absolute bg-white w-full h-full flex items-center justify-center`}
                  >
                    <div className="lds-dual-ring"></div>
                  </div>
                  <QrReader
                    videoId={"qrreader"}
                    onResult={()=>{}}
                    containerStyle={{ width: '100%',  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    videoStyle={{width: '300%', height: '100%', objectFit: 'fill' }}
                    videoContainerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding:'0'}}
                    constraints={{ facingMode: "environment" }}
                    scanDelay={100}
                />
                </div>
              </div>

              <button
                onClick={takePhoto}
                className=" Vxxs:my-4  my-10 rounded-full border border-white text-green-100 font-bold px-5 py-2 bg-white"
              >
                Tomar fotografía
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TakeBagEvidence;
