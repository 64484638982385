import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_BASE_URL


/**
 * @description get a user if it finds a match in db
 * @returns
 * "id": 2,
    "name": "Isabel",
    "lastname": "Ferrara",
    "avatar_url": "NA"
 */
  
export const getUserByPhone = (phone) => {
  return fetch(`${apiUrl}/auth/verify/${phone}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return { error: false, data: res };
    })
    .catch((e) => {
      return { error: true, message: e };
    });
};


/**
 * @description set cookies in browser
 * @returns
 * 
 */
    export const setCookies = async()=>{
      try{
        return axios.get(`https://beautiful-beach-kwel0ptv5gey.vapor-farm-c1.com/sanctum/csrf-cookie`, {}, {
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
      }catch(error){
          return false
        }     
      } 
    
/**
    /**
 * @description get App users' login code 
 * @returns   
{
    "success": true,
    "message": "Created access code successfully",
    "body": {
        "code": "298489570"
    }
}
 */

export const generateCode =  (userId, send)=> {
  return  fetch(`${apiUrl}/auth/generateSms/${userId}`,{
    method:'POST',     
      headers: {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({forWhatsApp: send}) 
    }) 
    .then(res => res.json())
    .then(res =>{
       return { error: false,  message:res.message, data:res }}
    )
    .catch(e => {
      return { error: true, message: e  }
    })
  }

/**
    /**
 * @description send whatsapp with the code 
 * @returns   
{
    "success": true,
    "message": "Created access code successfully",
    "body": {
        "code": "298489570"
    }
}
 */

export const generateWhatsappCode =  (phoneNumber, name, code) => {
  return  fetch(`https://99minutos.calixtachat.com/api/v1/chats?api_token=rjwWoyCsx9f5tK7qhEqBgd2MjYSU4i&channel_id=10&session=${phoneNumber}&template_id=ff435488_5de8_4037_855f_a233766336ab:codigo_de_acceso:es_MX&vars[]=${name}&vars[]=${code}`,{
    method:'POST',     
      headers: {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      },
      body:{}
    }) 
    .then(res => res.json())
    .then(res =>{
       return { error: false,  message: res.message, data:res }}
    )
    .catch(e => {
      return { error: true, message: e  }
    })
  }


/**
 * @description verify sms code in order to login user
 * @returns   
 * "message": "string",
  "code": number,
  "accessToken": string"
 */

export const loginUser = async (id, code)=>{
  try{
    const res = await axios.post(`${apiUrl}/auth/login`,
    {
    userId: id,
    smsCode: code,
    }, 
    {
  headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
    return res.data
  }catch(error){
    return false
  } 
} 

/**
 * @description logout user
 * @returns   
 */

  export const logoutUser = async (token)=>{
    try{
      return axios.post(`${apiUrl}/auth/logout`,{},
      {
    headers: {
        Accept: 'application/json',
        Authorization:`Bearer ${token}`,
      },
    });
    }catch(error){
      return false
    } 
  } 

/**
 * @description get complete user info 
 * @returns   
 *
    "id": 2,
    "name": "Isabel",
    "email": "isabel@99minutos.com",
    "email_verified_at": null,
    "created_at": "2021-07-19T14:00:19.000000Z",
    "updated_at": "2021-07-19T14:00:19.000000Z",
    "tenant_id": "123456",
    "lastname": "Ferrara",
    "phone_number": "5539393631",
    "username": "NA",
    "avatar_url": "NA",
    "is_active": 1,
    "current_dispatch_place_id": 1,
    "country_code": "+52",
    "current_dispatch_place": {
        "id": 1,
        "tenant_id": "123456",
        "client_id": "123456",
        "owner_name": "Leonides",
        "owner_lastname": "Zapata Saavedra",
        "description": "Un comercio propio de ...",
        "responsible_name": "Leonides",
        "responsible_phone_number": "555555555555",
        "phone_number": "123456",
        "email": "leonides@algo.com",
        "address_line": "Mi dirección",
        "postal_code": "07812",
        "country": "México",
        "neighborhood": "Jorge Negrete",
        "state": "Ciudad de México",
        "latitude": "0.12122130",
        "longitude": "-78.21321300",
        "service_type": "DELIVERY_RECOLLECTION",
        "is_active": 1,
        "clabe": "12345678213213",
        "beneficiary_name": "Leonides Zapata Saavedra",
        "bank_brand": "Banamex",
        "current_rate_id": 123456,
        "current_rate_amount": "456.00",
        "volume_capacity": "92.10000000",
        "weight_capacity": "12.10000000",
        "current_package_count": 5,
        "created_at": null,
        "updated_at": "2021-08-06T14:22:51.000000Z",
        "rfc": "1",
        "business_name": "Abarrotes 1",
        "website": "www.leo.com",
        "public_holiday": 1,
        "business_line": "Abarrotes",
        "max_capacity": 40
 */



  export const getCompleteUserData=(token) => {
    return  fetch(`${apiUrl}/auth/userInfo`,{
      method:'GET',     
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization:`Bearer ${token}`,
        },
      }) 
      .then(res => res.json())
      .then(res =>{

        const unauth= res.message==="Unauthenticated."
        unauth &&localStorage.removeItem("userToken")
        res= unauth?false: res
         return { error: false,  data:res
        }
      }
      )
      .catch(e => {
        return { error: true, message: e , data:[] }
      })
    }

   /**
 * @description get point99 general information
 * @returns  
 *  "client_id": "123456",
// "tenant_id": "123456",
// "owner_name": "Leonides",
// "rfc": "89",
// "business_name": "Abarrotes 1",
// "owner_lastname": "Zapata Saavedra",
// "description": "Un comercio propio de ...",
// "responsible_name": "Leonides",
// "responsible_phone_number": "555555555555",
// "email": "leonides@algo.com",
// "address_line": "Mi dirección",
// "postal_code": "07812",
// "country": "México",
// "neighborhood": "Jorge Negrete",
// "state": "Ciudad de México",
// "latitude": 0.1212213,
// "longitude": -78.213213,
// "service_type": "DELIVERY_ONLY",
// "clabe": "12345678213213",
// "beneficiary_name": "Leonides Zapata Saavedra",
// "bank_brand": "Banamex",
// "website":"www.leo.com",
// "public_holiday":true,
// "business_line":"Abarrotes"
 **/
export const getDispatch= async ( id, token) =>{
  try{
    return await axios.get(`${apiUrl}/dispatchPlace/${id}`, {
      headers: {
          Accept: 'application/json',
          Authorization:`Bearer ${token}`,
        }
      })
  }catch(error){
    console.log(error);
    return false
  }
}




/**
* @description update point99 general information
* @returns  if request is succesfull, returns updated user info
* else, false statement
*
**/
export const updateDispatch= async (data, id, token) =>{
  try{
    return await axios.put(`${apiUrl}/dispatchPlace/${id}`,data, {
      headers: {
          Accept: 'application/json',
          Authorization:`Bearer ${token}`,
        }
      });
  }catch(error){
    return false
  }
}




/**
* @description  get all payments filtred by id
* @returns 
*
**/
  //pasar a axios
 export const getPayments = async (query) => {
    return axios.post(`${process.env.REACT_APP_DB_PAYMENT_URL}/gql`, { query });
  };

