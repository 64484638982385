import React from "react";
import IconBtn from "../IconBtn";
import RoundImage from "../RoundImage";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../utils/axiosRequest";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  picture: string;
  token: string | any;
}

export const SideMenu = ({ open, setOpen, picture, token }: Props) => {
  let history = useHistory();
  const LogOutandPush = () => {
    logoutUser(token);
    localStorage.removeItem("userToken");
    history.push("/telefono");
  };
  return (
    <>
      {open && (
        <div className="w-full  gradient-2  h-screen sliderMenu">
          <div className="absolute top-5 left-5">
            <IconBtn
              icon="../icons/arrow_white.svg"
              onClick={() => setOpen(false)}
            />
          </div>

          <section className="flex justify-between items-center mx-5 mt-10 mb-8">
            <div className="text-center flex flex-col items-start ">
              <h3 className="m-0 color-white">Mi perfil</h3>
              <IconBtn icon="../icons/point_black.svg" />
            </div>

            <div className="flex items-center">
              <RoundImage
                image={picture}
                size={3}
                onClick={() => setOpen(false)}
              />
              {open ? (
                <IconBtn
                  icon="../icons/arrow_white2.svg"
                  onClick={() => setOpen(false)}
                />
              ) : (
                <IconBtn
                  icon="../icons/arrow_gray.svg"
                  onClick={() => setOpen(true)}
                />
              )}
            </div>
          </section>
          <div className="flex flex-col w-9/12 m-auto justify-between items-start">
            <button
              onClick={() => history.push("/profile")}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIcon"
                src="../icons/icon_tusdatos.svg"
                alt="Mis datos"
              />
              <h4 className="normal color-white m-0">Mis datos</h4>
            </button>
            <button
              onClick={() => history.push("/horario")}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIcon"
                src="../icons/icon_horarios.svg"
                alt="Mis datos"
              />
              <h4 className="normal color-white m-0">Horarios</h4>
            </button>
            <button
              onClick={() => history.push("/pagos")}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIcon"
                src="../icons/icon_datosbancarios.svg"
                alt="Mis datos"
              />
              <h4 className="normal color-white m-0 text-left">Datos bancarios</h4>
            </button>
            <button
              onClick={() => history.push("/faq")}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIcon"
                src="../icons/icon_comofunciona.svg"
                alt="Mis datos"
              />
              <h4 className="color-white normal m-0 text-left">Preguntas frecuentes</h4>
            </button>
          </div>
          <div className="flex flex-col items-start m-auto w-9/12 absolute bottom-5 left-0 right-0 mt-3">
            <button
              onClick={() => history.push("/terminos-legales")}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIconClose"
                src="../icons/icon_terminos.svg"
                alt="Mis datos"
              />
              <h4 className="color-white normal m-0 text-left">Términos y condiciones</h4>
            </button>
            <button
              onClick={LogOutandPush}
              className={`flex items-center btn-plain my-3`}
            >
              <img
                className="siderMenuIconClose"
                src="../icons/icon_cierresesion.svg"
                alt="Mis datos"
              />
              <h4 className="color-white normal m-0">Cierra la sesión</h4>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
