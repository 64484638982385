import { useState, useEffect } from "react";
import "../components/Carousel/bootstrap.min.css";
import Screen1 from "../components/OnBoarding/Screen1";
import Screen2 from "../components/OnBoarding/Screen2";
import Screen3 from "../components/OnBoarding/Screen3";
import Carousel from "react-bootstrap/Carousel";
import BackgroundShape from "../components/BackgroundShape";
import { useHistory } from "react-router-dom";

function OnBoarding() {
  //we use index to control the slide
  const [index, setIndex] = useState(0);
  const { push } = useHistory();
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const intro = localStorage.getItem("intro");
    // console.log(intro, "intro value");
    const loggedIn = true;
    if (intro && loggedIn) {
      push("/telefono");
    }
  }, []);

  return (
    <div className={`h-screen w-full overflow-y-hidden fixed`}>
      <div className="relative w-full ">
        <BackgroundShape type={"quarter"} position="top" />
        <Carousel
          wrap={false}
          controls={false}
          interval={null}
          variant="dark"
          className="relative w-full h-screen"
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <Screen1 setIndex={setIndex} />
          </Carousel.Item>
          <Carousel.Item>
            <Screen2 setIndex={setIndex} />
          </Carousel.Item>
          <Carousel.Item>
            <Screen3 setIndex={setIndex} />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default OnBoarding;