function reducer(state, action) {
  switch (action.type) {
    case "SET_MULTIPLE_ORDERS":
      return {
        ...state,
        multipleOrders: action.payload,
      };
    case "SET_EVIDENCE_DELIVERY":
        return {
          ...state,
          evidenceDel: action.payload,
        };
  case "SET_EVIDENCE_RECOLLECT":
          return {
            ...state,
            evidence: action.payload,
          };
    case "SELECT_ORDER":
      return {
        ...state,
        selectedOrder: action.payload,
      }
    default:
      throw new Error();
  }
}

export default reducer;
