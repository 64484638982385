import { MomentInput } from 'moment'
import React, {useState}  from 'react'
import { CloseIcon } from '../../assets/icons'
import Divider from '../Divider'
import RoundIconBtn from '../RoundIconBtn'
import ReturnedVeloz from './ReturnedVeloz'
import moment from "moment";
import { updateOrder, updateOrderPayment } from '../../utils/requests'
import { toast } from 'react-toastify'
import ModalWrapper from '../ModalWrapper'


type Props = {
    setIsOpen:React.Dispatch<React.SetStateAction<boolean>>,
    activeTab:number,
    activePackage: any,
    isOpen:boolean,
    setIsOpenEvidenceModal:React.Dispatch<React.SetStateAction<boolean>>,
    setEvidenceUrl:React.Dispatch<React.SetStateAction<boolean>>,
    user:any,
    setOrderList:React.Dispatch<React.SetStateAction<any>>,
    orderList:any
}


const VoluntaryPackageDetails = ({setIsOpen ,activePackage, isOpen, setIsOpenEvidenceModal, setEvidenceUrl, user,setOrderList, orderList}: Props) => {
  const [modal, setModal]=useState(false)



    const getCurrentStatus=(order: string)=>{
        if(["1","2","3","5","11","12" ].includes(order) ){
         return({icon:'icon_local', text:'En mi local', iconTop:'icon_check'})
        }else if(["4", "6","13" ].includes(order)){
         return({icon:'icon_flag', text:'Entregado al cliente', iconTop:'icon_check'})
        }else{
         return({icon:'icon_local', text:'NA', iconTop:'icon_check'})
        }
       }
    
    
      function sendMail(mail: any, name: string) {
        var link = `mailto:${mail}`
                 + "&subject=" + encodeURIComponent(`${name} te ha mandado un mensaje`)
        ;
        
        window.location.href = link;
    }
    
    const checkSelfPickupCriteria=(order: { client_id: string; origin_email: string; origin_name: string; sender_phone: string | any[]; deliver_to: any })=>{
      
      //Criterio 1: same_client: El nombre del Punto99 que recolecto la guía se encuentra en el clientid relacionado a la guía
      const clientNameLikeBusiness= order.client_id?.toLowerCase().includes(user?.current_dispatch_place?.business_name.toLowerCase())
    
      //Criterio 2: same_email_point_name: El nombre del Punto99 que recolecto la guía se encuentra en el mail originador relacionado a la guía
      const clientEmailLikeName= order.origin_email?.toLowerCase().includes(user?.current_dispatch_place?.business_name.toLowerCase())
      
      //Criterio 3: same_emails: El mail del Punto99 que recolecto la guía es idéntico al mail originador relacionado a la guía
      const clientEmailIsBusinessEmail= order?.origin_email?.toLowerCase()===user.current_dispatch_place.email.toLowerCase()
    
      //Criterio 4: similar_emails: El mail del Punto99 que recolecto la guía se incluye en email del originador o viceversa
      const clientEmailIsLikeBusinessEmail= order?.origin_email?.includes(user.current_dispatch_place.email) || user.current_dispatch_place.email.includes(order.origin_email)
      
      //Criterio 5.1: same_phone: El número celular del responsable del Punto99 que recolecto la guía se incluye en el número teléfono del originador de la guía 
      const clientPhoneIsResponsiblePhone= order?.sender_phone===user.phone_number || order?.sender_phone===user.current_dispatch_place.responsible_phone_number
      
      //Criterio 5.2: same_phone: el teléfono fijo del Punto99 que recolecto la guía es idéntico al número de teléfono del originador de la guía
      const clientPhoneLikeName= order.sender_phone?.includes(user.phone_number) || order.sender_phone?.includes(user.current_dispatch_place.responsible_phone_number)
      
      //Criterio 6: same_responsible_name: El nombre del responsable del Punto99 que recolecto la guía es igual al nombre del cliente originador  relacionado a la guía
      const clientIsResponsibleName= order?.origin_name===user.current_dispatch_place.responsible_name
    
      return clientNameLikeBusiness || clientIsResponsibleName || clientEmailLikeName || clientEmailIsBusinessEmail || clientPhoneIsResponsiblePhone || clientPhoneLikeName || clientEmailIsLikeBusinessEmail 
    }




    const updateSelfPickupOrder = async(self_pickup_type:string, order: any, cost:number) => { 
      return await updateOrder(
        order.id,
         {
             ...order,
             self_pickup_type,
             cost
         },
       ).then((success) => {
         if (!success.data.success) {
          toast.error('Error al actualizar la orden ');
          return false
         } 
         let list=orderList
         const newList= list.filter((o:any)=> o.id !== success.data.body.id)
         setOrderList(newList);
         return true
       });
   };

   const getPaymentOrdersByInternalId = async (self_pickup_type:string, order: any, cost:number) => { 
    return await updateOrderPayment(
      order.id,
      self_pickup_type,
      cost
     ).then((success) => {
       if (!success.error) {
         return success.data
       } else {
          toast.error('Error al actualizar el pago de la orden ');
         return
       }
     });
 };
  


    const updateToExternal = async ()=>{
      const isSelfPickup= checkSelfPickupCriteria(activePackage)
      const invoice= user.current_dispatch_place.apply_invoice===1
      const price= invoice?5.8: 4.5
      if(isSelfPickup){
          const success= await updateSelfPickupOrder('fraudulent',activePackage, price)
          if(success){
            await getPaymentOrdersByInternalId('fraudulent',activePackage, price)
            setModal(false)
          setIsOpen(false)
          }
          toast.error('Error al actualizar el pago de la orden ');
      } else if (!isSelfPickup){
          const success= await updateSelfPickupOrder('external',activePackage, price)
          if(success){
            await getPaymentOrdersByInternalId('external',activePackage, price)
            setModal(false)
            setIsOpen(false)

          }
          toast.error('Error al actualizar el pago de la orden ');
        }
    }


    const orderAreForReturn = (order: any) => {
        let isForReturn = false;
        const permissiveOnDeliverStatus = [10, 11];
        order.logs.forEach((log: { new_status_id: number }) => {
          if (permissiveOnDeliverStatus.indexOf(log.new_status_id) !== -1) {
            isForReturn = true;
          }
        });
        return isForReturn;
      };
    
  return (
    <>
    <div
    style={{
      transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
      height: "min-content",
      zIndex: 6,
      overflowY: 'auto',
      maxHeight: '90vh'
    }}
    className={`absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green mx-auto right-0 md:w-10/12 lg:w-5/12 ${
      isOpen ? "slide-open" : "slide-close"
    }`}
  >
    <button
      onClick={() => setIsOpen(false)}
      className="flex w-full pt-3 pr-5 justify-end"
    >
      <CloseIcon />
    </button>
    <div className="w-full flex flex-col items-center">
    <div className="mb-3">
      <img  className='w-10 h-10' alt={`../icons/${getCurrentStatus(activePackage.order_status_id.toString()).text}.svg`} src={`../icons/${getCurrentStatus(activePackage.order_status_id.toString()).iconTop}.svg`}/>
       </div>   
    <div className="flex flex-row">
          <span className="flex flex-row items-center w-1/4 text-white ">
            Nº &nbsp;
          </span>
          <span className="text-white text-lg font-thin font-semibold">
            {activePackage.external_reference}
          </span>
        </div>
        <div className="w-11/12 px-5 py-2">
            <div
              className={`px-4 py-1 rounded-md bg-white semi-bold color-green99 text-center w-max	m-auto`}>
                {getCurrentStatus(activePackage.order_status_id.toString()).text}
            </div>
      <div className="returnPackageClient flex w-full justify-between items-center px-4 py-2">
        <div>
          <p className="bold color-gray100">Cliente</p>
          <p className="bold color-batman">{activePackage.deliver_to}</p>
        </div>
        <div className="flex">

          <div className="mx-2">
            <RoundIconBtn
              size={1.2}
              image="../icons/icon_phone.svg"
              padding={ 0.3 }
              borderColor="var(--gray25)"
              onClick={ () => window.open(`tel:+52${ activePackage.client_phone }`) }
            />
          </div>
          <div className="mx-2">
            <RoundIconBtn
              size={ 1.2 }
              image="../icons/icon_email.svg"
              padding={ 0.3 }
              borderColor="var(--gray25)"
              onClick={()=>sendMail(activePackage.destination_email,'')}
            />
          </div>
        </div>
      </div>
     {activePackage.assignment &&
        ([11, 12, 13].indexOf(activePackage.order_status_id) === -1 ||
          (activePackage.order_status_id === 3 &&
            !orderAreForReturn(activePackage))) && (
          <ReturnedVeloz
            driverPersonalData={activePackage.assignment.driver_info}
            deliveryDate={
              activePackage.assignment.eta
                ? activePackage.assignment.eta
                : "Sin fecha estimada de llegada"
            }
          />
        )}
   
        <div className="flex flex-row w-full returnPackageVeloz relative p-4">
          <p className="color-gray100 semi-bold pb-1 pt-2">
              Información de ruta
          </p>
          <div className="w-full">
        {activePackage.logs?.length > 0 ? (
          activePackage.logs?.map((evidence: { new_status_id: string; changed_at: MomentInput; evidence_url: string; },i:number) => {
            return JSON.parse(evidence.evidence_url).length>0&& (
              <>
              <div className="w-full flex flex-row justify-between items-center my-1">
                <div className="flex flex-col w-7/12">
                <div className="flex">
                <img
                    src={`../icons/${getCurrentStatus(evidence.new_status_id.toString()).icon}.svg`}
                    alt="icon"
                    className="w-4 h-4 mr-1"
                  />
                  <p className="color-batman bold">{getCurrentStatus(evidence.new_status_id.toString()).text}</p>
                </div>
                <div className="flex ml-5">
                  <p className="semi-bold  color-gray100  ">{ evidence &&  moment(evidence.changed_at).format('DD/MM/yyyy ')} </p> &nbsp;
                  <p className=" semi-bold color-green99  ">{ evidence &&  moment(evidence.changed_at).format(' • HH:mm')   } </p>
                </div>
                {/* {console.log(evidence)} */}
              </div>
              {JSON.parse(evidence.evidence_url).length>0&&<button className="bold color-green99 text-sm w-4/12" onClick={() => {setEvidenceUrl(JSON.parse(evidence.evidence_url)[0]); setIsOpenEvidenceModal(true)}} >Evidencia</button>}
              </div>

              {activePackage.logs.length!==1 && activePackage.logs.length!==i+1 && <Divider percentage={90} spacing={0.09}/>}
              </>
            )
          })
        ): <span className="text-white ml-3">Aún no hay evidencias.</span>}
        </div>
          
        </div>
        <div className="w-8/12 flex flex-col text-center justify-center mt-4 mb-6 mx-auto">
          {( [2,3,5,11,12].includes(activePackage.order_status_id) && user.current_dispatch_place.is_self_pickup===1) &&
          <button onClick={()=>setModal(true)} className='color-white border border-white w-full rounded-3xl py-2 my-3 '><p className='bold'>Cambiar a recolección externa</p></button>
          }
          <a className="bg-white w-full rounded-3xl py-2 my-3" 
          target="_blank"
          rel="noreferrer"
          href={`https://tracking.99minutos.com/search/${activePackage.external_reference}`}> <p className="color-green99 bold"> Rastrea tu paquete</p></a>
        </div>
      </div>
    </div>

  </div>
  <div className='fixed z-50'>
      <ModalWrapper setModal={setModal} modal={modal}>
        <b className='mt-10 px-6 text-center color-batman'> ¿Quieres actualizar esta orden como recolección externa?</b>
        <div className='flex mt-5 justify-evenly w-full'>
          <button onClick={()=>setModal(false)} className='agave-button-thin ag-button-secondary'><p className='bold'>Cancelar</p></button>
          <button onClick={updateToExternal} className='agave-button-thin ag-button-primary'><p className='bold'>Continuar</p></button>
        </div>
      </ModalWrapper>
  </div>

  </>
  )
}

export default VoluntaryPackageDetails