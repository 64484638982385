import { useReducer } from "react";
import MultipleOrdersReducer from "./reducer";
import MultpleOrdersContext from "./context";

const MultipleOrdersState = ({ children }) => {
  const INITIAL_STATE = {
    multipleOrders: [],
    selectedOrder: null,
    evidence: '',
    evidenceDel:''
  };

  const [state, dispatch] = useReducer(MultipleOrdersReducer, INITIAL_STATE);

  const setMultipleOrders = (orders) => {
    dispatch({
      type: "SET_MULTIPLE_ORDERS",
      payload: orders,
    });
  };

  const setEvidenceURLRecollect = (evidence) => {
    dispatch({
      type: "SET_EVIDENCE_RECOLLECT",
      payload: evidence,
    });
  };

  const setEvidenceURLDelivery = (evidenceDel) => {
    dispatch({
      type: "SET_EVIDENCE_DELIVERY",
      payload: evidenceDel,
    });
  }



  const selectOrder = (index) => {
    const copyOrders = JSON.parse(JSON.stringify(state.multipleOrders))
    const orderToSelectCopy = { ...copyOrders[index] };
    orderToSelectCopy.isSelected = orderToSelectCopy.isSelected ? false : true;
    copyOrders.forEach((order) => {
      order.isSelected = false
    })
    dispatch({
      type: "SELECT_ORDER",
      payload:  state.selectedOrder ? null : orderToSelectCopy,
    });
    copyOrders[index] =  orderToSelectCopy
    dispatch({
      type: "SET_MULTIPLE_ORDERS",
      payload: copyOrders,
    })
  };

  const cleanState = () => {
    dispatch({
      type: "SET_MULTIPLE_ORDERS",
      payload: [],
    })
    dispatch({
      type: "SELECT_ORDER",
      payload: null,
    })
    dispatch({
      type: "SET_EVIDENCE_RECOLLECT",
      payload: null,
    })
    dispatch({
      type: "SET_EVIDENCE_DELIVERY",
      payload: null,
    })
  }

  return (
    <MultpleOrdersContext.Provider
      value={{
        state: { ...state },
        cleanState,
        setMultipleOrders,
        selectOrder,
        setEvidenceURLRecollect,
        setEvidenceURLDelivery
      }}
    >
      {children}
    </MultpleOrdersContext.Provider>
  );
};

export default MultipleOrdersState;
