
function DeliveredIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.666"
      height="25.07"
      viewBox="0 0 32.666 36.07"
    >
      <g
        id="Group_4667"
        data-name="Group 4667"
        transform="translate(-190.676 -292.138)"
      >
        <path
          id="Path_3670"
          data-name="Path 3670"
          d="M18.981,3l14.981,8.427V28.281L18.981,36.708,4,28.281V11.427L18.981,3"
          transform="translate(188.009 290.5)"
          fill="none"
          stroke="#959595"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></path>
        <line
          id="Line_1210"
          data-name="Line 1210"
          x1="7.5"
          y1="3.964"
          transform="translate(192.028 301.927)"
          fill="none"
          stroke="#959595"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></line>
        <line
          id="Line_1211"
          data-name="Line 1211"
          y1="3.964"
          x2="7.5"
          transform="translate(214.491 301.927)"
          fill="none"
          stroke="#959595"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="2"
        ></line>
        <g
          id="Group_4635"
          data-name="Group 4635"
          transform="translate(201.074 305.038)"
        >
          <line
            id="Line_1166"
            data-name="Line 1166"
            y2="15.973"
            transform="translate(6.019 0.66)"
            fill="none"
            stroke="#959595"
            stroke-linecap="round"
            strokeLinejoin="round"
            stroke-width="2"
          ></line>
          <path
            id="Path_3594"
            data-name="Path 3594"
            d="M94.13,2745.436l5.935-5.116,5.935,5.116"
            transform="translate(-94.13 -2740.32)"
            fill="none"
            stroke="#959595"
            stroke-linecap="round"
            strokeLinejoin="round"
            stroke-width="2"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default DeliveredIcon;
