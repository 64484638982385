import { useHistory } from "react-router-dom";

interface Props {}

export const NotFound = (props: Props) => {
  const { goBack } = useHistory();

  return (
    <div className="containerNotFound">
      <h3 className="color-white"> Not found</h3>
      <button onClick={goBack} className="goBack">
        {" "}
        Go Back{" "}
      </button>
    </div>
  );
};
