import React from "react";
import styles from "./EvidenceModal.module.css";

interface Props {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children?:any;
}

const EvidenceModal: React.FC<Props> = ({ children, modal, setModal }) => {
  return (
    <>
      {modal && (
        <>
          <div
            className={styles.backgroundBlur}
            onClick={() => setModal(false)}
          />
          <div className={styles.sliderContainer}>
            <button
              className={styles.closeButton}
              onClick={() => setModal(false)}
            >
              x
            </button>
            <div className={styles.innerContainer}>{children}</div>
          </div>
        </>
      )}
    </>
  );
};

export default EvidenceModal;
