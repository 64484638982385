import React from 'react'

function BlackDot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.197" height="15.197" viewBox="0 0 15.197 15.197">
      <path id="Path_3605" data-name="Path 3605" d="M1250.008,277.119a7.6,7.6,0,1,0,7.6,7.6A7.6,7.6,0,0,0,1250.008,277.119Zm0,10.871a3.273,3.273,0,1,1,3.273-3.273A3.273,3.273,0,0,1,1250.008,287.99Z" transform="translate(-1242.409 -277.119)" fill="#333634"></path>
    </svg>
  )
}

export default BlackDot
