import IconBtn from "../IconBtn";
import RoundImage from "../RoundImage";
import { useLocation } from "react-router-dom";
import { SideMenu } from "./SideMenu";

interface Props {
  token: string | any ;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  picture: string;
  titleColor?: string;
  BackButton?: any
}

const ProfileSection = ({titleColor= "color-green99", open, setOpen, title, picture, token, BackButton }: Props) => {
  const location = useLocation();

  return (
    <>
        {BackButton ? (
            <div>
                {BackButton}
            </div>
        ) : null}
      <div className="text-center ">
        {location.pathname === "/home" ? (
          <>
            <p className="Vxs:text-sm color-gray50 button-text">¡Bienvenido nuevamente!</p>
            <span className="m-0 Vxs:text-md font-bold Vmd:text-2xl">{title}</span>
          </>
        ) : location.pathname === '/exchange-point/home' ? (
          <>
            <p className="color-gray50 button-text">{title}</p>
            <h4 className="m-0">Delivery cell</h4>
          </>
        ) : (
          <>
            <h3 className={`m-0 ${titleColor} `}>{title}</h3>
            {title&& <IconBtn icon="../icons/point_black.svg" />}
          </>
        )}
      </div>

      <div className="flex items-center">
        <RoundImage image={picture} size={3} onClick={() => setOpen(true)} />

        <IconBtn icon="../icons/arrow_gray.svg" onClick={() => setOpen(true)} />
      </div>
      {open && (
        <SideMenu
          open={open}
          setOpen={setOpen}
          picture={picture}
          token={token}
        />
      )}
    </>
  );
};

export default ProfileSection;
