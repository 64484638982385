import { Route, Redirect } from "react-router-dom";

const UserNotAllowed = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("userToken");

  return (
    <Route
      {...rest}
      render={(props) =>
        //if the user is already logged in, then redirects to homepage
        user ? <Redirect to="/home" /> : <Component {...props} />
      }
    />
  );
};

export default UserNotAllowed;
