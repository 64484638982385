// @ts-nocheck
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ArrowRightIcon,
  BlackDot,
  CheckIcon,
  CodeIcon,
  UserIcon,
} from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";
import Divider from "../components/Divider";
import GreenModalWrapper from "../components/GreenModalWrapper";
import MultipleOrdersContext from "../context/MultipleOrdersContext/context";
import { changeOrderStatus, updateOrder } from "../utils/requests";
import { ContextWrapper } from "../context/AppWrapper";
import { ToastContainer,toast } from "react-toastify";
import { updateDispatch } from "../utils/axiosRequest";

function ConfirmRecollectPage() {
  const { goBack, push, location } = useHistory();
  const {pathname} = useLocation();
  const [warning, setWarning]=useState(false)
  const [confirm, setConfirm] = useState(false);
  const [loading, setloading] = useState(false);
  const { userData } = useContext(ContextWrapper);
  const [user, setUser] = userData;

  const { cleanState , state, setEvidenceURLRecollect} = useContext(MultipleOrdersContext);

  const resetAndGoBack=()=>{
    setEvidenceURLRecollect('')
    sessionStorage.removeItem('evidence')
  }
  

  const updateIfNotSelfPickup = async (self) => { 
    const userToken = localStorage.getItem("userToken");
    await updateDispatch(
        {
            ...user.current_dispatch_place,
            is_self_pickup:true,
            schedule: JSON.parse(user.current_dispatch_place.schedule)
        },
        user.current_dispatch_place_id,
        userToken
      ).then((success) => {
        if (success) {
          setUser({...user,current_dispatch_place:success.data })
        } else {
          toast.error('Error al activar modalidad de recolección propia, favor de intentarlo nuevamente. ');
        }
      });
  };



  const updateSelfPickupOrder = async (self_pickup_type, order, cost) => { 
   await updateOrder(
      order.id,
       {
           ...order,
           self_pickup_type,
           cost
       },
     ).then((success) => {
       if (success) {
         console.log(success);
       } else {
         toast.error('Error al activar modalidad de recolección propia, favor de intentar nuevamente. ');
       }
     });
 };



  const confirmRecollection = async (self) => {
    if (location.state?.orderInfo?.dispatch_service === 'RETURN_ORIGINATOR' && location.state?.orderInfo?.order_status_id === 12) {
      cleanState();
      setloading(false)
      setConfirm(true);
      return
    }
    const { error, message } = await changeOrderStatus(
      state.evidence || sessionStorage.getItem('evidence'),
      location.state?.orderNumber,
      location.state?.orderInfo.dispatch_service === "DELIVERY_TO_CORE"
        ? "recollect_from_seller"
        : "recollect_from_driver",
        null,
        null,
    );
    
    if(error){
      toast.error( message|| 'Ocurrió un error al recolectar la orden, codigo de error CRP32COS');
      setloading(false);
      return error
    }
    cleanState();
    setWarning(false)
    
    if(!error) self && await updateIfNotSelfPickup(self)

    const selfType=pathname==='/confirm-recollect/external'? 'external':'voluntary'
      if(!error && selfType==='voluntary'){
         await updateSelfPickupOrder('voluntary', location.state?.orderInfo, 0) 
      }
    setloading(false)
    setConfirm(true);
    resetAndGoBack()
    return error
  };

  
const checkSelfPickupCriteria=(order)=>{
  //Criterio 1: same_client: El nombre del Punto99 que recolecto la guía se encuentra en el clientid relacionado a la guía
  const clientNameLikeBusiness= order?.client_id?.toLowerCase().includes(user?.current_dispatch_place?.business_name.toLowerCase())

  //Criterio 2: same_email_point_name: El nombre del Punto99 que recolecto la guía se encuentra en el mail originador relacionado a la guía
  const clientEmailLikeName= order?.origin_email?.toLowerCase().includes(user?.current_dispatch_place?.business_name.toLowerCase())
  
  //Criterio 3: same_emails: El mail del Punto99 que recolecto la guía es idéntico al mail originador relacionado a la guía
  const clientEmailIsBusinessEmail= order?.origin_email?.toLowerCase()===user?.current_dispatch_place?.email?.toLowerCase()

  //Criterio 4: similar_emails: El mail del Punto99 que recolecto la guía se incluye en email del originador o viceversa
  const clientEmailIsLikeBusinessEmail= order?.origin_email?.includes(user?.current_dispatch_place?.email) || user?.current_dispatch_place?.email?.includes(order?.origin_email)
  
  //Criterio 5.1: same_phone: El número celular del responsable del Punto99 que recolecto la guía se incluye en el número teléfono del originador de la guía 
  const clientPhoneIsResponsiblePhone= order?.sender_phone===user?.phone_number || order?.sender_phone===user?.current_dispatch_place?.responsible_phone_number
  
  //Criterio 5.2: same_phone: el teléfono fijo del Punto99 que recolecto la guía es idéntico al número de teléfono del originador de la guía
  const clientPhoneLikeName= order?.sender_phone?.includes(user?.phone_number) || order?.sender_phone?.includes(user?.current_dispatch_place?.responsible_phone_number)
  
  //Criterio 6: same_responsible_name: El nombre del responsable del Punto99 que recolecto la guía es igual al nombre del cliente originador  relacionado a la guía
  const clientIsResponsibleName= order?.origin_name===user?.current_dispatch_place?.responsible_name

  
  //logs for testing
  
    clientNameLikeBusiness && console.log('clientId igual nombre de dp:' ,  order.client_id, user?.current_dispatch_place?.business_name)
    clientEmailLikeName && console.log('email de cliente incluye nombre de dp:' , order.origin_email , user?.current_dispatch_place?.business_name)
    clientEmailIsBusinessEmail && console.log('email de cliente igual a email de dp:' , order.origin_email , user?.current_dispatch_place?.email)
    clientEmailIsLikeBusinessEmail && console.log('email de cliente incluye a  email de dp o, email de dp incluye a email de cliente:' , order.origin_email , user?.current_dispatch_place?.email)
    clientPhoneIsResponsiblePhone&& console.log('tel de cliente igual a celular o telefono de dp:' , order.sender_phone , user?.phone_number , user?.current_dispatch_place?.phone_number)
    clientPhoneLikeName && console.log('tel de cliente incluye a celular o telefono de dp:' , order.sender_phone , user?.phone_number ,  user?.current_dispatch_place?.responsible_phone_number)
    clientIsResponsibleName && console.log('nombre de cliente igual a nombre de resonsable:' , order.origin_name , user?.current_dispatch_place?.responsible_name)

  return clientNameLikeBusiness || clientEmailLikeName || clientEmailIsBusinessEmail || clientPhoneIsResponsiblePhone || clientPhoneLikeName || clientEmailIsLikeBusinessEmail || clientIsResponsibleName
}



  const isFraudulent =async ()=>{
    setloading(true)
    const isSelfPickup= checkSelfPickupCriteria(location.state?.orderInfo)

    if (isSelfPickup && pathname==='/confirm-recollect/external'){
      setWarning(true)
      setloading(false)

    }else{
      const selfTypeOwn=pathname==='/confirm-recollect/own' && (user.current_dispatch_place.is_self_pickup === false || user.current_dispatch_place.is_self_pickup === 0)
      await confirmRecollection(selfTypeOwn)
    }
    return 
  }


  return (
    <>
      <ToastContainer />
    <div className="h-screen w-screen overflow-y-hidden fixed">
      <BackgroundShape direction="revert" type={"large"} position="bottom" />
      <div className="flex flex-col justify-between h-full w-full">
        <div>
          <div
            onClick={()=>{
              resetAndGoBack()
              goBack()
            }
            }
            className="px-4 py-8 xs:px-2 xs:py-4"
            id="back-button"
          >
            <ArrowRightIcon rotate={"90"} />
          </div>
          <div className="flex w-full justify-center">
            <span className="text-md text-center font-semibold color-batman">
              {!confirm? 'Estás recolectando': 'Recolección'}
              <br />
              <span className="color-green99">{!confirm? `un paquete ${pathname==='/confirm-recollect/external'?'externo':'propio'}`: 'realizada'}</span>
            </span>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <BlackDot />
          </div>
          {!confirm && (
            <div className="px-8 w-full mt-20">
              <div
                style={{ boxShadow: "0 10px 20px 0 rgba(128, 128, 128, 0.31)" }}
                className="w-full bg-white rounded-md"
              >
                <div className="flex flex-row items-center px-5 py-4 border-b border-gray-1800">
                  <div className="flex items-center justify-center w-1/12">
                    <UserIcon />
                  </div>
                  <span className="ml-5 semi-bold color-batman">
                    {location.state?.orderInfo?.client_id}
                  </span>
                </div>
                <div className="flex flex-row items-center px-5 py-4">
                  <div className="flex items-center justify-center w-1/12">
                    <CodeIcon />
                  </div>
                  <span className="ml-5 semi-bold color-batman">{location.state?.orderNumber}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col w-full items-center">
          {confirm && (
            <div className="flex flex-col items-center w-full px-10">
              <div>
                <CheckIcon />
              </div>
              <div className="font-bold text-white">Guarda el paquete</div>
              <div className="font-thin text-white text-center">
                 
                {pathname==='/confirm-recollect/external'?'Pronto pasarán por':'Pronto un veloz pasará por '}

                <br />
                 tu tienda a retirarlo
                
              </div>
            </div>
          )}
          {confirm ? (
            <>
              <div className="flex flex-col items-center w-full mt-10">
                <div
                  onClick={() => push("/home")}
                  className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                >
                  <span className="text-md text-green-100 font-semibold">
                    Finalizar
                  </span>
                </div>
                <div
                  onClick={()=>{
                    resetAndGoBack()
                    goBack()
                  }}
                  className="flex items-center justify-center my-5 px-4 py-2 w-2/3  rounded-full"
                >
                  <span className="text-md text-white bold">
                    Quiero recolectar más paquetes
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center w-full mb-16">
                <button
                disabled={loading|| warning|| !user || (!state.evidence &&  !sessionStorage.getItem('evidence'))}
                  onClick={() => (loading && !user ? () => {} : isFraudulent())}
                  className="agave-button  ag-button-white flex items-center justify-center px-4 py-2 w-2/3 "
                >
                  {loading ? (
                    <div className="lds-dual-ring-small"></div>
                  ) : (
                    <span className="text-md text-green-100 font-semibold">
                      Recolectar paquete
                    </span>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
    <GreenModalWrapper setModal={setWarning} modal={warning} canClose={true} scroll={false} colorClose="white" backgroundColor="linear-gradient(0deg, rgba(124,208,45,1) 0%, rgba(253,187,45,0.9570421918767507) 100%)">
    <div className="flex flex-col p-4 pt-10  text-center">
    <p>Notamos que este paquete en realidad corresponde a un paquete propio</p>
    <br/>
    <p className="bold">¿Estás seguro que quieres continuar con la recolección?</p>
    <div >
      <div className="m-auto">
    <Divider percentage={90}  spacing={1} />
    <div className="flex">
      <span className="w-20 color-white">No</span>
      <span  className=" color-white">{location.state?.orderNumber}</span>
    </div>
    <div className="flex">
      <span className="w-20 color-white">cliente</span>
      <span  className=" color-white">{location.state?.orderInfo?.client_id}</span>
    </div>
    <Divider percentage={90} spacing={1}/>
    </div>
    </div>
   
  <button onClick={async ()=>{
    await confirmRecollection(false)
     updateSelfPickupOrder('fraudulent', location.state?.orderInfo, location.state?.orderInfo.cost) 

    }
    } className="  w-10/12 color-green99 rounded-3xl	m-auto bg-white py-1 px-3 mb-5"  >
    
    <p className="semi-bold">Sí, continuar</p> 
  </button>
  <button onClick={async ()=>{
    await confirmRecollection(user.current_dispatch_place.is_self_pickup === false || user.current_dispatch_place.is_self_pickup === 0)
     updateSelfPickupOrder('voluntary', location.state?.orderInfo,0) 
    }}  className=" w-10/12 color-white rounded-3xl	border-white border-solid  border-2 m-auto py-1 px-3 mb-5">
   <p  className="semi-bold">Recolectar paquetes propios</p> 
  </button>
  <button className="color-white pb-5 " onClick={()=>{
    setWarning(false)
    resetAndGoBack()
    push("/home")
    }}>
    <p className="semi-bold">Cancelar recolección</p>
  </button>
  </div>
  </GreenModalWrapper>
  </>
  );
}

export default ConfirmRecollectPage;
