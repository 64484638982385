import "./BackgroundShape.css";

type Props = {
  position: "top" | "bottom";
  type: "small" | "quarter" | "medium" | "large"| "extraLarge";
  direction?: "normal" | "revert";
};

const types: any = {
  small: {
    height: 170,
    curveInit: 170,
    curveFinish: 215,
  },
  quarter: {
    height: 220,
    curveInit: 220,
    curveFinish: 290,
  },
  medium: {
    height: 300,
    curveInit: 300,
    curveFinish: 350,
  },
  large: {
    height: 325,
    curveInit: 325,
    curveFinish: 385,
  },
  extraLarge: {
    height: 405,
    curveInit: 425,
    curveFinish: 495,
  },
};

const BackgroundShape: React.FC<Props> = ({
  position = "bottom",
  type = "small",
  direction = "normal"
}) => {
  if (position === "bottom") {
    return (
      <div className="custom-shape-divider-bottom">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 500"
          preserveAspectRatio="none"
        >
          <defs>
            {direction === "normal" ? (
              <linearGradient id="gradient" x1="100%" y1="0%" x2="50%" y2="90%">
                <stop offset="0%" stopColor="#00d2b6" />
                <stop offset="100%" stopColor="#85c440" />
              </linearGradient>
            ) : (
              <linearGradient id="gradient" x1="100%" y1="0%" x2="50%" y2="90%">
                <stop offset="0%" stopColor="#85c440" />
                <stop offset="100%" stopColor="#00d2b6" />
              </linearGradient>
            )}
          </defs>
          <path
            d={`M 0 0 V ${types[type].height} C 0 ${types[type].curveInit} 268.63 ${types[type].curveFinish} 601 ${types[type].curveFinish} C 931.37 ${types[type].curveFinish} 1200 ${types[type].curveInit} 1200 ${types[type].height} V 0 Z`}
            className="shape-fill"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <div className="custom-shape-divider-top">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 500"
        preserveAspectRatio="none"
      >
        <defs>
          {direction === "normal" ? (
            <linearGradient id="gradient" x1="100%" y1="0%" x2="50%" y2="90%">
              <stop offset="0%" stopColor="#00d2b6" />
              <stop offset="100%" stopColor="#85c440" />
            </linearGradient>
          ) : (
            <linearGradient id="gradient" x1="100%" y1="0%" x2="50%" y2="90%">
              <stop offset="0%" stopColor="#85c440" />
              <stop offset="100%" stopColor="#00d2b6" />
            </linearGradient>
          )}
        </defs>
        <path
          d={`M 0 0 V ${types[type].height} C 0 ${types[type].curveInit} 268.63 ${types[type].curveFinish} 601 ${types[type].curveFinish} C 931.37 ${types[type].curveFinish} 1200 ${types[type].curveInit} 1200 ${types[type].height} V 0 Z`}
          className="shape-fill"
        ></path>
      </svg>
    </div>
  );
};

export default BackgroundShape;
