import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { updateDispatch } from "../../utils/axiosRequest";
import RoundImage from "../RoundImage";
import {  toast } from "react-toastify";
import { ContextWrapper } from "../../context/AppWrapper";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  user:any;
}

const Screen3 = ({ setModal, user }: Props) => {
  let history = useHistory();
  const { userData } = useContext(ContextWrapper);
const [ _, setUser ] = userData;

  const userToken = localStorage.getItem("userToken");


  const setSelfPickup = (type:boolean) => {    
    updateDispatch(
      {
          ...user.current_dispatch_place,
          is_self_pickup:type,
          schedule: JSON.parse(user.current_dispatch_place.schedule)
      },
      user.current_dispatch_place_id,
      userToken
    ).then((success) => {
      if (success) {
        localStorage.setItem("selfPickupIntro", "true");
        setModal(false)
        setUser({...user,current_dispatch_place:success.data })
        // history.push( 
        //    `/scanner-recollect/${type?'own':'external'}`
        // )
      } else {
        toast.error('Error al activar modalidad de recolección propia, favor de intentar nuevamente. ');
        setModal(false)
        history.push(`/scanner-recollect/${type?'own':'external'}`);
      }
    });
  };


  return (
    <>
    <div className="flex flex-col my-20">
      <p className="bold text-white text-center w-10/12 m-auto">¿Tu punto99 hace recolecciones de paquetes propios?</p>
      <div className="w-3 h-3 border-4 m-auto	rounded-full border-black mt-2"/>
      <div className="flex m-auto mt-3">
        <div className="flex flex-col items-center">
        <RoundImage
                  image="../icons/icon_SI.svg"
                  size={ 3 }
                  alt="recolección"
                  backgroundColor="white"
                  shadow={ true }
                  padding={0}
                  onClick={()=>setSelfPickup(true)}
                />
                <p className="bold color-white ">SÍ</p>
        </div>
        <div className="flex flex-col items-center">
        <RoundImage
                  image="../icons/icon_NO.svg"
                  size={ 3 }
                  alt="recolección"
                  backgroundColor="white"
                  shadow={ true }
                  padding={0}
                  onClick={()=>setSelfPickup(false)}
                />
                <p className="bold color-white ">NO</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Screen3;
