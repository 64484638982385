import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        label: "¿Cuándo y cómo se realiza mi pago?",
        children: (
            <React.Fragment>

                <b>📅 ¿Cuándo?</b>
                <p>
                El pago se hace de manera mensual. El periodo de gestión inicia el 21 de cada mes y termina el 20 del mes posterior; el pago de este período se realizará el día 10 del siguiente mes.
                </p>
                <br />
                <p>
                    <b>Ejemplo:</b> el pago por los paquetes que recolectaste y entregaste en tu local del día 21 de marzo al 20 de abril será depositado en tu cuenta bancaria el 10 de mayo.
                </p>
                <br />

                <b>💳 ¿Cómo?</b>
                <p>
                Por transferencia interbancaria a la cuenta CLABE que proporcionaste al momento de la afiliación.
                </p>
                <br />
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo se calcula mi pago?",
        children: (
            <React.Fragment>
                <p>
                Tu pago se compone por la cantidad de paquetes que recolectaste y entregaste durante un período. 📦          </p>
                <p>
                    <br />

                    ❗Te recordamos que el período inicia el 21 de cada mes y termina el 20 del mes posterior. 
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo se cuál es el estado de mi pago?",
        children: (
            <React.Fragment>
                <p>
                Podrás encontrarlo en la sección <b>"Estado de pago"</b> en la página  <b>"Mis ganancias "</b>. Este mostrará uno de los siguientes estados:

                    <p>

                        <br />

                        <li className="ml-2 underline">{"  "}📌 Si facturas</li>

                        <b>Actual:</b>
                        el período contable se encuentra abierto. Te recordamos que el período contable inicia el 21 de cada mes y termina el 20 del mes posterior. Es decir, todos los paquetes que recolectes dentro de este período se verán reflejados aquí.                         </p>
                    <p>
                        <b>Por facturar: </b>
                        el período contable terminó y debes enviar tu factura al equipo de punto99 para que podamos validar tu información. 📣 Para más información consulta: ¿Cuáles son sus datos fiscales para emitir mi factura?  y ¿Cuándo y a cuál correo debo enviar mi factura?                        </p>
                    <p>
                        <b>Factura en validación: </b> recibimos tu factura y estamos validando que los datos sean correctos.
                    </p>
                    <p>
                        <b>En proceso de pago:</b>
                        tu factura fue aprobada y estamos haciendo los movimientos necesarios para hacer el depósito de tus ganancias.
                    </p>
                    <p>
                        <b> Pago pendiente: </b>
                        el depósito de este pago está pendiente porque no hemos recibido tu factura o hubo alguna inconsistencia con la información que presentaste.
                    </p>
                    <p>
                        <b>Pagada: </b> Tu factura ya fue pagada.
                    </p>
                    <p>
                        <b>Pagado-Ajuste:  </b>
                        Tu pago se ha realizado de acuerdo a la previa aclaración por:
                        <li className="ml-2">{"  "}Descuentos por extravío </li>
                        <li className="ml-2">{"  "}Descuento por penalizaciones en entrega tardía de guías</li>
                        <li className="ml-2">{"  "}Descuento por guías de Autoinducción</li>
                    </p>
                    <p>
                        <b>Pago Pendiente - BAJA:</b>Tu pago se encuentra pendiente debido a que decidiste darte de baja de la red, necesitamos nos contactes al siguiente correo: pagos_punto99@99minutos.com, para realizar la aclaración correspondiente y poder generar tu pago en el próximo periodo de corte.
                    </p>
                    <p>
                        <b>Pago Vencido:</b>Tu pago ha sido suspendido a falta de recepción de factura y constancia fiscal para su seguimiento. Es importante que compartas tu factura a tiempo  en cada periodo contable, o si se solicita documentación para el seguimiento del mismo, la compartas a tiempo. La cláusula de los términos y condiciones, estipula que los servicios prestados con una fecha anterior a los 3 (tres) meses de su gestión o incluídos a la fecha de emisión de la factura, 99 Minutos no estará obligada a pagar dicha factura ni dichos Servicios.
                    </p>
                    <br />
                    <li className="ml-2 underline">{"  "}📌 Si no facturas</li>
                    <p>
                        <b>Actual:</b>
                        el período contable se encuentra abierto. Te recordamos que el período contable inicia el 21 de cada mes y termina el 20 del mes posterior. Es decir, todos los paquetes que recolectes dentro de este período se verán reflejados aquí.
                    </p>
                    <p>
                        <b>Pago pendiente: </b>el periodo contable terminó.
                    </p>
                    <p>
                        <b>En proceso de pago: </b>
                        estamos haciendo los movimientos necesarios para hacer el depósito de tus ganancias.
                    </p>
                    <p>
                        <b>Pago pendiente:</b> el depósito de este pago está pendiente.
                    </p>
                    <p>
                        <b>Pagada: </b> Hicimos el pago.
                    </p>
                    <p>
                        <b>Pagado-Ajuste:  </b>
                        Tu pago se ha realizado de acuerdo a la previa aclaración por:
                        <li className="ml-2">{"  "}Descuentos por extravío </li>
                        <li className="ml-2">{"  "}Descuento por penalizaciones en entrega tardía de guías</li>
                        <li className="ml-2">{"  "}Descuento por guías de Autoinducción</li>
                    </p>
                    <p>
                        <b>Pago Pendiente - BAJA:</b>Tu pago se encuentra pendiente debido a que decidiste darte de baja de la red, necesitamos nos contactes al siguiente correo: pagos_punto99@99minutos.com, para realizar la aclaración correspondiente y poder generar tu pago en el próximo periodo de corte.
                    </p>
                    <p>
                        <b>Pago Vencido:</b>Tu pago ha sido suspendido a falta de recepción de factura y constancia fiscal para su seguimiento. Es importante que compartas tu factura a tiempo  en cada periodo contable, o si se solicita documentación para el seguimiento del mismo, la compartas a tiempo. La cláusula de los términos y condiciones, estipula que los servicios prestados con una fecha anterior a los 3 (tres) meses de su gestión o incluídos a la fecha de emisión de la factura, 99 Minutos no estará obligada a pagar dicha factura ni dichos Servicios.
                    </p>
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo puedo ver la información de los paquetes que me están pagando?",
        children: (
            <React.Fragment>
                <p>
                Para ver los paquetes que recolectaste en el periodo da clic en "Ver detalles", dentro de "Mis ganancias". Se desplegará una pantalla donde podrás ver la información de tus paquetes y tus datos fiscales. En caso de que quieras descargar un reporte, selecciona el botón de "Excel" o "PDF". 📄                </p>
                <p>
                Esto iniciará la descarga de un archivo con la siguiente información:
                                </p>
                <p>
                    <li className="ml-2">{"  "}Folio fiscal</li>
                    <li className="ml-2">{"  "}Clave de rastreo de pago</li>
                    <li className="ml-2">{"  "}Fecha de recolección</li>
                    <li className="ml-2">{"  "}Fecha y hora de transacción de pago</li>
                    <li className="ml-2">{"  "}Número de guía 99minutos </li>
                    <li className="ml-2">{"  "}Fecha de recolección</li>
                    <li className="ml-2">{"  "}Cliente</li>
                    <li className="ml-2">{"  "}Tipo de entrega (Entrega a veloz o entrega a cliente)</li>
                    <li className="ml-2">{"  "}Ganancias </li>
                    <li className="ml-2">{"  "}Tipo de recolección </li>
                    <li className="ml-2">{"  "}Tipo de operación </li>

                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Las recolecciones de paquetes propios se pagan?",
        children: (
            <React.Fragment>
                <p>
                No, únicamente se pagan las recolecciones de paquetes externos. Para más información, puedes leer nuestros Términos y Condiciones que se encuentran dentro de la sección "Mi perfil" en la app. 📲                </p>
               
            </React.Fragment>
        )
    },
    {
        label: "¿Cuándo y a cuál correo debo enviar mi factura?",
        children: (
            <React.Fragment>
                <p>
                📅 El día 25 de cada mes es la fecha límite de envio de la factura para que podamos pagarte el día 10 del siguiente mes.
                </p>
                <br/>
                <p>
                📨 Debes enviar tu factura al correo pagos_punto99@99minutos.com.                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cuáles son sus datos fiscales para emitir mi factura?",
        children: (
            <React.Fragment>
                <p>📑 Estos son nuestros datos fiscales para generar tu factura:</p>
                <p>
                    <b>RFC receptora:</b> GPA120516LY0
                </p>
                <p>
                    <b>Nombre receptor:</b> Godo Parsa, S.A.P.I. de C.V.
                </p>
                <p>
                    <b>Régimen fiscal:</b> 601 GENERAL DE LEY PERSONAS MORALES
                </p>
                <p>
                    <b>Efecto de comprobante:</b> Ingreso
                </p>
                <p>
                    <b>Clave del producto y/o servicio:</b> 78131701
                </p>
                <p>
                    <b>Descripción:</b> SERVICIO DE ALMACENAJE AL 21 DEL (MES QUE SE ESTÁ FACTURANDO) 2024
                </p>
                <p>
                    <b>Moneda:</b> Peso mexicano
                </p>
                <p>
                    <b>Forma de pago:</b> Transferencia electrónica de fondos (incluye SPEI)
                </p>
                <p>
                    <b>Método de pago:</b> (Pago en parcialidades diferido) - complemento de pago
                </p>
                <p>
                    <b>USO CFDI:</b> Gastos en General
                </p>
                <p>
                    <b>Domicilio fiscal:</b> Av. presidente Masaryk 29, Intr. Piso 03, Col. Polanco V Sección, Alcaldía Miguel Hidalgo Cp. 11560, CDMX.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Qué pasa si no pude compartir mi factura a tiempo?",
        children: (
            <React.Fragment>
                <p>
                ❗Si no pudiste compartir tu factura antes del 25 del mes, deberás esperar al mes siguiente para poder generarla. La factura generada el mes siguiente podrá ser enviada desde el día 01 al 25 de ese mismo mes para ser pagada en el período más próximo.                </p>
                <p>
                    <br/>
                <i>Por ejemplo:</i> si no compartiste tu factura del 21 al 25 de marzo, deberás esperar al 01 de abril para poder generarla. Tu factura generada el 01 de abril podrás compartirla desde ese día y como límite al 25 de abril, para que el pago pendiente se realice el 10 de mayo.        
                </p>
                <p>
                <br/>
                <b>Importante:</b> 
                 si generas tu factura a partir del día 26 del mes a pagar, deberás cancelarla y generar una nueva a partir del dia 01 del mes siguiente. Te recomendamos que no generes facturas a partir del día 26 para evitar cancelaciones.                </p>
            </React.Fragment>
        )
    },
    {
        label: "Mi pago no me ha caído, ¿qué puedo hacer?",
        children: (
            <React.Fragment>
                <p>
                Puedes solicitar asistencia enviando un correo electrónico a atencion@punto99.mx o también usando el botón de asistencia de tu aplicación. <br/>
                <br/>
                📣 Si tienes alguna duda de cómo pedir asistencia, dirígete al apartado '¿Cómo puedo solicitar asistencia?'.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "Si no he compartido factura de ningún periodo, ¿qué sucede con mis gestiones de pago?",
        children: (
            <React.Fragment>
                <p>
                ❗Tus gestiones de pago pasarán a estar “Vencidas” y no podremos abonarte las ganancias correspondientes. La vigencia de los periodos es de un mes.
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Dónde puedo consultar mi cuenta bancaria para pagos?",
        children: (
            <React.Fragment>
                <p>
                Desde tu aplicacion puedes consultar y editar tu infromación en la sección del perfil "Datos bancarios". 📲 Podrás cosultar todos los datos bancarios proporcionados en tu afiliacion tales como: 
                    <li className="ml-2">{"  "}Beneficiario</li>
                    <li className="ml-2">{"  "}Cuenta CLABE interbancaria </li>
                    <li className="ml-2">{"  "}Banco receptor. </li>
                </p>
            </React.Fragment>
        )
    },
    {
        label: "¿Cómo puedo cambiar o actualizar mi cuenta bancaria?",
        children: (
            <React.Fragment>
                <p>
                Para que puedas cambiar o actualizar tus datos bancarios, es importante que nos envíes un email a <b> atencion@punto99.mx 📨</b> 📨 con:                    
                    <li className="ml-2">{"  "}Motivo del cambio </li>
                    <li className="ml-2">{"  "}Nombre del negocio</li>
                    <li className="ml-2">
                        {"  "}Caratula con datos bancarios a actualizar (como beneficiario, nueva cuenta CLABE y banco receptor){" "}
                    </li>
                    <br/>
                    📲 También lo puedes hacer a través del chabot Donatello en la app de Punto99.
                </p>
            </React.Fragment>
        )
    },

]