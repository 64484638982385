interface Props {
  percentage: number;
  spacing?: number;
}

const Divider = ({ percentage, spacing, ...props }: Props) => {
  return (
    <div
      className="divider"
      style={{ width: `${percentage}%`, margin: `${spacing}em` }}
      {...props}
    ></div>
  );
};

export default Divider;
