import React, {useEffect, useCallback} from "react";
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import Webcam from "react-webcam";

const BarcodeScannerComponent = ({
  onUpdate,
  onError,
  width = "100%",
  height = "100%",
  facingMode = "environment",
  webcamRef,
  delay = 500,
  videoConstraints,
  stopStream,
}: {
  onUpdate: (arg0: unknown, arg1?: Result, capturedImage?: string | null) => void;
  onError?: (arg0: string | DOMException) => void;
  width?: number | string;
  height?: number | string;
  facingMode?: "environment" | "user";
  torch?: boolean;
  delay?: number;
  videoConstraints?: MediaTrackConstraints;
  stopStream?: boolean;
  webcamRef?:any, 
}): React.ReactElement => {

  const capture = useCallback(() => {
    const codeReader = new BrowserMultiFormatReader();
    if(!webcamRef.current)return
    const image = webcamRef.current?.getScreenshot();
    if (image) {
      codeReader
        .decodeFromImage(undefined, image)
        .then((result) => {
          onUpdate(null, result, image);
        })
        .catch((err) => {
          onUpdate(err);
        });
    }
  }, [onUpdate]);


  useEffect(() => {
    if (stopStream) {
      let stream = webcamRef?.current?.video.srcObject;
      if (stream) {
        stream.getTracks().forEach((track: any) => {
          stream.removeTrack(track);
          track.stop();
        });
        stream = null;
      }
    }
  }, [stopStream]);

  useEffect(() => {
    const interval = setInterval(capture, delay);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ width, height, position:'relative'}} className="flex items-center justify-center text-center">
    <Webcam
      width={'100%'}
      height={'100%'}
      ref={webcamRef}
      screenshotFormat="image/png"
      forceScreenshotSourceSize
      minScreenshotHeight={200}
      minScreenshotWidth={250}
      screenshotQuality={1}
      imageSmoothing={false}
      videoConstraints={{
        facingMode,
      }}
      style={{
        height: "100%",
        width: "100%",
        objectFit: "fill",
       position: "absolute",
       backgroundColor:'black'
     }}
      audio={false}
      onUserMediaError={onError}
    />
    </div>
   
  );
};

export default BarcodeScannerComponent;