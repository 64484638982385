import moment from 'moment'
import RoundIconBtn from "../RoundIconBtn";
import RoundImage from "../RoundImage";

interface Props {
  driverPersonalData: string;
  deliveryDate?: string;
}

const ReturnedVeloz = ( {
                          driverPersonalData,
                          deliveryDate = "Pendiente",
                        }: Props ) => {
  let data = JSON.parse(driverPersonalData);
  const {
    driverName = "Nombre completo",
    driverPhoto = "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png",
    driverNickname = "Sin nombre de Veloz",
    driverPhoneNumber = "5539393631",
  } = data;


  return (
    <div className="returnPackageVeloz relative p-2">
      <p className="color-gray100 semi-bold">Veloz asignado</p>
      { deliveryDate !== 'Sin fecha estimada de llegada' && 
      <div className="flex items-center pt-0">
     
        <p >Llegará aprox. El &nbsp; </p> 
        <p className="bold color-green99  ">{ deliveryDate &&  moment(deliveryDate).format('DD/MM/yyyy ')} </p> &nbsp;
        <p className="bold color-batman  ">{ deliveryDate &&  moment(deliveryDate).format(' • HH:mm')   } </p>
         
      </div>
      }
      <div className="flex flex-col w-full">
        <div className="flex w-full p-1 items-center justify-evenly">
          <RoundImage  size={ 3 } image={ driverPhoto }/>
          <div className="flex flex-col w-8/12 ">
            <p className=" color-batman bold pl-2 mb-1">{ driverName } </p>
            <div className="flex w-full justify-start ">
            <div className="mx-2">
          <RoundIconBtn
            size={ 1.2 }
            image="../icons/icon_phone.svg"
            padding={ 0.3 }
            borderColor="var(--gray25)"
            onClick={ () => window.open(`tel:+52${ driverPhoneNumber }`) }
          />
        </div>
            <div className="mx-2">
          <RoundIconBtn
            size={ 1.2 }
            image="../icons/icon_message.svg"
            padding={ 0.3 }
            borderColor="var(--gray25)"
            onClick={ () => window.open(`sms://${ driverPhoneNumber }`) }
          />
        </div>
        <div className="mx-2">
          <RoundIconBtn
            size={ 1.2 }
            image="../icons/icon_chat.svg"
            padding={ 0.3 }
            borderColor="var(--gray25)"
            onClick={ () =>
              window.open(
                `//api.whatsapp.com/send?phone=52${ driverPhoneNumber }text=Hola,${ driverName }`
              )
            }
          />
        </div>
        
       
      </div>
          </div>
        </div>
      </div>
      

    </div>
  );
};

export default ReturnedVeloz;
