import Navigator from "./utils/Navigator";
import { AppWrapper } from "./context/AppWrapper";
import MultipleOrdersState from "./context/MultipleOrdersContext/state";
import {IntercomProvider} from "react-use-intercom";
// import MaintenanceModal from "./components/MaintenanceModal";

function App() {

  return (
    <>
      <IntercomProvider autoBoot appId={"jwd56hxv" || ""}>

      <AppWrapper>
        <MultipleOrdersState>
        {/* <MaintenanceModal/>/ */}
          
          <Navigator />
        </MultipleOrdersState>
      </AppWrapper>
      </IntercomProvider>
    </>
  );
}

export default App;
