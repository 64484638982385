import React from "react";

interface Props {
  image: string;
  size: number;
  borderColor?: string;
  alt?: string;
  shadow?: boolean;
  onClick?: (event?: any) => void;
  padding?: number;
  backgroundColor?: string;
  objectFit?: boolean
}

const RoundImage = ({
  image,
  size,
  borderColor = "none",
  alt = "image",
  shadow = false,
  backgroundColor = "none",
  onClick,
  padding = 0,
  objectFit = true
}: Props) => {
  let cursorType = onClick ? "pointer" : "auto";
  let isShadow = shadow
    ? " 0px -1px 21px -3px rgba(151, 151, 151, 0.25)"
    : "none";
  return (
    <>
      <img
        onClick={onClick}
        src={image}
        alt={alt}
        className="rounded-pic"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src='https://catking.in/wp-content/uploads/2017/02/default-profile-1.png'
        }}
        style={{
          width: `${size}em`,
          height: `${size}em`,
          cursor: `${cursorType}`,
          border: `1px solid ${borderColor} `,
          padding: `${padding}em`,
          objectFit: `${objectFit ? "contain" : "cover"}`,
          boxShadow: isShadow,
          backgroundColor: backgroundColor,
        }}
      />
    </>
  );
};

export default RoundImage;
