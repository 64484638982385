type Font = "bold" | "semi-bold" | "normal";
interface Props {
  icon?: string;
  label?: string;
  labelColor?: string;
  labelWeight?: string;
  labelDecoration?: string;
  padding?: string | number;
  text?: string;
  textColor?: string;
  textDecoration?: string;
  textWeight?: Font;
  input?: any;
  opciones: number[] | string[];
  placeholder?: string;
  setInput?: any;
  edit?: boolean;
}

const IconLabelSelect = ({
  icon,
  label,
  labelColor,
  labelWeight,
  labelDecoration,
  padding,
  text,
  textDecoration,
  edit,
  textColor = "#12344a",
  textWeight = "normal",
  opciones,
  input,
  setInput,
  placeholder = "Seleciona una opción...",
}: Props) => {
  return (
    <div className="w-full">
      <div className="flex items-center">
        {/* only shows image if there is an icon props */}
        {icon && <img className="icon" src={icon} alt={text} />}
        <strong
          className={`${labelWeight} color-batman`}
          style={{
            color: labelColor,
            textDecoration: labelDecoration,
          }}
        >
          {label}
        </strong>
      </div>
      {/* show text if edit=true, if false then show input */}
      {edit ? (
        <div className="flex items-center">
          <select
            name={label}
            className={`input-underline-gray input pl-5`}
            onChange={(e) => setInput(e.target.value)}
          >
            <option value={input ? input : placeholder}>
              {input ? input : placeholder}
            </option>
            {opciones.map((option, i) => (
              <>
                <option key={i + 123} value={option}>
                  {option}
                </option>
              </>
            ))}
          </select>
        </div>
      ) : (
        <p
          className={`flex flex-col my-025 ${textWeight}`}
          style={{
            color: textColor,
            textDecoration: textDecoration,
            paddingLeft: `${padding}em`,
          }}
        >
          {text}&nbsp;
        </p>
      )}
    </div>
  );
};

export default IconLabelSelect;
