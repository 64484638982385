import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, BlackDot } from "../assets/icons";
import BackgroundShape from "../components/BackgroundShape";
import CustomButton from "../components/CustomButton";
import MultipleOrdersContext from "../context/MultipleOrdersContext/context";
import MultpleOrdersContext from "../context/MultipleOrdersContext/context";
import { getOrderInfo } from "../utils/requests";

function MultipleOrders() {
  const { cleanState } = useContext(MultipleOrdersContext);
  const { push } = useHistory();
  const goBack = () => {
    cleanState();
    push("/home");
  };
  const continueWithOrder = async () => {
    if (selectedOrder !== null) {
      const { error, message } = await getOrderInfo(
        selectedOrder.external_reference
      );
      if (error) {
        alert(message || "Hubo un error seleccionando tu orden");
        return;
      }
      push("/confirm-recollect", {
        orderInfo: selectedOrder,
        imageURL: "",
        orderNumber: selectedOrder.external_reference,
      });
    }
  };
  const {
    state: { multipleOrders, selectedOrder },
    selectOrder,
  } = useContext(MultpleOrdersContext);
  const localSelectOrder = (idx) => {
    selectOrder(idx);
  };
  return (
    <div className="w-screen h-screen overflow-y-hidden">
      <BackgroundShape type="quarter" position="bottom" />
      <div className="w-full h-10/12 px-5 flex flex-col">
        <div onClick={goBack} className="py-8 xs:py-4" id="back-button">
          <ArrowRightIcon rotate={"90"} />
        </div>
        <div className="flex flex-col items-center">
          <span className="text-xl font-semibold mb-2 text-green-100">
            Selecciona la orden correcta
          </span>
          <BlackDot />
          <span className="mt-2 text-md text-gray-200 font-light text-center">
            Hay varias ordenes con el mismo numero de guia, por favor selecciona
            la correcta para continuar
          </span>
        </div>
        <div className="w-full h-2/3 py-3 overflow-y-auto px-2">
          {multipleOrders.map((order, idx) => {
            const { external_reference, package_size, deliver_to, client_id } =
              order;
            return (
              <div
                onClick={() => {
                  localSelectOrder(idx);
                }}
                style={{ borderWidth: order?.isSelected ? "3px" : "1px" }}
                className={`flex flex-col mb-5 px-3 bg-white py-2 rounded-lg shadow-xl ${
                  order?.isSelected
                    ? "border-gray-200 border-opacity-65"
                    : "border-gray-900"
                }`}
              >
                <div className="w-full flex flex-row my-2">
                  <div className="w-5/12">
                    <span className="text-sm font-semibold text-green-100">
                      Número de guía:
                    </span>
                  </div>
                  <div className="w-7/12">
                    <span className="text-gray-200 text-sm">
                      {external_reference}
                    </span>
                  </div>
                </div>
                <div className="w-full flex flex-row my-2">
                  <div className="w-5/12">
                    <span className="text-sm font-semibold text-green-100">
                      Tamaño del paquete:
                    </span>
                  </div>
                  <div className="w-7/12">
                    <span className="text-gray-200 text-sm">
                      {package_size}
                    </span>
                  </div>
                </div>
                <div className="w-full flex flex-row my-2">
                  <div className="w-5/12">
                    <span className="text-sm font-semibold text-green-100">
                      Entregar a:
                    </span>
                  </div>
                  <div className="w-7/12">
                    <span className="text-gray-200 text-sm">{deliver_to}</span>
                  </div>
                </div>
                <div className="w-full flex flex-row my-2">
                  <div className="w-5/12">
                    <span className="text-sm font-semibold text-green-100">
                      Quien envia:
                    </span>
                  </div>
                  <div className="w-7/12">
                    <span className="text-gray-200 text-sm">{client_id}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-2/12 flex flex-col items-center justify-center">
        <CustomButton
          className="my-1"
          onClick={continueWithOrder}
          disabled={selectedOrder === null ? true : false}
          text="Continuar"
          type="white"
        />
        <CustomButton
          className="my-1"
          onClick={() => push("/home")}
          text="Cancelar"
          type="white"
        />
      </div>
    </div>
  );
}

export default MultipleOrders;
