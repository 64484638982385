import { useState } from "react";
import { useHistory } from "react-router-dom";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { ArrowRightIcon, BlackDot } from "../assets/icons";
import { saveEvidence } from "../utils/requests";

function ScannerReturnPage() {
  const { goBack, push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [smsCode, setSmsCode] = useState("");

  const [data, setData] = useState("");

  const captureVideo = (video: any) => {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var canvasContext = canvas.getContext("2d");
      //@ts-ignore
      canvasContext.drawImage(video, 0, 0);
      return canvas.toDataURL("image/png");
    } catch (e) {
      console.log(e);
    }
  };

  const scanSuccessful=()=>{
    const audio = new Audio('/Sonido2.mp3');
    audio.play()
        
    if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }
    window.navigator.vibrate(300);
  }

  const onScan = (result: any, error: any, e: any) => {
    if (error) {
      return;
    }
    const getFrame = captureVideo(document.getElementById('qrreader'))
    setLoading(true);
    scanSuccessful()
    setData(result)
    setSmsCode(result)
    saveEvidence({ type: 'return', orderNumber: result.getText(), image: getFrame })
    push('/confirm-return')
    setLoading(false);

  };

  return (
    <div className="h-screen w-screen gradient-green overflow-y-hidden fixed">
      <div
        onClick={goBack}
        className="px-4 py-8 xs:px-2 xs:py-4"
        id="back-button"
      >
        <ArrowRightIcon rotate={"90"} tint={"white"} />
      </div>
      <div className="h-full w-screen px-8 xs:px-4">
        <div className="flex flex-col items-center justify-center">
          <span className="Vxxs:text-md font-bold text-xl xs:text-lg text-white mb-3">
            Estás realizando una devolución
          </span>
          <div className=" Vxxs:hidden">
          <BlackDot />
          </div>
        </div>
        <div className="flex mt-24 xs:mt-8 items-center justify-center">
          <span className="text-center text-white">
            Escanea el código
            <span className="font-bold"> QR del paquete</span>
          </span>
        </div>
        <div className="flex items-center justify-center w-full mt-10 Vxxs:mt-3">
          <div className="content sm:w-100 sm:h-52  md:h-72 h-48 w-72  Vxxs:w-4/6 Vxxs:h-30	 relative bg-white flex items-center justify-center" style={{backgroundColor:'white', borderRadius:'10px'}}>
          <div style={{ width: '101%', height: '101%' }} className="absolute z-10">
            </div>
            {loading ?
            <div className={`bg-white absolute w-full h-full flex items-center justify-center  `}>
              <div className="lds-dual-ring"></div>
            </div>:
            <QrReader
              videoId={"qrreader"}
              onResult={onScan}
              containerStyle={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              videoStyle={{width: '300%', height: '100%',objectFit: 'fill' }}
              videoContainerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%',  objectFit: 'fill',  padding:'0'}}
              constraints={{ facingMode: "environment" }}
              scanDelay={100}
            />}
            </div>
          </div>

        <div className="flex flex-col w-full justify-center items-center mt-3 Vxxs:mt-2">
          <span className="Vxxs:text-sm text-lg font-thin text-white">
            Enfoca el código QR en el recuadro
          </span>
        </div>
      </div>
    </div>
  );
}

export default ScannerReturnPage;
