// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ArrowRightIcon, CloseIcon } from "../../assets/icons";
import BackgroundShape from "../../components/BackgroundShape";
import ProfileSection from "../../components/HomePage/ProfileSection";
import { ContextWrapper } from "../../context/AppWrapper";
import ReturnedVeloz from "../../components/PackagesPage/ReturnedVeloz";
import { getContainersList} from "../../utils/requests";
import EvidenceModal from "../../components/PackagesPage/EvidenceModal";
import AgaveButton from "../../components/AgaveButton";
import { DeliveredBags, RecolectedBags } from "../../components/ExchangePoint";
import { toast } from "react-toastify";
import { formatDate, formatDateWithoutTime } from "../../utils/functions";
import ToReceiveBags from "../../components/ExchangePoint/ToReceiveBags";

type ActivePackage = {
  id: string;
  dispatch_service: string;
  assignment?: any;
  deliver_to: string;
  order_status_id: string;
  created_at: string;
  profits: number;
  evidences?: any[];
  packages: []
};

function PackagesPage() {
  const { goBack } = useHistory();
  const { userData, userToken } = useContext(ContextWrapper);
  const [ user ] = userData;
  const [ token ] = userToken;
  const [ activeTab, setActiveTab ] = useState(2);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isSiderOpen, setIsSiderOpen ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ evidenceUrl, setEvidenceUrl ] = useState('');
  const [ isOpenEvidenceModal, setIsOpenEvidenceModal ] = useState(false);
  const [ localPackages, setLocalPackages ] = useState({total:0, pages:1, data:[]});
  const [ deliverPackages, setDeliverPackages ] = useState({total:0, pages:1, data:[]});
  const [ toReceivePackages, setToReceivePackages ] = useState({total:0, pages:1, data:[]});

  const [ activePackage, setactivePackage ] = useState<ActivePackage>({
    id: "1234567890",
    assignment: null,
    dispatch_service: "...",
    deliver_to: "...",
    order_status_id: "...",
    created_at: "...",
    profits: 15,
    packages: []
  });



  const setPackages = async (
    type: "local" | "deliver" | "toReceive",
    page?: number,
    pageSize?:number
  ) => {
    
    const{data, error, meta}= await getContainersList(
      user.current_dispatch_place.id, page, pageSize, type==='local', type==='deliver', false, type==='toReceive',
    )
    return {data, error, meta}
  };

  const handlePageChange=async (e, tab=activeTab)=>{
    setLoading(true)

    const {data, meta, error}=await setPackages(tab === 0 ? 'local': tab===1 ? 'deliver': 'toReceive', e)
    if(error){
      toast.error('503: No se pudo recuperar la información')
      return 
    }

    tab === 0
    ? setLocalPackages({...localPackages,total:meta.total, pages:meta.last_page, current_page:meta.current_page-1, data, date:data.filter(o=>o.collected_at)[0]?.collected_at})
    : tab === 1 
    ? setDeliverPackages({...deliverPackages,total:meta.total, pages:meta.last_page, current_page:meta.current_page-1,  data, date:data.filter(o=>o.delivered_at)[0]?.delivered_at})
    : setToReceivePackages({...toReceivePackages,total:meta.total, pages:meta.last_page, current_page:meta.current_page-1,  data})
    setLoading(false)
    console.log(data );

  }

  const initialPagination = async (
  ) => {
    setLoading(true)
    //get 
    const  { data,  error, meta}= await setPackages('local', 1);
    
    if(error){
      toast.error('503: No se pudo recuperar la información "En tu local"')
      return 
    }
    const  { data:deliver,  error:err, meta:metaDeliver}= await setPackages('deliver', 1);
    if(err){
      toast.error('503: No se pudo recuperar la información')
      return 
    }
    
    const  {  data:receive, meta: metaReceive}= await setPackages('toReceive', 1);
        setDeliverPackages({...deliverPackages,total:metaDeliver.total, pages:metaDeliver.last_page, current_page:0, date:deliver.filter(o=>o.delivered_at)[0]?.delivered_at})
        setLocalPackages({...localPackages,total:meta.total, pages:meta.last_page, data,  current_page:0, date:data.filter(o=>o.collected_at)[0]?.collected_at})
        setToReceivePackages({...toReceivePackages,total:metaReceive.total, pages:metaReceive.last_page, data:receive,  current_page:0})
        setLoading(false)
    };

  //initial render
  useEffect(() => {
    if (user) {
      initialPagination()
    }
  }, [ user ]);




  const statusByCode: { [index: number]: any } = {
    1: "En mi local",
    2: "En mi local",
    3: "Entregada",
  };

  const getColorByStatus = ( status: string ) => {
    return activeTab === 0
      ? "purple-100"
      : activeTab === 1
        ? "green-400"
        : "yellow-200";
  };

  const getFlagColorByStatus = ( status: string ) => {
    const colors: { [key: string]: string } = {
      'A tiempo': 'green-200',
      'Demorado': 'red-200',
    }
    return colors[status] || 'yellow-200'
  }

//pedir logs
  const getStatusSpanish = ( status: string ) => {
    
    const log: { [key: string]: string } = {
      'COLLECTION': 'Recolectado',
      'RELEASE': 'Entregado'
    }
    return log[status] || 'Estatus no reconocido'
  }


  const changeTab = ( tab: number ) => {
    setIsOpen(false);
    setActiveTab(tab);
  };


  return (
    <>
      <div
        className={ ` h-screen w-screen overflow-y-visible fixed"
        }` }
      >
        <EvidenceModal
          // evidenceUrl={evidenceUrl}
          modal={ isOpenEvidenceModal }
          setModal={ setIsOpenEvidenceModal }
        >
          <div className="flex-col  w-11/12 flex justify-center items-center mx-10 mt-10 mb-5">
            <strong>
              Evidencia de saco: { activePackage.tag }
            </strong>
            <div>
              <img src={ evidenceUrl } alt={'evidencia'} style={{maxHeight:'40vh'}}/>
            </div>
          </div>
        </EvidenceModal>
        <BackgroundShape type="small" position="bottom"/>
        <div
          style={ {
            transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
            height: "min-content",
            zIndex: 6,
            overflowY: 'auto',
            maxHeight: '90vh'
          } }
          className={ `absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green mx-auto right-0 md:w-10/12 lg:w-5/12 ${
            isOpen ? "slide-open" : "slide-close"
          }` }
        >
          <button
            onClick={ () => setIsOpen(false) }
            className="flex w-full pt-5 pr-5 justify-end"
          >
            <CloseIcon/>
          </button>

          <div className="flex flex-col items-center px-10">
            { activeTab === 1 && (
              <ReturnedVeloz
                driverPersonalData={ JSON.stringify({
                  driverName: activePackage?.veloz?.name,
                  driverPhoto: "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png",
                  driverNickname: activePackage?.veloz?.user,
                  driverLicense: "N/A",
                  driverPhoneNumber: activePackage?.veloz?.phone,
                } || {}) }
                deliveryDate={
                  activePackage?.assignment?.eta
                    ? activePackage?.assignment?.eta
                    : "Sin fecha estimada de llegada"
                }
              />
            ) }
            <span className="text-white font-semibold mb-2">
              Detalle del saco
            </span>
            <div className="flex flex-col w-full">
              <div className="flex flex-row">
                <span className="flex flex-row items-center w-2/4 text-white text-sm font-semibold">
                  Tag del saco
                </span>
                <span className="text-white text-lg font-thin">
                  { activePackage.tag }
                </span>
              </div>
             
            </div>
            {activeTab===1 &&
            <div className="flex flex-col w-full mt-2">
              <div className="flex flex-row">
                <span className="flex flex-row items-center w-2/4 text-white text-sm font-semibold">
                  No de paquetes
                </span>
                <span className="text-white text-lg font-thin">
                  { activePackage.orders?.length || 0}
                </span>
              </div>
            </div>
          }
            <div className="w-full bg-white my-5 px-3 py-1 rounded-md">
              <div className="flex flex-row w-full border-gray-1700 border-b">
                <div className="w-1/3 px-3 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-sm">
                    Estado
                  </span>
                </div>
                <div className="px-3 py-2 ">
                  <div
                    className={ `px-4 py-1 rounded-md bg-opacity-15 bg-${ getColorByStatus(
                      statusByCode[activePackage.order_status_id]
                    ) }` }
                  >
                    <span
                      className={ `bold text-${ getColorByStatus(
                        statusByCode[activePackage.order_status_id]
                      ) }` }
                    >
                      { activeTab === 0
                        ? "En mi local"
                        : activeTab === 2
                          ? "A recibir"
                          : activePackage.order_status_id===5 ? "Entregado sin liberación":"Entregado" }
                    </span>
                  </div>
                </div>
              </div>
              { activeTab === 0 && (
                <div className="flex flex-row w-full border-gray-1700 border-b">
                  <div className="w-1/3 px-3 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-sm">
                    Estado de entrega
                  </span>
                  </div>
                  <div className="px-3 py-3 flex items-center justify-center">
                    <div
                      className={ `rounded-lg px-4 py-1 bg-opacity-15 bg-${ getFlagColorByStatus(activePackage.collected_status) }` }>
                  <span className={ `bold text-${ getFlagColorByStatus(activePackage.collected_status) }` }>
                    { activePackage.collected_status }
                  </span>
                    </div>
                  </div>
                </div>
              ) }
              { activeTab === 2 &&
              <div className="flex flex-row w-full border-gray-1700 border-b">
                <div className="w-1/3 px-3 py-3 border-gray-1700 border-b border-r">
                  <span className="text-gray-800 font-semibold text-sm ">
                    Cantidad de paquetes
                  </span>
                </div>
                <div className="px-3 py-3 flex items-center">
                  <span className="text-gray-800 bold text-md ">
                    {activePackage.orders?.length || 0}
                  </span>
                </div>
              </div>
              }
              <div className="flex flex-row w-full">
                <div className="w-2/3 px-3 py-3 border-gray-1700 border-r">
                  <span className="text-gray-800 font-semibold text-sm ">
                    Fecha de { activeTab === 0 ? 'recoleccion' :activeTab === 1? 'entrega' :'recepción'}
                  </span>
                </div>
                <div className="px-3 py-3 flex items-center">
                  <span className="text-gray-800 font-thin text-md ">
                   { activeTab === 0 ? formatDateWithoutTime(activePackage?.collected_at) : activeTab === 1 ? formatDateWithoutTime(activePackage?.delivered_at) :  formatDateWithoutTime(activePackage.assigned_at || activePackage.collected_at) }
                  </span>
                </div>
              </div>
            </div>
            { activeTab !== 2 &&
            
            <div className="my-5 w-full">
              <span className="text-white text-lg font-semibold">
                Evidencias:
              </span>
              { activePackage.evidences?.length > 0 ? (
                activePackage.evidences?.map(( evidence ) => {
                  
                  return (
                    <div className="w-full my-3 flex flex-row justify-between items-center">
                      <span className="text-white">{ getStatusSpanish(evidence.type) }</span>
                      <AgaveButton type="white" text="Ver evidencia" onClick={ () => {
                        setEvidenceUrl(evidence.url);
                        setIsOpenEvidenceModal(true)
                      } }/>
                    </div>
                  )
                })
              ) : <span className="text-white ml-3">Aún no hay evidencias.</span> }
            </div>
            }
          </div>
        </div>
        <div id="back-button" className=" mt-5 ml-5">
          <div onClick={ goBack }>
            <ArrowRightIcon rotate={ "90" }/>
          </div>
        </div>
        <div className="px-3">
          <div id="body-pakcages">
            <div className="flex  justify-between items-center mt-1 ml-4 mr-2 mb-8">
              <ProfileSection
                token={ token }
                title={ "Mis sacos" }
                picture={
                  !user || user.avatar_url === "NA"
                    ? "https://catking.in/wp-content/uploads/2017/02/default-profile-1.png"
                    : user.avatar_url
                }
                open={ isSiderOpen }
                setOpen={ setIsSiderOpen }
              />
            </div>

            <div className="flex flex-row w-full h-auto mt-7  justify-evenly md:w-96 m-auto">
            <div
                onClick={ async() => {
                  changeTab(2)
                  await handlePageChange(1,2)
                }}
                className={ `flex flex-col h-26 w-26 px-2 bg-white justify-center border-2 ${
                  activeTab === 2 ? "border-yellow-200" : "border-white"
                } rounded-md shadow-lg` }
              >
                <span className="text-sm font-semibold text-yellow-200">
                  A recibir
                </span>
                <span className="text-xl font-bold">{ toReceivePackages.total }</span>
              </div>

              <div
                onClick={ async() => {
                  changeTab(0)
                  await handlePageChange(1,0)
                }}
                className={ `flex flex-col h-26 w-26 px-2 bg-white justify-center border-2 ${
                  activeTab === 0 ? "border-purple-100" : "border-white"
                } rounded-md shadow-lg` }
              >
                <span className="text-sm font-semibold text-purple-100">
                  En mi local
                </span>
                <span className="text-xl font-bold">{ localPackages.total }</span>
              </div>
              <div
                onClick={async () => {
                  changeTab(1)
                  await handlePageChange(1,1)
                } }
                className={ `flex flex-col h-26 w-26 px-2 bg-white justify-center border-2 ${
                  activeTab === 1 ? "border-green-400" : "border-white"
                } rounded-md shadow-lg` }
              >
                <span className="text-sm font-semibold text-green-400">
                  Entregados
                </span>
                <span className="text-xl font-bold text-black">
                  { deliverPackages.total }
                </span>
              </div>
            </div>

            <div className="w-full flex flex-col h-auto items-center justify-center mt-7">
              { {
                0: <RecolectedBags lastPackage={ localPackages.date }/>,
                1: <DeliveredBags lastPackage={ deliverPackages.date }/>,
                2: <ToReceiveBags />

                // 2: <ReturnedPackages lastPackage={onDeliverPackages[0]} />,
              }[activeTab] || <div>No reached</div> }
            </div>
          </div>
        </div>
        {activeTab === 0 && 
        <div className="text-center w-10/12 mx-auto border border-gray-1700 my-3 py-3">
          <b className="semi-bold color-batman leading-3	">
          Para entregar un saco a un veloz, primero deberás escanear su etiqueta y tomar una fotografía de su evidencia
          </b>
        </div>}
        <div
          style={ {
            // height: `calc(100vh - ${bodyHeight}px - ${backButtonHeight}px - 1.45rem*3)`,
            height: "45vh",
          } }
          className=" bg-white mt-5 border border-gray-1700 overflow-y-auto w-10/12 md:w-96 mx-auto"
        >
          { !loading?
          <>
          { (activeTab === 0
              ? localPackages.data 
              : activeTab === 1?
              deliverPackages.data 
              : toReceivePackages.data
          ).map(( bag: any, idx ) => {
            return (
              <>
              {!bag.empty?
              
              
              <div
                onClick={ () => {
                  setactivePackage({
                    ...bag,
                    evidences:bag.evidences
                    
                  })
                  setIsOpen(true);
                } }
                className="flex flex-row items-center justify-between px-3 py-3 border-b border-gray-1700"
              >
              <>
                <span className="font-extralight text-base text-gray-1800 w-7/12">
                  { idx + 1 }
                  <span className="text-gray-1900 font-light ml-2">
                    { bag.tag }
                  </span>
                </span>
                { (activeTab === 0 && activeTab!== 1) ? (
                  <>
                    <div className={ `rounded-lg px-4 py-1 bg-opacity-15 bg-${ getFlagColorByStatus(bag.collected_status) }` }>
                      <span className={ `bold text-${ getFlagColorByStatus(bag.collected_status) }` }>
                        { bag.collected_status }
                      </span>
                    </div>
                  </>
                  )
                  :(activeTab!== 1)&&
                  <>
                  <div className={ `rounded-lg px-4 py-1 bg-opacity-15 bg-${ getFlagColorByStatus('A recibir') }` }>
                    <span className={ `bold text-${ getFlagColorByStatus('A recibir')}` }>
                       A recibir
                    </span>
                  </div>
                </>
                  }
                </>
              </div>
            : 
            <div className="w-full flex justify-center my-4 bold color-gray50">
              No se encontraron sacos para mostrar.
            </div>
          }
            </>
            );
          })
           }

          </>
         :<div className="w-full flex justify-center">
          <div className="lds-dual-ring"/>
        </div>

        }  
        </div>
        <div
          style={ { paddingLeft: "8.333333%", paddingRight: "8.333333%" } }
          className="w-full"
        >
          <ReactPaginate
            previousLabel={ "" }
            nextLabel={ "" }
            breakLabel={ "..." }
            breakClassName={ "break-me" }
            pageCount={ 
              activeTab === 0
              ? localPackages.pages
              :  activeTab === 1? deliverPackages.pages
              : toReceivePackages.current_page
            }
            marginPagesDisplayed={ 2 }
            pageRangeDisplayed={ 2 }
            onPageChange={ ( e ) => {
              handlePageChange(e.selected + 1 )
            } }
            containerClassName={ "pagination-bottom mt-0 w-10/12 md:w-96 mx-auto" }
            // @ts-ignore
            subContainerClassName={ "pages pagination" }
            activeClassName={ "active" }
           forcePage={
            activeTab === 0
            ?  localPackages.current_page: activeTab === 1?
             deliverPackages.current_page: toReceivePackages.current_page
           }
          />
        </div>
      </div>
    </>
  );
}

export default PackagesPage;
