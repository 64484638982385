import React from "react";

interface Props {
  setInput: any;
  input: string;
  edit: any;
}

const PointType = ({ setInput, input, edit }: Props) => {
  let serviceType = "Entrega a veloz  a cliente";
  if (input === "DELIVERY_RECOLLECTION") {
    serviceType = "Entrega a veloz y a cliente";
  } else if (input === "DELIVERY_ONLY") {
    serviceType = "Entrega a cliente";
  } else {
    serviceType = "Entrega a veloz";
  }

  return (
    <>
      <div className="flex">
        <img className="icon" src="../icons/icon_direccion.svg" alt="icon" />
        <strong className={`bold color-batman`}>
          Tipo de servicio de punto99
        </strong>
      </div>
      {edit ? (
        <div className="flex mt-2 ml-5 justify-start items-center flex-wrap">
          <div className="inputRadioContainer flex justify-start items-center my-1">
            <input
              className="inputRadio"
              type="radio"
              id="DELIVERY_ONLY"
              name="DELIVERY_ONLY"
              onChange={() => setInput("DELIVERY_ONLY")}
              checked={input === "DELIVERY_ONLY"}
            />
            <label htmlFor="DELIVERY_ONLY" className="inputRadio">
              Entrega a cliente
            </label>
          </div>
          <div className="inputRadioContainer flex justify-start items-center  my-1">
            <input
              className="inputRadio"
              type="radio"
              id="RECOLLECTION_ONLY"
              name="RECOLLECTION_ONLY"
              onChange={() => setInput("RECOLLECTION_ONLY")}
              checked={input === "RECOLLECTION_ONLY"}
            />
            <label htmlFor="RECOLLECTION_ONLY" className="inputRadio">
              Entrega a veloz
            </label>
          </div>
          <div className="inputRadioContainer flex justify-start items-center  my-1">
            <input
              className="inputRadio"
              type="radio"
              id="DELIVERY_RECOLLECTION"
              name="DELIVERY_RECOLLECTION"
              onChange={() => setInput("DELIVERY_RECOLLECTION")}
              checked={input === "DELIVERY_RECOLLECTION"}
            />
            <label htmlFor="DELIVERY_RECOLLECTION" className="inputRadio">
              Entrega a veloz y a cliente
            </label>
          </div>
        </div>
      ) : (
        <p
          className={`flex flex-col my-1 normal`}
          style={{
            color: "var(--gray100)",
            paddingLeft: `1.8em`,
          }}
        >
          {serviceType}
          &nbsp;
        </p>
      )}
    </>
  );
};

export default PointType;
