// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  BlackDot,
  CheckIcon,
  CloseIcon,
} from "../../assets/icons";
import BackgroundShape from "../../components/BackgroundShape";

function ConfirmDeliver() {
  const { goBack, push, location } = useHistory();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  const confirmDeliver = async () => {
    setLoading(true);
    // const { error, message, data } = await deliverBagApi(location?.state?.containerID, location?.state?.evidenceUrl)
    // if (error) {
    //   setLoading(false)
    //   toast.error(message)
    //   return
    // }
    setConfirm(true)
    setLoading(false)
    toast.success("Saco entregado correctamente!");
  };

  useEffect(() => (
    confirmDeliver()
  ), [])

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setReason(e.target.value);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className="h-screen w-screen overflow-y-hidden fixed">
        <div
          style={{
            transition: "all 0.4s cubic-bezier(0.34, -0.46, 0.42, 1.36) 0s",
            height: "min-content",
            zIndex: 6,
          }}
          className={`absolute rounded-t-xl left-0 bottom-0 flex flex-col w-full gradient-green ${isOpen ? "slide-open" : "slide-close"
            }`}
        >
          <div
            onClick={() => setIsOpen(false)}
            className="flex w-full pt-5 pr-5 justify-end"
          >
            <CloseIcon />
          </div>

          <div className="flex flex-col items-center px-10">
            <span className="font-bold text-white text-lg text-center">
              Motivo por el que no aceptó
              <br />
              su paquete
            </span>
            <textarea
              value={reason}
              onChange={onChange}
              className="w-full rounded-lg h-32 px-4 py-2 mt-5"
              placeholder="Escribe el motivo"
            />
            <div
              // onClick={()}
              className="flex items-center justify-center my-5 px-4 py-2 w-2/3 bg-white rounded-full"
            >
              <span className="text-lg text-center text-green-100 font-medium">
                Solicitar devolución
              </span>
            </div>
          </div>
        </div>

        <BackgroundShape type={"quarter"} position="bottom" />
        <div className="flex flex-col justify-between h-full w-full">
          <div>
            {!confirm && (
              <>
                <div className="flex w-full justify-center mt-20">
                  <span className="text-xl text-center font-semibold text-green-100">
                    Entregando
                    <br />
                    <span className="text-gray-200">un saco...</span>
                  </span>
                </div>
                <div className="flex items-center justify-center w-full mt-2">
                  <BlackDot />
                </div>
                <div className="flex items-center justify-center mt-7 w-full">
                  <span className="text-md text-gray-200 font-light text-center">
                    Esto puede demorar
                    <br />
                    un poco, gracias por esperar.
                  </span>
                </div>
              </>
            )}
            {confirm ? (
              <div className="flex flex-col items-center w-full px-10 mt-32">
                <div>
                  <CheckIcon color="rgb(133, 196, 64)" />
                </div>
                <div className="font-bold text-green-100">
                  ¡Gracias por entregar el saco!
                </div>
                <div className="font-bold text-lg text-green-100 text-center mt-5">
                </div>
              </div>
            ) : (
              <div className="w-full flex mt-10 items-center justify-center">
                <div className="lds-dual-ring" />
              </div>
            )}
          </div>

          <div className="flex flex-col w-full items-center">
            {confirm ? (
              <>
                <div className="flex flex-col items-center w-full mb-12">
                  <div
                    onClick={() => push("/exchange-point/home")}
                    className="flex items-center justify-center px-4 py-2 w-2/3 bg-white rounded-full"
                  >
                    <span className="text-lg text-green-100 font-semibold">
                      Finalizar
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>

              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeliver;
